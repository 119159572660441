import {Form, Formik} from "formik";
import * as yup from "yup";
import styles from "./registerForm.module.scss";
import {useTranslation} from "react-i18next";
import Button from "../../../../common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Api, {ApiError} from "../../../../classes/Api";
import {useContext, useState} from "react";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";
import AuthContext from "../../../../context/AuthContext";
import {useHistory} from "react-router-dom";
import classNames from "classnames";

interface RegisterFormProps {
    widget?: boolean
}

export function RegisterForm(props: RegisterFormProps): JSX.Element {
    const {t} = useTranslation();
    const {authenticate} = useContext(AuthContext);
    const history = useHistory();

    const [error, setError] = useState<string | null>(null);
    const [creating, setCreating] = useState(false);
    const [created, setCreated] = useState<boolean | null>(null);

    const schema = yup.object({
        firstname: yup.string().required(t("Le prénom doit être complété")),
        lastname: yup.string().required(t("Le nom doit être complété")),
        email: yup
          .string()
          .email(t("Veuillez entrer une adresse mail valide"))
          .required(t("L'adresse mail doit être complétée")),
        password: yup.string().required(t("Le mot de passe doit être complété")).min(6, t("Le mot de passe doit être de 6 caractères minimums."))
    });

    return <Formik
      validationSchema={schema}
      onSubmit={(values) => {
          setError(null);
          setCreating(true);

          Api.post("/users", {
              email: values.email,
              password: values.password,
              first_name: values.firstname,
              last_name: values.lastname
          }, {
              credentials: false,
              includeToken: false,
              ignoreAuthState: true
          }).then(() => {
              if (props.widget) {
                  setCreated(true);
                  return;
              }

              authenticate(values.email, values.password).then(() => {
                  history.push("/");
                  notifySuccess(t("Bienvenu chez Kamat"));
              }).catch(() => {
                  setCreating(false);
                  notifyError(t("Une erreur est survenue lors de la création de la session. Essayez de vous connectez avec les informations que vous avez saisies."));
              });
          }).catch((r ?: ApiError[]) => {
              setCreating(false);

              if (r !== undefined && r.length === 1) {
                  const error = r.shift();

                  if (error !== undefined) {
                      if (error.extensions.code === "RECORD_NOT_UNIQUE") {
                          setError(t("L'adresse mail saisie est déjà utilisée."));
                          return;
                      } else if (error.extensions.code === "FAILED_VALIDATION" && error.extensions?.field === "password") {
                          setError(t("Le mot de passe doit être de 6 caractères minimums."));
                          return;
                      }
                  }
              }

              if (props.widget) {
                  setCreated(false);
                  setError(t("Une erreur est survenue lors de la création du compte..."));
                  return;
              }

              notifyError(t("Une erreur est survenue lors de la création du compte..."));
          });
      }}
      initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: ""
      }}
    >
        {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              isValid,
              errors,
          }) => (
          <Form
            onSubmit={handleSubmit}
            className={styles.form}
          >
              {props.widget && created === true && <>
                  <div className={classNames(styles.alert, styles.alertSuccess)}>
                      <FontAwesomeIcon icon={["fas", "check-circle"]}/>
                      <div>
                          <p>Bienvenue chez Kamat !</p>
                          <p>Votre compte à bien été créé.</p>
                      </div>
                  </div>
                  <Button rounded bold faIcon={["fas", "sign-in"]} style={{
                      margin: "20px auto 0",
                      width: "100%",
                      maxWidth: 320,
                      display: "table"
                  }} to={"/login"} target={"_BLANK"}>Me connecter</Button>
              </>}

              {(props.widget !== true || created !== true) && <>
                  <div className={"row"}>
                      {error !== null && <div className={styles.error}>
                          <FontAwesomeIcon icon={["fas", "circle-xmark"]}/>
                          <p>{error}</p>
                      </div>}

                      <div className={"col-12 col-lg-6"}>
                          <div className={"km-input titled"}>
                              <label>{t("Nom")}</label>
                              <input
                                  className={errors.lastname && touched.lastname ? "is-invalid" : ""}
                                  type="text"
                                  name="lastname"
                                  placeholder={t("Votre nom...")}
                                  value={values.lastname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={creating}
                              />
                          </div>
                          <div className={styles.errorText}>
                              {errors.lastname && touched.lastname && `${errors.lastname}`}
                          </div>
                      </div>

                      <div className={"col-12 col-lg-6"}>
                          <div className={"km-input titled"}>
                              <label>{t("Prénom")}</label>
                              <input
                                  type="text"
                                  name="firstname"
                                  className={errors.firstname && touched.firstname ? "is-invalid" : ""}
                                  placeholder={t("Votre prénom...")}
                                  value={values.firstname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={creating}
                              />
                          </div>
                          <div className={styles.errorText}>
                              {errors.firstname && touched.firstname && `${errors.firstname}`}
                          </div>
                      </div>
                  </div>

                  <div className={"km-input titled"} style={{
                      marginTop: 30
                  }}>
                      <label>{t("Adresse mail")}</label>

                      <input
                          className={errors.email && touched.email ? "is-invalid" : ""}
                          type="email"
                          name="email"
                          placeholder={t("Votre adresse mail...")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          disabled={creating}
                      />
                  </div>
                  <div className={styles.errorText}>
                      {errors.email && touched.email && `${errors.email}`}
                  </div>

                  <div className={"km-input titled"} style={{
                      marginTop: 30
                  }}>
                      <label>{t("Mot de passe")}</label>

                      <input
                          className={errors.password && touched.password ? "is-invalid" : ""}
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          placeholder={t("Votre mot de passe...")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          disabled={creating}
                      />
                  </div>
                  <div className={styles.errorText}>
                      {errors.password && touched.password && `${errors.password}`}
                  </div>

                  <Button rounded style={{
                      marginTop: 40,
                      width: 260,
                      lineHeight: "50px",
                      height: 50
                  }} disabled={!isValid} bold loading={creating}>
                      <FontAwesomeIcon icon={["fas", "user-plus"]} style={{
                          fontSize: 20,
                          verticalAlign: -4,
                          marginRight: 6
                      }}/> {t("Créer mon compte")}
                  </Button>
              </>}
          </Form>
        )}
    </Formik>;
}
