import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import OfferContext from "../../context/OfferContext";
import AuthContext from "../../context/AuthContext";
import {LoginState, Offer, Quote} from "../../interfaces/Interfaces";
import Api from "../../classes/Api";

interface ItemsToLoad {
    offers: boolean,
    quotes: boolean
}

export default function OfferHOC({children}: {
    children: ReactNode
}): JSX.Element {
    const {user, loginState} = useContext(AuthContext);
    const {id: idUser} = user;

    const [requests, setRequests] = useState<Offer[]>([]);
    const [sent, setSent] = useState<Quote[]>([]);
    const [received, setReceived] = useState<Quote[]>([]);
    const [loading, setLoading] = useState(true);

    const loaded = useRef<ItemsToLoad>({
        offers: false,
        quotes: false
    });

    useEffect(() => {
        if (idUser === undefined || loginState !== LoginState.LOGGED) {
            return;
        }

        setLoading(true);
        load();
    }, [idUser, loginState]);

    const onLoad = (key: keyof ItemsToLoad) => {
        if (!loading) {
            return;
        }

        loaded.current[key] = true;

        let finished = 0;
        const keys = (Object.keys(loaded.current) as (keyof ItemsToLoad)[]);

        keys.forEach(k => {
            if (loaded.current[k]) {
                finished++;
            }
        });

        if (finished === keys.length) {
            setLoading(false);
        }
    };

    const load = () => {
        Api.get<{
            received: Quote[],
            sent: Quote[],
            request: Offer[]
        }>("/kamat/offers").then(d => {
            setReceived(d.received);
            setSent(d.sent);
            setRequests(d.request);
        }).catch(() => {
            setReceived([]);
            setSent([]);
            setRequests([]);
        }).finally(() => {
            setLoading(false);
        });
    };

    return <OfferContext.Provider value={{
        received,
        setReceived,
        sent,
        setSent,
        requests,
        setRequests,
        loading,
        reload: () => load()
    }}>
        {children}
    </OfferContext.Provider>;
}