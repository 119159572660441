import Modal from "react-modal";
import { Formular } from "./components/Formular";
import styles from "./changeModal.module.scss";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import closeLogo from "assets/close.svg";

Modal.setAppElement("#root");

export const ChangeModal = ({ modalIsOpen, handleCloseModal }) => {
  const { t } = useTranslation();

  return <Modal
    isOpen={modalIsOpen}
    contentLabel="Login Modal"
    onRequestClose={handleCloseModal}
    shouldCloseOnOverlayClick={true}
    overlayClassName="modal-backdrop"
    className={classNames(styles.container, "modal-container")}
    shouldFocusAfterRender={false}
  >
    <img src={closeLogo} alt={"close"} className={"modal-close"} onClick={handleCloseModal}/>

    {modalIsOpen ? <>
      <h4>{t("Changer de mot de passe")}</h4>
      <Formular handleCloseModal={handleCloseModal}/>
    </>: null}
  </Modal>;
};
