import {DependencyList, Fragment, ReactNode, useEffect, useRef} from "react";

export function useDidMountEffect(effect: () => void, deps: DependencyList): void {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            effect();
        } else {
            didMount.current = true;
        }
    }, deps);
}

export function repeatNode(node: ReactNode, times: number) {
    const nodes: ReactNode[] = [];

    for (let i = 0; i < times; i++) {
        nodes.push(<Fragment key={i}>
            {node}
        </Fragment>);
    }

    return nodes;
}