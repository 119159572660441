import Modal from "common/Modal/Modal";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import {Formik} from "formik";
import Button from "../../../../common/Button/Button";
import {User} from "../../../../interfaces/Interfaces";
import styles from "./userInformationModal.module.scss";
import Api from "../../../../classes/Api";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";

interface UserInformationModalProps {
    display?: boolean,
    onClose?: () => void,
}

export default function UserInformationModal(props: UserInformationModalProps): JSX.Element {
    const {user, setUser} = useContext(AuthContext);
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);

    const schema = yup.object({
        first_name: yup.string().required(t("Le prénom est obligatoire")),
        last_name: yup.string().required(t("Le nom est obligatoire")),
        email: yup.string().email(t("L'adresse mail n'est pas valide")).required(t("L'adresse mail est obligatoire")),
        phone: yup.string()
    });

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }

        setSaving(false);
    };

    const onSave = (v: Partial<User>) => {
        delete v["email"];

        setSaving(true);

        Api.patch<{
            data: User
        }>("/users/me", v).then(v => {
            setUser({
                ...user,
                ...v.data
            });

            notifySuccess(t("Informations modifiées avec succès."));
        }).catch(() => {
            notifyError(t("Une erreur est survenue lors de la modification des informations."));
        }).finally(() => {
            setSaving(false);
            onClose();
        });
    };

    return <Modal onClose={onClose} display={props.display} className={styles.userInformationModal}>
        <Formik
          validationSchema={schema}
          onSubmit={onSave}
          key={props.display ? 1 : 0}
          initialValues={{
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              phone: user.phone ?? ""
          }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                  isValid,
                  errors,
              }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                  <h4>{t("Mes informations")}</h4>

                  <div className="row">
                      <div className="km-input titled required col-6">
                          <label>{t("Nom")}</label>
                          <input
                            className={touched.last_name && errors.last_name ? "is-invalid" : undefined}
                            type="text"
                            value={values.last_name}
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.last_name && touched.last_name &&
                              <div className="invalid-feedback">{errors.last_name}</div>}
                      </div>

                      <div className="km-input titled required col-6">
                          <label>{t("Prénom")}</label>
                          <input
                            className={touched.first_name && errors.first_name ? "is-invalid" : undefined}
                            type="text"
                            value={values.first_name}
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.first_name && touched.first_name &&
                              <div className="invalid-feedback">{errors.first_name}</div>}
                      </div>
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Adresse mail")}</label>
                      <input
                        type="text"
                        value={values.email}
                        disabled
                      />
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Numéro de téléphone")}</label>
                      <input
                        className={touched.phone && errors.phone ? "is-invalid" : undefined}
                        type="text"
                        value={values.phone ?? ""}
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.phone && touched.phone &&
                          <div className="invalid-feedback">{errors.phone}</div>}
                  </div>

                  <div className={"km-flex-middle km-space-between"} style={{
                      marginTop: 50
                  }}>
                      <p className={"required-legend"}>{t("Champs obligatoires")}</p>
                      <Button disabled={!isValid} type={"blue"} loading={saving}>
                          {t("Sauvegarder")}
                      </Button>
                  </div>
              </form>)}
        </Formik>
    </Modal>;
}