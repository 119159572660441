import Modal from "common/Modal/Modal";
import {useContext} from "react";
import AppContext from "../../../../context/AppContext";
import styles from "./faq.module.scss";

interface FaqProps {
    display?: boolean,
    onClose?: () => void,
}

export default function Faq(props: FaqProps): JSX.Element {
    const {config} = useContext(AppContext);

    const getContent = () => {
        return config.faq.map((d, i) => <div className={styles.faqContainer} key={i}>
            <p className={styles.faqQuestion}>{d.question}</p>
            <div className={styles.faqAnswer} dangerouslySetInnerHTML={{__html: d.answer}}/>
        </div>);
    };

    return <Modal className={styles.faq} display={props.display} onClose={props.onClose}>
        <h1>FAQ Kamat</h1>
        <div className={styles.faqBody}>
            {getContent()}
        </div>
    </Modal>;
}