import Modal from "common/Modal/Modal";
import useLocationSite from "../../hooks/useLocationSite";
import {LocationSite} from "../../interfaces/Interfaces";
import styles from "./siteListingModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "common/Button/Button";
import SiteForm, {SiteFormRef} from "../SiteForm/SiteForm";
import {useCallback, useRef, useState} from "react";

interface SiteListingModalProps {
    display?: boolean,
    onClose?: () => void,
    onSelect?: (idSite: number, site: LocationSite) => void,
}

export default function SiteListingModal(props: SiteListingModalProps) : JSX.Element {
    const {buildingSites, getSiteAddress} = useLocationSite();

    const [valid, setValid] = useState(false);
    const [saving, setSaving] = useState(false);

    const form = useRef<SiteFormRef>(null);

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const onAdd = (site: LocationSite) => {
        if (props.onSelect) {
            props.onSelect(site.id, site);
        }
    };

    const getSites = useCallback(() => {
        const nodes = buildingSites.filter(s => s.type === "building").map(site => {
            const address = getSiteAddress(site);

            if (address === null) {
                return;
            }

            return <div className={styles.siteListingModalAddress} key={site.id}>
                <FontAwesomeIcon icon={["fas", "map-location-dot"]} className={styles.siteListingModalAddressIcon}/>
                <div className={styles.siteListingModalAddressContent}>
                    {address.map((a, i) => <p key={i}>{a}</p>)}
                </div>
                <Button type={"yellow"} outline size={"small"} style={{
                    fontSize: 14
                }} bold onClick={() => {
                    if (props.onSelect) {
                        props.onSelect(site.id, site);
                    }
                }}>
                    Sélectionner ce site
                </Button>
            </div>;
        }).filter(n => n !== null);

        if (nodes.length === 0) {
            return <div className={styles.siteListingModalEmpty}>
                <FontAwesomeIcon icon={["fas", "box-open"]}/>
                <p>Vous n'avez aucun site de construction</p>
            </div>;
        }

        return nodes;
    }, [buildingSites]);

    return <Modal display={props.display} onClose={onClose} className={styles.siteListingModal} unmountOnClose={true}>
        <div className={styles.siteListingModalWrapper}>
            <div className={styles.siteListingModalListing}>
                <h2 className={styles.siteListingModalTitle}>Sélectionner un site existant</h2>
                {getSites()}
            </div>
            <div className={styles.siteListingModalForm}>
                <h2 className={styles.siteListingModalTitle}>Ajouter un nouveau site</h2>
                <SiteForm type={"building"} onSaving={setSaving} onValid={setValid} onFinish={onAdd}
                          key={props.display ? 1 : 0} ref={form}/>
                <Button type={"yellow"} outline bold className={styles.siteListingModalFormBtn} loading={saving}
                        disabled={!valid} onClick={() => form.current?.submit()}>
                    Ajouter & sélectionner ce site
                </Button>
            </div>
        </div>
    </Modal>;
}