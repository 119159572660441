import classNames from "classnames";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import Api, {ApiRequestConfig} from "../../classes/Api";
import Pagination from "../Pagination/Pagination";
import {useDidMountEffect} from "../../utils/Component";

interface PaginateDataProps<T> {
    className?: string,
    loader?: ReactNode,
    empty?: ReactNode,
    pageSize?: number,
    fetcher: {
        url: string,
        filter?: {},
        config?: ApiRequestConfig
    }
    item: (item: T, index: number) => ReactNode,
    onCount?: (count: number) => void,
    search?: string,
    minSearch?: number
}

export interface PaginateDataRef<T> {
    setData: (data: T[]) => T[]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
const PaginateData = React.forwardRef<PaginateDataRef<T>, PaginateDataProps<T>>(function <T>(props: PaginateDataProps<T>, ref: PaginateDataRef<T>) {
    const pageSize = props.pageSize ?? 20;
    const minSearch = props.minSearch ?? 3;

    const [fetching, setFetching] = useState(true);

    const [page, setPage] = useState(1);
    const [data, setData] = useState<T[]>([]);
    const [count, setCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    const search = useRef<string>(props.search ?? "");
    const searchThrottler = useRef<number | null>(null);

    useEffect(() => {
        fetch();
    }, []);

    useDidMountEffect(() => {
        onSearch(props.search);
    }, [props.search]);

    useDidMountEffect(() => {
        fetch();
    }, [page]);

    useEffect(() => {
        if (props.onCount) {
            props.onCount(count);
        }
    }, [count]);

    const isSearching = () => search.current.length >= minSearch;

    const fetch = () => {
        setFetching(true);

        let url = props.fetcher.url;
        let queries: string[] = [];

        if (url.includes("?")) {
            const tmp = url.split("?")[1];
            queries = tmp.split("&");
        }

        queries.push("meta=filter_count");
        queries.push(`limit=${pageSize}`);
        queries.push(`offset=${pageSize * (page - 1)}`);

        if (search.current.trim().length > minSearch) {
            queries.push(`search=${search.current}`);
        }

        url += "?" + queries.join("&");

        Api.get<{
            data: T[],
            meta: {
                filter_count: number
            }
        }>(url, props.fetcher.filter ?? {}, props.fetcher.config).then(r => {
            setData(r.data);
            setCurrentCount(r.meta.filter_count);

            if (!isSearching()) {
                setCount(r.meta.filter_count);
            }
        }).catch(() => {
            setData([]);
            setCurrentCount(0);

            if (!isSearching()) {
                setCount(0);
            }
        }).finally(() => {
            setFetching(false);
        });
    };

    const onSearch = (query ?: string) => {
        query = (query ?? "").trim();
        search.current = query;

        if (searchThrottler.current !== undefined) {
            clearTimeout(searchThrottler.current as unknown as NodeJS.Timeout);
        }

        if (query.length <= minSearch) {
            //reset
            if (query.length === 0) {
                fetch();
            }

            return;
        }

        searchThrottler.current = setTimeout(() => {
            fetch();
        }, 300) as unknown as number;
    };

    return <>
        <div className={classNames(props.className)}>
            {fetching && (props.loader ?? <p>Chargement...</p>)}
            {!fetching && data.map((d, i) => props.item(d, i))}
        </div>

        {!fetching && data.length === 0 && props.empty !== undefined && props.empty}

        {currentCount > pageSize &&
            <Pagination total={currentCount} pageSize={pageSize} locked={fetching} onPageChange={p => setPage(p)}
                        showLastPage/>}
    </>;
});

export default PaginateData;