import {FunctionComponent, useContext, useEffect, useState} from "react";
import {CatalogCategory, CatalogItem} from "interfaces/Interfaces";
import {CatalogGrid} from "common/CatalogGrid/CatalogGrid";
import Api from "../../../../classes/Api";
import {RentalCatalogContext} from "../../RentalCatalog";

export const AllCategories: FunctionComponent = () => {
    const {setPromotion, setBreadcrumb, cache} = useContext(RentalCatalogContext);

    const [categories, setCategories] = useState<CatalogItem[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPromotion(0);
        setBreadcrumb([]);

        if (cache.hasOwnProperty("all")) {
            setCategories(cache["all"] as CatalogItem[]);
            return;
        }

        setLoading(true);
        Api.get<{
            data: CatalogCategory[]
        }>("/items/catalog_category").then(d => {
            const data = d.data.map(c => ({
                label: c.label,
                url: c.slug,
                picture: c.picture
            }));

            cache["all"] = data;
            setCategories(data);
        }).finally(() => setLoading(false));
    }, []);

    return <CatalogGrid array={categories} mainCategory={true} loading={loading}/>;
};
