import styles from "./tabs.module.scss";
import {createContext, HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {omit} from "../../utils/ObjectUtils";
import {getUniqKey} from "../../utils/NumberUtils";

interface Tabs {
    [key: string | number]: ReactNode
}

interface TabsContextProps {
    active: string | number | undefined,
    tabs: Tabs,
    registerTab: (key: string | number, label: ReactNode) => void,
}

interface TabsPropsNavigationItem {
    content: ReactNode,
    active?: boolean,
    onClick?: () => void,
    className?: string
}

interface TabsProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
    active?: string | number,
    children?: ReactNode,
    navigation?: {
        itemClassName?: string,
        visible?: boolean,
        extraContent?: ReactNode,
        customItems?: TabsPropsNavigationItem[]
    },
    onChange?: (tab: string | null) => void,
}

export const TabsContext = createContext<TabsContextProps>({} as TabsContextProps);

export default function Tabs(props: TabsProps): JSX.Element {
    const [active, setActive] = useState<string>();
    const [opened, setOpened] = useState(false);

    const tabs = useRef<Tabs>({});
    const id = useRef("tabs-" + getUniqKey());

    useEffect(() => {
        setActive("" + props.active);

        document.body.addEventListener("click", onBodyClick);
    }, [props.active]);

    const onBodyClick = (e: MouseEvent) => {
        const target = e.target as HTMLDivElement;

        if (target.closest("#" + id.current + "-toggle") || target.id === (id.current + "-toggle")) {
            return;
        }

        setOpened(false);
    };

    const onSelect = (k: string) => {
        if (k !== active) {
            setActive(k);

            if (props.onChange) {
                props.onChange(k);
            }
        }
    };

    const getNavigationItems = useCallback(() => {
        const navigation: ReactNode[] = [];
        const defaultItems: ReactNode[] = Object.keys(tabs.current).map((k, i) => <li key={i}
                                                                                      onClick={() => onSelect(k)}
                                                                                      className={classNames(k === active ? styles.tabsHeaderActive : undefined, props.navigation?.itemClassName)}>
            {tabs.current[k]}
        </li>);

        if (props.navigation?.visible !== false) {
            navigation.push(defaultItems);
        }

        if (props.navigation?.customItems !== undefined) {
            props.navigation.customItems.forEach((item, i) => {
                navigation.push(<li key={"c_" + i} onClick={item.onClick}
                                    className={classNames(item.active === true ? styles.tabsHeaderActive : undefined, item.className)}>
                    {item.content}
                </li>);
            });
        }

        return navigation;
    }, [props.navigation, active]);

    const restProps = omit(props, "active", "onChange", "navigation", "children");

    return <TabsContext.Provider value={{
        registerTab: (k, l) => tabs.current["" + k] = l,
        active,
        tabs: tabs.current,
    }}>
        <div {...restProps as HTMLAttributes<HTMLDivElement>}>
            <div className={styles.tabsHeader}>
                {getNavigationItems().length > 0 && <div
                    className={classNames(styles.tabsHeaderNavigationWrapper, opened ? styles.tabsHeaderNavigationOpened : undefined)}>
                    <div className={styles.tabsHeaderNavigationSelect} id={id.current + "-toggle"}
                         onClick={() => setOpened(!opened)}>
                        <label>Navigation</label>
                        <div
                            className={classNames(props.navigation?.itemClassName, styles.tabsHeaderNavigationSelectLabel)}>
                            {active !== undefined && typeof tabs.current[active] !== undefined ? tabs.current[active] : "Menu..."}
                        </div>
                        <span/>
                    </div>

                    <ul className={styles.tabsHeaderNavigation}>
                        {getNavigationItems()}
                    </ul>
                </div>}

                {props.navigation?.extraContent !== undefined && props.navigation?.extraContent}
            </div>

            <div className={styles.tabs}>
                {props.children}
            </div>
        </div>
    </TabsContext.Provider>;
}