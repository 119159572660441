import classNames from "classnames";
import styles from "../layout.module.scss";
import {Link, useHistory} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../../context/AuthContext";
import {useTranslation} from "react-i18next";
import {LoginState} from "../../../interfaces/Interfaces";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ucFirst} from "../../../utils/StringUtils";

export default function Header(): JSX.Element {
    const history = useHistory();
    const {t} = useTranslation();

    const {isAccountComplete, user, loginState} = useContext(AuthContext);

    return <div className={classNames(`${styles.topnav}`)}>
        {loginState === LoginState.LOGGED && <>
            {!isAccountComplete && <div className={styles.accountAlert}>
                <FontAwesomeIcon icon={["fas", "circle-exclamation"]}/>
                <p>{t("Votre compte est incomplet. Complétez les informations manquantes pour commencer à louer.")}
                    <Link to={"/settings?society"}>{t("Consulter")}</Link></p>
            </div>}

            {isAccountComplete && user.verified !== true && <div className={styles.accountAlert}>
                <FontAwesomeIcon icon={["fas", "circle-info"]}/>
                <p>{t("Votre est complet. Vous pourrez commencer à louer une fois que notre équipe aura validée votre informations.")}
                    <Link to={"/settings?society"}>{t("En savoir plus")}</Link></p>
            </div>}
        </>}

        {loginState === LoginState.LOGGED &&
            <p className={styles.itemText}>Welcome back {ucFirst(user.first_name)} !</p>}

        <img
          onClick={() => history.push("/messenger")}
          className={classNames(styles.item)}
          src={require("assets/topNav/help.svg").default}
          alt="Logo"
        />

        {loginState === LoginState.LOGGED && <img
            onClick={() => history.push("/settings")}
            className={classNames(styles.item)}
            src={require("assets/topNav/settings.svg").default}
            alt="Logo"
        />}

        {/*<div onClick={() => setNotification(!notification)} id={"notificationButton"}
                 className={classNames(styles.item, styles.notification, notificationCount > 0 ? styles.haveNotification : undefined)}>
                <img
                  src={require("assets/topNav/notification.svg").default}
                  alt="Logo"
                />

                <NotificationDropdown display={notification} onClose={() => setNotification(false)}
                                      onCountUpdate={c => setNotificationCount(c)}/>
            </div>*/}
    </div>;
}