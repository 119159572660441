import classNames from "classnames";
import UserInformation from "pages/MySettings/components/UserInformation/UserInformation";
import {ReactElement, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CompanyInformation from "./components/CompanyInformation/CompanyInformation";
import styles from "./mySettings.module.scss";
import SubMenu from "../../common/SubMenu/SubMenu";
import {useLocation} from "react-router-dom";
import Settings from "./components/Settings/Settings";
import Payment from "./components/Payment/Payment";
import {IMenu} from "../../interfaces/Interfaces";
import AuthContext from "../../context/AuthContext";

interface Menu {
    [key: string]: {
        name: string,
        info: string,
        logo: string,
        display?: () => boolean,
        component: ReactElement
    }
}

export default function MySettings(): JSX.Element {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    const menu: Menu = {
        profile: {
            name: t("Mon Profil"),
            info: t("Consultez et éditez vos informations"),
            logo: "settings/profile.svg",
            component: <UserInformation/>,
        },
        company: {
            name: t("Mon entreprise"),
            info: t("Gérez les informations de votre société"),
            logo: "settings/company.svg",
            component: <CompanyInformation/>,
        },
        settings: {
            name: t("Mes paramètres"),
            info: t("Gérez vos paramètres"),
            logo: "settings/points.svg",
            component: <Settings/>
        },
        payment: {
            name: t("Paiement en ligne"),
            info: t("Gérez les paramètres liés aux paiements en ligne"),
            logo: "settings/billing.svg",
            component: <Payment/>,
            display: () => user.verified === true
        }
    };

    const [active, setActive] = useState<keyof typeof menu>("profile");
    const location = useLocation();

    useEffect(() => {
        if (location.search.startsWith("?society")) {
            setActive("company");
        } else if (location.search.startsWith("?payment")) {
            setActive("payment");
        }
    }, [location]);

    return (
      <div className={classNames("container-fluid p-xl-3 py-xl-5", styles.settingsContainer)}>
          <h3 className="sectionTitle mb-xl-5 ms-xs-4" style={{top: -55}}>{t("MES PARAMETRES")}</h3>
          <div className="row pt-4 pt-xl-0" style={{zIndex: 5}}>
              <div className="col-xl-2half">
                  <div className={classNames(styles.menuContainer)}>
                      <SubMenu
                        items={Object.keys(menu).map(k => (menu[k].display === undefined || (menu[k].display as () => boolean)()) ? {
                            id: k,
                            name: menu[k].name,
                            logosPath: menu[k].logo,
                            info: menu[k].info
                        } : null).filter(n => n !== null) as IMenu[]} idMenu={active} onMenuChange={setActive}/>
                  </div>
              </div>
              <div className="col-xl-9half">
                  <div className={classNames(styles.mainContainer, "px-1")}>
                      {menu[active].component}
                  </div>
              </div>
          </div>
      </div>
    );
}
