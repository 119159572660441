import { useTranslation } from "react-i18next";
import styles from "./argumentsSection.module.scss";

export const ArgumentsSection = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className={`${styles.argument} col-lg-3 `}>
        <p className={styles.number}>#1</p>
        <h5 className={styles.title}>
          {t("Location instantannée, sans intermédiaire, no bullshit !")}
        </h5>
      </div>
      <div className={`${styles.argument} col-lg-3`}>
        <p className={styles.number}>#2</p>
        <h5 className={styles.title}>{t("Votre site de Construction de A à Z, 24/7")}</h5>
      </div>
      <div className={`${styles.argument} col-lg-3`}>
        <p className={styles.number}>#3</p>
        <h5 className={styles.title}>{t("Des prix imbattables négociés en gros")}</h5>
      </div>
    </>
  );
};
