import {ChangeEvent, useEffect, useRef, useState} from "react";
import styles from "./partnerProductManager.module.scss";
import {useTranslation} from "react-i18next";
import {PartnerProduct} from "interfaces/Interfaces";
import {PartnerProductCard} from "common/PartnerProductCard/PartnerProductCard";
import productLogo from "./assets/productLogo.svg";
import fleetLogo from "assets/fleetLogo.svg";
import lightningLogo from "assets/lightningLogo.svg";
import plusLogo from "assets/plusLogo.svg";
import searchLogo from "assets/loupe.svg";
import Api, {ApiRequestConfig} from "../../../classes/Api";
import PartnerProductFromModal from "./components/PartnerProductFormModal/PartnerProductFormModal";
import ConfirmModal from "../../../common/ConfirmModal/ConfirmModal";
import ArrayUtils from "../../../utils/ArrayUtils";
import {notifyError, notifySuccess} from "../../../common/Notification/notification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaginateData, {PaginateDataRef} from "../../../common/PaginateData/PaginateData";

export default function PartnerProductManager(): JSX.Element {
    const {t} = useTranslation();

    const requestConfig: ApiRequestConfig = {
        fields: ["*", "technical_sheet.*", "product.*", "location.*", "cce.*"]
    };

    const [formProduct, setFormProduct] = useState<PartnerProduct | null>();
    const [rentCount, setRentCount] = useState(0);
    const [count, setCount] = useState(0);

    const [deleteProduct, setDeleteProduct] = useState<number | null>(null);
    const [deleting, setDeleting] = useState(false);

    const [search, setSearch] = useState<string>("");

    const ref = useRef<PaginateDataRef<PartnerProduct>>(null);

    useEffect(() => {
        Api.get<{
            count: number
        }>("/kamat/rent-machine").then(r => {
            setRentCount(r.count);
        }).catch(() => {
            setRentCount(0);
        });
    }, []);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
    };

    const onProductEdit = (product: PartnerProduct) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref.current?.setData((d: PartnerProduct[]) => ArrayUtils.replaceOrPush(d, p => p.id === product.id, product));
    };

    const onDelete = () => {
        if (deleteProduct === null) {
            return;
        }

        setDeleting(true);

        Api.delete(`/items/machine/${deleteProduct}`).then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current?.setData((d: PartnerProduct[]) => ArrayUtils.delete(d, p => p.id === deleteProduct));
            notifySuccess(t("Le produit a bien été supprimé"));
        }).catch(() => {
            notifyError(t("Impossible de supprimer ce produit"));
        }).finally(() => {
            setDeleting(false);
            setDeleteProduct(null);
        });
    };

    return <div className={styles.productManager}>
        <div className={styles.productManagerHeader}>
            <div className={styles.productManagerHeaderImg}>
                <img src={productLogo} alt="productLogo"/>
            </div>

            <div className={styles.productManagerHeaderBox}>
                <img src={fleetLogo} alt="nbSites" height="40"/>

                <div>
                    <h5>{count}</h5>
                    <h6> {t("produits enregistrés")}</h6>
                </div>
            </div>

            <div className={styles.productManagerHeaderBox}>
                <img src={lightningLogo} alt="nbSites" height="40"/>

                <div>
                    <h5>{rentCount}</h5>
                    <h6> {t("produits loués")}</h6>
                </div>
            </div>
        </div>

        <div className={styles.productManagerToolBar}>
            <div className={"km-search"}>
                <img src={searchLogo} alt=""/>
                <input placeholder={"Chercher un équipement"} onChange={onSearch}/>
            </div>

            <button
              className={styles.productManagerToolBarBtn}
              onClick={() => setFormProduct(null)}
            >
                <img src={plusLogo} alt="plusLogo" className="mx-3"/>
                {t("Ajouter une machine")}
            </button>
        </div>

        <PaginateData fetcher={{
            url: "/items/machine",
            config: requestConfig
        }} item={(product: PartnerProduct, i) => <PartnerProductCard
          key={i}
          onDelete={() => setDeleteProduct(product.id)}
          onEdit={() => setFormProduct(product)}
          product={product}
        />} className={styles.productManagerBody} empty={<div className={styles.productManagerEmpty}>
            <FontAwesomeIcon icon={["fas", "box-open"]}/>
            <div>
                <h4>{t("Aucun produit trouvé")}.</h4>
                <a onClick={() => setFormProduct(null)}><FontAwesomeIcon
                  icon={["fas", "circle-plus"]}/> {t("Ajoutez des machines pour commencer à les louer")}</a>
            </div>
        </div>} pageSize={10} onCount={setCount} search={search}/>

        <PartnerProductFromModal display={formProduct !== undefined} onClose={() => setFormProduct(undefined)}
                                 onFinish={onProductEdit}
                                 product={formProduct ?? undefined} requestConfig={requestConfig}/>

        <ConfirmModal display={deleteProduct !== null} onClose={() => setDeleteProduct(null)}
                      title={t("Supprimer un produit de ma flotte")} onConfirm={onDelete} loading={deleting}>
            {t("Êtes-vous certain de vouloir supprimer ce produit de votre flotte ? Cette action est irréversible.")}
        </ConfirmModal>
    </div>;
}
