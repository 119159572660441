import classNames from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./companyInformation.module.scss";
import {ReactNode, useContext, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import AppContext from "../../../../context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {User} from "../../../../interfaces/Interfaces";
import Button from "../../../../common/Button/Button";
import SocietyInformationModal from "../SocietyInformationModal/SocietyInformationModal";

export default function CompanyInformation(): JSX.Element {
    const {t} = useTranslation();

    const {config} = useContext(AppContext);
    const {user, isAccountComplete, getAccountFieldLabel} = useContext(AuthContext);

    const [edit, setEdit] = useState(false);

    const getCompletenessAlert = () => {
        if (isAccountComplete) {
            return null;
        }

        const missingFields = config.required_account_fields.map(f => {
            if (user[f] !== null && user[f] !== undefined && ("" + user[f]).trim().length > 0) {
                return null;
            }

            return getAccountFieldLabel(f).toLowerCase();
        }).filter(v => v !== null);
        const percent = 100 - Math.ceil((missingFields.length / config.required_account_fields.length) * 100);

        return <div className={styles.companyInformationCompletenessAlert}>
            <div className={styles.companyInformationCompletenessAlertBody}>
                <h5><FontAwesomeIcon icon={["fas", "circle-exclamation"]}/> {t("Informations manquantes")}</h5>
                <p>{t("Afin de pouvoir commencer à louer, vous devez compléter ces informations manquantes sur votre société")} :</p>
                <p style={{
                    marginTop: 8
                }}>{missingFields.join(", ")}</p>
            </div>

            <div className={styles.companyInformationCompletenessAlertPercent}>
                <p>{percent}%</p>
                <span>complet</span>
            </div>
        </div>;
    };

    const getWaitingAlert = () => {
        if (!isAccountComplete || user.verified === true) {
            return null;
        }

        return <div className={styles.companyInformationWaitingAlert}>
            <h2>Merci.</h2>
            <p><FontAwesomeIcon icon={["fas", "check-circle"]}/> Votre compte est complet.</p>
            <p>Afin de garantir la meilleure expérience à nos utilisateurs, nous allons procéder à la vérification des informations saisies d'ici quelques instants.</p>
            <p>Une fois la vérification effectuée, vous serez averti et vous pourrez commencer à louer.</p>
        </div>;
    };

    const getInformation = () => {
        const fields: (keyof User)[] = [
            "society_name",
            "society_identification",
            "society_legal_status",
            "society_vat_number",
            "society_location_address",
            "society_location_city",
            "society_location_zipcode",
            "society_location_country",
            "sav_phone"
        ];

        return fields.map(f => {
            const value = user[f];
            const isFilled = value !== null && value !== undefined && ("" + value).trim().length > 0;

            let displayedValue: ReactNode = value;
            let prefix: ReactNode = null;

            if (!isFilled) {
                displayedValue = <span>{t("non complété")}</span>;

                if (config.required_account_fields.indexOf(f) !== -1) {
                    prefix = <FontAwesomeIcon icon={["fas", "exclamation-circle"]}/>;
                }
            }

            return <p key={f}>{prefix}<b>{getAccountFieldLabel(f)} : </b>{displayedValue}</p>;
        });
    };

    return <div className={classNames(styles.company, "ps-sm-4 row pb-4 pb-xl-0")}>
        <div className={classNames("col-xl-9 col-xxl-7", styles.companyInformation)}>
            <h2 className={styles.companyInformationTitle}>{t("Mon Entreprise")}</h2>

            {getCompletenessAlert()}
            {getWaitingAlert()}
            {getInformation()}

            <Button type={"black"} outline onClick={() => setEdit(true)} bold style={{
                marginTop: 20
            }}>
                <FontAwesomeIcon icon={["fas", "pen-to-square"]} style={{
                    fontSize: 19,
                    marginRight: 4
                }}/> {t("Modifier les informations")}
            </Button>

            <SocietyInformationModal display={edit} onClose={() => setEdit(false)}/>
        </div>
    </div>;
}
