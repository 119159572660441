import Select from "react-select";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./linkedProductSelect.module.scss";
import link from "./assets/link.png";
import {PartnerProduct} from "../../../../interfaces/Interfaces";
import Api from "../../../../classes/Api";
import AuthContext from "../../../../context/AuthContext";

interface LinkedProductSelectProps {
    onSelect: (product: PartnerProduct) => void,
    idProduct: number,
    products?: PartnerProduct[]
}

interface Option {
    value: number,
    label: string
}

export default function LinkedProductSelect({
                                                idProduct,
                                                onSelect,
                                                products: defaultProducts
                                            }: LinkedProductSelectProps): JSX.Element {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {id: idUser} = user;

    const [products, setProduct] = useState<PartnerProduct[]>(defaultProducts ?? []);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState<Option>();

    useEffect(() => {
        if (idUser === undefined) {
            setLoading(false);
            return;
        }

        Api.get<{
            data: PartnerProduct[]
        }>("/items/machine", {
            _and: [
                {
                    owner: idUser
                },
                {
                    product: idProduct
                }
            ]
        }).then(d => setProduct(d.data)).catch(() => setProduct([])).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (products.length === 1) {
            const product = [...products].shift();

            if (product === undefined) {
                return;
            }

            onSelect(products[0]);
            setValue(toOption(product));
        }
    }, [products]);

    const toOption = (product: PartnerProduct): Option => ({
        value: product.id,
        label: product.label + " (Réf. : " + product.internal_reference + ")",
    });

    const options = useMemo<Option[]>(() => products.map(p => (toOption(p))), [products, loading]);

    const onChange = (opt: Option) => {
        const product = products.filter(p => p.id === opt.value)[0];

        if (product === undefined) {
            return;
        }

        onSelect(product);
        setValue(opt);
    };

    return <div className={styles.linkedProductSelectContainer}>
        <div className={styles.linkedProductSelectPrefix}>
            <img src={link} alt={""}/>
        </div>

        <Select classNamePrefix={"linkedProduct"} className={styles.linkedProductSelect}
                placeholder={t("Sélectionner un équipement")}
                options={options} isSearchable={false} isLoading={loading} onChange={v => onChange(v as Option)}
                isDisabled={loading} value={value}
                noOptionsMessage={() => t("Aucun équipement disponible")}
        />
    </div>;
}