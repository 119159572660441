import {HTMLAttributes, ReactNode, useContext, useEffect, useMemo} from "react";
import {TabsContext} from "./Tabs";
import styles from "./tabs.module.scss";

interface TabPaneProps extends HTMLAttributes<HTMLDivElement> {
    tab: string | number,
    label: ReactNode,
    children?: ReactNode
}

export default function TabPane(props: TabPaneProps): JSX.Element {
    const parent = useContext(TabsContext);

    if (parent === undefined) {
        console.error("Tab pane muse be in a Tabs component");
        return null as unknown as JSX.Element;
    }

    useEffect(() => {
        parent.registerTab(props.tab, props.label);
    }, []);

    return useMemo(() => {
        if (parent.active !== ("" + props.tab)) {
            return null;
        }

        const isFirst = Object.keys(parent.tabs)[0] === ("" + props.tab);

        return <div className={styles.tabsContent} style={isFirst ? {
            borderTopLeftRadius: 0
        } : undefined}>
            {props.children}
        </div>;
    }, [parent.active, props]) as JSX.Element;
}