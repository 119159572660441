import {AllOfferItemOption, OfferItemOption} from "../interfaces/Interfaces";
import {useTranslation} from "react-i18next";

export default function useProductOptions() : {
    [key in AllOfferItemOption]: string
} {
    const {t} = useTranslation();

    return {
        brh: t("BRH"),
        bucket: t("Godet"),
        hoist: t("Treuil"),
        carrycot_basket: t("Panier nacelle"),
        gallows: t("Potence"),
        operator: t("Avec opérateur"),
        saturday_include: t("Samedi inclus"),
        sunday_include: t("Dimanche inclus"),
        safety_harness: t("Harnais de sécurité")
    };
}