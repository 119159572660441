import missingImage from "assets/missingImage.svg";

interface IMissingImageProps {
  logosPath: string;
  image?: string;
  className: string;
  [id: string]: string|number|undefined|null;
}

export const MissingImage: React.FunctionComponent<IMissingImageProps> = ({
  logosPath,
  image,
  className,
  ...props
}) => {
  try {
    return image ? (
      <img
        className={className}
        src={require(`assets/${logosPath.replace(/^\/|\/$/, "")}/${image.replace(/^\/|\/$/, "")}`).default}
        alt="Logo"
        {...props}
      />
    ) : (
      <img
        className={className}
        src={require(`assets/${logosPath.replace(/^\/|\/$/, "")}`)}
        alt="Logo"
        {...props}
      />
    );
  } catch (error) {
    console.error(error);
    return <img className={className} src={missingImage} alt="missingImage" {...props}/>;
  }
};
