import {Form, Formik} from "formik";
import * as yup from "yup";
import styles from "./contactForm.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {notifyError, notifySuccess} from "common/Notification/notification";
import {useContext, useRef, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import {LoginState} from "../../../../interfaces/Interfaces";
import Button from "../../../../common/Button/Button";
import Uploader, {UploaderRef} from "../../../../common/Uploader/Uploader";
import AppContext from "../../../../context/AppContext";
import Api from "../../../../classes/Api";
import {getUniqKey} from "../../../../utils/NumberUtils";

export function ContactForm(): JSX.Element {
    const {t} = useTranslation();
    const {config} = useContext(AppContext);
    const {user, loginState} = useContext(AuthContext);
    const {
        first_name,
        last_name,
        phone,
        email
    } = user;

    const [sending, setSending] = useState(false);
    const [update, setUpdate] = useState(0);

    const uploader = useRef<UploaderRef>(null);

    const schema = yup.object({
        reason: yup.string().required(t("Vous devez choisir un sujet de contact")).notOneOf(["default"], t("Vous devez choisir un sujet de contact")),
        name: yup.string().required(t("Votre nom est requis")),
        email: yup
          .string()
          .email(t("Une adresse mail valide est requise"))
          .required(t("Une adresse mail  est requise")),
        phone: yup.string().required(t("Un numéro de téléphone est requis")),
        message: yup
          .string()
          .required(t("Le message doit être complété")),
    });

    const onSend = (values: Record<string, string | number>) => {
        if (uploader.current === null) {
            return;
        }

        setSending(true);

        const sendMessage = (idFile: string | null) => {
            Api.post("/items/contact", {
                name: values.name,
                subject: values.reason,
                mail: values.email,
                message: values.message,
                phone: values.phone,
                file: idFile
            }).then(() => {
                notifySuccess("Message envoyé");
                setUpdate(getUniqKey());
            }).catch(() => {
                notifyError("Une erreur est survenue lors de l'envoi du message...");
            }).finally(() => setSending(false));
        };

        uploader.current.upload({
            folder: config.support_folder
        }).then(m => {
            sendMessage(m !== null ? m.id : null);
        }).catch(() => {
            sendMessage(null);
        });
    };

    return <Formik
      key={update}
      validationSchema={schema}
      isInitialValid={false}
      onSubmit={onSend}
      initialValues={{
          email: loginState === LoginState.LOGGED ? email : "",
          name: loginState === LoginState.LOGGED ? `${first_name} ${last_name}` : "",
          phone: loginState === LoginState.LOGGED ? (phone ?? "") : "",
          reason: "default",
          message: "",
      }}
    >
        {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              isValid,
              errors,
          }) => (
          <Form onSubmit={handleSubmit} className={classNames(styles.form)}>
              <div className="km-input required">
                  <select
                    className={errors.reason && touched.reason ? "is-invalid" : ""}
                    name="reason"
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                      <option selected disabled value="default">
                          {t("Choisir un sujet de contact")}
                      </option>
                      <option value="Site Web">
                          {t("Un soucis technique avec le site web")}
                      </option>
                      <option value="Order issue">
                          {t("Une question à propos d'une commande")}
                      </option>
                      <option value="Order incident">
                          {t("Un incident avec une commande")}
                      </option>
                      <option value="GDPR">{t("Exercer mes droits GDPR")}</option>
                      <option value="Autre">{t("Autre")}</option>
                  </select>

                  <div className="invalid-feedback">
                      {errors.reason && touched.reason && `${errors.reason}`}
                  </div>
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("Nom")}</label>

                  <input
                    className={errors.name && touched.name ? "is-invalid" : ""}
                    type="text"
                    name="name"
                    placeholder="Votre nom..."
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <div className="invalid-feedback">
                      {errors.name && touched.name && `${errors.name}`}
                  </div>
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("Email")}</label>

                  <input
                    className={errors.email && touched.email ? "is-invalid" : ""}
                    type="email"
                    name="email"
                    placeholder={t("Adresse mail...")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  <div className="invalid-feedback">
                      {errors.email && touched.email && `${errors.email}`}
                  </div>
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("Téléphone")}</label>

                  <input
                    className={errors.phone && touched.phone ? "is-invalid" : ""}
                    name="phone"
                    placeholder={t("Téléphone") + "..."}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />

                  <div className="invalid-feedback">
                      {errors.phone && touched.phone && `${errors.phone}`}
                  </div>
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("Message")}</label>

                  <textarea
                    className={errors.message && touched.message ? "is-invalid" : ""}
                    name="message"
                    placeholder={t("Votre message...")}
                    rows={8}
                    cols={50}
                    maxLength={400}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                  />

                  <div className="invalid-feedback">
                      {errors.message && touched.message && `${errors.message}`}
                  </div>
              </div>

              <Uploader ref={uploader} className={"km-mt-25"} title={"Joindre un fichier"}/>

              <Button
                type={"yellow"}
                bold
                rounded
                style={{
                    marginTop: 30,
                    width: 200
                }}
                loading={sending}
                disabled={!isValid}
              >
                  {t("Envoyer")}
              </Button>
          </Form>
        )}
    </Formik>;
}
