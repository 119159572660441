import {CatalogProduct, Offer, OfferItem, OfferItemOption} from "../interfaces/Interfaces";
import {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../context/AppContext";
import Api from "../classes/Api";
import AuthContext from "../context/AuthContext";
import ArrayUtils from "../utils/ArrayUtils";

export interface CartItem {
    product: number,
    quantity: number,
    options?: OfferItemOption[],
    operator?: boolean,
    saturday_include?: boolean,
    sunday_include?: boolean,
    date: {
        start: Date | number,
        end: Date | number
    }
}

interface DebounceParams {
    [key: number]: {
        params: Partial<CartItem>,
        callback: number
    }
}

export interface CartHook {
    cart: Offer | null,
    reload: () => void,
    isCartFilled: boolean,
    setDebounceParams: <T extends keyof OfferItem>(idItem: number, key: T, value: OfferItem[T], timeout ?: number) => void,
    addItem: (item: CartItem) => Promise<Offer>,
    removeItem: (id: number) => Promise<Offer>,
    loading: boolean,
}

let cartLocker = false;

export default function useCart(): CartHook {
    const {user} = useContext(AuthContext);
    const {cart: manager} = useContext(AppContext);
    const {entity: cart, loaded, setEntity: setCart} = manager;
    const [loading, setLoading] = useState(false);
    const debounceParams = useRef<DebounceParams>({});
    const {id: idUser} = user;

    useEffect(() => {
        if (!loaded) {
            setLoading(true);
            manager.loaded = true;
            load();
        }

        return () => {
            Object.keys(debounceParams.current).forEach(idItem => {
                const params = debounceParams.current[parseInt("" + idItem)];
                clearTimeout(params.callback);
                Api.patch(`/items/offer_item/${idItem}`, params.params);
            });
        };
    }, []);

    const config = {
        fields: [
            "*",
            "items.*",
            "items.catalog_product.*",
            "items.location.*"
        ]
    };

    const load = () => {
        if (cartLocker) {
            return;
        }

        cartLocker = true;

        Api.get<{
            data: Offer[]
        }>("/items/offer", {
            _and: [
                {
                    client: idUser
                },
                {
                    is_cart: true
                }
            ]
        }, config).then(o => {
            const c = o.data.shift();

            if (c !== undefined) {
                setCart(c);
            } else {
                setCart(null);
            }
        }).catch(() => setCart(null)).finally(() => {
            setLoading(false);
            cartLocker = false;
        });
    };

    const getOfferItems = (item ?: CartItem) => {
        const items: {
            id?: number,
            catalog_product: number,
            quantity: number,
            rent_start: string,
            rent_end: string,
            options?: OfferItemOption[],
            operator: boolean,
            saturday_include: boolean,
            sunday_include: boolean,
        }[] = [...(cart?.items ?? []).map(it => ({
            id: (it as OfferItem).id,
            catalog_product: ((it as OfferItem).catalog_product as CatalogProduct).id,
            quantity: (it as OfferItem).quantity,
            rent_start: (it as OfferItem).rent_start,
            rent_end: (it as OfferItem).rent_end,
            options: (it as OfferItem).options ?? null,
            operator: (it as OfferItem).operator ?? false,
            saturday_include: (it as OfferItem).saturday_include ?? false,
            sunday_include: (it as OfferItem).sunday_include ?? false,
        }))];

        //add new items
        if (item !== undefined) {
            items.push({
                catalog_product: item.product,
                quantity: item.quantity,
                options: Array.isArray(item.options) ? (item.options as OfferItemOption[]) : undefined,
                rent_start: (typeof item.date.start === "number" ? new Date(item.date.start) : item.date.start).toISOString(),
                rent_end: (typeof item.date.end === "number" ? new Date(item.date.end) : item.date.end).toISOString(),
                operator: item.operator ?? false,
                saturday_include: item.saturday_include ?? false,
                sunday_include: item.sunday_include ?? false,
            });
        }

        return items;
    };

    const add = (item: CartItem): Promise<Offer> => {
        return new Promise<Offer>((resolve, reject) => {
            //update cart (must re-set all items)
            if (cart !== null) {
                Api.patch<{
                    data: Offer
                }>(`/items/offer/${cart.id}`, {
                    items: getOfferItems(item)
                }, config).then(d => {
                    setCart(d.data);
                    resolve(d.data);
                }).catch(reject);

                return;
            }

            //not cart exists, create it
            Api.post<{
                data: Offer
            }>("/items/offer", {
                client: idUser,
                items: getOfferItems(item)
            }, config).then(d => {
                setCart(d.data);
                resolve(d.data);
            }).catch(reject);
        });
    };

    const remove = (id: number): Promise<Offer> => {
        return new Promise<Offer>((resolve, reject) => {
            if (cart === null) {
                reject();
                return;
            }

            Api.patch<{
                data: Offer
            }>(`/items/offer/${cart.id}`, {
                items: ([...cart.items].filter((it: number | OfferItem) => typeof it !== "number" && (it as OfferItem).id !== id) as OfferItem[]).map(it => it.id)
            }, config).then(d => {
                setCart(d.data);
                resolve(d.data);
            }).catch(reject);
        });
    };

    const setDebounceParams = (idItem: number, key: string, value: string | boolean | object | number, timeout ?: number) => {
        setCart({
            ...(cart as Offer),
            items: ArrayUtils.replace((cart as Offer).items as OfferItem[], it => typeof it === "object" && it.id === idItem, it => ({
                ...(it as OfferItem),
                [key]: value
            }))
        });

        if (debounceParams.current.hasOwnProperty(idItem)) {
            clearTimeout(debounceParams.current[idItem].callback);
        }

        if (typeof value === "object" && value.hasOwnProperty("id")) {
            value = (value as {
                id: number
            }).id;
        }

        debounceParams.current[idItem] = {
            params: {
                ...(debounceParams.current[idItem]?.params ?? {}),
                [key as keyof CartItem]: value as CartItem[keyof CartItem]
            },
            callback: setTimeout(() => {
                Api.patch(`/items/offer_item/${idItem}`, debounceParams.current[idItem].params);
                delete debounceParams.current[idItem];
            }, timeout ?? 6500) as unknown as number
        };
    };

    return {
        cart,
        addItem: add,
        isCartFilled: cart !== null && cart.items.length > 0,
        removeItem: remove,
        loading,
        setDebounceParams: (idItem, key, value, timeout) => {
            setDebounceParams(idItem, key, value, timeout);
        },
        reload: () => load()
    };
}