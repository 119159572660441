import React, {useContext, useState} from "react";
import styles from "../payment.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Select from "react-select";
import {countries} from "../../../../../configs";
import Button from "../../../../../common/Button/Button";
import {Formik} from "formik";
import * as yup from "yup";
import Api from "../../../../../classes/Api";
import {useTranslation} from "react-i18next";
import {notifyError} from "../../../../../common/Notification/notification";
import AuthContext from "../../../../../context/AuthContext";

export default function AccountInformation(): JSX.Element {
    const {t} = useTranslation();
    const {refreshUser} = useContext(AuthContext);

    const [saving, setSaving] = useState(false);

    const onSave = (v: typeof initialValues) => {
        setSaving(true);

        const onError = (e ?: string) => {
            let msg = "Une erreur est survenue, nous ne pouvons pas donner suite à la création de votre compte de paiement, contactez-nous";

            if (typeof e === "string") {
                msg = e;
            }

            notifyError(msg);
            setSaving(false);
        };

        Api.post<{
            success?: boolean,
            error?: string
        }>("/kamat/merchant", {
            ...v,
            ownerNationality: v["ownerNationality"]["value"],
        }).then(async (r) => {
            if (r !== undefined && typeof r === "object" && r.hasOwnProperty("success")) {
                if (r.success) {
                    await refreshUser();
                    return;
                }

                if (r.hasOwnProperty("error") && typeof r.error === "string") {
                    onError(r.error);
                    return;
                }
            }

            onError();
        }).catch(() => {
            onError();
        });
    };

    const companyTypes = {
        company: "Entreprise",
        entrepreneur: "Auto-entrepreneur"
    };

    const initialValues = {
        companyType: "company",
        activity: "",
        iban: "",
        bic: "",
        ownerNationality: {
            label: "",
            value: ""
        },
        ownerBirth: "",
        ownerPhone: "",
        bankAccountOwner: "",
        cgv: "",
    };

    const schema = yup.object({
        companyType: yup.string().required("Type d'entreprise obligatoire").oneOf(Object.keys(companyTypes), "Type d'entreprise obligatoire"),
        activity: yup.string().required("Description de l'activité obligatoire").min(20, "La description de l'activité obligatoire doit faire min 20 caractères."),
        cgv: yup.boolean().required("Vous devez accepter les conditions générales d'utilisation").oneOf([true], "Vous devez accepter les conditions générales d'utilisation"),
        ownerNationality: yup.object().shape({
            label: yup.string(),
            value: yup.string().required("Nationalité obligatoire"),
        }),
        ownerBirth: yup.string().required("Date de naissance obligatoire").matches(/(\d{2}\/){2}\d{4}/, {
            message: "La date de naissance doit être au format : JJ/MM/AAAA"
        }),
        ownerPhone: yup.string().required("Le téléphone est obligatoire"),
        bankAccountOwner: yup.string().required("Le titulaire du compte est obligatoire"),
        iban: yup.string().required("L'IBAN est obligatoire"),
        bic: yup.string().required("Le BIC est obligatoire")
    });

    return <Formik
      onSubmit={onSave}
      validationSchema={schema}
      initialValues={initialValues}
    >
        {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              values,
              isValid,
              errors,
              setFieldValue
          }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
              <p className={styles.settingsSectionTitle} style={{
                  marginTop: 50
              }}><FontAwesomeIcon icon={["fas", "building"]}/> Informations sur la société</p>

              <div className="km-input titled required km-mt-25">
                  <label>Type d'entreprise</label>
                  <select
                    className={classNames(errors.companyType ? "is-invalid" : "")}
                    name={"Type d'entreprise"}
                    value={values.companyType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                      {Object.keys(companyTypes).map(k => <option key={k}
                                                                  value={k}>{companyTypes[k as keyof typeof companyTypes] as string}</option>)}
                  </select>
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>Activité</label>
                  <textarea
                    placeholder={"Décrivez votre activité, les produits et/ou les services que vous commercialisez"}
                    className={touched.activity && errors.activity ? "is-invalid" : undefined}
                    value={values.activity ?? ""}
                    name="activity"
                    rows={5}
                    onChange={handleChange}
                    onBlur={handleBlur}/>

                  {errors.activity && touched.activity &&
                      <div className="invalid-feedback">{errors.activity}</div>}
              </div>
              <p className={styles.settingsHelper} style={{
                  marginTop: 4
              }}>Minimum 20 caractères</p>

              <p className={styles.settingsSectionTitle} style={{
                  marginTop: 50
              }}><FontAwesomeIcon icon={["fas", "user"]}/> Informations civiles du gérant de la société</p>

              <div className="km-input titled required km-mt-25">
                  <label>Date de naissance</label>
                  <input
                    className={touched.ownerBirth && errors.ownerBirth ? "is-invalid" : undefined}
                    type="text"
                    value={values.ownerBirth ?? ""}
                    name="ownerBirth"
                    placeholder={"JJ/MM/AAAA"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.ownerBirth && touched.ownerBirth &&
                      <div className="invalid-feedback">{errors.ownerBirth}</div>}
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>Nationalité</label>

                  <Select
                    isSearchable
                    classNamePrefix={"km-input-select"}
                    className={classNames("km-input-select", touched.ownerNationality && errors.ownerNationality ? "is-invalid" : undefined)}
                    name={"ownerNationality"}
                    value={values.ownerNationality}
                    options={Object.keys(countries).map(k => ({
                        value: countries[k as keyof typeof countries].alpha_3,
                        label: countries[k as keyof typeof countries].name
                    }))}
                    placeholder={"Nationalité..."}
                    onBlur={handleBlur}
                    onChange={(newValue) => setFieldValue("ownerNationality", newValue)}
                  />

                  {errors.ownerNationality && touched.ownerNationality &&
                      <div className="invalid-feedback">{errors.ownerNationality.value}</div>}
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>Téléphone</label>
                  <input
                    className={touched.ownerPhone && errors.ownerPhone ? "is-invalid" : undefined}
                    type="text"
                    value={values.ownerPhone ?? ""}
                    name="ownerPhone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.ownerPhone && touched.ownerPhone &&
                      <div className="invalid-feedback">{errors.ownerPhone}</div>}
              </div>

              <p className={styles.settingsSectionTitle} style={{
                  marginTop: 50
              }}><FontAwesomeIcon icon={["fas", "building-columns"]}/> Informations bancaires</p>

              <div className="km-input titled required km-mt-25">
                  <label>Titulaire du compte</label>
                  <input
                    className={touched.bankAccountOwner && errors.bankAccountOwner ? "is-invalid" : undefined}
                    type="text"
                    value={values.bankAccountOwner ?? ""}
                    name="bankAccountOwner"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.bankAccountOwner && touched.bankAccountOwner &&
                      <div className="invalid-feedback">{errors.bankAccountOwner}</div>}
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("IBAN")}</label>
                  <input
                    className={touched.iban && errors.iban ? "is-invalid" : undefined}
                    type="text"
                    value={values.iban ?? ""}
                    name="iban"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.iban && touched.iban &&
                      <div className="invalid-feedback">{errors.iban}</div>}
              </div>

              <div className="km-input titled required km-mt-25">
                  <label>{t("BIC")}</label>
                  <input
                    className={touched.bic && errors.bic ? "is-invalid" : undefined}
                    type="text"
                    value={values.bic ?? ""}
                    name="bic"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.bic && touched.bic &&
                      <div className="invalid-feedback">{errors.bic}</div>}
              </div>

              <div className="km-input km-input-checkbox km-mt-25">
                  <input
                    className={touched.cgv && errors.cgv ? "is-invalid" : undefined}
                    type="checkbox"
                    value={values.cgv ?? ""}
                    name="cgv"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="cgv"
                  />

                  <label htmlFor={"cgv"}>J'ai lu et j'accepte les <a href={"https://www.easytransac.com/fr/cgv"}
                                                                     target={"_BLANK"}
                                                                     rel={"noreferrer"}>conditions générales d'utilisation</a> de notre partenaire Easytransac.</label>

                  {errors.cgv && touched.cgv &&
                      <div className="invalid-feedback">{errors.cgv}</div>}
              </div>

              <div className={"km-flex-middle km-space-between"} style={{
                  marginTop: 50
              }}>
                  <Button disabled={!isValid} size={"large"} style={{
                      width: 400
                  }} loading={saving}>
                      {t("Valider et continuer")}
                  </Button>
              </div>
          </form>)}
    </Formik>;
}