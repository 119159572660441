import {DefaultType, RecursiveGenericType} from "../interfaces/Interfaces";

export function cloneObject<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
}

export function omit<T>(obj: T, ...keys: (keyof T)[]): T {
    if (!keys.length) {
        return obj;
    }

    const {[keys.pop() as (keyof T)]: omitted, ...rest} = obj;
    return omit<T>(rest as T, ...keys);
}

export function pick<T>(obj: T, ...keys: (keyof T)[]): T {
    const picked: Partial<T> = {};

    keys.forEach(function (prop) {
        picked[prop] = obj[prop];
    });

    return picked as T;
}

export function isSameArray(arr1: (number | string)[], arr2: (number | string)[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

export function getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + document.body.scrollLeft,
        top: rect.top + document.body.scrollTop
    };
}

export function isValidItem(data : string|object|null|undefined|string[]|number[]|unknown) : boolean {
    return data !== null && data !== undefined && typeof data === "object" && data.hasOwnProperty("id");
}

export function getItemId(data : string|object|null|undefined|string[]|number[], path : string) : number|string|null {
    const arr = path.split(".");

    console.log(data);

    if (isValidItem(data)) {
        const key = arr.shift() as string;

        //last element
        if (arr.length === 0) {
            return (data as DefaultType<string>)[key] ?? null;
        }

        return getItemId((data as DefaultType<string>)[key], arr.join("."));
    }

    return null;
}