import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import AuthContext from "../../context/AuthContext";
import {LoginState, User} from "../../interfaces/Interfaces";
import {useTranslation} from "react-i18next";
import TagManager from "react-gtm-module";
import Api from "../../classes/Api";
import styles from "./authHOC.module.scss";
import CookieConsent from "react-cookie-consent";
import AppContext from "../../context/AppContext";
import {useCookies} from "react-cookie";

interface AuthHOCProps {
    children: ReactNode
}

export default function AuthHOC({children}: AuthHOCProps): JSX.Element {
    const {t} = useTranslation();
    const {config} = useContext(AppContext);
    const [, , removeCookie] = useCookies();

    const [user, setUser] = useState<User | null>(null);
    const [loginState, setLoginState] = useState<LoginState>(LoginState.PENDING);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState<string | null>(null);

    const renewAt = useRef<number | null>(null);
    const fetching = useRef(false);

    useEffect(() => {
        setLoading(true);
        setLoginState(LoginState.PENDING);
        Api.setAuthRenewTokenHandler(refreshToken);

        retrieveSession();

        window.addEventListener("focus", ensureSessionValidity);
        document.addEventListener("click", ensureSessionValidity);

        return () => {
            window.removeEventListener("focus", ensureSessionValidity);
            document.removeEventListener("click", ensureSessionValidity);
        };
    }, []);

    useEffect(() => {
        if (renewAt.current === null) {
            return;
        }

        ensureSessionValidity();
    });

    const ensureSessionValidity = () => {
        if (renewAt.current === null || fetching.current) {
            return;
        }

        if (renewAt.current > (new Date()).valueOf()) {
            return;
        }

        refreshToken(false).then(() => {
            fetchMe();
        }).catch(() => {
            fetching.current = false;
        });
    };

    const retrieveSession = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("access_token");

        if (typeof token === "string" && token.trim().length > 10) {
            Api.setAuthToken(token);
            fetchMe();
        } else {
            refreshToken(false).then(() => {
                fetchMe();
            }).catch(() => {
                fetching.current = false;
            });
        }
    };

    const refreshToken = (unlock?: boolean): Promise<undefined> => {
        return new Promise((resolve, reject) => {
            if (fetching.current) {
                reject();
                return;
            }

            fetching.current = true;

            Api.post<{
                data: {
                    access_token: string,
                    expires: number
                }
            }>("/auth/refresh", {}, {
                includeToken: false,
                ignoreAuthState: true,
            }).then(r => {
                const d = new Date();
                d.setMilliseconds(d.getMilliseconds() + r.data.expires - 1000);
                renewAt.current = d.valueOf();

                Api.setTokenValidity(renewAt.current);
                Api.setAuthToken(r.data.access_token);
                setToken(r.data.access_token);

                resolve(undefined);
            }).catch(() => {
                setLoginState(LoginState.FAILED);
                Api.resetAuthToken();
                setLoading(false);
                setToken(null);
                removeCookie("directus_refresh_token");

                reject();
            }).finally(() => {
                if (unlock !== false) {
                    fetching.current = false;
                }
            });
        });
    };

    const authenticate = (mail: string, password: string): Promise<undefined> => {
        return new Promise<undefined>((resolve, reject) => {
            Api.post<{
                data: {
                    access_token: string,
                    expires: number
                }
            }>("/auth/login", {
                email: mail,
                password,
                mode: "cookie"
            }, {
                ignoreAuthState: true
            }).then(r => {
                const d = new Date();
                d.setMilliseconds(d.getMilliseconds() + r.data.expires - 1000);
                renewAt.current = d.valueOf();

                Api.setTokenValidity(renewAt.current);
                Api.setAuthToken(r.data.access_token);
                setToken(r.data.access_token);
                fetchMe();

                resolve(undefined);
            }).catch(reject);
        });
    };

    const fetchMe = (triggerEvent ?: boolean, callback ?: () => void) => {
        Api.get<{
            data: User
        }>("/users/me", undefined, {
            fields: ["*", "society_logo.*"],
            ignoreAuthState: true
        }).then(u => {
            setUser(u.data);
            setLoginState(LoginState.LOGGED);

            if (triggerEvent !== false) {
                // Tag manager login
                TagManager.dataLayer({
                    dataLayer: {
                        event: "login",
                        userId: u.data.id
                    }
                });
            }
        }).catch(() => {
            setLoginState(LoginState.FAILED);
            Api.resetAuthToken();
            setToken(null);
        }).finally(() => {
            setLoading(false);
            fetching.current = false;

            if (callback !== undefined) {
                callback();
            }
        });
    };

    const getMissingInformation = useCallback((): (keyof User)[] => {
        if (user === null) {
            return [];
        }

        const missing: (keyof User)[] = [];

        (config?.required_account_fields ?? []).forEach(k => {
            if (user[k] !== null && user[k] !== undefined && ("" + user[k]).trim().length > 0) {
                return;
            }

            missing.push(k);
        });

        return missing;
    }, [user, config]);

    const isAccountComplete = useMemo(() => {
        if (user === null) {
            return false;
        }

        return getMissingInformation().length === 0;
    }, [user, config]);

    const getAccountFieldLabel = (field: keyof User): string => {
        switch (field) {
            case "email":
                return t("Adresse mail");
            case "last_name":
                return t("Nom");
            case "first_name":
                return t("Prénom");
            case "phone":
                return t("Numéro de téléphone");
            case "society_identification":
                return t("Numéro d'identification");
            case "society_legal_status":
                return t("Forme légale");
            case "society_location_address":
                return t("Adresse");
            case "society_location_country":
                return t("Pays");
            case "society_location_city":
                return t("Ville");
            case "society_location_zipcode":
                return t("Code postal");
            case "society_vat_number":
                return t("Numéro de TVA intracommunautaire");
            case "sav_phone":
                return t("Numéro de téléphone du SAV");
            case "society_name":
                return t("Dénomination de la société");
        }

        return field;
    };

    return <AuthContext.Provider value={{
        user: {
            ...(user ?? {} as User),
            access_token: token ?? undefined
        },
        setUser,
        loginState,
        setLoginState,
        authenticate,
        isAccountComplete,
        getAccountFieldLabel,
        tokenExpiration: renewAt.current,
        refreshToken,
        refreshUser: () => new Promise(resolve => {
            fetchMe(false, () => resolve(undefined));
        })
    }}>
        {(loading || (loginState === LoginState.LOGGED && Object.keys(config ?? {}).length === 0)) ?
          <div className={styles.loader}>
              <div className={styles.loaderSpinner}/>
              <p>{t("Chargement")}</p>
          </div> : children}

        {loginState === LoginState.FAILED && <CookieConsent
            location="bottom"
            buttonText={t("Accepter")}
            cookieName="consentCookie"
            disableStyles={true}
            containerClasses={styles.cookieBand}
            contentClasses={styles.cookieContent}
            buttonClasses={styles.cookieButton}
            expires={150}
        >
            {t("Ce site web utilise des cookies pour améliorer votre expérience utilisateur")}
        </CookieConsent>}
    </AuthContext.Provider>;
}