import classNames from "classnames";
import styles from "./signup.module.scss";
import {FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo} from "react";
import {Redirect} from "react-router";
import {useTranslation} from "react-i18next";
import kamatLogo from "./assets/kamatLogo.svg";
import AuthContext from "../../context/AuthContext";
import {LoginState} from "../../interfaces/Interfaces";
import {RegisterForm} from "./components/RegisterForm/RegisterForm";
import {Link, useLocation} from "react-router-dom";

export const Signup: FunctionComponent = () => {
    const {t} = useTranslation();
    const {loginState} = useContext(AuthContext);
    const location = useLocation();

    const isWidget = useMemo(() => (new URLSearchParams(location.search)).get("widget") !== null, [location]);
    const getWrapper = useCallback((children: ReactNode) => {
        if (isWidget) {
            return <div className={classNames(styles.loginContainerWidget)}>
                {children}
            </div>;
        }

        return <div className={classNames(styles.loginContainer)}>
            <div className="d-flex justify-content-center align-items-center">
                <img
                  src={kamatLogo}
                  alt={"logo"}
                  className={classNames(styles.logo)}
                />
            </div>

            <div
              className={classNames(
                styles.formContainer,
                "col-xl-5 offset-xl-7"
              )}
            >
                <h1 className={classNames(styles.title)}>{t("S'inscrire")}</h1>
                <Link to={"/login"} className={classNames(styles.back)}>{t("Déjà un compte ? Connectez-vous.")}</Link>

                <div className={classNames(styles.formContainerWrapper)}>
                    {children}
                </div>
            </div>
        </div>;
    }, [isWidget]);

    useEffect(() => {
        return () => {
            document.body.classList.remove("signup-widget");
        };
    }, []);

    useEffect(() => {
        if (isWidget) {
            document.body.classList.add("signup-widget");
        } else {
            document.body.classList.remove("signup-widget");
        }
    }, [isWidget]);

    if (loginState === LoginState.LOGGED) {
        const url = sessionStorage.getItem("url") || "/";
        sessionStorage.removeItem("url");
        return <Redirect to={url}/>;
    }

    return getWrapper(<RegisterForm widget={isWidget}/>) as JSX.Element;
};

export default Signup;
