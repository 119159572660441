import Button from "../../common/Button/Button";
import styles from "./error.module.scss";
import logo from "./logo.png";

export default function Error(): JSX.Element {
    return <div className={styles.error}>
        <img src={logo} alt={""}/>
        <h1>Oops, problème...</h1>
        <h2>La page que vous essayez de consulter ne semble pas être disponible ou n'a pas été trouvée.</h2>
        <Button size={"large"} faIcon={["fas", "home"]} to={"/"} bold rounded>Retour à l'accueil</Button>
    </div>;
}