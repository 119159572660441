import {forwardRef, SyntheticEvent, useImperativeHandle, useState} from "react";
import visaIcon from "./assets/Visa.svg";
import amexIcon from "./assets/Amex.svg";
import mastercardIcon from "./assets/Mastercard.svg";
import discoverIcon from "./assets/Discover.svg";
import maestroIcon from "./assets/Maestro.svg";
import cvvIcon from "./assets/cvv.png";
import styles from "./creditCardForm.module.scss";
import moment from "moment";
import classNames from "classnames";

interface CreditCartFormProps {
    label?: {
        cardNumber?: string,
        cardOwner?: string,
    }
}

export interface CreditCardFormRef {
    isValid: () => boolean,
    getValues: () => {
        cardNumber: string,
        cardMonth: string,
        cardYear: string,
        cardCvv: string,
        cardOwner: string
    },
}

// eslint-disable-next-line react/display-name
const CreditCartForm = forwardRef<CreditCardFormRef, CreditCartFormProps>((props, ref) => {
    const [ccn, setCcn] = useState("");
    const [expiration, setExpiration] = useState("");
    const [cvv, setCvv] = useState("");
    const [owner, setOwner] = useState("");

    const [error, setError] = useState<string | null>(null);
    const [ownerError, setOwnerError] = useState(false);

    useImperativeHandle(ref, () => ({
        isValid: validate,
        getValues: () => ({
            cardNumber: ccn,
            cardMonth: expiration.substring(0, 2),
            cardYear: expiration.substring(2, 4),
            cardCvv: cvv,
            cardOwner: owner
        })
    }));

    const validate = (): boolean => {
        setError(null);
        setOwnerError(false);

        if (!/^[0-9]{15,19}$/.test(ccn)) {
            setError("Le numéro de carte est invalide");
            return false;
        }

        if (!/^[0-9]{4}$/.test(expiration)) {
            setError("La date d'expiration est invalide");
            return false;
        }

        if (!/^[0-9]{3,4}$/.test(cvv)) {
            setError("Le numéro CVV n'est est invalide");
            return false;
        }

        const month = parseInt(expiration.substring(0, 2));
        const year = parseInt(expiration.substring(2, 4));
        const now = moment();

        if (year <= parseInt(now.format("YY")) && month <= parseInt(now.format("M"))) {
            setError("La carte est expirée");
            return false;
        }

        if (owner.trim().length <= 2) {
            setOwnerError(true);
            return false;
        }

        return true;
    };

    const getCardIcon = () => {
        if (ccn.startsWith("3")) {
            return <img src={amexIcon} alt={""}/>;
        } else if (ccn.startsWith("4")) {
            return <img src={visaIcon} alt={""}/>;
        } else if (ccn.startsWith("5")) {
            if (ccn.length >= 2 && ["1", "2", "3", "4", "5"].indexOf(ccn[1])) {
                return <img src={mastercardIcon} alt={""}/>;
            }
            return <img src={maestroIcon} alt={""}/>;
        } else if (ccn.startsWith("6")) {
            return <img src={discoverIcon} alt={""}/>;
        }

        return <i className={"uil uil-credit-card"}/>;
    };

    const onCCNChange = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const originalValue = target.value;
        let value = target.value;
        let cursor = target.selectionStart;

        setCcn((value.match(/\d+/g) ?? []).join(""));

        if (cursor === null) {
            return;
        }

        const matches = value.substring(0, cursor).match(/[^0-9]/g);

        if (matches) {
            cursor -= matches.length;
        }
        value = value.replace(/[^0-9]/g, "").substring(0, 16);
        let formatted = "";
        for (let i = 0, n = value.length; i < n; i++) {
            if (i && i % 4 === 0) {
                if (formatted.length <= cursor) {
                    cursor++;
                }

                formatted += " ";
            }
            formatted += value[i];
        }

        if (formatted === originalValue) {
            return;
        }

        target.value = formatted;
        target.selectionEnd = cursor;
    };

    const onCCNKeyDown = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const cursor = target.selectionStart;

        if (target.selectionEnd !== cursor || cursor === null || target.selectionStart === null || target.selectionEnd === null) {
            return;
        }
        if ((e as unknown as KeyboardEvent).which == 46 && target.value[cursor] == " ") {
            target.selectionStart++;
        } else if ((e as unknown as KeyboardEvent).which == 8 && cursor && target.value[cursor - 1] == " ") {
            target.selectionEnd--;
        }
    };

    const onExpirationChange = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const originalValue = target.value;
        let value = target.value;
        let cursor = target.selectionStart;

        setExpiration((value.match(/\d+/g) ?? []).join(""));

        if (cursor === null) {
            return;
        }

        const matches = value.substring(0, cursor).match(/[^0-9]/g);

        if (matches) {
            cursor -= matches.length;
        }

        value = value.replace(/[^0-9]/g, "").substring(0, 4);

        let formatted = "";
        for (let i = 0, n = value.length; i < n; i++) {
            if (i && i % 2 === 0) {
                if (formatted.length <= cursor) {
                    cursor += 3;
                }

                formatted += " / ";
            }

            formatted += value[i];
        }

        if (formatted === originalValue) {
            return;
        }

        target.value = formatted;
        target.selectionEnd = cursor;
    };

    const onCVCChange = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const value = target.value;
        let cursor = target.selectionStart;

        setCvv((value.match(/\d+/g) ?? []).join(""));

        if (cursor === null) {
            return;
        }

        const matches = value.substring(0, cursor).match(/[^0-9]/g);

        if (matches) {
            cursor -= matches.length;
        }

        target.value = value.replace(/[^0-9]/g, "").substring(0, 3);
        target.selectionEnd = cursor;
    };

    return <div className={classNames(styles.form, error !== null ? styles.formInvalid : "")}>
        <p>Informations de la carte</p>
        <div className={styles.formCcn}>
            <input autoComplete="cc-number" autoCorrect="off" spellCheck="false" type="text" inputMode="numeric"
                   placeholder="1234 1234 1234 1234" onInput={onCCNChange} onKeyDown={onCCNKeyDown}/>
            {getCardIcon()}
        </div>

        <input autoComplete="cc-exp" onInput={onExpirationChange}
               autoCorrect="off" spellCheck="false" type="text" inputMode="numeric"
               placeholder="MM / AA" className={styles.formCce}/>

        <div className={styles.formCvv}>
            <input autoComplete="cc-csc" onInput={onCVCChange}
                   autoCorrect="off" spellCheck="false" type="text" inputMode="numeric" placeholder="CVV"/>
            <img src={cvvIcon} alt={""}/>
        </div>

        {error !== null && <p className={styles.formError}>{error}</p>}

        <p style={{
            marginTop: 20
        }}>{props.label?.cardOwner ?? "Nom du titulaire de la carte"}</p>
        <input autoComplete="ccname" autoCorrect="off" spellCheck="false" type="text"
               className={classNames(styles.formCowner, ownerError ? styles.formCownerInvalid : undefined)}
               value={owner} onChange={e => setOwner((e.target as HTMLInputElement).value)}/>
        {ownerError && <p className={styles.formError}>Le titulaire de la carte doit être complété</p>}
    </div>;
});

export default CreditCartForm;