const configs = {
    localhost: {
        apiUrl: "http://localhost:8055/",
        frontUrl: "http://localhost:300",
        stripeKey: "pk_test_51IY9CRKTSEUTrLX6zVEWlW5ORzDKAUXfaErTsFmeJBsofxLsHrT2mWxUIkpSteGQk1hAcj7nlgj8JC5kyZUy7XRa00jC0lkwBM",
        cguLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/cgu_may_2021.pdf",
        ppLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/privacy_policy_may_2021.pdf",
        gtm: undefined
    },
    dev: {
        apiUrl: "https://api-dev.kamat.co",
        frontUrl: "https://app-dev.kamat.co",
        stripeKey: "pk_test_51IY9CRKTSEUTrLX6zVEWlW5ORzDKAUXfaErTsFmeJBsofxLsHrT2mWxUIkpSteGQk1hAcj7nlgj8JC5kyZUy7XRa00jC0lkwBM",
        cguLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/cgu_may_2021.pdf",
        ppLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/privacy_policy_may_2021.pdf",
        gtm: undefined
    },
    prod: {
        apiUrl: "https://api.kamat.co",
        frontUrl: "https://app.kamat.co",
        stripeKey: "pk_live_51IY9CRKTSEUTrLX6sgNqZ8zJYrwCsakudo36aJLUBpQIol3jtlMBgXYMjl0jut3fDq9ILYXBMpjlooLfH3anrjuT00B2xaNrpo",
        cguLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/cgu_may_2021.pdf",
        ppLink: "https://kamat-public.s3.eu-central-1.amazonaws.com/privacy_policy_may_2021.pdf",
        gtm: "GTM-TW5CM2M"
    },
};

export const config = {
    ...(configs[process.env.REACT_APP_CLIENT_DEV_ENV || "localhost"] || configs.localhost),
    vatRate: 17,
};

export const easytransacErrors = [{"Code": 1, "Error": "Requête invalide"}, {
    "Code": 2,
    "Error": "Protocole utilisé invalide, HTTPS obligatoire"
}, {"Code": 3, "Error": "Application invalide"}, {"Code": 4, "Error": "Clé d'API non renseignée"}, {
    "Code": 5,
    "Error": "Paramètre manquant"
}, {"Code": 7, "Error": "Configuration de compte invalide, veuillez nous contacter"}, {
    "Code": 8,
    "Error": "Le service ne peut pas être utilisé en mode démo"
}, {"Code": 9, "Error": "Service momentanément indisponible"}, {
    "Code": 10,
    "Error": "Service injoignable, veuillez réessayer"
}, {"Code": 11, "Error": "L'adresse IP du serveur n'est pas autorisée"}, {
    "Code": 12,
    "Error": "Signature invalide"
}, {"Code": 13, "Error": "Création impossible, vérifiez le contenu de vos paramètres"}, {
    "Code": 100,
    "Error": "Mot de passe invalide"
}, {"Code": 101, "Error": "Mot de passe manquant"}, {"Code": 102, "Error": "Token manquant ou invalide"}, {
    "Code": 103,
    "Error": "Utilisateur introuvable"
}, {"Code": 104, "Error": "Utilisateur non actif"}, {
    "Code": 105,
    "Error": "Compte invalide pour cette connexion"
}, {"Code": 106, "Error": "Email déjà utilisé"}, {"Code": 107, "Error": "Mot de passe peu sûr"}, {
    "Code": 108,
    "Error": "Aucune carte de crédit valide trouvée pour ce client"
}, {"Code": 109, "Error": "L'utilisation de jetons n'est pas autorisée avec cette application"}, {
    "Code": 110,
    "Error": "Utilisateur temporairement bloqué ou sur liste noire"
}, {"Code": 111, "Error": "Trop de tentatives, l'utilisateur a été désactivé"}, {
    "Code": 112,
    "Error": "Le paramètre Token est obligatoire"
}, {"Code": 113, "Error": "Le marchand renseigné est incorrect"}, {
    "Code": 114,
    "Error": "Périphérique introuvable"
}, {"Code": 115, "Error": "Code 2FA invalide"}, {
    "Code": 116,
    "Error": "Aucune clé publique trouvée pour cet appareil"
}, {"Code": 117, "Error": "La signature ne correspond pas"}, {
    "Code": 118,
    "Error": "Carte sur liste noire"
}, {"Code": 150, "Error": "L'email est invalide"}, {
    "Code": 151,
    "Error": "Le numéro de téléphone renseigné est incorrect"
}, {"Code": 152, "Error": "Le nom renseigné est incorrect"}, {
    "Code": 153,
    "Error": "Le montant renseigné est incorrect"
}, {"Code": 154, "Error": "Les champs nom ou prénom sont invalides"}, {
    "Code": 155,
    "Error": "L'IP du client est invalide"
}, {"Code": 156, "Error": "Numéro de carte bancaire invalide"}, {
    "Code": 157,
    "Error": "La date d'expiration de la carte bancaire est invalide"
}, {"Code": 158, "Error": "Le cryptogramme visuel est incorrect"}, {
    "Code": 160,
    "Error": "La limite renseignée est incorrecte (numérique et 1000 maximum)"
}, {"Code": 161, "Error": "L'IBAN renseigné est incorrect"}, {
    "Code": 162,
    "Error": "Le BIC renseigné est incorrect"
}, {"Code": 163, "Error": "Le numéro de TVA intracommunautaire est invalide"}, {
    "Code": 164,
    "Error": "Le titulaire de la carte ou du compte est invalide"
}, {"Code": 165, "Error": "La date de naissance est incorrecte"}, {
    "Code": 166,
    "Error": "Le numéro SIRET est invalide"
}, {"Code": 167, "Error": "L'adresse renseignée est invalide"}, {
    "Code": 168,
    "Error": "Le code postal est invalide"
}, {"Code": 169, "Error": "La ville renseignée est incorrecte"}, {
    "Code": 170,
    "Error": "La nationalité renseignée est incorrecte"
}, {"Code": 171, "Error": "L'entreprise est invalide"}, {
    "Code": 172,
    "Error": "L'indicatif téléphonique est invalide"
}, {"Code": 173, "Error": "Le numéro de commande ne respecte pas le bon format"}, {
    "Code": 174,
    "Error": "L'identifiant utilisateur ne respecte pas le bon format"
}, {"Code": 175, "Error": "Le paramètre de langue est incorrect"}, {
    "Code": 176,
    "Error": "Le comportement des virements est incorrect"
}, {"Code": 178, "Error": "Les paramètres From ou To sont invalides, vérifiez le format AAAA-MM-JJ"}, {
    "Code": 179,
    "Error": "Le Client est incorrect"
}, {"Code": 180, "Error": "L'Alias renseigné est incorrect"}, {
    "Code": 181,
    "Error": "Le paramètre Tid est invalide"
}, {"Code": 182, "Error": "Le paramètre OperationType est incorrect"}, {
    "Code": 183,
    "Error": "Le paramètre ReturnUrl est invalide"
}, {"Code": 184, "Error": "Le paramètre CancelUrl est invalide"}, {
    "Code": 185,
    "Error": "Le pays renseigné est incorrect"
}, {"Code": 186, "Error": "Paramètre Produits invalide"}, {
    "Code": 187,
    "Error": "Paramètre SendLater non valide, vérifier le format AAAA-MM-JJ"
}, {"Code": 188, "Error": "Le genre est invalide"}, {"Code": 189, "Error": "Paramètre Page invalide"}, {
    "Code": 190,
    "Error": "Le nom de naissance est invalide"
}, {"Code": 191, "Error": "L'activité renseignée est invalide"}, {
    "Code": 192,
    "Error": "Le type d'entreprise est invalide"
}, {"Code": 193, "Error": "Le pays de l'entreprise est incorrect"}, {
    "Code": 194,
    "Error": "La fréquence de virement est invalide"
}, {"Code": 195, "Error": "L'adresse 0 de l'entreprise est invalide"}, {
    "Code": 196,
    "Error": "L'adresse de l'entreprise est invalide"
}, {"Code": 197, "Error": "Le code postal de l'entreprise est invalide"}, {
    "Code": 198,
    "Error": "La ville de l'entreprise est invalide"
}, {"Code": 199, "Error": "Le paramètre de date est invalide"}, {
    "Code": 200,
    "Error": "Une erreur s'est produite, merci de nous contacter si le problème persiste"
}, {"Code": 201, "Error": "Erreur lors de l'ajout de la carte au système d'acquisition"}, {
    "Code": 202,
    "Error": "Un remboursement est déjà en mémoire tampon, veuillez patienter"
}, {"Code": 203, "Error": "Aucune transaction trouvée"}, {"Code": 204, "Error": "Transaction invalide"}, {
    "Code": 205,
    "Error": "Le 3DSecure est obligatoire pour ce paiement"
}, {"Code": 206, "Error": "Le remboursement a échoué"}, {"Code": 207, "Error": "Client introuvable"}, {
    "Code": 208,
    "Error": "L'annulation de paiement récurrent a échoué"
}, {"Code": 209, "Error": "Cette transaction n'est pas récurrente ou ne peut être annulée"}, {
    "Code": 210,
    "Error": "Carte bancaire non supportée"
}, {"Code": 211, "Error": "La conversion de devise a échoué"}, {
    "Code": 212,
    "Error": "Remboursement impossible, solde insuffisant"
}, {"Code": 213, "Error": "L'OperationType demandé n'est pas autorisé sur ce compte"}, {
    "Code": 214,
    "Error": "Remboursement impossible, limite de temps dépassée"
}, {"Code": 215, "Error": "Le remboursement dépasse le montant de la transaction"}, {
    "Code": 216,
    "Error": "Les produits ne sont pas autorisés"
}, {"Code": 217, "Error": "Entrée de produit incorrecte"}, {
    "Code": 218,
    "Error": "Montant total incorrect pour les produits choisis"
}, {
    "Code": 219,
    "Error": "Le remboursement partiel n'est pas supporté avec cette configuration de compte"
}, {"Code": 220, "Error": "La préautorisation et la capture ne sont pas autorisées"}, {
    "Code": 221,
    "Error": "Aucune page de paiement trouvée ou statut incorrect"
}, {"Code": 222, "Error": "La validation a échoué"}, {"Code": 223, "Error": "L'annulation a échoué"}, {
    "Code": 224,
    "Error": "Carte bancaire introuvable"
}, {"Code": 225, "Error": "Aucune information trouvée"}, {"Code": 226, "Error": "Virement introuvable"}, {
    "Code": 227,
    "Error": "L'email du client ne peut pas être identique au vôtre"
}, {"Code": 228, "Error": "La capture dépasse le montant de la pré-autorisation"}, {
    "Code": 229,
    "Error": "Valeur incorrecte des frais"
}, {"Code": 230, "Error": "Limite du nombre d'envois atteinte"}, {
    "Code": 231,
    "Error": "Cette page n'est pas programmée pour un envoi par email ou SMS"
}, {"Code": 232, "Error": "Aucun virement trouvé"}, {
    "Code": 233,
    "Error": "Aucune opération trouvée pour ce compte"
}, {"Code": 300, "Error": "KYC incomplètes or invalides"}, {
    "Code": 301,
    "Error": "Paiement refusé par la politique anti-fraude"
}, {"Code": 302, "Error": "Montant maximal par jour et par carte de crédit atteint"}, {
    "Code": 303,
    "Error": "Montant maximal par carte de crédit atteint"
}, {"Code": 304, "Error": "Le montant maximal autorisé est dépassé"}, {
    "Code": 305,
    "Error": "Nombre maximal de transactions capturées par jour atteint pour cette carte de crédit"
}, {
    "Code": 306,
    "Error": "Nombre maximal de transactions échouées par jour atteint pour cette carte de crédit"
}, {
    "Code": 307,
    "Error": "Votre compte ne permet pas encore d'effectuer des paiements en plusieurs fois ou des abonnements"
}, {"Code": 308, "Error": "Pays de la carte non autorisé"}, {
    "Code": 400,
    "Error": "Veuillez choisir entre Rebill et MultiplePayments"
}, {"Code": 401, "Error": "Acompte invalide"}, {
    "Code": 402,
    "Error": "Le versement initial ne peut excéder 70% du montant total"
}, {"Code": 403, "Error": "Le versement initial ne peut être inférieur à 1€"}, {
    "Code": 404,
    "Error": "Paramètre MultiplePaymentsRepeat non valide, doit être numérique uniquement et compris entre 2 et 10"
}, {"Code": 405, "Error": "Rebill n'est pas compatible avec le paramètre MultiplePaymentsRepeat"}, {
    "Code": 407,
    "Error": "Les paiements multiples doivent être d'un minimum de 50,00 euros"
}, {"Code": 408, "Error": "Paramètre RebillRecurrence non valide"}, {
    "Code": 409,
    "Error": "La durée totale des paiements multiples ne peut dépasser 90 jours"
}, {"Code": 410, "Error": "Paiement récurrent non supporté avec cette carte bancaire"}, {
    "Code": 411,
    "Error": "Extension de fichier non autorisée"
}, {"Code": 412, "Error": "Le nom du fichier transmis est incorrect ou contient des caractères spéciaux"}, {
    "Code": 413,
    "Error": "Ce paiement en plusieurs fois est déjà complet"
}, {"Code": 414, "Error": "Paramètres de paiement en plusieurs fois non valides"}, {
    "Code": 415,
    "Error": "Le paiement en plusieurs fois est terminé ou annulé"
}, {"Code": 500, "Error": "KYC déjà existant"}, {"Code": 501, "Error": "Paramètre DocumentType invalide"}, {
    "Code": 502,
    "Error": "Paramètre DocumentId invalide"
}, {"Code": 503, "Error": "Document introuvable"}, {
    "Code": 504,
    "Error": "L'extension du document n'est pas supportée, veuillez utiliser les fichiers JPG | PNG | TIFF | PDF"
}, {"Code": 505, "Error": "Contenu du document non valide, taille ou type MIME"}, {
    "Code": 506,
    "Error": "La vérification a échoué"
}, {
    "Code": 507,
    "Error": "L'extension du logo n'est pas supportée, veuillez utiliser les fichiers JPG | PNG"
}, {"Code": 508, "Error": "Le logo doit avoir un format carré d'au moins 256x256"}, {
    "Code": 509,
    "Error": "Taille maximale autorisée de 200ko"
}, {"Code": 600, "Error": "Paramètre PayoutId invalide"}, {
    "Code": 601,
    "Error": "Un virement est déjà en cours"
}, {"Code": 602, "Error": "Solde disponible insuffisant"}, {
    "Code": 603,
    "Error": "L'IBAN du client doit être en zone SEPA"
}, {"Code": 604, "Error": "Mauvaise configuration d'acquéreur"}, {
    "Code": 605,
    "Error": "Le transfert P2P a échoué"
}, {"Code": 606, "Error": "Transfert P2P impossible, solde insuffisant"}, {
    "Code": 607,
    "Error": "Limite de virements quotidiens dépassée"
}, {"Code": 608, "Error": "Virements manuels temporairement bloqués"}, {
    "Code": 609,
    "Error": "Le statut du compte ne permet pas de recevoir un virement"
}, {"Code": 700, "Error": "Le nom du produit est incorrect"}, {
    "Code": 701,
    "Error": "Le nom de la catégorie est incorrecte"
}, {"Code": 702, "Error": "La catégorie existe déjà"}, {"Code": 703, "Error": "Catégorie introuvable"}, {
    "Code": 704,
    "Error": "Produit introuvable"
}, {"Code": 705, "Error": "La référence n'est pas valide"}, {
    "Code": 706,
    "Error": "La référence interne n'est pas valide"
}, {"Code": 707, "Error": "Vous n'avez pas accès à cette action"}, {
    "Code": 708,
    "Error": "Format attendu: jpg, jpeg, png"
}, {"Code": 709, "Error": "La taille du fichier doit être inférieure ou égale à 100ko"}, {
    "Code": 10000,
    "Error": "Voucher introuvable"
}, {"Code": 10001, "Error": "Voucher expiré"}, {"Code": 10002, "Error": "Voucher déjà consommé"}, {
    "Code": 10003,
    "Error": "Fonds insuffisants sur le voucher"
}];

export const countries = {
    "AF": {"name": "Afghanistan", "alpha_3": "AFG"},
    "AX": {"name": "Iles Aland", "alpha_3": "ALA"},
    "AL": {"name": "Albanie", "alpha_3": "ALB"},
    "DZ": {"name": "Algérie", "alpha_3": "DZA"},
    "AS": {"name": "Samoa américaines", "alpha_3": "ASM"},
    "AD": {"name": "Andorre", "alpha_3": "AND"},
    "AO": {"name": "Angola", "alpha_3": "AGO"},
    "AI": {"name": "Anguilla", "alpha_3": "AIA"},
    "AQ": {"name": "Antarctique", "alpha_3": "ATA"},
    "AG": {"name": "Antigua-et-Barbuda", "alpha_3": "ATG"},
    "AR": {"name": "Argentine", "alpha_3": "ARG"},
    "AM": {"name": "Arménie", "alpha_3": "ARM"},
    "AW": {"name": "Aruba", "alpha_3": "ABW"},
    "AU": {"name": "Australie", "alpha_3": "AUS"},
    "AT": {"name": "Autriche", "alpha_3": "AUT"},
    "AZ": {"name": "Azerbaïdjan", "alpha_3": "AZE"},
    "BS": {"name": "Bahamas", "alpha_3": "BHS"},
    "BH": {"name": "Bahreïn", "alpha_3": "BHR"},
    "BD": {"name": "Bangladesh", "alpha_3": "BGD"},
    "BB": {"name": "Barbade", "alpha_3": "BRB"},
    "BY": {"name": "Biélorussie", "alpha_3": "BLR"},
    "BE": {"name": "Belgique", "alpha_3": "BEL"},
    "BZ": {"name": "Belize", "alpha_3": "BLZ"},
    "BJ": {"name": "Bénin", "alpha_3": "BEN"},
    "BM": {"name": "Bermudes", "alpha_3": "BMU"},
    "BT": {"name": "Bhoutan", "alpha_3": "BTN"},
    "BO": {"name": "Bolivie", "alpha_3": "BOL"},
    "BQ": {"name": "Bonaire, Saint-Eustache et Saba", "alpha_3": "BES"},
    "BA": {"name": "Bosnie Herzégovine", "alpha_3": "BIH"},
    "BW": {"name": "Botswana", "alpha_3": "BWA"},
    "BV": {"name": "Île Bouvet", "alpha_3": "BVT"},
    "BR": {"name": "Brésil", "alpha_3": "BRA"},
    "IO": {"name": "Territoire britannique de l'océan Indien", "alpha_3": "IOT"},
    "BN": {"name": "Brunei Darussalam", "alpha_3": "BRN"},
    "BG": {"name": "Bulgarie", "alpha_3": "BGR"},
    "BF": {"name": "Burkina Faso", "alpha_3": "BFA"},
    "BI": {"name": "Burundi", "alpha_3": "BDI"},
    "KH": {"name": "Cambodge", "alpha_3": "KHM"},
    "CM": {"name": "Cameroun", "alpha_3": "CMR"},
    "CA": {"name": "Canada", "alpha_3": "CAN"},
    "CV": {"name": "Cap-Vert", "alpha_3": "CPV"},
    "KY": {"name": "Îles Caïmans", "alpha_3": "CYM"},
    "CF": {"name": "République centrafricaine", "alpha_3": "CAF"},
    "TD": {"name": "Tchad", "alpha_3": "TCD"},
    "CL": {"name": "Chili", "alpha_3": "CHL"},
    "CN": {"name": "Chine", "alpha_3": "CHN"},
    "CX": {"name": "Île de noël", "alpha_3": "CXR"},
    "CC": {"name": "Îles Cocos (Keeling)", "alpha_3": "CCK"},
    "CO": {"name": "Colombie", "alpha_3": "COL"},
    "KM": {"name": "Comores", "alpha_3": "COM"},
    "CG": {"name": "Congo", "alpha_3": "COG"},
    "CD": {"name": "Congo, République démocratique du Congo", "alpha_3": "COD"},
    "CK": {"name": "Îles Cook", "alpha_3": "COK"},
    "CR": {"name": "Costa Rica", "alpha_3": "CRI"},
    "CI": {"name": "Côte d'Ivoire", "alpha_3": "CIV"},
    "HR": {"name": "Croatie", "alpha_3": "HRV"},
    "CU": {"name": "Cuba", "alpha_3": "CUB"},
    "CW": {"name": "Curacao", "alpha_3": "CUW"},
    "CY": {"name": "Chypre", "alpha_3": "CYP"},
    "CZ": {"name": "République Tchèque", "alpha_3": "CZE"},
    "DK": {"name": "Danemark", "alpha_3": "DNK"},
    "DJ": {"name": "Djibouti", "alpha_3": "DJI"},
    "DM": {"name": "Dominique", "alpha_3": "DMA"},
    "DO": {"name": "République dominicaine", "alpha_3": "DOM"},
    "EC": {"name": "Equateur", "alpha_3": "ECU"},
    "EG": {"name": "Egypte", "alpha_3": "EGY"},
    "SV": {"name": "Le Salvador", "alpha_3": "SLV"},
    "GQ": {"name": "Guinée Équatoriale", "alpha_3": "GNQ"},
    "ER": {"name": "Érythrée", "alpha_3": "ERI"},
    "EE": {"name": "Estonie", "alpha_3": "EST"},
    "ET": {"name": "Ethiopie", "alpha_3": "ETH"},
    "FK": {"name": "Îles Falkland (Malvinas)", "alpha_3": "FLK"},
    "FO": {"name": "Îles Féroé", "alpha_3": "FRO"},
    "FJ": {"name": "Fidji", "alpha_3": "FJI"},
    "FI": {"name": "Finlande", "alpha_3": "FIN"},
    "FR": {"name": "France", "alpha_3": "FRA"},
    "GF": {"name": "Guyane Française", "alpha_3": "GUF"},
    "PF": {"name": "Polynésie française", "alpha_3": "PYF"},
    "TF": {"name": "Terres australes françaises", "alpha_3": "ATF"},
    "GA": {"name": "Gabon", "alpha_3": "GAB"},
    "GM": {"name": "Gambie", "alpha_3": "GMB"},
    "GE": {"name": "Géorgie", "alpha_3": "GEO"},
    "DE": {"name": "Allemagne", "alpha_3": "DEU"},
    "GH": {"name": "Ghana", "alpha_3": "GHA"},
    "GI": {"name": "Gibraltar", "alpha_3": "GIB"},
    "GR": {"name": "Grèce", "alpha_3": "GRC"},
    "GL": {"name": "Groenland", "alpha_3": "GRL"},
    "GD": {"name": "Grenade", "alpha_3": "GRD"},
    "GP": {"name": "Guadeloupe", "alpha_3": "GLP"},
    "GU": {"name": "Guam", "alpha_3": "GUM"},
    "GT": {"name": "Guatemala", "alpha_3": "GTM"},
    "GG": {"name": "Guernesey", "alpha_3": "GGY"},
    "GN": {"name": "Guinée", "alpha_3": "GIN"},
    "GW": {"name": "Guinée-Bissau", "alpha_3": "GNB"},
    "GY": {"name": "Guyane", "alpha_3": "GUY"},
    "HT": {"name": "Haïti", "alpha_3": "HTI"},
    "HM": {"name": "Îles Heard et McDonald", "alpha_3": "HMD"},
    "VA": {"name": "Saint-Siège (État de la Cité du Vatican)", "alpha_3": "VAT"},
    "HN": {"name": "Honduras", "alpha_3": "HND"},
    "HK": {"name": "Hong Kong", "alpha_3": "HKG"},
    "HU": {"name": "Hongrie", "alpha_3": "HUN"},
    "IS": {"name": "Islande", "alpha_3": "ISL"},
    "IN": {"name": "Inde", "alpha_3": "IND"},
    "ID": {"name": "Indonésie", "alpha_3": "IDN"},
    "IR": {"name": "Iran (République islamique d", "alpha_3": "IRN"},
    "IQ": {"name": "Irak", "alpha_3": "IRQ"},
    "IE": {"name": "Irlande", "alpha_3": "IRL"},
    "IM": {"name": "île de Man", "alpha_3": "IMN"},
    "IL": {"name": "Israël", "alpha_3": "ISR"},
    "IT": {"name": "Italie", "alpha_3": "ITA"},
    "JM": {"name": "Jamaïque", "alpha_3": "JAM"},
    "JP": {"name": "Japon", "alpha_3": "JPN"},
    "JE": {"name": "Jersey", "alpha_3": "JEY"},
    "JO": {"name": "Jordan", "alpha_3": "JOR"},
    "KZ": {"name": "Kazakhstan", "alpha_3": "KAZ"},
    "KE": {"name": "Kenya", "alpha_3": "KEN"},
    "KI": {"name": "Kiribati", "alpha_3": "KIR"},
    "KP": {"name": "République populaire démocratique de Corée", "alpha_3": "PRK"},
    "KR": {"name": "Corée, République de", "alpha_3": "KOR"},
    "XK": {"name": "Kosovo", "alpha_3": "XKX"},
    "KW": {"name": "Koweit", "alpha_3": "KWT"},
    "KG": {"name": "Kirghizistan", "alpha_3": "KGZ"},
    "LA": {"name": "République démocratique populaire lao", "alpha_3": "LAO"},
    "LV": {"name": "Lettonie", "alpha_3": "LVA"},
    "LB": {"name": "Liban", "alpha_3": "LBN"},
    "LS": {"name": "Lesotho", "alpha_3": "LSO"},
    "LR": {"name": "Libéria", "alpha_3": "LBR"},
    "LY": {"name": "Jamahiriya arabe libyenne", "alpha_3": "LBY"},
    "LI": {"name": "Liechtenstein", "alpha_3": "LIE"},
    "LT": {"name": "Lituanie", "alpha_3": "LTU"},
    "LU": {"name": "Luxembourg", "alpha_3": "LUX"},
    "MO": {"name": "Macao", "alpha_3": "MAC"},
    "MK": {"name": "Macédoine, ancienne République yougoslave de", "alpha_3": "MKD"},
    "MG": {"name": "Madagascar", "alpha_3": "MDG"},
    "MW": {"name": "Malawi", "alpha_3": "MWI"},
    "MY": {"name": "Malaisie", "alpha_3": "MYS"},
    "MV": {"name": "Maldives", "alpha_3": "MDV"},
    "ML": {"name": "Mali", "alpha_3": "MLI"},
    "MT": {"name": "Malte", "alpha_3": "MLT"},
    "MH": {"name": "Iles Marshall", "alpha_3": "MHL"},
    "MQ": {"name": "Martinique", "alpha_3": "MTQ"},
    "MR": {"name": "Mauritanie", "alpha_3": "MRT"},
    "MU": {"name": "Ile Maurice", "alpha_3": "MUS"},
    "YT": {"name": "Mayotte", "alpha_3": "MYT"},
    "MX": {"name": "Mexique", "alpha_3": "MEX"},
    "FM": {"name": "Micronésie, États fédérés de", "alpha_3": "FSM"},
    "MD": {"name": "Moldova, République de", "alpha_3": "MDA"},
    "MC": {"name": "Monaco", "alpha_3": "MCO"},
    "MN": {"name": "Mongolie", "alpha_3": "MNG"},
    "ME": {"name": "Monténégro", "alpha_3": "MNE"},
    "MS": {"name": "Montserrat", "alpha_3": "MSR"},
    "MA": {"name": "Maroc", "alpha_3": "MAR"},
    "MZ": {"name": "Mozambique", "alpha_3": "MOZ"},
    "MM": {"name": "Myanmar", "alpha_3": "MMR"},
    "NA": {"name": "Namibie", "alpha_3": "NAM"},
    "NR": {"name": "Nauru", "alpha_3": "NRU"},
    "NP": {"name": "Népal", "alpha_3": "NPL"},
    "NL": {"name": "Pays-Bas", "alpha_3": "NLD"},
    "AN": {"name": "Antilles néerlandaises", "alpha_3": "ANT"},
    "NC": {"name": "Nouvelle Calédonie", "alpha_3": "NCL"},
    "NZ": {"name": "Nouvelle-Zélande", "alpha_3": "NZL"},
    "NI": {"name": "Nicaragua", "alpha_3": "NIC"},
    "NE": {"name": "Niger", "alpha_3": "NER"},
    "NG": {"name": "Nigeria", "alpha_3": "NGA"},
    "NU": {"name": "Niue", "alpha_3": "NIU"},
    "NF": {"name": "l'ile de Norfolk", "alpha_3": "NFK"},
    "MP": {"name": "Îles Mariannes du Nord", "alpha_3": "MNP"},
    "NO": {"name": "Norvège", "alpha_3": "NOR"},
    "OM": {"name": "Oman", "alpha_3": "OMN"},
    "PK": {"name": "Pakistan", "alpha_3": "PAK"},
    "PW": {"name": "Palau", "alpha_3": "PLW"},
    "PS": {"name": "Territoire palestinien, occupé", "alpha_3": "PSE"},
    "PA": {"name": "Panama", "alpha_3": "PAN"},
    "PG": {"name": "Papouasie Nouvelle Guinée", "alpha_3": "PNG"},
    "PY": {"name": "Paraguay", "alpha_3": "PRY"},
    "PE": {"name": "Pérou", "alpha_3": "PER"},
    "PH": {"name": "Philippines", "alpha_3": "PHL"},
    "PN": {"name": "Pitcairn", "alpha_3": "PCN"},
    "PL": {"name": "Pologne", "alpha_3": "POL"},
    "PT": {"name": "Le Portugal", "alpha_3": "PRT"},
    "PR": {"name": "Porto Rico", "alpha_3": "PRI"},
    "QA": {"name": "Qatar", "alpha_3": "QAT"},
    "RE": {"name": "Réunion", "alpha_3": "REU"},
    "RO": {"name": "Roumanie", "alpha_3": "ROM"},
    "RU": {"name": "Fédération Russe", "alpha_3": "RUS"},
    "RW": {"name": "Rwanda", "alpha_3": "RWA"},
    "BL": {"name": "Saint Barthélemy", "alpha_3": "BLM"},
    "SH": {"name": "Sainte-Hélène", "alpha_3": "SHN"},
    "KN": {"name": "Saint-Christophe-et-Niévès", "alpha_3": "KNA"},
    "LC": {"name": "Sainte-Lucie", "alpha_3": "LCA"},
    "MF": {"name": "Saint Martin", "alpha_3": "MAF"},
    "PM": {"name": "Saint-Pierre-et-Miquelon", "alpha_3": "SPM"},
    "VC": {"name": "Saint-Vincent-et-les-Grenadines", "alpha_3": "VCT"},
    "WS": {"name": "Samoa", "alpha_3": "WSM"},
    "SM": {"name": "Saint Marin", "alpha_3": "SMR"},
    "ST": {"name": "Sao Tomé et Principe", "alpha_3": "STP"},
    "SA": {"name": "Arabie Saoudite", "alpha_3": "SAU"},
    "SN": {"name": "Sénégal", "alpha_3": "SEN"},
    "RS": {"name": "Serbie", "alpha_3": "SRB"},
    "CS": {"name": "Serbie et Monténégro", "alpha_3": "SCG"},
    "SC": {"name": "les Seychelles", "alpha_3": "SYC"},
    "SL": {"name": "Sierra Leone", "alpha_3": "SLE"},
    "SG": {"name": "Singapour", "alpha_3": "SGP"},
    "SX": {"name": "St Martin", "alpha_3": "SXM"},
    "SK": {"name": "Slovaquie", "alpha_3": "SVK"},
    "SI": {"name": "Slovénie", "alpha_3": "SVN"},
    "SB": {"name": "Les îles Salomon", "alpha_3": "SLB"},
    "SO": {"name": "Somalie", "alpha_3": "SOM"},
    "ZA": {"name": "Afrique du Sud", "alpha_3": "ZAF"},
    "GS": {"name": "Géorgie du Sud et îles Sandwich du Sud", "alpha_3": "SGS"},
    "SS": {"name": "Soudan du sud", "alpha_3": "SSD"},
    "ES": {"name": "Espagne", "alpha_3": "ESP"},
    "LK": {"name": "Sri Lanka", "alpha_3": "LKA"},
    "SD": {"name": "Soudan", "alpha_3": "SDN"},
    "SR": {"name": "Suriname", "alpha_3": "SUR"},
    "SJ": {"name": "Svalbard et Jan Mayen", "alpha_3": "SJM"},
    "SZ": {"name": "Swaziland", "alpha_3": "SWZ"},
    "SE": {"name": "Suède", "alpha_3": "SWE"},
    "CH": {"name": "la Suisse", "alpha_3": "CHE"},
    "SY": {"name": "République arabe syrienne", "alpha_3": "SYR"},
    "TW": {"name": "Taiwan, Province de Chine", "alpha_3": "TWN"},
    "TJ": {"name": "Tadjikistan", "alpha_3": "TJK"},
    "TZ": {"name": "Tanzanie, République-Unie de", "alpha_3": "TZA"},
    "TH": {"name": "Thaïlande", "alpha_3": "THA"},
    "TL": {"name": "Timor-Leste", "alpha_3": "TLS"},
    "TG": {"name": "Aller", "alpha_3": "TGO"},
    "TK": {"name": "Tokelau", "alpha_3": "TKL"},
    "TO": {"name": "Tonga", "alpha_3": "TON"},
    "TT": {"name": "Trinité-et-Tobago", "alpha_3": "TTO"},
    "TN": {"name": "Tunisie", "alpha_3": "TUN"},
    "TR": {"name": "dinde", "alpha_3": "TUR"},
    "TM": {"name": "Turkménistan", "alpha_3": "TKM"},
    "TC": {"name": "îles Turques-et-Caïques", "alpha_3": "TCA"},
    "TV": {"name": "Tuvalu", "alpha_3": "TUV"},
    "UG": {"name": "Ouganda", "alpha_3": "UGA"},
    "UA": {"name": "Ukraine", "alpha_3": "UKR"},
    "AE": {"name": "Emirats Arabes Unis", "alpha_3": "ARE"},
    "GB": {"name": "Royaume-Uni", "alpha_3": "GBR"},
    "US": {"name": "États-Unis", "alpha_3": "USA"},
    "UM": {"name": "Îles mineures éloignées des États-Unis", "alpha_3": "UMI"},
    "UY": {"name": "Uruguay", "alpha_3": "URY"},
    "UZ": {"name": "Ouzbékistan", "alpha_3": "UZB"},
    "VU": {"name": "Vanuatu", "alpha_3": "VUT"},
    "VE": {"name": "Venezuela", "alpha_3": "VEN"},
    "VN": {"name": "Viet Nam", "alpha_3": "VNM"},
    "VG": {"name": "Îles Vierges britanniques", "alpha_3": "VGB"},
    "VI": {"name": "Îles Vierges américaines, États-Unis", "alpha_3": "VIR"},
    "WF": {"name": "Wallis et Futuna", "alpha_3": "WLF"},
    "EH": {"name": "Sahara occidental", "alpha_3": "ESH"},
    "YE": {"name": "Yémen", "alpha_3": "YEM"},
    "ZM": {"name": "Zambie", "alpha_3": "ZMB"},
    "ZW": {"name": "Zimbabwe", "alpha_3": "ZWE"}
};