import {FunctionComponent, useContext, useEffect, useState} from "react";
import {CatalogProduct} from "interfaces/Interfaces";
import {useParams} from "react-router";
import {CatalogGrid} from "common/CatalogGrid/CatalogGrid";
import Api, {ApiFilter} from "../../../../classes/Api";
import {RentalCatalogBreadcrumb, RentalCatalogContext} from "../../RentalCatalog";
import ProductDetail from "../ProductDetail/ProductDetail";
import RentProductModal from "../RentProductModal/RentProductModal";
import {useDidMountEffect} from "../../../../utils/Component";
import AuthContext from "../../../../context/AuthContext";
import ConfirmModal from "../../../../common/ConfirmModal/ConfirmModal";
import {useHistory} from "react-router-dom";

export const ProductList: FunctionComponent = () => {
    const {setBreadcrumb, search, setPromotion, cache} = useContext(RentalCatalogContext);
    const {user} = useContext(AuthContext);

    const [products, setProducts] = useState<CatalogProduct[]>([]);
    const [product, setProduct] = useState<CatalogProduct | null>(null);
    const [rent, setRent] = useState<CatalogProduct | null>(null);
    const [incompleteAccount, setIncompleteAccount] = useState(false);

    const {mainCategoryCode, subCategoryCode} = useParams<{
        mainCategoryCode: string;
        subCategoryCode: string;
    }>();

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setPromotion(1);
        fetch();
    }, []);

    useDidMountEffect(() => {
        fetch();
    }, [search]);

    const fetch = () => {
        const keyCache = mainCategoryCode + "_" + subCategoryCode;
        let filter: ApiFilter = {};

        if (search === null || search.trim().length < 3) {
            if (cache.hasOwnProperty(keyCache)) {
                initBreadcrumb([...cache[keyCache] as CatalogProduct[]]);
                setProducts(cache[keyCache] as CatalogProduct[]);
                return;
            }

            filter = {
                family: {
                    slug: {
                        _eq: subCategoryCode
                    }
                }
            };
        } else {
            setBreadcrumb([]);
        }

        setLoading(true);
        Api.get<{
            data: CatalogProduct[]
        }>("/items/catalog_product", filter, {
            fields: [
                "*",
                "family.*",
                "family.category.*"
            ],
            queries: search !== null && search.trim().length >= 3 ? [`search=${search}`] : undefined
        }).then(d => {
            if (search === null || search.trim().length < 3) {
                cache[keyCache] = [...d.data];
            }

            initBreadcrumb([...d.data]);
            setProducts(d.data);
        }).finally(() => setLoading(false));
    };

    const initBreadcrumb = (products: CatalogProduct[]) => {
        if (subCategoryCode === undefined) {
            return;
        }

        const product = products.shift();

        if (product === undefined) {
            console.log(subCategoryCode);
            Api.get<RentalCatalogBreadcrumb>(`/kamat/catalog-breadcrumb?family=${subCategoryCode}`).then(r => {
                if (Array.isArray(r) && r.length > 0) {
                    setBreadcrumb(r);
                    return;
                }

                setBreadcrumb([]);
            }).catch(() => {
                setBreadcrumb([]);
            });
            return;
        }

        if (product.id === -1) {
            return;
        }

        const breadcrumb: RentalCatalogBreadcrumb = [];

        if (typeof product.family === "object") {
            breadcrumb.push({
                label: product.family.label,
                slug: product.family.slug
            });

            if (typeof product.family.category === "object") {
                breadcrumb.push({
                    label: product.family.category.label,
                    slug: product.family.category.slug
                });
            }
        }

        setBreadcrumb(breadcrumb.reverse());
    };

    const onRent = (product: CatalogProduct) => {
        if (user.verified === true) {
            setRent(product);
            return;
        }

        setIncompleteAccount(true);
    };

    return <>
        <CatalogGrid
          array={products}
          productCardType={true}
          onProductDetail={setProduct}
          onProductRent={onRent}
          loading={loading}
        />

        <ProductDetail product={product ?? undefined} onClose={() => setProduct(null)}/>
        <RentProductModal onClose={() => setRent(null)} product={rent ?? undefined}/>
        <ConfirmModal display={incompleteAccount} style={{
            paddingTop: 45
        }} confirmText={"Compléter mes informations"} onConfirm={() => history.push("/settings?society")}
                      onClose={() => setIncompleteAccount(false)} cancelText={"Fermer"}>
            <p style={{
                fontWeight: 600,
                fontSize: "1.1em"
            }}>Vous ne pouvez pas encore louer d'équipement.</p>
            <p>En effet, vous devez d'abord compléter les informations manquantes concernant votre entreprise.</p>
        </ConfirmModal>
    </>;
};

export default ProductList;
