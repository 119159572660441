import React, {forwardRef, HTMLAttributes, ReactElement, useEffect, useImperativeHandle, useState} from "react";
import style from "./pagination.module.scss";
import {omit} from "../../utils/ObjectUtils";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
    total: number;
    pageSize: number;
    current?: number;
    showQuickJumper?: boolean;
    showNavigationButton?: boolean;
    showLastPage?: boolean;
    showLastPageAt?: number;
    onPageChange?: (pageNo: number, offset: number) => void;
    onTriggerPageChange?: () => void;
    locked?: boolean;
    nextContent?: string | ReactElement;
    previousContent?: string | ReactElement;
}

export interface PaginationRef {
    setPage: (pageNo: number) => void,
}

// eslint-disable-next-line react/display-name
const Pagination = forwardRef<PaginationRef, PaginationProps>((props, ref) => {
    const [canPrevious, setCanPrevious] = useState(false);
    const [canNext, setCanNext] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [displayedPages, setDisplayedPages] = useState<number[]>([]);
    const [locked, setLocked] = useState(props.locked !== undefined && props.locked);

    const [showQuickJumper, setShowQuickJumper] = useState(false);
    const showNavigationButton = (props.showNavigationButton === undefined || props.showNavigationButton);

    useImperativeHandle(ref, () => ({
        setPage: p => setCurrentPage(p)
    }));

    //on mount
    useEffect(() => {
        setTotalPage(Math.ceil(props.total / props.pageSize));
    }, []);

    useEffect(() => {
        setTotalPage(Math.ceil(props.total / props.pageSize));
    }, [props.total, props.pageSize]);

    useEffect(() => {
        if (props.current === undefined) {
            return;
        }

        setCurrentPage(props.current);
    }, [props.current]);

    //handle props changes
    useEffect(() => {
        if (props.locked !== undefined && props.locked !== locked) {
            setLocked(props.locked);
        }
    }, [props.locked]);

    //handle totalPage number change
    useEffect(() => {
        if (props.showQuickJumper) {
            if (totalPage > 5) {
                setShowQuickJumper(true);
            }
        }
    }, [totalPage]);

    //handle currentPage change
    useEffect(() => {
        setDisplayedPages(getButtonsView());

        //handle page changers buttons
        setCanNext(currentPage < totalPage);
        setCanPrevious(currentPage > 1);
    }, [currentPage, totalPage]);

    const getButtonsView = (): number[] => {
        let view: number[] = [];

        if (totalPage > 3) {
            if (currentPage - 1 > 0 && currentPage + 1 <= totalPage) {
                view = [currentPage - 1, currentPage, currentPage + 1];
            } else if (currentPage === 1) {
                view = [currentPage, currentPage + 1, currentPage + 2];
            } else {
                view = [currentPage - 2, currentPage - 1, currentPage];
            }
        } else {
            for (let i = 1; i <= totalPage; i++) {
                view.push(i);
            }
        }

        return view;
    };

    const getPageButtonClasses = (pageNo : number): string => {
        const classes = [style.kmPaginationBtn];

        if (pageNo === currentPage) {
            classes.push(style.kmPaginationBtnActive);
        }

        return classes.join(" ");
    };

    const changePage = (e: React.MouseEvent, pageNo: number) => {
        e.preventDefault();

        if (locked) {
            return;
        }

        if (props.onTriggerPageChange) {
            props.onTriggerPageChange();
        }

        if (props.onPageChange) {
            props.onPageChange(pageNo, (pageNo - 1) * props.pageSize);
        }

        setCurrentPage(pageNo);
    };

    const showFirstPage = (): boolean => {
        if (totalPage < (props.showLastPageAt ?? 5) || props.showLastPage !== true) {
            return false;
        }

        return currentPage >= (props.showLastPageAt ?? 5);
    };

    const showLastPage = (): boolean => {
        if (totalPage < (props.showLastPageAt ?? 5) || props.showLastPage !== true) {
            return false;
        }

        return currentPage <= (totalPage - 2);
    };

    const restProps = omit(props, "total",
        "className",
        "pageSize",
        "current",
        "showQuickJumper",
        "showNavigationButton",
        "showLastPage",
        "showLastPageAt",
        "onPageChange",
        "onTriggerPageChange",
        "locked",
        "nextContent",
        "previousContent");

    return <div
      className={classNames(style.kmPagination, (locked ? style.kmPaginationLocked : ""), props.className)} {...restProps}>
        {showQuickJumper &&
            <button className={classNames(style.kmPaginationBtn, style.kmPaginationFirst)} disabled={locked}>
                <FontAwesomeIcon icon={["fas", "angle-double-left"]}/>
            </button>}

        {showNavigationButton &&
            <button className={classNames(style.kmPaginationBtn, style.kmPaginationPrevious)} onClick={(e) => changePage(e, currentPage - 1)}
                    disabled={!canPrevious || locked}>
                {props.previousContent ?? <FontAwesomeIcon icon={["fas", "angle-left"]}/>}
            </button>}

        {showFirstPage() && <>
            <button className={classNames(style.kmPaginationBtn)} onClick={e => changePage(e, 1)} disabled={locked}>
                1
            </button>
            <div className={classNames(style.kmPaginationBtnAbsoluteSeparator)}/>
        </>}

        {displayedPages.map(no => <button
          className={getPageButtonClasses(no)}
          onClick={e => changePage(e, no)}
          disabled={locked} key={no}>
            {no}
        </button>)}

        {showLastPage() && <>
            <div className={classNames(style.kmPaginationBtnAbsoluteSeparator)}/>
            <button className={classNames(style.kmPaginationBtn)} disabled={locked} onClick={e => changePage(e, totalPage)}>
                {totalPage}
            </button>
        </>}

        {showNavigationButton &&
            <button className={classNames(style.kmPaginationBtn, style.kmPaginationNext)} onClick={e => changePage(e, currentPage + 1)}
                    disabled={!canNext || locked}>
                {props.nextContent ?? <FontAwesomeIcon icon={["fas", "angle-right"]}/>}
            </button>}

        {showQuickJumper &&
            <button className={classNames(style.kmPaginationBtn, style.kmPaginationNext)} disabled={locked}>
                <FontAwesomeIcon icon={["fas", "angle-double-right"]}/>
            </button>}
    </div>;
});

export default Pagination;