import styles from "./layout.module.scss";
import classNames from "classnames";
import {ReactNode, useContext} from "react";
import {RouterContext} from "../../Router";
import Navigation from "./components/Navigation";
import Header from "./components/Header";
import Footer from "./components/Footer";

interface ILayout {
    children: ReactNode;
}

export function Layout(props: ILayout): JSX.Element {
    const {useLayout} = useContext(RouterContext);

    return <div className={classNames(styles.mainLayout)}>
        {useLayout && <Header/>}

        <div className={styles.mainLayoutContent}>
            {useLayout && <Navigation/>}
            {props.children}
        </div>

        {useLayout && <Footer/>}
    </div>;
}