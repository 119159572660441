import Button from "common/Button/Button";
import {Order, Quote, QuoteItem, QuotePrice, User} from "../../../interfaces/Interfaces";
import styles from "./../checkoutModal.module.scss";
import React, {ReactNode, useContext, useMemo, useRef, useState} from "react";
import {getItemsInformation, getQuotePrice} from "../../../utils/OrderUtils";
import {Picture} from "../../Picture/Picture";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppContext from "../../../context/AppContext";
import AuthContext from "../../../context/AuthContext";
import {toPrice} from "../../../utils/NumberUtils";
import Api from "../../../classes/Api";
import CreditCardForm, {CreditCardFormRef} from "../../CreditCardForm/CreditCardForm";
import Loader from "common/Loader/Loader";
import {easytransacErrors} from "../../../configs";

interface CheckoutPaymentStepProps {
    quote: Quote,
    onFinish: (order: Order) => void,
}

export default function CheckoutPaymentStep(props: CheckoutPaymentStepProps): JSX.Element {
    const {user} = useContext(AuthContext);
    const {config} = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [redirect, setRedirect] = useState<string | null>(null);

    const creditCardForm = useRef<CreditCardFormRef>(null);

    const checkout = () => {
        if (!creditCardForm.current?.isValid()) {
            return;
        }

        const defaultError = "Nous ne pouvons pas traiter la transaction pour le moment. Vous ne serez pas débiter. Merci de réessayer ultérieurement. Si le problème persiste, contactez-nous.";
        const card = creditCardForm.current.getValues();

        setLoading(true);
        setError(null);

        Api.post<{
            success?: boolean,
            "3dsecure"?: string,
            error?: string,
            code?: number,
            order?: Order,
        }>("/kamat/checkout", {
            id: props.quote.id,
            token: user.access_token,
            ...card
        }).then(r => {
            if (r.success === true) {
                if (r.order !== undefined) {
                    props.onFinish(r.order);
                } else if (r["3dsecure"] !== undefined) {
                    setRedirect(r["3dsecure"]);

                    setTimeout(() => {
                        window.location.replace(r["3dsecure"] as string);
                    }, 500);
                }
            } else {
                let error = r.error ?? defaultError;

                if (error === "invalid card") {
                    error = "Le numéro de carte saisie n'est pas valide.";
                } else if (error === "expired card") {
                    error = "La carte saisie est expirée";
                } else if (error === "invalid card owner") {
                    error = "Le propriétaire de la carte saisie n'est pas valide";
                } else if (error === "invalid payment account") {
                    error = "Le compte de paiement du bénéficiaire n'est pas ou n'est plus valide, nous ne pouvons pas donner suite à cette transaction.";
                } else if (r.code !== undefined) {
                    const matchedError = easytransacErrors.filter(e => e.Code === r.code)[0];

                    if (matchedError !== undefined) {
                        error = matchedError.Error;
                    }
                }

                setError(error);
                setLoading(false);
            }
        }).catch(() => {
            setError(defaultError);
            setLoading(false);
        });
    };

    const getItems = (): ReactNode => {
        return props.quote.items.map(item => {
            if (typeof item !== "object") {
                return null;
            }

            const info = getItemsInformation(item as QuoteItem);

            if (info === null) {
                return null;
            }

            return <li key={item.id} className={styles.checkoutModalBodyPaymentItem}>
                <div className={styles.checkoutModalBodyPaymentItemPic}>
                    <Picture src={info.picture}
                             alt={""}/>
                </div>

                <div className={classNames(styles.checkoutModalBodyPaymentItemInfo)}>
                    <h5>{info.label}</h5>
                    <p><FontAwesomeIcon
                      icon={["fas", "calendar-days"]}/> {info.date}</p>
                    <p><FontAwesomeIcon
                      icon={["fas", "location-dot"]}/> {info.address}
                    </p>
                    {info.options.length > 0 && <p><FontAwesomeIcon
                        icon={["fas", "sliders"]}/> {info.options.join(", ").toLowerCase()}
                    </p>}
                </div>
            </li>;
        }).filter(n => n !== null);
    };

    const price = useMemo<QuotePrice>(() => {
        return getQuotePrice(props.quote, (props.quote.author as User).fee ?? config.default_fee);
    }, [props.quote]);

    return <div className={styles.checkoutModalBodyPayment}>
        {redirect !== null && <div className={styles.checkoutModalBodyPaymentSecure}>
            <Loader size={40} type={"primary"}/>
            <p className={styles.checkoutModalBodyPaymentSecureTitle}>Paiement sécurisé par 3DSecure</p>
            <p>Vous allez être redirigé dans quelques instants vers le portail de sécurité 3DSecure pour valider la transaction.</p>
            <p>Vous n'êtes pas redirigé ? <a href={redirect}>Cliquez ici</a>.</p>
        </div>}

        <div className={styles.checkoutModalBodyPaymentForm} style={redirect !== null ? {
            display: "none"
        } : undefined}>
            <h2>Paiement par carte</h2>

            <div className={styles.checkoutModalBodyPaymentFormAlert}>
                <FontAwesomeIcon icon={["fas", "lock"]}/>
                <p>Kamat ne stocke aucunes informations de paiement. La transaction est traitée et sécurisée par notre partenaire et prestataire Easytransac.</p>
            </div>

            {error !== null && <div className={styles.checkoutModalBodyPaymentFormError}>
                <FontAwesomeIcon icon={["fas", "circle-exclamation"]}/>
                <p>{error}</p>
            </div>}

            <CreditCardForm ref={creditCardForm}/>

            <Button className={styles.checkoutModalBodyPaymentFormBtn} size={"large"} bold loading={loading}
                    onClick={() => checkout()}>
                Payer
            </Button>
            <p>Paiement sécurisé 3DSecure par Easytransac.</p>
        </div>

        <div className={styles.checkoutModalBodyPaymentResume} style={redirect !== null ? {
            display: "none"
        } : undefined}>
            <h2>Résumé</h2>
            <ul>
                {getItems()}
            </ul>

            <div className={styles.checkoutModalBodyPaymentResumePrice}>
                <p>Sous-total</p>
                <p>{toPrice(price.ht)}</p>
            </div>
            <div className={styles.checkoutModalBodyPaymentResumePrice}>
                <p>TVA ({config.vat_rate}%)</p>
                <p>{toPrice(price.vat)}</p>
            </div>
            <div
              className={classNames(styles.checkoutModalBodyPaymentResumePrice, styles.checkoutModalBodyPaymentResumePriceTotal)}>
                <p>A payer</p>
                <p>{toPrice(price.ttc)} TTC</p>
            </div>
        </div>
    </div>;
}