import {Configuration, LocationSite, Offer} from "../interfaces/Interfaces";
import {createContext, Dispatch} from "react";

export interface iAppContext {
    cart: {
        entity: Offer | null,
        setEntity: Dispatch<Offer | null>,
        loaded: boolean
    },
    config: Configuration,
    setConfig: Dispatch<Configuration>,
    sites: LocationSite[]|null,
    setSites: Dispatch<LocationSite[]>,
}

const AppContext = createContext<iAppContext>({} as iAppContext);
export default AppContext;