import styles from "./../payment.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {Payout} from "../../../../../interfaces/Interfaces";
import moment from "moment";
import Api from "../../../../../classes/Api";
import Loader from "../../../../../common/Loader/Loader";

export default function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [payouts, setPayouts] = useState<Payout[]>([]);

    useEffect(() => {
        Api.get<Payout[]>("/kamat/merchant/info/transfers").then(setPayouts).catch(() => setPayouts([])).finally(() => setLoading(false));
    }, []);

    return <div>
        <div className={styles.settingsSuccess}>
            <FontAwesomeIcon icon={["fas", "check"]}/>
            <div>
                <p style={{
                    fontWeight: 600
                }}>Compte de paiement valide.</p>
                <p>Vous pouvez louer votre équipement et recevoir des fonds directement sur votre compte bancaire.</p>
            </div>
        </div>

        <p className={styles.settingsSectionTitle}>Derniers virements effectués</p>

        {loading && <div className={"km-flex-middle"}>
            <Loader type={"dark"} size={25} style={{
                marginRight: 10
            }}/>
            <p style={{
                margin: 0
            }}>Chargement...</p>
        </div>}

        {!loading && payouts.length === 0 && <div className={styles.settingsPayoutsEmpty}>
            <FontAwesomeIcon icon={["fas", "box-open"]}/>
            <p>Aucun virement effectué pour le moment</p>
        </div>}

        <ul className={styles.settingsPayouts}>
            {payouts.map((p, i) => <li key={i}>
                <div className={styles.settingsPayoutsContent}>
                    <p>Virement #{p.Id}</p>
                    <p><FontAwesomeIcon icon={["fas", "bank"]}/> {p.Iban}</p>
                </div>
                <p className={styles.settingsPayoutsAmount}>{p.Amount} {p.Currency}</p>
                <p
                  className={styles.settingsPayoutsDate}>date d'émission {moment(p.Date).format("DD-MM-YYYY HH:mm")}</p>
                <p className={styles.settingsPayoutsStatus}>
                    <span
                      className={p.Status === "captured" ? styles.settingsPayoutsSuccess : styles.settingsPayoutsWaiting}>{p.Status === "captured" ? "Transféré" : "En attente"}</span>
                </p>
            </li>)}
        </ul>
    </div>;
}