import {ChangeEvent, useEffect, useRef, useState} from "react";
import styles from "./siteManager.module.scss";
import {useTranslation} from "react-i18next";
import searchLogo from "assets/loupe.svg";
import nbSites from "./assets/nbSites.svg";
import siteLogo from "./assets/siteLogo.svg";
import flagLogo from "./assets/pennant.svg";
import chantierLogo from "./assets/chantierLogo.svg";
import plusLogo from "./assets/plusLogo.svg";
import Api from "../../../classes/Api";
import {ApiDataCountResponse, LocationSite, LocationSiteType} from "../../../interfaces/Interfaces";
import {SiteCard} from "common/SiteCard/SiteCard";
import Loader from "../../../common/Loader/Loader";
import empty from "assets/missingImage.svg";
import SiteFormModal from "../../../common/SiteFormModal/SiteFormModal";
import ArrayUtils from "../../../utils/ArrayUtils";
import ConfirmModal from "../../../common/ConfirmModal/ConfirmModal";
import {notifyError, notifySuccess} from "../../../common/Notification/notification";
import useLocationSite from "../../../hooks/useLocationSite";

export default function SiteManager() : JSX.Element {
    const {t} = useTranslation();
    const {deleteSite: onDeleteSite} = useLocationSite();

    const [type, setType] = useState<LocationSiteType>("building");

    const [data, setData] = useState<LocationSite[]>([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const [site, setSite] = useState<LocationSite | null>();

    const [deleteSite, setDeleteSite] = useState<number | null>(null);
    const [deleting, setDeleting] = useState(false);

    const searchThrottler = useRef<number | null>(null);

    useEffect(() => {
        setLoading(true);
        fetch();
    }, [type]);

    const fetch = (search ?: string) => {
        setLoading(true);

        Api.get<ApiDataCountResponse<LocationSite>>("/items/site" + (search !== undefined ? "?search=" + search : ""), {
            type
        }, {
            meta: {
                filter_count: true
            }
        }).then(d => {
            setData(d.data);

            if (search === undefined) {
                setCount(d.meta.filter_count);
            }
        }).catch(() => {
            setData([]);

            if (search === undefined) {
                setCount(0);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onDelete = () => {
        if (deleteSite === null) {
            return;
        }

        setDeleting(true);

        Api.delete(`/items/site/${deleteSite}`).then(() => {
            setData(ArrayUtils.delete(data, p => p.id === deleteSite));
            onDeleteSite(deleteSite);

            notifySuccess(t("Le site a bien été supprimé"));
        }).catch(() => {
            notifyError(t("Impossible de supprimer ce site"));
        }).finally(() => {
            setDeleting(false);
            setDeleteSite(null);
        });
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        if (searchThrottler.current !== undefined) {
            clearTimeout(searchThrottler.current as unknown as NodeJS.Timeout);
        }

        const search = event.target.value.trim();

        if (search.length <= 3) {
            //reset
            if (search.length === 0) {
                fetch();
            }

            return;
        }

        searchThrottler.current = setTimeout(() => {
            fetch(search);
        }, 300) as unknown as number;
    };

    return <div className={styles.container}>
        <div className={styles.siteManagerTopBar}>
            <img src={type === "storage" ? siteLogo : chantierLogo}
                 className={styles.siteManagerTopBarLogo} alt={""} key={type}/>

            <div className={styles.siteManagerTopBarBox}>
                <img src={nbSites} alt="nbSites" height="40"/>

                <div>
                    <h5>{count}</h5>
                    <h6>{t("Sites de " + (type === "storage" ? "stockage" : "construction"))}</h6>
                </div>
            </div>
        </div>

        <div className={styles.siteManagerHeader}>
            <div className={styles.siteManagerSelector}>
                <a onClick={() => setType("building")}
                   className={type === "building" ? styles.siteManagerSelectorActive : ""}>
                    {t("Mes chantiers")}
                </a>

                <div className={styles.siteManagerSelectorIcon}>
                    <img src={flagLogo} alt={""}/>
                </div>

                <a onClick={() => setType("storage")}
                   className={type === "storage" ? styles.siteManagerSelectorActive : ""}>
                    {t("Mes dépôts")}
                </a>
            </div>

            <div className={styles.siteManagerHeaderTools}>
                <div className={"km-search"}>
                    <img src={searchLogo} alt=""/>
                    <input
                      onChange={onSearch}
                      type="search"
                      placeholder={t("Chercher un site de " + (type === "storage" ? "stockage" : "construction"))}/>
                </div>

                <button className={styles.siteManagerAddButton} onClick={() => setSite(null)}>
                    <img src={plusLogo} alt="plusLogo" className="mx-3"/>
                    {t("Ajouter un site")}
                </button>
            </div>
        </div>

        <div className={styles.siteManagerBody}>
            {loading &&
                <div className={styles.siteManagerLoading}><Loader type={"primary"} size={24}/> {t("Chargement")}</div>}

            {!loading && data.length === 0 && <div className={styles.siteManagerBodyEmpty}>
                <img src={empty} alt={""}/>
                <p>{t("Aucun site de " + (type === "storage" ? "stockage" : "construction") + " trouvé") + "..."}</p>
            </div>}

            {data.map(site => <SiteCard
              key={site.id}
              site={site}
              onDelete={() => setDeleteSite(site.id)}
              onEdit={() => setSite(site)}
            />)}
        </div>

        <SiteFormModal display={site !== undefined} onClose={() => setSite(undefined)}
                       site={site ?? undefined} type={type}
                       onFinish={s => setData(ArrayUtils.replaceOrPush(data, d => d.id === s.id, s))}/>

        <ConfirmModal display={deleteSite !== null} onClose={() => setDeleteSite(null)}
                      title={t("Supprimer un site")} onConfirm={onDelete} loading={deleting}>
            {t("Êtes-vous certain de vouloir supprimer ce site ? Cette action est irréversible.")}
        </ConfirmModal>
    </div>;
}
