import classNames from "classnames";
import {Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import styles from "./login.module.scss";
import {FunctionComponent, useContext, useEffect, useState} from "react";
import {Redirect} from "react-router";
import {notifyError, notifySuccess} from "common/Notification/notification";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import kamatLogo from "./assets/kamatLogo.svg";
import AuthContext from "../../context/AuthContext";
import {LoginState} from "../../interfaces/Interfaces";
import {useHistory, useLocation} from "react-router-dom";
import Api, {ApiError} from "../../classes/Api";
import PasswordResetModal from "../../common/PasswordResetModal/PasswordResetModal";
import Button from "common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IFormValues {
    email: string;
    password: string;
}

interface ResetValues {
    password: string,
    confirmPassword: string
}

export interface ILoginProps {
}

export const Login: FunctionComponent<ILoginProps> = () => {
    const {t} = useTranslation();
    const [reset, setReset] = useState(false);

    const [loading, setLoading] = useState(false);
    const [resetToken, setResetToken] = useState<string | null>(null);

    const {setLoginState, loginState, authenticate} = useContext(AuthContext);

    const history = useHistory();
    const location = useLocation();

    const schema = yup.object({
        email: yup
          .string()
          .email(t("Entrez une adresse valide"))
          .required(t("Une adresse mail est requise")),
        password: yup.string().required(t("Champ obligatoire")),
    });

    const resetSchema = yup.object({
        password: yup.string().required(t("Champ obligatoire")).min(6, "Le mot de passe doit être de 6 caractère minimum"),
        confirmPassword: yup.string().required(t("Champ obligatoire")).min(6, "Le mot de passe doit être de 6 caractère minimum"),
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params.has("token")) {
            setResetToken(params.get("token"));
        }
    }, [location]);

    const onLogin = (values: IFormValues, actions: FormikHelpers<IFormValues>) => {
        authenticate(values.email, values.password).catch((e: ApiError[]) => {
            if (Array.isArray(e) && (e.shift() as ApiError).extensions.code === "INVALID_CREDENTIALS") {
                notifyError(t("Adresse mail ou mot de passe invalide"));
            } else {
                notifyError(t("Impossible de joindre le serveur de connexion"));
            }

            actions.setSubmitting(false);
            setLoginState(LoginState.FAILED);
        });
    };

    const onReset = (v: ResetValues) => {
        if (v.password.trim().length < 6) {
            notifyError(t("Le mot de passe doit être de 6 caractères minimum."));
            return;
        }

        if (v.password !== v.confirmPassword) {
            notifyError(t("Les 2 mots de passe ne correspondent pas."));
            return;
        }

        setLoading(true);

        Api.post("/auth/password/reset", {
            token: resetToken,
            password: v.password
        }).then(() => {
            notifySuccess(t("Mot de passe modifié. Vous pouvez désormais vous connecter avec le nouveau mot de passe."));
        }).catch(() => {
            notifyError(t("Demande de réinitialisation de mot de passe expirée."));
        }).finally(() => {
            setLoading(false);
            setResetToken(null);
        });
    };

    if (loginState === LoginState.LOGGED) {
        const url = sessionStorage.getItem("url") || "/";
        sessionStorage.removeItem("url");
        return <Redirect to={url}/>;
    }

    return <>
        <div className={classNames(styles.loginContainer)}>
            <div className="d-flex justify-content-center align-items-center">
                <img
                  src={kamatLogo}
                  alt={kamatLogo}
                  className={classNames(styles.logo)}
                />
            </div>

            {resetToken === null ? <Formik
              validationSchema={schema}
              onSubmit={onLogin}
              initialValues={{
                  email: "",
                  password: "",
              }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      isValid,
                      touched,
                      errors,
                  }) => <div
                  className={classNames(
                    styles.formContainer,
                    styles.tmp,
                    "col-xl-3 offset-xl-8"
                  )}
                >
                    <form noValidate onSubmit={handleSubmit}>
                        <h1 className={classNames(styles.title)}>Welcome</h1>
                        <h2 className={classNames(styles.subTitle)}>Login to enjoy the revolution</h2>

                        <div className="my-4">
                            <label>{t("Email")}</label>
                            <input
                              className={classNames(
                                styles.loginInput,
                                touched.email && errors.email
                                  ? "form-control is-invalid"
                                  : "form-control"
                              )}
                              type="text"
                              placeholder={t("Email")}
                              aria-describedby={t("Email")}
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>

                        <div className="my-4">
                            <label>{t("Mot de passe")}</label>
                            <input
                              className={classNames(
                                styles.loginInput,
                                touched.password && errors.password
                                  ? "form-control is-invalid"
                                  : "form-control"
                              )}
                              type="password"
                              name="password"
                              placeholder={t("Mot de passe")}
                              aria-describedby={t("Mot de passe")}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            {touched.password && errors.password &&
                                <div className="invalid-feedback">{errors.password}</div>}
                        </div>

                        <Button
                          className={classNames(styles.loginBtn)}
                          disabled={!isValid} bold
                        >
                            Se connecter
                        </Button>

                        <a className={styles.lostPassword}
                           onClick={() => setReset(true)}>{t("Mot de passe perdu ?")}</a>
                    </form>

                    <div className={classNames(styles.lineBreak, "my-4")}/>
                    <div className={classNames(styles.callToAction, "my-xs-4")}>
                        <p className={styles.slogan}>{t("Voulez-vous rejoindre le mouvement ? ")}</p>

                        <a className={styles.signUp}
                           onClick={() => history.push("/register")}>
                            <p dangerouslySetInnerHTML={{__html: t("S'inscrire <span>gratuitement</span>")}}/>
                            <FontAwesomeIcon icon={["fas", "truck-fast"]}/>
                        </a>
                    </div>
                </div>}
            </Formik> : <Formik
              validationSchema={resetSchema}
              onSubmit={onReset}
              initialValues={{
                  password: "",
                  confirmPassword: ""
              }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      isValid,
                      touched,
                      errors,
                  }) => <div
                  className={classNames(
                    styles.formContainer,
                    styles.tmp,
                    "col-xl-3 offset-xl-8"
                  )}
                >
                    <form noValidate onSubmit={handleSubmit} autoComplete="off">
                        <h1 className={classNames(styles.title)} style={{
                            fontSize: 33
                        }}>Nouveau mot de passe</h1>

                        <div className="my-4">
                            <label>{t("Mot de passe")}</label>
                            <input
                              className={classNames(
                                styles.loginInput,
                                touched.password && errors.password
                                  ? "form-control is-invalid"
                                  : "form-control"
                              )}
                              type="password"
                              placeholder={t("Nouveau mot de passe...")}
                              name="password"
                              autoComplete={"off"}
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            {touched.password && errors.password &&
                                <div className="invalid-feedback">{errors.password}</div>}
                        </div>

                        <div className="my-4">
                            <label>{t("Confirmer le mot de passe")}</label>
                            <input
                              className={classNames(
                                styles.loginInput,
                                touched.confirmPassword && errors.confirmPassword
                                  ? "form-control is-invalid"
                                  : "form-control"
                              )}
                              type="password"
                              name="confirmPassword"
                              placeholder={t("Confirmer le nouveau mot de passe...")}
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />

                            {touched.confirmPassword && errors.confirmPassword &&
                                <div className="invalid-feedback">{errors.confirmPassword}</div>}
                        </div>

                        <Button
                          type={"black"}
                          outline
                          rounded
                          disabled={!isValid}
                          size={"large"}
                          loading={loading}
                          style={{
                              width: "100%"
                          }}
                        >
                            Réinitialiser le mot de passe
                        </Button>
                    </form>
                </div>}
            </Formik>}
        </div>

        <PasswordResetModal display={reset} onClose={() => setReset(false)}/>

        <CookieConsent
          location="bottom"
          buttonText={t("Accepter")}
          cookieName="consentCookie"
          disableStyles={true}
          containerClasses={styles.cookieBand}
          contentClasses={styles.cookieContent}
          buttonClasses={styles.cookieButton}
          expires={150}
        >
            {t(
              "Ce site web utilise des cookies pour améliorer votre expérience utilisateur"
            )}
        </CookieConsent>
    </>;
};

export default Login;
