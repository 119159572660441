import {FunctionComponent, useContext, useEffect, useState} from "react";
import {CatalogFamily, CatalogItem} from "interfaces/Interfaces";
import {useParams} from "react-router";
import {CatalogGrid} from "common/CatalogGrid/CatalogGrid";
import Api from "../../../../classes/Api";
import {RentalCatalogBreadcrumb, RentalCatalogContext} from "../../RentalCatalog";

interface ICatalogSectionProps {
}

export const MainCategory: FunctionComponent<ICatalogSectionProps> = () => {
    const [families, setFamilies] = useState<CatalogItem[]>([]);
    const [loading, setLoading] = useState(false);

    const {mainCategoryCode} = useParams<{ mainCategoryCode: string }>();
    const {setBreadcrumb, setPromotion, cache} = useContext(RentalCatalogContext);

    useEffect(() => {
        setPromotion(2);

        if (cache.hasOwnProperty(mainCategoryCode)) {
            initBreadcrumb([...cache[mainCategoryCode] as CatalogFamily[]]);
            setFamilies((cache[mainCategoryCode] as CatalogFamily[]).map(f => ({
                label: f.label,
                url: `${mainCategoryCode}/${f.slug}`,
                picture: f.picture
            })));
            return;
        }

        setLoading(true);
        Api.get<{
            data: CatalogFamily[]
        }>("/items/catalog_family", {
            category: {
                slug: {
                    _eq: mainCategoryCode
                }
            }
        }, {
            fields: [
                "*",
                "category.*"
            ],
        }).then(d => {
            initBreadcrumb([...d.data]);
            cache[mainCategoryCode] = [...d.data];
            setFamilies(d.data.map(f => ({
                label: f.label,
                url: `${mainCategoryCode}/${f.slug}`,
                picture: f.picture
            })));
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const initBreadcrumb = (data: CatalogFamily[]) => {
        const family = data.shift();

        if (family === undefined) {
            Api.get<RentalCatalogBreadcrumb>(`/kamat/catalog-breadcrumb?category=${mainCategoryCode}`).then(r => {
                if (Array.isArray(r) && r.length > 0) {
                    setBreadcrumb(r);
                    return;
                }

                setBreadcrumb([]);
            }).catch(() => {
                setBreadcrumb([]);
            });
            return;
        }

        if (family.id === -1) {
            return;
        }

        const breadcrumb: RentalCatalogBreadcrumb = [];

        if (typeof family.category === "object") {
            breadcrumb.push({
                label: family.category.label,
                slug: family.category.slug
            });
        }

        setBreadcrumb(breadcrumb);
    };

    return <CatalogGrid array={families} loading={loading}/>;
};
