import {OfferItem, Quote, QuoteItem, QuotePrice, User} from "../interfaces/Interfaces";
import moment from "moment";
import {useTranslation} from "react-i18next";
import Api from "../classes/Api";
import {config} from "../configs";

interface ItemInfo {
    picture: string,
    label: string,
    date: string,
    rentDuration: {
        days: number,
    },
    address: string,
    options: string[]
}

export function getItemsInformation(item: QuoteItem | OfferItem, isOffer ?: boolean, t ?: (s : string) => string): ItemInfo | null {
    if (t === undefined) {
        const tr = useTranslation();
        t = tr.t;
    }

    if (isOffer === undefined) {
        isOffer = false;
    }

    if (typeof item !== "object") {
        return null;
    }

    const offerItem = !isOffer ? (item as QuoteItem).offer_item : item as OfferItem;

    if (typeof offerItem !== "object") {
        return null;
    }

    const catalogProduct = offerItem.catalog_product;

    if (typeof catalogProduct !== "object") {
        return null;
    }

    let options: string[] = [];

    if (offerItem.operator) {
        options.push(t("Avec opérateur"));
    }

    if (offerItem.transport) {
        options.push(t("Avec transport"));
    }

    if (offerItem.saturday_include && offerItem.sunday_include) {
        options.push(t("Samedi & dimanche inclus"));
    } else if (offerItem.sunday_include) {
        options.push(t("Dimanche inclus"));
    } else if (offerItem.saturday_include) {
        options.push(t("Samedi inclus"));
    }

    const customOptions: string[] = [];

    if (typeof offerItem.options !== "undefined" && Array.isArray(offerItem.options)) {
        if (offerItem.options.indexOf("brh") !== -1) {
            customOptions.push(t("BRH"));
        }

        if (offerItem.options.indexOf("bucket") !== -1) {
            customOptions.push(t("Godet"));
        }

        if (offerItem.options.indexOf("hoist") !== -1) {
            customOptions.push(t("Treuil"));
        }

        if (offerItem.options.indexOf("carrycot_basket") !== -1) {
            customOptions.push(t("Panier nacelle"));
        }

        if (offerItem.options.indexOf("gallows") !== -1) {
            customOptions.push(t("Potence"));
        }

        if (offerItem.options.indexOf("safety_harness") !== -1) {
            customOptions.push(t("Harnais de sécurité"));
        }
    }

    if (customOptions.length > 0) {
        options = [...options, ...customOptions];
    }

    const start = moment(offerItem.rent_start).startOf("day");
    const end = moment(offerItem.rent_end).startOf("day");
    const days = end.diff(start, "days") + 1;
    let date = "le " + start.format("DD-MM-YYYY");

    if (start.valueOf() !== end.valueOf()) {
        date = "du " + start.format("DD-MM-YYYY") + " au " + end.format("DD-MM-YYYY");
    }

    return {
        label: catalogProduct.label,
        date: t("Loué") + " " + days + " " + t(days > 1 ? "jours" : "jour") + " " + date,
        picture: Api.getUrl(`/assets/${catalogProduct.picture}`, true),
        options,
        rentDuration: {
            days
        },
        address: (offerItem.location_address ?? "n/a").replaceAll("\n", ", ")
    };
}

export function getQuotePrice(quote : Quote, defaultFee : number) : QuotePrice {
    let price = 0;
    let transport = 0;
    let insurance = 0;
    let commission = 0;

    (quote ?? {
        items: []
    }).items.forEach(it => {
        if (typeof it !== "object") {
            return;
        }

        price += it.price;
        commission += (it.price) * (defaultFee / 100);
        transport += it.price_transport;
        insurance += it.price_insurance;
    });

    const ht = price + transport + insurance + commission;
    const vat = ht * (config.vatRate / 100);

    return {
        price,
        transport,
        insurance,
        commission,
        vat,
        ht: ht,
        ttc: ht + vat
    };
}