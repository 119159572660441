import Picker, {PickerProps, RangePicker} from "rc-picker";
import "rc-picker/assets/index.css";
import "./datePicker.module.scss";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import frFR from "rc-picker/lib/locale/fr_FR";
import enUS from "rc-picker/lib/locale/en_US";
import i18n from "i18next";
import {omit} from "../../utils/ObjectUtils";
import moment, {Moment} from "moment";
import {HTMLAttributes, useRef, useState} from "react";
import {RangeInfo, RangePickerProps} from "rc-picker/lib/RangePicker";

interface IDatePickerProps extends Omit<HTMLAttributes<HTMLInputElement>, "value" | "onChange" | "defaultValue" | "onSelect" | "placeholder"> {
    canSelectTime?: boolean,
    name?: string,
    value?: number | Moment | Date | [number, number] | [Moment, Moment] | [Date, Date],
    defaultValue?: number | Moment | Date,
    onChange?: (value: number | [number, number] | null) => void,
    getDropdownContainer?: (node: HTMLElement) => HTMLElement;
    isRange?: boolean,
    placeholder?: string | string[],
    namedPanel?: boolean,
    minDate?: number | Date | Moment,
    maxDate?: number | Date | Moment
}

enum RangePanel {
    START,
    END
}

export default function DatePicker(props: IDatePickerProps) {
    const rangePanelRef = useRef(RangePanel.START);
    const [forceUpdate, setForceUpdate] = useState(0);

    const onChange = (v: Moment | null) => {
        if (props.onChange) {
            props.onChange(v !== null ? (Array.isArray(v) ? v.map(v => v.valueOf()) as [number, number] : v.valueOf()) : null);
        }
    };

    const onSelect = (date: Moment) => {
        if (props.canSelectTime !== true || props.onChange === undefined) {
            return;
        }

        props.onChange(date.isValid() ? date.valueOf() : null);
    };

    const getMoment = (value: Date | number | Moment | [number, number] | [Moment, Moment] | [Date, Date] | undefined): Moment | [Moment, Moment] | undefined => {
        if (value === undefined) {
            return undefined;
        }

        if (Array.isArray(value)) {
            if (props.isRange !== true) {
                value = value[0];
            } else {
                return value.slice(0, 2).map((v: (Date | number | Moment)) => moment.isMoment(value) ? v : moment(v)) as [Moment, Moment];
            }
        }

        if (moment.isMoment(value)) {
            return value;
        }

        return moment(value);
    };

    const forceRender = () => {
        setForceUpdate(new Date().valueOf() + (Math.random() * (999 - 100) + 100));
    };

    const sharedProps = {
        ...omit(props, "isRange", "onChange", "value", "defaultValue", "canSelectTime", "onBlur") as Omit<IDatePickerProps, "value" | "onChange" | "defaultValue">,
        picker: "date",
        defaultValue: getMoment(props.defaultValue),
        value: getMoment(props.value),
        showTime: props.canSelectTime !== undefined ? {
            showHour: true,
            showMinute: true,
            showSecond: false,
            format: "HH[h]mm",
            minuteStep: 5
        } : undefined,
        locale: i18n.language === "fr" ? {
            ...frFR,
            ok: "Valider"
        } : enUS,
        generateConfig: momentGenerateConfig,
        showNow: false,
        format: props.canSelectTime ? "DD/MM/yyyy [à] HH[h]mm" : "DD/MM/yyyy",
        onChange,
        onSelect,
        getPopupContainer: props.getDropdownContainer,
        disabledDate: props.minDate !== undefined || props.maxDate !== undefined ? (d: Moment) => {
            if (props.minDate !== undefined && d.valueOf() < (getMoment(props.minDate) as Moment).valueOf()) {
                return true;
            }

            if (props.maxDate !== undefined && d.valueOf() > (getMoment(props.maxDate) as Moment).valueOf()) {
                return true;
            }

            return false;
        } : undefined
    };

    const haveRangePlaceholder = (): boolean => {
        return props.namedPanel !== false && props.placeholder !== undefined && Array.isArray(props.placeholder) && props.placeholder.length === 2;
    };

    if (props.isRange === true) {
        const rangePicker = {
            ...sharedProps,
            dropdownClassName: haveRangePlaceholder() ? "rc-picker-named" : undefined,
            onFocus: () => forceRender,
            onOpenChange: forceRender,
            // eslint-disable-next-line react/display-name
            dateRender: haveRangePlaceholder() ? (currentDate: Moment, _today: Moment, info: RangeInfo) => {
                rangePanelRef.current = info.range === "start" ? RangePanel.START : RangePanel.END;
                return <div className="rc-picker-cell-inner">{currentDate.date()}</div>;
            } : undefined,
            separator: "-",
            // eslint-disable-next-line react/display-name
            renderExtraFooter: haveRangePlaceholder() ? () => {
                return <div className={"rc-picker-tab-name"} key={forceUpdate}>
                    {(props.placeholder as string[])[rangePanelRef.current === RangePanel.START ? 0 : 1]}
                </div>;
            } : undefined
        };

        return <RangePicker {...rangePicker as unknown as RangePickerProps<Moment>}/>;
    }

    return <Picker {...sharedProps as unknown as PickerProps<Moment>}/>;
}