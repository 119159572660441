import {AllCategories} from "./components/AllCategories/AllCategories";
import {createContext, Dispatch, FunctionComponent, useEffect, useRef, useState} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import {MainCategory} from "./components/MainCategory/MainCategory";
import {ProductList} from "./components/ProductList/ProductList";
import styles from "./rentalCatalog.module.scss";
import {CatalogLayout} from "../../common/CatalogLayout/CatalogLayout";
import CatalogPromotion from "../../common/CatalogPromotion/CatalogPromotion";
import {CatalogFamily, CatalogItem, CatalogProduct} from "../../interfaces/Interfaces";
import {CheckoutModal} from "../../common/CheckoutModal/CheckoutModal";

interface IRentalProps {
}

export type RentalCatalogBreadcrumb = {
    label: string,
    slug: string
}[];

interface iRentalCatalogContext {
    breadcrumb: RentalCatalogBreadcrumb,
    setBreadcrumb: Dispatch<RentalCatalogBreadcrumb>,
    search: string | null,
    setSearch: Dispatch<string | null>,
    setPromotion: Dispatch<number>,
    cache: {
        [key: string]: (CatalogProduct | CatalogFamily | CatalogItem)[]
    }
}

export const RentalCatalogContext = createContext<iRentalCatalogContext>({} as iRentalCatalogContext);

export const Rental: FunctionComponent<IRentalProps> = () => {
    const [breadcrumb, setBreadcrumb] = useState<RentalCatalogBreadcrumb>([]);
    const [search, setSearch] = useState<string | null>(null);
    const [promotion, setPromotion] = useState(0);
    const [order, setOrder] = useState<string | null>(null);

    const cache = useRef({});
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const idOrder = params.get("order");

        if (typeof idOrder === "string" && idOrder.trim().length > 5) {
            setOrder(idOrder);
        }
    }, [location]);

    return <div className={styles.container}>
        <RentalCatalogContext.Provider
          value={{breadcrumb, setBreadcrumb, search, setSearch, setPromotion, cache: cache.current}}>
            <Switch>
                <CatalogLayout promotion={<CatalogPromotion index={promotion}/>}>
                    {(search === null || search.trim().length) < 3 ? <>
                        <Route exact path="/rentals">
                            <AllCategories/>
                        </Route>

                        <Route exact path={"/rentals/:mainCategoryCode"}>
                            <MainCategory/>
                        </Route>

                        <Route exact path={"/rentals/:mainCategoryCode/:subCategoryCode"}>
                            <ProductList/>
                        </Route>
                    </> : <ProductList/>}
                </CatalogLayout>

                {/*<Route
                  exact
                  path={"/rentals/:mainCategoryCode/:subCategoryCode/:productCode"}
                >
                    <SingleProductPage/>
                </Route>*/}

            </Switch>

            <CheckoutModal display={order !== null} onClose={() => setOrder(null)} reference={order ?? undefined}/>
        </RentalCatalogContext.Provider>
    </div>;
};
