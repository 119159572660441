import styles from "./cart.module.scss";
import {useTranslation} from "react-i18next";
import Button from "../../common/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useCart from "../../hooks/useCart";
import empty from "assets/empty.svg";
import {Fragment, ReactNode, useContext, useState} from "react";
import {CatalogProduct, LocationSite, OfferItem} from "../../interfaces/Interfaces";
import Api from "../../classes/Api";
import DatePicker from "../../common/DatePicker/DatePicker";
import times from "./assets/times.svg";
import change from "./assets/update.svg";
import moment from "moment";
import classNames from "classnames";
import {Link} from "react-router-dom";
import SiteListingModal from "../../common/SiteListingModal/SiteListingModal";
import useLocationSite from "../../hooks/useLocationSite";
import order from "./assets/order.png";
import {notifyError} from "../../common/Notification/notification";
import OfferContext from "../../context/OfferContext";
import useProductOptions from "../../hooks/useProductOption";
import {Picture} from "../../common/Picture/Picture";

interface Option {
    label: string,
    isActive: (item: OfferItem) => boolean,
    onSwitch: (item: OfferItem) => void,
}

interface ItemErrors {
    [key: number]: {
        date?: boolean,
        site?: boolean
    }
}

export default function Cart(): JSX.Element {
    const {t} = useTranslation();

    const {getSiteAddress} = useLocationSite();
    const {reload: reloadOffers} = useContext(OfferContext);
    const {cart, isCartFilled, loading, setDebounceParams, removeItem, reload} = useCart();

    const [selectingSite, setSelectingSite] = useState<number | null>(null);
    const [createdOffer, setCreatedOffer] = useState<string | null>(null);
    const [creating, setCreating] = useState(false);
    const [errors, setErrors] = useState<ItemErrors>({});

    const optionsLabel = useProductOptions();
    const options: Option[] = [
        {
            label: t("Avec opérateur"),
            isActive: item => item.operator,
            onSwitch: item => {
                setDebounceParams(item.id, "operator", !item.operator);
            }
        },
        {
            label: t("Samedi inclus"),
            isActive: item => item.saturday_include,
            onSwitch: item => {
                setDebounceParams(item.id, "saturday_include", !item.saturday_include);
            }
        },
        {
            label: t("Dimanche inclus"),
            isActive: item => item.sunday_include,
            onSwitch: item => {
                setDebounceParams(item.id, "sunday_include", !item.sunday_include);
            }
        }
    ];

    const getItems = (): ReactNode[] => {
        return (cart?.items ?? []).map(it => {
            if (typeof it !== "object") {
                return null;
            }

            const product = it.catalog_product as CatalogProduct;

            if (typeof product !== "object") {
                return null;
            }

            const itemOptions: ReactNode[] = [];
            let toTreat = [...options];
            let currentRow: ReactNode[] = [];

            if (Array.isArray(product.options) && product.options.length > 0) {
                product.options.forEach(opt => {
                    toTreat = [{
                        label: optionsLabel[opt],
                        isActive: item => Array.isArray(item.options) && item.options.indexOf(opt) !== -1,
                        onSwitch: item => {
                            const newOptions = (item.options ?? []);
                            const index = newOptions.indexOf(opt);

                            if (index === -1) {
                                newOptions.push(opt);
                            } else {
                                newOptions.splice(index, 1);
                            }

                            setDebounceParams(item.id, "options", newOptions);
                        }
                    }, ...toTreat];
                });
            }

            while (toTreat.length > 0) {
                const opt = toTreat.shift();

                if (opt === undefined) {
                    break;
                }

                currentRow.push(<Fragment key={toTreat.length}>
                    {currentRow.length > 0 && <span className={styles.cartItemSep}/>}

                    <a
                      className={classNames(styles.cartItemOption, opt.isActive(it) ? styles.cartItemOptionActive : undefined)}
                      onClick={() => opt.onSwitch(it)}>
                        {opt.label} {opt.isActive(it) && <FontAwesomeIcon icon={["fas", "check"]}/>}
                    </a>
                </Fragment>);

                if (currentRow.length >= 2 || toTreat.length === 0) {
                    itemOptions.push(<div
                      className={itemOptions.length === 0 ? styles.cartItemRow : styles.cartItemRowWithoutLabel}
                      key={itemOptions.length}>
                        {itemOptions.length === 0 && <p>Options : </p>}
                        {currentRow}
                    </div>);

                    currentRow = [];
                }
            }

            const invalid = errors[it.id]?.date === true || errors[it.id]?.site === true;

            return <div
              className={classNames(styles.cartItem, invalid ? styles.cartItemInvalid : undefined)}
              key={it.id}>
                <h3 className={styles.cartItemNameMobile}>{product.label}</h3>

                <div className={styles.cartItemSidebar}>
                    <div className={styles.cartItemPic}>
                        <Picture src={Api.getUrl(`/assets/${product.picture}`, true)}/>
                    </div>
                    <a onClick={() => removeItem(it.id)}><img src={times} alt={""}/> {t("Supprimer")}</a>
                </div>

                <div className={styles.cartItemBody}>
                    <h3 className={styles.cartItemName}>{product.label}</h3>
                    <div className={styles.cartItemConfig}>
                        <div>
                            {errors[it.id]?.date === true && <div className={styles.cartItemError}>
                                <FontAwesomeIcon icon={["fas", "circle-xmark"]}/>
                                <p>{t("La date de location saisie est invalide.")}</p>
                            </div>}

                            <div className={styles.cartItemRow}>
                                <p>Date : </p>
                                <DatePicker className={styles.cartItemInputPicker} defaultValue={moment(it.rent_start)}
                                            minDate={moment()} maxDate={moment(it.rent_end)}
                                            onChange={d => setDebounceParams(it.id, "rent_start", (new Date(d as number)).toISOString())}/>
                                <span className={styles.cartItemSep}/>
                                <DatePicker className={styles.cartItemInputPicker} defaultValue={moment(it.rent_end)}
                                            minDate={moment(it.rent_start)}
                                            onChange={d => setDebounceParams(it.id, "rent_end", (new Date(d as number)).toISOString())}/>
                            </div>
                            {itemOptions}
                        </div>

                        <div className={styles.cartItemLocation}>
                            {errors[it.id]?.site === true && <div className={styles.cartItemError}>
                                <FontAwesomeIcon icon={["fas", "circle-xmark"]}/>
                                <p>{t("Le site de location doit être sélectionné.")}</p>
                            </div>}

                            <p>{t("Où doit-être livré cet équipement ?")}</p>

                            {(it.location === null || typeof it.location !== "object") &&
                                <a className={styles.cartItemLocationSelect} onClick={() => setSelectingSite(it.id)}>
                                    <FontAwesomeIcon icon={["fas", "helmet-safety"]}
                                                     className={styles.cartItemLocationSelectIcon}/>
                                    <p>Sélectionner un site de construction</p>
                                    <FontAwesomeIcon icon={["fas", "chevron-right"]}
                                                     className={styles.cartItemLocationSelectArrow}/>
                                </a>}

                            {it.location !== null && typeof it.location === "object" &&
                                <div className={styles.cartItemLocationAddress}>
                                    <FontAwesomeIcon icon={["fas", "location-dot"]}/>
                                    <div>
                                        {(getSiteAddress(it.location) ?? []).map((p, i) => <p key={i}>
                                            {p}
                                        </p>)}
                                    </div>
                                    <a onClick={() => setSelectingSite(it.id)}>
                                        <img src={change} alt={""}/> {t("Changer de site")}
                                    </a>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>;
        }).filter(n => n !== null);
    };

    const onSelect = (idSite: number, site: LocationSite) => {
        if (selectingSite === null) {
            return;
        }

        if (errors[selectingSite]?.site === true) {
            setErrors({
                ...errors,
                [selectingSite]: {
                    ...errors[selectingSite],
                    site: false
                }
            });
        }

        setDebounceParams(selectingSite, "location", site, 10);
        setSelectingSite(null);
    };

    const createOffer = () => {
        if (cart === null) {
            return;
        }

        setErrors({});

        const now = moment().startOf("day").valueOf();
        const missingLocation: number[] = [];
        const invalidDate: number[] = [];

        for (const item of (cart.items ?? [])) {
            if (typeof item !== "object") {
                continue;
            }

            if (item.location === null || item.location === undefined) {
                missingLocation.push(item.id);
            }

            if (moment(item.rent_start).valueOf() < now || moment(item.rent_start).valueOf() > moment(item.rent_end).valueOf()) {
                invalidDate.push(item.id);
            }
        }

        const newErrors: ItemErrors = {};

        missingLocation.forEach(id => {
            if (!newErrors.hasOwnProperty(id)) {
                newErrors[id] = {};
            }

            newErrors[id].site = true;
        });

        invalidDate.forEach(id => {
            if (!newErrors.hasOwnProperty(id)) {
                newErrors[id] = {};
            }

            newErrors[id].date = true;
        });

        setErrors(newErrors);

        if (missingLocation.length > 0) {
            notifyError("Un ou plusieurs équipements n'ont pas de site de construction.");
            return;
        }

        if (invalidDate.length > 0) {
            notifyError("Un ou plusieurs équipements ont une date de location invalide.");
            return;
        }

        const onError = () => {
            notifyError("Une erreur est survenue lors de la validation du panier...");
        };

        setCreating(true);

        Api.post<{
            success?: boolean,
            reference?: string
        }>("/kamat/offer", {
            id: cart.id
        }).then(r => {
            if (typeof r === "object" && r.hasOwnProperty("success") && r.success === true && typeof r.reference === "string") {
                setCreatedOffer(r.reference);
                reloadOffers();
                reload();
                return;
            }

            onError();
        }).catch(onError).catch(() => setCreating(false));
    };

    const getSuccessContent = () => {
        return <>
            <div className={styles.cartTitle}>
                <h1>{t("Ma demande d'équipement")} <small>{t("Référence").toLowerCase()} #{createdOffer}</small></h1>
            </div>

            <div className={styles.cartSuccess}>
                <div className={styles.cartSuccessAlert}>
                    <FontAwesomeIcon icon={["fas", "circle-check"]}/>
                    <p>Votre demande d'équipement #{createdOffer} a bien été prise en compte.</p>
                </div>

                <div className={styles.cartSuccessResume}>
                    <img src={order} alt={""}/>
                    <div className={styles.cartSuccessResumeBody}>
                        <p style={{
                            fontWeight: 700,
                            fontSize: "1.2em"
                        }}>Votre demande d'équipement a bien été envoyée aux partenaires Kamat.</p>

                        <p style={{
                            marginTop: 5,
                            fontSize: "1.1em"
                        }}>Vous allez recevoir d'ici quelques instants des devis personnalisés.</p>

                        <p
                          className={styles.cartSuccessResumeBodyHelper}>Vous serez avertis par mail lorsque vous recevrez un devis.</p>
                        <p
                          className={styles.cartSuccessResumeBodyHelper}>Vous pouvez également retrouver toutes vos demandes émises et les offres reçues dans l'onglet "<Link
                          to={"/offers"}>Mes offres Kamat</Link>".</p>

                        <p style={{
                            marginTop: 30
                        }}>Vous avez des questions ? Contactez nous !</p>
                        <p style={{
                            marginTop: 5
                        }}>Merci pour votre confiance.</p>

                        <Button to={"/rentals"} outline bold style={{
                            marginTop: 30
                        }}>
                            <FontAwesomeIcon icon={["fas", "truck"]} style={{
                                transform: "scaleX(-1)",
                                marginRight: 6
                            }}/> Retour au catalogue
                        </Button>
                    </div>
                </div>
            </div>
        </>;
    };

    return <section className={styles.cart}>
        <div className={styles.cartHeader}>
            <div>
                <span>#1</span>
                <p>{t("Location instantanée, sans intermédiaire, no bullshit !")}</p>
            </div>
            <div>
                <span>#2</span>
                <p>{t("Votre site de construction de A à Z, 24/7")}</p>
            </div>
            <div>
                <span>#3</span>
                <p>{t("Des prix imbattables négociés en gros")}</p>
            </div>
        </div>

        {createdOffer !== null ? getSuccessContent() : <>
            <div className={styles.cartTitle}>
                <h1>{t("Mon panier")}</h1>

                <Button to={"/rentals"} outline bold>
                    <FontAwesomeIcon icon={["fas", "truck"]} style={{
                        transform: "scaleX(-1)",
                        marginRight: 6
                    }}/> Retour au catalogue
                </Button>
            </div>

            <div className={styles.cartBody}>
                {loading && <p>Chargement...</p>}
                {!isCartFilled && !loading && <div className={styles.cartBodyEmpty}>
                    <img src={empty} alt={""}/>
                    <p>{t("Votre panier est vide...")}</p>
                    <Button type={"black"} rounded to={"/rentals"}>{t("Commencer à louer")}</Button>
                </div>}

                {getItems()}
            </div>

            {isCartFilled && <div className={styles.cartFooter}>
                <Button type={"yellow"} rounded bold size={"large"}
                        className={classNames(styles.cartFooterBtn, creating ? styles.cartFooterBtnDisabled : undefined)}
                        onClick={createOffer} loading={creating}>
                    Louer maintenant <FontAwesomeIcon icon={["fas", "bolt-lightning"]} style={{
                    marginLeft: 10
                }}/>
                </Button>

                <Link to={"/rentals"} className={styles.cartFooterBack}>{t("Retour au catalogue")}</Link>
            </div>}
        </>}

        {selectingSite !== null &&
            <SiteListingModal display={true} onClose={() => setSelectingSite(null)} onSelect={onSelect}/>}
    </section>;
}
