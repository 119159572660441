import {Link} from "react-router-dom";
import styles from "./catalogCard.module.scss";
import Api from "../../classes/Api";
import {Picture} from "../Picture/Picture";
import {CatalogItem} from "../../interfaces/Interfaces";

interface ICardGridProps extends CatalogItem {
    mainCategory?: boolean;
}

export const CatalogCard: React.FunctionComponent<ICardGridProps> = ({
                                                                         picture,
                                                                         url,
                                                                         label,
                                                                         mainCategory
                                                                     }) => {
    return <Link
      to={`/rentals/${url}`}
      style={mainCategory !== true ? {boxShadow: "none"} : undefined}
      className={styles.catalogCard}
    >
        <Picture src={Api.getUrl(`/assets/${picture}`, true)} alt={label} className={styles.catalogCardPic}/>
        <div className={styles.catalogCardTitle}>{label}</div>
        <div className={styles.catalogCardBreak}/>
    </Link>;
};
