import React, {useEffect, useRef, useState} from "react";
import Modal from "../Modal/Modal";
import {LocationSite, LocationSiteType} from "../../interfaces/Interfaces";
import {useTranslation} from "react-i18next";
import Button from "../Button/Button";
import styles from "./siteFormModal.module.scss";
import SiteForm, {SiteFormRef} from "../SiteForm/SiteForm";

interface SiteFormModalProps {
    site?: LocationSite,
    display?: boolean,
    onClose?: () => void,
    onFinish?: (site: LocationSite) => void,
    type?: LocationSiteType,
    canSwitch?: boolean,
}

export default function SiteFormModal(props: SiteFormModalProps): JSX.Element {
    const {t} = useTranslation();

    const [saving, setSaving] = useState(false);
    const [type, setType] = useState<LocationSiteType>(props.type ?? "building");
    const [valid, setValid] = useState(true);

    const form = useRef<SiteFormRef>(null);

    useEffect(() => {
        setType(props.type ?? "building");
    }, [props.type]);

    const onFinish = (site: LocationSite) => {
        onClose();
        if (props.onFinish) {
            props.onFinish(site);
        }
    };

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }

        setType(props.type ?? "building");
        setSaving(false);
    };

    return <Modal display={props.display} className={styles.siteFormModal} onClose={onClose}>
        <h2
          className={styles.siteFormModalTitle} style={props.canSwitch === false ? {
            marginBottom: 45
        } : undefined}>{t((props.site !== undefined ? "Modifier" : "Ajouter") + " un site")}</h2>

        {props.site === undefined && props.canSwitch !== false && <>
            <p className={styles.siteFormModalTypeLabel}>{t("Quel type de site voulez-vous ajouter ?")}</p>
            <div className={styles.siteFormModalType}>
                <a className={type === "building" ? styles.siteFormModalTypeActive : ""}
                   onClick={() => setType("building")}>
                    {t("Site de construction")}
                </a>
                <a className={type === "storage" ? styles.siteFormModalTypeActive : ""}
                   onClick={() => setType("storage")}>
                    {t("Site de stockage")}
                </a>
            </div>
        </>}

        <SiteForm onFinish={onFinish} onValid={setValid} onSaving={setSaving} site={props.site} type={type} ref={form}
                  key={props.display ? 1 : 0}/>

        <div className={"km-flex-middle km-space-between"} style={{
            marginTop: 50
        }}>
            <p className={"required-legend"}>{t("Champs obligatoires")}</p>
            <Button disabled={!valid} type={"black"} loading={saving} onClick={() => form.current?.submit()}>
                {props.site !== undefined ? "Modifier" : "Ajouter"}
            </Button>
        </div>
    </Modal>;
}