import styles from "./siteForm.module.scss";
import classNames from "classnames";
import {useFormik} from "formik";
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import * as yup from "yup";
import {ApiMutationResponse, LocationSite, LocationSiteType} from "../../interfaces/Interfaces";
import Api from "../../classes/Api";
import {notifyError, notifySuccess} from "../Notification/notification";
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";
import useLocationSite from "../../hooks/useLocationSite";

interface SiteFormProps {
    site?: LocationSite,
    type?: LocationSiteType,
    onFinish?: (site: LocationSite) => void,
    onSaving?: (saving: boolean) => void,
    onValid?: (valid: boolean) => void,
}

export interface SiteFormRef {
    submit: () => void,
}

// eslint-disable-next-line react/display-name
const SiteForm = forwardRef<SiteFormRef, SiteFormProps>((props, ref) => {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);
    const {setSite} = useLocationSite();
    const {id: idUser} = user;

    const [saving, setSaving] = useState(false);
    const defaultValue = {
        name: props.site?.label ?? "",
        address: props.site?.address ?? "",
        city: props.site?.city ?? "",
        zipcode: props.site?.zipcode ?? "",
        manager: props.site?.manager ?? "",
        phone: props.site?.phone ?? ""
    };
    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        isValid,
        errors,
        touched,
    } = useFormik({
        initialValues: defaultValue,
        validationSchema: yup.object({
            name: yup.string().required(t("Nom du site obligatoire")),
            address: yup.string().required(t("Adresse du site obligatoire")),
            city: yup.string().required(t("Ville du site obligatoire")),
            zipcode: yup.string().required(t("Code postal du site obligatoire")),
            manager: yup.string().required(t("Manager du site obligatoire")),
            phone: yup.string().required(t("Téléphone du manager obligatoire")),
        }),
        onSubmit: (values) => save(values)
    });

    useImperativeHandle(ref, () => ({
        submit: () => handleSubmit()
    }));

    useEffect(() => {
        if (props.onValid) {
            props.onValid(isValid);
        }
    }, [isValid]);

    useEffect(() => {
        if (props.onSaving) {
            props.onSaving(saving);
        }
    }, [saving]);

    const save = async (values: typeof defaultValue) => {
        const data: Partial<LocationSite> & {
            [key: string]: string | number | boolean,
        } = {
            label: values.name,
            address: values.address,
            city: values.city,
            zipcode: values.zipcode,
            manager: values.manager,
            phone: values.phone,
        };

        if (props.site === undefined) {
            data.type = props.type;
            data.user = idUser;
        }

        setSaving(true);

        (props.site !== undefined ? Api.patch<ApiMutationResponse<LocationSite>>(`/items/site/${props.site.id}`, data) : Api.post<ApiMutationResponse<LocationSite>>("/items/site", data)).then(d => {
            if (props.site !== undefined) {
                notifySuccess("Le site à bien été modifié.");
            } else {
                notifySuccess("Le site a bien été ajouté.");
            }

            setSite(d.data);

            if (props.onFinish) {
                props.onFinish(d.data);
            }
        }).catch(() => {
            notifyError("Impossible de sauvegarder le site.");
        }).finally(() => {
            setSaving(false);
        });
    };

    return <form
      noValidate
    >
        <div className="km-input titled required" style={props.site !== undefined ? {
            marginTop: 50
        } : undefined}>
            <label>Nom du site</label>
            <input
              className={touched.name && errors.name ? "is-invalid" : undefined}
              type="text"
              value={values.name}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>

        <div className="km-input titled km-mt-25 required">
            <label>Adresse du site</label>
            <input
              className={touched.address && errors.address ? "is-invalid" : undefined}
              type="text"
              value={values.address}
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.address && touched.address && <div className="invalid-feedback">{errors.address}</div>}
        </div>

        <div className={classNames(styles.siteFormRow, "km-flex km-space-between km-mt-25")}>
            <div className="km-input titled required">
                <label>{t("Code postal")}</label>
                <input
                  className={touched.zipcode && errors.zipcode ? "is-invalid" : undefined}
                  type="text"
                  value={values.zipcode}
                  name="zipcode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.zipcode && touched.zipcode &&
                    <div className="invalid-feedback">{errors.zipcode}</div>}
            </div>

            <div className="km-input titled required">
                <label>{t("Ville")}</label>
                <input
                  className={touched.city && errors.city ? "is-invalid" : undefined}
                  type="text"
                  value={values.city}
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {touched.city && errors.city && <div className="invalid-feedback">{errors.city}</div>}
            </div>
        </div>

        <div className={classNames(styles.siteFormRow, "km-flex km-space-between km-mt-25")}>
            <div className="km-input titled required site-manager">
                <label>{t("Manager")}</label>
                <input
                  className={touched.manager && errors.manager ? "is-invalid" : undefined}
                  type="text"
                  value={values.manager}
                  name="manager"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.manager && touched.manager &&
                    <div className="invalid-feedback">{errors.manager}</div>}
            </div>

            <div className="km-input titled required site-manager-phone">
                <label>{t("Téléphone du manager")}</label>
                <input
                  className={touched.phone && errors.phone ? "is-invalid" : undefined}
                  type="text"
                  value={values.phone}
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {touched.phone && errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
            </div>
        </div>
    </form>;
});

export default SiteForm;