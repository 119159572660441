import { ReactComponent as EditLogo } from "./assets/edit.svg";
import { ReactComponent as DeleteLogo } from "./assets/delete.svg";
import styles from "./actionButton.module.scss";
import React, {cloneElement, FunctionComponentElement, HTMLAttributes} from "react";
import {omit} from "../../utils/ObjectUtils";
import classNames from "classnames";

interface IActionButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type : "edit"|"delete",
  disabled ?: boolean,
  onClick ?: (e : React.MouseEvent<HTMLButtonElement>) => void,
}

export default function ActionButton(props : IActionButtonProps) : FunctionComponentElement<IActionButtonProps> {
  const logo = props.type === "edit" ? <EditLogo/> : <DeleteLogo/>;

  const onClick = (e : React.MouseEvent<HTMLButtonElement>) => {
    if (props.disabled === true) {
      return;
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  return cloneElement(logo, {
    ...omit(props, "type", "className", "disabled", "onClick"),
    fill: props.disabled === true ? "grey" : "#ffc22b",
    className: classNames(styles.button, props.className),
    onClick,
  });
}