import {FunctionComponent, useState} from "react";
import catalogPromotionFunction from "common/CatalogPromotion/catalogPromotionFunction";
import {ArgumentsSection} from "common/ArgumentsSection/ArgumentsSection";
import classNames from "classnames";
import ArrowLogo from "./assets/Arrow.svg";
import FAQLogo from "assets/FAQ.svg";
import CGVLogo from "assets/CGV.svg";
import PPLogo from "assets/PP.svg";
import styles from "./support.module.scss";
import {ContactForm} from "pages/Support/components/ContactForm/ContactForm";
import {useTranslation} from "react-i18next";
import {config} from "configs";
import Faq from "./components/Faq/Faq";

export const Support: FunctionComponent = () => {
    const Promotion = catalogPromotionFunction(3);
    const {t} = useTranslation();

    const [faq, setFaq] = useState(false);

    return <section className={classNames(styles.supportSection, "p-5")}>
        <div className="row">
            <ArgumentsSection/>
        </div>

        <div className={classNames("row mt-5 pt-5", styles.leftSection)}>
            <div className={classNames("col-xl-5")}>
                <div className={classNames("row", styles.decoration)}>
                    <div className="col-12 col-xl-6">
                        <Promotion/>
                    </div>
                    <div className="col-xl-6">
                        <img
                          src={ArrowLogo}
                          alt="ArrowLogo"
                          className={classNames(styles.arrow)}
                        />
                    </div>
                </div>

                <div className="row">
                    {t("Au 21eme siècle, la location de machines ne devrait plus être un parcours d'obstacle pour "
                      + "les artisans et les grands groupes ; Kamat est la plateforme qui vous permet de maitriser "
                      + "vos coûts dans ce marché croissant ; trouver une machine devient une tâche aisée, et cela "
                      + "vous permet de vous focaliser sur une chose - votre métier.")}
                </div>

                <div className="d-flex justify-content-center align-items-center p-5">
                    <img
                      src={FAQLogo}
                      alt="FAQLogo"
                      width="50"
                      className={classNames("mx-5")}
                      onClick={() => setFaq(true)}
                    />
                    <img
                      src={CGVLogo}
                      alt="CGVLogo"
                      width="50"
                      className={classNames("mx-5")}
                      onClick={() => window.open(config.cguLink, "_blank")}
                    />
                    <img
                      src={PPLogo}
                      alt="PPLogo"
                      width="50"
                      className={classNames("mx-5")}
                      onClick={() => window.open(config.ppLink, "_blank")}
                    />
                </div>
            </div>

            <div className="col-xl-4 offset-xl-1">
                <div className="row">
                    <ContactForm/>
                </div>
            </div>
        </div>

        <Faq display={faq} onClose={() => setFaq(false)}/>
    </section>;
};
