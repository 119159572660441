import {useContext, useEffect, useState} from "react";
import AuthHOC from "./common/AuthHOC/AuthHOC";
import AuthContext from "./context/AuthContext";
import moment from "moment";
import "moment/locale/fr";
import {useTranslation} from "react-i18next";
import TagManager from "react-gtm-module";
import {Stripe} from "@stripe/stripe-js";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import AppContext from "./context/AppContext";
import {Configuration, LocationSite, Offer} from "./interfaces/Interfaces";
import OfferHOC from "common/OfferHOC/OfferHOC";
import Router from "./Router";
import Api from "./classes/Api";

library.add(fas, far);

declare global {
    interface Window {
        stripe: Stripe | null,
        __locationSiteHook?: string
    }
}

/*loadStripe(config.stripeKey).then((s) => {
    window.stripe = s;
});*/

function usePageViews() {
    const {user} = useContext(AuthContext);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: "pageview",
                userId: user?.id
            }
        });
    }, [location, user]);
}

function App(): JSX.Element {
    const {i18n} = useTranslation();

    const [cart, setCart] = useState<Offer | null>(null);
    const [config, setConfig] = useState<Configuration>({} as Configuration);
    const [sites, setSites] = useState<LocationSite[] | null>(null);

    usePageViews();

    useEffect(() => {
        moment.locale(i18n.language);
        loadConfig();
    }, []);

    const loadConfig = () => {
        Api.get<{
            data: Configuration
        }>("/items/configuration", undefined, {
            ignoreAuthState: true
        }).then(r => {
            setConfig(r.data);
        });
    };

    return <AppContext.Provider value={{
        cart: {
            entity: cart,
            setEntity: setCart,
            loaded: false
        },
        config,
        setConfig,
        sites,
        setSites
    }}>
        <AuthHOC>
            <OfferHOC>
                <Router/>
            </OfferHOC>
        </AuthHOC>
    </AppContext.Provider>;
}

export default App;
