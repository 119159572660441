import Modal from "common/Modal/Modal";
import {
    CatalogProduct,
    LocationSite,
    Media,
    PartnerProduct,
    RecursiveGenericType
} from "../../../../../interfaces/Interfaces";
import classNames from "classnames";
import {Formik} from "formik";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import usePartnerProducts from "../../../../../hooks/usePartnerProducts";
import styles from "./partnerProductFormModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext, useEffect, useRef, useState} from "react";
import {Picture} from "../../../../../common/Picture/Picture";
import Api, {ApiRequestConfig} from "../../../../../classes/Api";
import Button from "../../../../../common/Button/Button";
import {notifyError, notifySuccess} from "../../../../../common/Notification/notification";
import useLocationSite from "../../../../../hooks/useLocationSite";
import DatePicker from "../../../../../common/DatePicker/DatePicker";
import moment from "moment";
import Uploader, {UploaderRef} from "../../../../../common/Uploader/Uploader";
import AuthContext from "../../../../../context/AuthContext";
import {isValidItem} from "../../../../../utils/ObjectUtils";
import SiteFormModal from "../../../../../common/SiteFormModal/SiteFormModal";

interface PartnerProductFromProps {
    product?: PartnerProduct,
    display: boolean,
    onClose: () => void,
    requestConfig: ApiRequestConfig,
    onFinish?: (product: PartnerProduct) => void
}

export default function PartnerProductFromModal(props: PartnerProductFromProps): JSX.Element {
    const {t} = useTranslation();
    const {options, products} = usePartnerProducts();
    const {user} = useContext(AuthContext);
    const {storageSites, getSiteName} = useLocationSite();
    const {id: idOwner} = user;

    const [selectedProduct, setSelectedProduct] = useState<CatalogProduct | null>(null);
    const [saving, setSaving] = useState(false);
    const [addSite, setAddSite] = useState(false);

    const technicalSheetUploader = useRef<UploaderRef>(null);
    const certificateUploader = useRef<UploaderRef>(null);

    useEffect(() => {
        if (props.product === undefined) {
            setSelectedProduct(null);
            return;
        }

        setSelectedProduct(typeof props.product.product === "number" ? products.filter(p => p.id === props.product?.product)[0] ?? null : props.product.product);
    }, [props.product]);

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }

        setSelectedProduct(null);
        setSaving(false);
    };

    const schema = yup.object({
        name: yup.string().required(t("Nom du produit obligatoire")),
        brand: yup.string().required(t("Marque du produit obligatoire")),
        insuranceRate: yup.number().required(t("Taux de l'assurance obligatoire (en %)")),
        priceDaily: yup.number().required(t("Prix journalier obligatoire")),
        referenceNumber: yup.string().required(t("Référence obligatoire")),
        hasOperator: yup.boolean(),
        control_validity_date: yup.string(),
        product_id: yup.object().shape({
            label: yup
              .string(),
            value: yup.number().min(0, t("Veuillez choisir un produit dans la liste")),
        }),
        location_id: yup.object().shape({
            label: yup
              .string(),
            value: yup.number().min(0, t("Veuillez choisir un site de stockage dans la liste")),
        }),
    });

    const getDefaultProductOption = () => {
        if (props.product === undefined) {
            return {
                value: -1,
                label: t("Sélectionner le produit partenaire"),
            };
        }

        if (typeof props.product.product === "number") {
            return {
                value: props.product.product,
                label: products.filter(p => p.id === props.product?.product)[0]?.label ?? "?"
            };
        }

        return {
            value: (props.product.product as CatalogProduct).id,
            label: (props.product.product as CatalogProduct).label
        };
    };

    const getDefaultSiteOption = () => {
        if (props.product === undefined || !isValidItem(props.product.location as unknown)) {
            return {
                value: -1,
                label: t("Sélectionner le site de stockage"),
            };
        }

        return {
            value: (props.product.location as LocationSite).id,
            label: getSiteName(props.product.location as LocationSite)
        };
    };

    const getDefaultInsurance = () => {
        if (props.product === undefined) {
            return "";
        }

        return parseFloat(props.product.insurance).toFixed(4);
    };

    const getStorageSites = (): {
        value: number,
        label: string,
        isDisabled?: boolean
    }[] => {
        return [
            {
                label: t("Site de stockage"),
                value: -1,
                isDisabled: true
            },
            ...storageSites.map(s => {
                return {
                    label: getSiteName(s),
                    value: s.id
                };
            })
        ];
    };

    const save = async (values: typeof defaultValue) => {
        const data: RecursiveGenericType = {
            label: values.name,
            brand: values.brand,
            owner: idOwner,
            insurance: values.insuranceRate,
            operator: values.hasOperator,
            price_daily: values.priceDaily,
            product: values.product_id.value,
            internal_reference: values.referenceNumber,
            location: values.location_id.value,
            control_validity_date: moment(values.control_validity_date).add(2, "d").toDate().toJSON()
        };

        type Response = {
            data: PartnerProduct
        };

        setSaving(true);

        try {
            const ts = await technicalSheetUploader.current?.upload();

            if (ts !== null && typeof ts === "object") {
                data.technical_sheet = ts.id;
            }
        } catch (e) {
        }

        try {
            const cert = await certificateUploader.current?.upload();

            if (cert !== null && typeof cert === "object") {
                data.cce = cert.id;
            }
        } catch (e) {
        }

        (props.product !== undefined ? Api.patch<Response>(`/items/machine/${props.product.id}`, data, props.requestConfig) : Api.post<Response>("/items/machine", data, props.requestConfig)).then(d => {
            if (props.product !== undefined) {
                notifySuccess("Le produit à bien été modifié.");
            } else {
                notifySuccess("Le produit a bien été ajouté.");
            }
            onClose();

            if (props.onFinish) {
                props.onFinish(d.data);
            }
        }).catch(() => {
            notifyError("Impossible de sauvegarder le produit.");
        }).finally(() => {
            setSaving(false);
        });
    };

    const getTechnicalSheet = (): Media | undefined => {
        if (props.product === undefined || props.product.technical_sheet === null || typeof props.product.technical_sheet !== "object") {
            return undefined;
        }

        return props.product.technical_sheet;
    };

    const getCertificate = (): Media | undefined => {
        if (props.product === undefined || props.product.cce === null || typeof props.product.cce !== "object") {
            return undefined;
        }

        return props.product.cce;
    };

    const defaultValue = {
        name: props.product?.label ?? "",
        brand: props.product?.brand ?? "",
        insuranceRate: getDefaultInsurance(),
        hasOperator: props.product?.operator ?? false,
        priceDaily: props.product?.price_daily ?? "",
        product_id: getDefaultProductOption(),
        referenceNumber: props?.product?.internal_reference ?? "",
        location_id: getDefaultSiteOption(),
        control_validity_date: props.product?.control_validity_date ?? undefined
    };

    return <Modal display={props.display} onClose={onClose} className={styles.productModal}>
        <Formik
          key={props.display ? 1 : 0}
          validationSchema={schema}
          onSubmit={save}
          initialValues={defaultValue}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  values,
                  isValid,
                  errors,
                  touched,
              }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
              >
                  <div className={styles.productModalHeader}>
                      {selectedProduct === null ? <div className={styles.productModalHeaderMissingIcon}>
                          <FontAwesomeIcon icon={["fas", "helmet-safety"]}/>
                      </div> : <div className={styles.productModalHeaderIcon}>
                          <Picture src={Api.getUrl(`/assets/${selectedProduct.picture}`, true)}/>
                      </div>}

                      <div className={styles.productModalHeaderContent}>
                          <h4>
                              {t(props.product !== undefined && props.product !== null ? "Modifier une machine" : "Ajouter une machine")}
                          </h4>

                          <div className="km-input titled required">
                              <label>Produit partenaire</label>
                              <Select
                                isSearchable
                                classNamePrefix={"km-input-select"}
                                className={classNames("km-input-select", errors.product_id ? "is-invalid" : undefined)}
                                name={"product_id"}
                                value={values.product_id}
                                options={[{
                                    label: t("Sélectionner le produit partenaire"),
                                    value: -1,
                                    isDisabled: true
                                }, ...options]}
                                placeholder={t("Sélectionner le produit partenaire")}
                                onBlur={handleBlur}
                                onChange={(newValue) => {
                                    setFieldValue("product_id", newValue);
                                    setSelectedProduct(newValue === null ? null : products.filter(p => p.id === newValue.value)[0] ?? null);
                                }}
                              />

                              {errors.product_id && touched.product_id &&
                                  <div className="invalid-feedback">{errors.product_id.value}</div>}
                          </div>
                      </div>
                  </div>

                  <div className="km-input km-mt-25 titled required">
                      <label>Site de stockage</label>
                      <Select
                        isSearchable
                        classNamePrefix={"km-input-select"}
                        className={classNames("km-input-select", errors.location_id ? "is-invalid" : undefined)}
                        value={values.location_id}
                        options={getStorageSites()}
                        onBlur={handleBlur}
                        name={"location_id"}
                        onChange={(newValue) => {
                            setFieldValue("location_id", newValue);
                        }}
                      />

                      {errors.location_id && touched.location_id &&
                          <div className="invalid-feedback">{errors.location_id.value}</div>}
                  </div>

                  <div className={styles.productModalSite}>
                      <p><FontAwesomeIcon icon={["fas", "circle-question"]}/> Le site n'est pas présent dans la liste ?
                      </p>
                      <Button size={"small"} faIcon={["fas", "warehouse"]} onClick={e => {
                          e.preventDefault();
                          setAddSite(true);
                      }}>
                          Ajouter un nouveau site
                      </Button>
                  </div>

                  <div className={classNames(styles.productModalRow, "km-flex km-space-between km-mt-25")}>
                      <div className="km-input titled required">
                          <label>{t("Nom du produit")}</label>
                          <input
                            className={touched.name && errors.name ? "is-invalid" : undefined}
                            type="text"
                            value={values.name}
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                      </div>

                      <div className="km-input titled required">
                          <label>{t("Marque")}</label>
                          <input
                            className={touched.brand && errors.brand ? "is-invalid" : undefined}
                            type="text"
                            value={values.brand}
                            name="brand"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {touched.brand && errors.brand && <div className="invalid-feedback">{errors.brand}</div>}
                      </div>
                  </div>

                  <div className={classNames(styles.productModalRowCheckbox, "km-flex-middle km-mt-25")}>
                      <div className="form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={values.hasOperator}
                            name="hasOperator"
                            onChange={handleChange}
                          />
                          <label className="form-check-label">{t("Avec opérateur")}</label>
                      </div>
                  </div>

                  <div className={classNames(styles.productModalRow, "km-flex km-space-between")} style={{
                      marginTop: 35
                  }}>
                      <div className="km-input titled suffixed required">
                          <label>{t("Tarif journalier de location")}</label>
                          <input
                            className={touched.priceDaily && errors.priceDaily ? "is-invalid" : undefined}
                            type="text"
                            value={values.priceDaily}
                            name="priceDaily"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span className={"km-input-suffix"}>€</span>
                          {touched.priceDaily && errors.priceDaily ?
                            <div className="invalid-feedback">{errors.priceDaily}</div> : null}
                      </div>

                      <div className="km-input titled required">
                          <label>{t("Taux de l'assurance (en %)")}</label>
                          <input
                            className={classNames(touched.insuranceRate && errors.insuranceRate ? "is-invalid" : undefined)}
                            type="number"
                            value={values.insuranceRate}
                            name="insuranceRate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {touched.insuranceRate && errors.insuranceRate ?
                            <div className="invalid-feedback">{errors.insuranceRate}</div> : null}
                      </div>
                  </div>

                  <div className="km-input titled required" style={{
                      marginTop: 35
                  }}>
                      <label>{t("Votre référence interne")}</label>
                      <input
                        className={touched.referenceNumber && errors.referenceNumber ? "is-invalid" : undefined}
                        type="text"
                        value={values.referenceNumber}
                        name="referenceNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.referenceNumber && errors.referenceNumber ?
                        <div className="invalid-feedback">{errors.referenceNumber}</div> : null}
                  </div>

                  <div className={classNames(styles.productModalRow, "km-flex km-space-between")} style={{
                      marginTop: 35
                  }}>
                      <div className="km-input titled">
                          <label>{t("Date du dernier contrôle périodique")}</label>
                          <DatePicker placeholder={"DD/MM/YYYY"}
                                      onChange={v => setFieldValue("control_validity_date", moment(v).format("YYYY-MM-DD"))}
                                      value={typeof values.control_validity_date === "string" ? moment(values.control_validity_date).valueOf() : undefined}/>
                          {touched.referenceNumber && errors.referenceNumber ?
                            <div className="invalid-feedback">{errors.referenceNumber}</div> : null}
                      </div>

                      <Uploader title={"Certificat de conformité européen"} media={getCertificate()}
                                ref={certificateUploader}/>
                  </div>

                  <Uploader title={"Fiche technique"} className={"km-mt-25"} media={getTechnicalSheet()}
                            ref={technicalSheetUploader}/>

                  <div className={"km-flex-middle km-space-between"} style={{
                      marginTop: 40
                  }}>
                      <p className={"required-legend"}>{t("Champs obligatoires")}</p>
                      <Button disabled={!isValid} type={"blue"} loading={saving} size={"large"} style={{
                          width: "100%",
                          maxWidth: 200
                      }}>
                          {props.product !== undefined ? "Modifier" : "Ajouter"}
                      </Button>
                  </div>

                  <SiteFormModal display={addSite} onClose={() => setAddSite(false)} type={"storage"} canSwitch={false}
                                 onFinish={s => setFieldValue("location_id", {
                                     label: getSiteName(s),
                                     value: s.id
                                 })}/>
              </form>
            )}
        </Formik>

    </Modal>;
}