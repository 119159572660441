import {CheckoutModal} from "./common/CheckoutModal/CheckoutModal";
import {Switch, useLocation} from "react-router-dom";
import {createContext, Dispatch, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {Layout} from "./common/Layout/Layout";
import {matchPath, Redirect, Route, RouteProps} from "react-router";
import {LoginState} from "./interfaces/Interfaces";
import Login from "./pages/Login/Login";
import AuthContext from "./context/AuthContext";
import Signup from "./pages/Signup/Signup";
import Invoice from "./pages/Invoice/Invoice";
import {Rental} from "./pages/RentalCatalog/RentalCatalog";
import Cart from "./pages/Cart/Cart";
import OfferForm from "./pages/OfferForm/OfferForm";
import OfferList from "./pages/OfferList/OfferList";
import MySettings from "./pages/MySettings/MySettings";
import RentalManager from "./pages/RentalManager/RentalManager";
import {Support} from "./pages/Support/Support";
import classnames from "classnames";
import styles from "./common/Layout/layout.module.scss";
import Error from "./pages/Error/Error";

interface RouterContextProps {
    useLayout: boolean,
    setUseLayout: Dispatch<boolean>,
    active: RouteConfig | null
}

interface Routes {
    [key: string]: RouteConfig
}

interface RouteConfig {
    exact?: boolean,
    private?: boolean,
    component: ReactNode,
    layout?: boolean,
    primary?: boolean,
    defaultPath?: string
}

interface PageProps extends RouteConfig {
    children: ReactNode
}

const routes: Routes = {
    "/404": {
        component: <Error/>,
        exact: true,
        layout: false
    },
    "/login": {
        component: <Login/>,
        exact: true,
        layout: false
    },
    "/register": {
        component: <Signup/>,
        exact: true,
        layout: false
    },
    "/invoice/:id": {
        exact: true,
        component: <Invoice/>,
        layout: false,
    },
    "/rentals/:family?/:category?": {
        component: <Rental/>,
        private: true,
        primary: true,
        defaultPath: "/rentals"
    },
    "/cart": {
        component: <Cart/>,
        private: true
    },
    "/offer/:token": {
        component: <OfferForm/>,
        exact: true
    },
    "/offers/:id?": {
        component: <OfferList/>,
        private: true,
        defaultPath: "/offers"
    },
    "/settings": {
        component: <MySettings/>,
        private: true,
    },
    "/management": {
        component: <RentalManager/>,
        private: true
    },
    "/messenger": {
        component: <Support/>
    }
};

type RouteWithPath = RouteConfig & {
    path: string;
}

export const RouterContext = createContext({} as RouterContextProps);

export default function Router(): JSX.Element {
    const location = useLocation();

    const {loginState} = useContext(AuthContext);

    const getPrimaryRoute = (): RouteWithPath => {
        const r = Object.keys(routes).filter(p => routes[p].primary)[0];

        if (r === undefined || (routes[r].private && loginState !== LoginState.LOGGED)) {
            return {
                ...routes["/login"],
                path: "/login"
            };
        }

        return {
            ...routes[r],
            path: routes[r].defaultPath ?? r
        };
    };

    const getCurrentRoute = (): RouteWithPath | null => {
        for (const path of Object.keys(routes)) {
            const route = routes[path];

            const match = matchPath(location.pathname, {
                path: path as string,
                ...(route as RouteProps)
            });

            if (match !== null) {
                if (route.private === true && loginState !== LoginState.LOGGED) {
                    break;
                }

                return {
                    ...route,
                    path: route.defaultPath ?? path
                };
            }
        }

        return null;
    };

    const getDefaultLayoutState = (): boolean => {
        return (getCurrentRoute() ?? getPrimaryRoute()).layout !== false;
    };

    const [orderFinished, setOrderFinished] = useState<string | null>(null);
    const [useLayout, setUseLayout] = useState(getDefaultLayoutState());
    const [activeRoute, setActiveRoute] = useState<RouteConfig | null>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const orderReference = params.get("order");

        if (typeof orderReference === "string" && orderReference.trim().length > 5) {
            setOrderFinished(orderReference);
        }

        setActiveRoute(getCurrentRoute());
    }, [location]);

    const nodes = useMemo(() => {
        const defaultRoute = <Route path={"/"} exact key={"/"} render={() => <Redirect to={getPrimaryRoute().path}/>}/>;

        return [defaultRoute, ...(Object.keys(routes) as (keyof typeof routes)[]).map(path => {
            const route = routes[path];
            const props: RouteProps = {
                exact: route.exact,
                path: path as string
            };

            const getContent = () => {
                if (route.private && loginState !== LoginState.LOGGED) {
                    return <Login/>;
                }

                return <Page {...route}>
                    {route.component}
                </Page>;
            };

            return <Route {...props} render={getContent} key={path as string}/>;
        })];
    }, [loginState]);

    return <RouterContext.Provider value={{
        active: activeRoute,
        setUseLayout,
        useLayout
    }}>
        <Layout>
            <Switch>
                {nodes}
                {orderFinished !== null &&
                    <CheckoutModal reference={orderFinished} display={true}
                                   onClose={() => setOrderFinished(null)}/>}
            </Switch>
        </Layout>
    </RouterContext.Provider>;
}

function Page(props: PageProps): JSX.Element {
    const {setUseLayout} = useContext(RouterContext);

    useEffect(() => {
        setUseLayout(props.layout !== false);
    }, []);

    if (props.layout !== false) {
        return <main className={classnames(styles.mainContent)}>
            {props.children}
        </main>;
    }

    return props.children as JSX.Element;
}