import styles from "./productDetail.module.scss";
import Modal from "../../../../common/Modal/Modal";
import {CatalogProduct, Energy} from "../../../../interfaces/Interfaces";
import Api from "../../../../classes/Api";
import dieselLogo from "../../../../assets/essence.svg";
import electricityLogo from "../../../../assets/electricity.svg";
import {useContext, useEffect, useMemo, useState} from "react";
import {getUniqKey} from "../../../../utils/NumberUtils";
import CartAddForm from "../CartAddForm/CartAddForm";
import {notifySuccess} from "../../../../common/Notification/notification";
import AuthContext from "../../../../context/AuthContext";
import Button from "../../../../common/Button/Button";

interface ProductDetailProps {
    product?: CatalogProduct,
    onClose: () => void,
}

export default function ProductDetail({product, onClose}: ProductDetailProps): JSX.Element {
    const {user} = useContext(AuthContext);

    const [update, setUpdate] = useState(0);

    useEffect(() => {
        setUpdate(getUniqKey());
    }, [product]);

    const content = useMemo(() => {
        if (product === undefined) {
            return null;
        }

        return <div className={styles.productDetailBody}>
            <h1>{product.label}</h1>

            <div className={styles.productDetailBodyPic}>
                <img src={Api.getUrl(`/assets/${product.picture}`, true)} alt={""}/>

                {product.energy !== null && <img
                    className={styles.productDetailBodyPicEnergy}
                    src={product.energy === Energy.DIESEL ? dieselLogo : electricityLogo}
                    alt="Logo"
                />}
            </div>

            <div className={styles.productDetailBodyTab}>
                <div className={styles.productDetailBodyTabTitle}>
                    <p>Description</p>
                </div>

                <div dangerouslySetInnerHTML={{__html: product.description ?? "<p>Aucune description...</p>"}}
                     className={styles.productDetailBodyTabBody}/>
            </div>

            <div className={styles.productDetailBodyTab}>
                <div className={styles.productDetailBodyTabTitle}>
                    <p>Caractéristiques techniques</p>
                </div>

                <div className={styles.productDetailBodyTabBody}>
                    <ul>
                        {(product.characteristics ?? []).map((c, i) => <li key={i}>
                            <p>{c.label}</p>
                            <p>{c.value}</p>
                        </li>)}
                    </ul>
                </div>
            </div>

            {user.verified === true ? <div className={styles.productDetailBodyRent}>
                <h3>Louer maintenant !</h3>

                <CartAddForm product={product} key={update} onAdd={() => {
                    onClose();
                    notifySuccess("Équipement ajouté au panier.");
                }}/>
            </div> : <div className={styles.productDetailBodyRentAlert}>
                <p><b>Votre compte n'est pas complet.</b></p>
                <p>Vous devez compléter les informations de votre société pour commencer à louer.</p>
                <Button to={"/settings?society"}>Compléter mes informations</Button>
            </div>}
        </div>;
    }, [product, update]);

    return <Modal display={product !== undefined} onClose={onClose} sideModal className={styles.productDetail}
                  closeOnBackdropClick>
        {content}
    </Modal>;
}