import styles from "./confirmModal.module.scss";
import Modal, {ModalProps} from "../Modal/Modal";
import {omit} from "../../utils/ObjectUtils";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import img from "./cone.svg";

interface ConfirmModalProps extends ModalProps {
    onConfirm?: () => void,
    title?: string,
    cancelText?: string,
    confirmText?: string,
    loading?: boolean,
}

export default function ConfirmModal(props: ConfirmModalProps): JSX.Element {
    const {t} = useTranslation();

    return <Modal {...omit(props, "onConfirm", "title", "loading", "confirmText", "cancelText")} className={styles.confirmModal}>
        <img src={img} className={styles.confirmModalCone} alt={""}/>
        {props.title !== undefined && <h2 className={styles.confirmModalTitle}>{props.title}</h2>}

        <div className={styles.confirmModalBody}>
            {props.children}
        </div>

        <div className={styles.confirmModalFooter}>
            <Button type={"black"} outline onClick={props.onClose}>{props.cancelText ?? t("Annuler")}</Button>
            <Button type={"black"} onClick={props.onConfirm}
                    loading={props.loading}>{props.confirmText ?? t("Valider")}</Button>
        </div>
    </Modal>;
}