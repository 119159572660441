import styles from "./catalogPromotion.module.scss";
import { useTranslation } from "react-i18next";

const catalogPromotionFunction = (index) => {
  const CatalogPromotion = () => {
    const { t } = useTranslation();
    switch (index) {
    default:
      return (
        <h2 className={styles.promotion}>
          {t("Louez tout votre équipement professionnel")}
          <span className={styles.emphasis}> 24/7</span>
        </h2>
      );
    case 0:
      return (
        <h2 className={styles.promotion}>
          {t("Louez tout votre équipement professionnel")}
          <span className={styles.emphasis}> 24/7</span>
        </h2>
      );
    case 1:
      return (
        <h2 className={styles.promotion}>
            The <span className={styles.emphasis}>smartest</span> and&nbsp;
          <span className={styles.emphasis}>fastest</span> rental in the world
        </h2>
      );
    case 2:
      return (
        <h2 className={styles.promotion}>
          {t("Louez instantanément au")}&nbsp;
          <span className={styles.emphasis}>{t("meilleur prix")}</span>
        </h2>
      );

    case 3:
      return (
        <h2 className={styles.promotion}>
          {t("Gardez le contact ")}
          <span className={styles.emphasis}>24/7</span>
        </h2>
      );

    case 4:
      return (
        <h2 className={styles.promotion}>
          {t("Choisissez votre méthode de ")}
          <span className={styles.emphasis}>paiement</span>
        </h2>
      );
    }
  };

  return CatalogPromotion;
};

export default catalogPromotionFunction;
