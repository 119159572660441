import missingImage from "assets/missingImage.svg";
import {ImgHTMLAttributes, SyntheticEvent} from "react";
import {omit} from "../../utils/ObjectUtils";

interface PictureProps extends ImgHTMLAttributes<HTMLImageElement> {
    fallbackImg?: string
}

export const Picture = (props: PictureProps): JSX.Element => {
    const onError = (e: SyntheticEvent) => {
        (e.target as HTMLImageElement).src = props.fallbackImg ?? missingImage;
    };

    return <img alt={""} {...omit(props, "fallbackImg")} onError={onError} onAbort={onError}/>;
};
