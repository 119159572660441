import classNames from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./offerDetailConditionsModal.module.scss";
import {IDashBoardPartnerProduct} from "interfaces/Interfaces";
import Modal from "common/Modal/Modal";
import Button from "../../../../../../common/Button/Button";
import {sanitizeHtmlString} from "../../../../../../utils/StringUtils";

interface OfferDetailConditionsModalProps {
    display: boolean,
    onClose: () => void,
    conditions: string,
    partnerProducts?: IDashBoardPartnerProduct[]
}

export default function OfferDetailConditionsModal({
                                                       display,
                                                       onClose,
                                                       conditions,
                                                       partnerProducts
                                                   }: OfferDetailConditionsModalProps) {
    const {t} = useTranslation();

    return <Modal
      display={display}
      onClose={onClose}
      className={styles.offerDetailConditionsModal}
    >
        <h2>{t("Conditions de location")}</h2>

        <div className={classNames(styles.offerDetailConditionsModalText)} dangerouslySetInnerHTML={{__html: sanitizeHtmlString(conditions)}}/>

        {partnerProducts && partnerProducts.map(p => <div key={String(p.id)}
                                                          className={classNames(styles.offerDetailConditionsModalText)}>
            {p.name}
        </div>)}

        <Button type={"black"} rounded onClick={onClose} style={{
            display: "table",
            margin: "20px auto 0"
        }}>
            {t("Fermer")}
        </Button>
    </Modal>;
}