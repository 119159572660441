import classNames from "classnames";
import {MissingImage} from "common/MissingImage/MissingImage";
import {IMenu} from "interfaces/Interfaces";
import styles from "./menuCard.module.scss";

interface IMenuCardProps {
    menu: IMenu;
    currentChosenMenu: number|string;

    handleChooseMenu(Menu: number|string): void;
}

export const MenuCard: React.FunctionComponent<IMenuCardProps> = ({
                                                                      menu,
                                                                      currentChosenMenu,
                                                                      handleChooseMenu,
                                                                  }) => {
    return (
      <div
        className={classNames(
          "mb-1 p-2",
          styles.menuCard,
          currentChosenMenu === menu.id ? styles.active : null
        )}
        onClick={() => {
            handleChooseMenu(menu.id);
        }}
      >
          <div className={classNames(styles.menuCardIcon, styles.flag)}>
              {menu.img !== undefined ? <img src={menu.img as string} className={classNames(styles.Logo)}
                                             alt={""}/> : (menu.logosPath !== undefined ?
                <MissingImage className={classNames(styles.Logo)} logosPath={menu.logosPath}/> : null)}
          </div>

          <div className={classNames(styles.menuCardContent)}>
              <h6 className={classNames("col-12 col-xl-12", styles.menuName)}>{menu.name}</h6>
              <div className={classNames(styles.info)}>{menu.info}</div>
          </div>
      </div>
    );
};
