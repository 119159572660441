import React, {ReactNode, useContext, useMemo, useState} from "react";
import classNames from "classnames";
import styles from "./offerDetail.module.scss";
import moment from "moment";
import {toPrice} from "../../../../utils/NumberUtils";
import {useTranslation} from "react-i18next";
import OfferDetailConditionsModal from "./components/OfferDetailConditionsModal/OfferDetailConditionsModal";
import {useHistory} from "react-router-dom";
import {
    EasytransacStatus,
    Offer,
    OfferItem,
    Quote,
    QuoteItem,
    QuotePrice,
    User
} from "../../../../interfaces/Interfaces";
import {Picture} from "../../../../common/Picture/Picture";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../../../common/Button/Button";
import {CheckoutModal} from "../../../../common/CheckoutModal/CheckoutModal";
import {getItemsInformation, getQuotePrice} from "../../../../utils/OrderUtils";
import AppContext from "../../../../context/AppContext";
import Api from "../../../../classes/Api";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";
import OfferContext from "../../../../context/OfferContext";
import AuthContext from "../../../../context/AuthContext";
import AccountNotCompleteAlert from "../../../../common/AccountNotCompleteAlert/AccountNotCompleteAlert";

interface OfferDetailProps {
    quote?: Quote,
    offer?: Offer,
    type?: "offer" | "quote" | "waitingQuote",
    onClose?: () => void,
}

export default function OfferDetail({quote, offer, type, onClose}: OfferDetailProps): JSX.Element {
    const {t} = useTranslation();
    const {config} = useContext(AppContext);
    const {user} = useContext(AuthContext);
    const {reload} = useContext(OfferContext);
    const history = useHistory();

    const [saving, setSaving] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [checkout, setCheckout] = useState(false);

    const preview = useMemo(() => {
        return user.easytransac_status !== EasytransacStatus.FULLY_VALIDATE || user.verified !== true;
    }, [user]);

    const getItems = (): ReactNode[] => {
        const arr = quote ?? offer;

        if (arr === undefined) {
            return [];
        }

        return arr.items.map(item => {
            if (typeof item !== "object") {
                return;
            }

            const info = getItemsInformation(item as QuoteItem | OfferItem, quote === undefined, t);

            if (info === null) {
                return;
            }

            return <li key={item.id} className={styles.offerDetailProduct}>
                <div className={styles.offerDetailProductImg}>
                    <Picture src={info.picture}
                             alt={""}/>
                </div>

                <div className={classNames(styles.offerDetailProductInfo)}>
                    <h5>{info.label}</h5>
                    <div>
                        <p>{info.date}</p>
                        {!preview && <p><FontAwesomeIcon
                          icon={["fas", "location-dot"]}/> {info.address}
                        </p>}
                        {info.options.length > 0 && <p>
                            <b>Option{info.options.length > 1 ? "s" : ""} :</b> {info.options.join(", ").toLowerCase()}
                        </p>}
                    </div>
                </div>
            </li>;
        });
    };

    const isExpired = (): boolean => {
        if (quote !== undefined || offer !== undefined) {
            const element = (quote ?? offer) as Offer | Quote;

            return element.expires_on === null || moment(element.expires_on).valueOf() < moment().valueOf();
        }

        return true;
    };

    const haveConditions = (): boolean => {
        return quote !== undefined && typeof quote.rental_conditions === "string" && quote.rental_conditions.trim().length > 0;
    };

    const cancel = () => {
        if (quote === undefined) {
            return;
        }

        setSaving(true);
        Api.delete(`/items/quote/${quote.id}`).then(() => {
            notifySuccess(t("Offre annulée"));
            history.push("/offers");
            reload();
        }).catch(() => {
            notifyError("Une erreur est survenue lors de l'annulation de l'offre");
            setSaving(false);
        });
    };

    const getCompanyName = (user: User | number | string | undefined): string => {
        if (typeof user !== "object") {
            return "?";
        }

        if (typeof user.society_name === "string" && user.society_name.trim().length > 0) {
            return user.society_name;
        }

        return user.last_name.toUpperCase() + " " + user.first_name.toUpperCase();
    };

    const price = useMemo<QuotePrice>(() => {
        if (quote === undefined) {
            return {
                price: 0,
                transport: 0,
                insurance: 0,
                vat: 0,
                ht: 0,
                ttc: 0,
                commission: 0
            };
        }
        return getQuotePrice(quote, (quote.author as User).fee ?? config.default_fee);
    }, [quote]);

    return <div className={classNames(styles.offerDetail)}>
        <a className={styles.offerDetailClose} onClick={onClose}>
            <FontAwesomeIcon icon={["fas", "times"]}/>
        </a>

        {type === "quote" && quote !== undefined &&
            <h2>Résumé de mon offre envoyée pour {getCompanyName(quote.client)}</h2>}
        {type === "offer" && quote !== undefined &&
            <h2>{t("Résumé de l'offre Kamat spéciale de")} {getCompanyName(quote.author)}</h2>}
        {type === "waitingQuote" && offer !== undefined && <h2>
            Résumé de la demande d'équipement de {getCompanyName(offer.client)}
        </h2>}

        <ul className={classNames(styles.offerDetailProducts)}>
            {getItems()}
        </ul>

        {quote !== undefined && <ul className={classNames(styles.offerDetailPrice)}>
            <li>
                <p>{t("Sous-total")}</p>
                <p>{toPrice(price.price)}</p>
            </li>
            <li>
                <p>{t("Transport A/R")}</p>
                <p>{toPrice(price.transport)}</p>
            </li>
            <li>
                <p>{t("Assurance")}</p>
                <p>{toPrice(price.insurance)}</p>
            </li>
            <li>
                <p>{t("Frais de dossier")}</p>
                <p>{toPrice(price.commission)}</p>
            </li>
            <li>
                <p>{t("TVA")}</p>
                <p>{config.vat_rate}%</p>
                <p>{toPrice(price.vat)}</p>
            </li>
            <li className={classNames(styles.offerDetailPriceTotal)}>
                <p>{("Total HT")}</p>
                <p>{toPrice(price.ht)}</p>
            </li>
            <li className={classNames(styles.offerDetailPriceTotalHT)}>
                <p>{t("soit") + " " + toPrice(price.ttc)} TTC</p>
            </li>
        </ul>}

        {haveConditions() && <a className={classNames(styles.offerDetailConditionsBtn)} onClick={() => setOpen(true)}>
            {t("Voir les conditions de location")}
        </a>}

        {offer !== undefined && type === "waitingQuote" && <>
            {preview && <AccountNotCompleteAlert actionLabel={"faire une offre"}/>}

            {!preview && <Button className={classNames(styles.offerDetailBtn)} onClick={() => {
                history.push("/offer/" + offer?.token);
            }} disabled={saving}
                                 type={"yellow"} size={"large"} rounded bold>
                <FontAwesomeIcon
                    icon={["fas", "bolt-lightning"]} style={{
                    marginRight: 6
                }}/> Faire une offre
            </Button>}
        </>}

        {quote !== undefined && type === "offer" && <>
            {isExpired() ? <p className={classNames(styles.offerDetailExpired)}>{t("Offre expirée")}</p> :
              <Button className={classNames(styles.offerDetailBtn)} onClick={() => setCheckout(true)} disabled={saving}
                      type={"yellow"} size={"large"} rounded bold>
                  <FontAwesomeIcon
                    icon={["fas", "basket-shopping"]} style={{
                      marginRight: 6
                  }}/> {t(saving ? "Chargement" : "Louer cet équipement")}
              </Button>}
        </>}

        {!isExpired() && quote !== undefined && type === "quote" && <>
            {!confirmCancel && quote.state === "waiting" &&
                <Button className={classNames(styles.offerDetailBtn)} onClick={() => setConfirmCancel(true)}
                        type={"black"}>
                    {t("Annuler l'offre")}
                </Button>}

            {confirmCancel && <div className={classNames(styles.offerDetailCancelConfirm)}>
                <p>Êtes-vous certain de vouloir annuler cette offre ?</p>

                <Button className={classNames(styles.offerDetailBtn)} onClick={cancel} disabled={saving}
                        type={"danger"}>
                    {t(saving ? "Annulation..." : "Confirmer")}
                </Button>

                <Button className={classNames(styles.offerDetailBtn)}
                        type={"gray"}
                        onClick={() => setConfirmCancel(false)}
                        disabled={saving}>
                    {t("Retour")}
                </Button>
            </div>}
        </>}

        {quote !== undefined && <OfferDetailConditionsModal display={open} onClose={() => setOpen(false)}
                                                            conditions={quote.rental_conditions ?? ""}
                                                            partnerProducts={[]}/>}

        <CheckoutModal display={checkout} quote={quote} onClose={() => setCheckout(false)}/>
    </div>;
}