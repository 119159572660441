import {Order} from "../../../interfaces/Interfaces";
import Button from "../../Button/Button";
import {useMemo} from "react";
import styles from "./../checkoutModal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface CheckoutFinishStepProps {
    order?: Order,
    reference: string,
    onClose?: () => void,
}

export default function CheckoutFinishStep(props: CheckoutFinishStepProps): JSX.Element {
    const close = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return useMemo(() => {
        const order = props.order;

        if (order === undefined) {
            return <div className={styles.checkoutModalResume}>
                <h2>Commande introuvable...</h2>
                <p>Nous ne sommes pas parvenu à retrouver les informations de la commande <span>{props.reference}</span>.
                </p>
                <p style={{
                    marginTop: 30
                }}>Vous pensez qu'il s'agit d'une erreur ?</p>
                <p>Prenez contact avec notre service commercial.</p>
                <Button type={"black"}>Fermer</Button>
            </div>;
        }

        if (order.state === "refused") {
            return <div className={classNames(styles.checkoutModalResume, styles.checkoutModalResumeRefused)}>
                <h2><FontAwesomeIcon icon={["fas", "square-xmark"]}/> Paiement refusé...</h2>
                <p>Nous ne sommes pas parvenu à aboutir à la transaction.</p>
                <p>Il semblerait que le paiement est été refusé par nos services.</p>
                <p>Commande N° <span>{props.reference}</span></p>
                <Button type={"black"}>Fermer</Button>
            </div>;
        }

        return <div className={classNames(styles.checkoutModalResume, styles.checkoutModalResumeSuccess)}>
            <h2><FontAwesomeIcon icon={["fas", "square-check"]}/> Commande validée</h2>
            <p>Nous vous confirmons la réception de votre paiement pour la commande N° <span>{props.reference}</span>.
            </p>
            <p style={{
                marginTop: 25
            }}>Notre service va traiter d'ici quelques instants votre règlement et mettre à jour les informations.</p>
            <p>Vous allez également recevoir une facture récapitulative par mail ainsi que sur votre espace personnel.</p>
            <p style={{
                marginTop: 25
            }}>Nous vous remercions pour votre confiance.</p>
            <p>Si vous avez des questions relatives à votre location, n'hésitez pas à nous contacter.</p>
            <Button type={"yellow"} onClick={close} size={"large"}>Terminer</Button>
        </div>;
    }, [props.order]);
}