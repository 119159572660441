import Modal from "common/Modal/Modal";
import {useState} from "react";
import Button from "../Button/Button";
import {Formik} from "formik";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import Api from "../../classes/Api";
import {notifyError, notifySuccess} from "../Notification/notification";
import {config} from "../../configs";

interface PasswordResetModal {
    display: boolean,
    onClose: () => void,
}

export default function PasswordResetModal(props: PasswordResetModal) : JSX.Element {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setLoading(false);
        props.onClose();
    };

    const schema = yup.object({
        email: yup.string().email(t("L'adresse mail n'est pas valide")).required(t("L'adresse mail est obligatoire")),
    });

    const onReset = (v: {
        email: string
    }) => {
        setLoading(true);

        Api.post("/auth/password/request", {
            email: v.email,
            reset_url: config.frontUrl
        }).then(() => {
            onClose();
            notifySuccess(t("Demande de réinitialisation de mot de passe envoyée."));
        }).catch(() => {
            notifyError(t("Aucun compte trouvé avec cette adresse mail."));
            setLoading(false);
        });
    };

    return <Modal display={props.display} onClose={onClose} style={{
        width: 450
    }}>
        <h4>Mot de passe perdu</h4>

        <Formik
          validationSchema={schema}
          onSubmit={onReset}
          key={props.display ? 1 : 0}
          initialValues={{
              email: ""
          }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                  errors,
              }) => <form onSubmit={handleSubmit}>
                <div className="km-input titled required" style={{
                    marginTop: 40
                }}>
                    <label>{t("Adresse mail")}</label>
                    <input
                      className={touched.email && errors.email ? "is-invalid" : undefined}
                      type="text"
                      value={values.email}
                      placeholder={t("Adresse mail...")}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.email && touched.email &&
                        <div className="invalid-feedback">{errors.email}</div>}
                </div>

                <div className={"km-flex"} style={{
                    marginTop: 25
                }}>
                    <Button type={"gray"} outline style={{
                        marginLeft: "auto"
                    }}>
                        Annuler
                    </Button>

                    <Button type={"black"} style={{
                        marginLeft: 20
                    }} loading={loading}>
                        Réinitialiser
                    </Button>
                </div>
            </form>}
        </Formik>
    </Modal>;
}