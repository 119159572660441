import {useState} from "react";
import styles from "./rentalManager.module.scss";
import {useTranslation} from "react-i18next";
import SiteManager from "./SiteManager/SiteManager";
import PartnerProductManager from "./PartnerProductManager/PartnerProductManager";
import {IMenu} from "../../interfaces/Interfaces";
import SubMenu from "../../common/SubMenu/SubMenu";
import currentImg from "../../assets/menu/current.svg";
import invoiceImg from "../../assets/menu/invoices.svg";
import productImg from "../../assets/menu/fleet.svg";
import siteImg from "../../assets/menu/site.svg";
import RentalTable from "./RentalTable/RentalTable";
import InvoiceTable from "../../common/InvoiceTable/InvoiceTable";

export default function RentalManager() : JSX.Element {
    const [menuId, setMenuId] = useState<number | string>(1);
    const {t} = useTranslation();

    const menu: IMenu[] = [
        {
            id: 1,
            name: t("Mes locations"),
            info: t("Locations en cours, à venir et terminées"),
            img: currentImg
        },
        {
            id: 2,
            name: t("Mes factures"),
            info: t("Factures pour l'utilisation du service"),
            img: invoiceImg
        },
        {
            id: 3,
            name: t("Ma flotte de machines"),
            info: t("Création ou édition de mes machines en vente"),
            img: productImg
        },
        {
            id: 4,
            name: t("Gestion des sites"),
            info: t("Localisation, nom, responsable, téléphone"),
            img: siteImg
        }
    ];

    const renderSwitch = (menuId: number | string) => {
        switch (menuId) {
            case 1:
                return <RentalTable/>;
            case 2:
                return <InvoiceTable/>;
            case 3:
                return <PartnerProductManager/>;
            case 4:
                return <SiteManager/>;

            default:
                return <h2>{t("En Construction")}</h2>;
        }
    };

    return <div className={styles.globalContainer}>
        <h3 className={"sectionTitle mb-sm-5 ms-xs-4"}>{t("GESTION DES LOCATIONS")}</h3>
        <div className={styles.wrapper}>
            <div className={styles.nav}>
                <SubMenu items={menu} idMenu={menuId} onMenuChange={setMenuId}/>
            </div>
            <div className={styles.body}>
                {renderSwitch(menuId)}
            </div>
        </div>
    </div>;
}
