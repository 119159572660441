// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sanitizeHtml from "sanitize-html-react";

export function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function sanitizeHtmlString(html: string): string {
    const defaults = (sanitizeHtml as unknown as {
        defaults: {
            allowedTags: string[],
            allowedAttributes: {}
        }
    }).defaults;

    return sanitizeHtml(html, {
        allowedTags: defaults.allowedTags.concat(["img", "span"]),
        allowedAttributes: {
            ...defaults.allowedAttributes,
            "*": ["style"]
        }
    });
}

export function ucFirst(s: string): string {
    return s.charAt(0).toUpperCase() + s.substring(1);
}