import {Form, Formik} from "formik";
import styles from "../checkoutModal.module.scss";
import arrow from "../assets/arrow.svg";
import transfer from "../assets/transfer.svg";
import card from "../assets/credit-card.svg";
import {config} from "../../../configs";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import Button from "common/Button/Button";

interface CheckoutConfirmationStepProps {
    onConfirm: () => void,
    onClose: () => void;
}

export default function CheckoutConfirmationStep(props : CheckoutConfirmationStepProps): JSX.Element {
    const {t} = useTranslation();

    const schema = yup.object({
        paymentType: yup.string(),
        newsletter: yup.bool(),
        policy: yup
          .bool()
          .oneOf([true], t("Vous devez accepter les Termes et Conditions!"))
        /*cgu: yup
          .bool()
          .oneOf([true], "Vous devez accepter les Termes et Conditions!"),*/
    });

    const onCheckout = () => {
        props.onConfirm();
    };

    return <Formik
      validationSchema={schema}
      onSubmit={onCheckout}
      initialValues={{
          policy: false,
          newsletter: false,
          //cgu: false,
      }}
      isInitialValid={false}
    >
        {({
              handleSubmit,
              handleChange,
              values,
              isValid,
          }) => <Form
          onSubmit={handleSubmit}
          className={styles.checkoutModalBody}
        >
            <div className={styles.payments}>
                <div>
                    <h4 dangerouslySetInnerHTML={{
                        __html: t("Choisissez votre <span>méthode</span> de paiement")
                    }}/>
                    <img src={arrow} className={styles.paymentsImg} alt={""}/>
                </div>

                <div className={styles.paymentsList}>
                    <div className={styles.paymentsMethod}>
                        <input
                          type="radio"
                          name="paymentType"
                          value="Transfert"
                          id={"transfer"}
                          disabled
                        />
                        <label className="form-check-label" htmlFor={"transfer"}>
                            {t("Virement bancaire")}
                        </label>

                        <img src={transfer} alt={""}/>
                        <div className={styles.paymentsMethodSoon}>
                            <p>{t("bientôt")}</p>
                        </div>
                    </div>

                    <div className={styles.paymentsMethod}>
                        <input
                          type="radio"
                          name="paymentType"
                          value="CreditCard"
                          id={"creditCard"}
                          defaultChecked={true}
                        />

                        <label className="form-check-label" htmlFor={"creditCard"}>
                            {t("Carte de crédit")}
                        </label>

                        <img src={card} alt={""}/>
                    </div>
                </div>
            </div>

            <div className={styles.conditions}>
                <h5>{t("Confirmation")}</h5>
                <p>{t("On y est presque ! Encore quelques clics et votre commande sera prête")}</p>

                <div className={styles.conditionsLine}>
                    <input
                      type="checkbox"
                      name="newsletter"
                      onChange={handleChange}
                      checked={values.newsletter}
                      id="newsletter"
                    />
                    <label className="form-check-label" htmlFor={"newsletter"}>
                        {t("Je consens aux emails promotionnels. Aucun spam, promis!")}
                    </label>
                </div>

                {/*<div className={styles.conditionsLine}>
                    <input
                      type="checkbox"
                      name="cgu"
                      onChange={handleChange}
                      checked={values.cgu}
                      id="cgu"
                    />

                    <label className="form-check-label" htmlFor={"cgu"}>
                        {t("Je payerai cet ordre à temps")}
                    </label>
                </div>*/}

                <div className={styles.conditionsLine}>
                    <input
                      type="checkbox"
                      name="policy"
                      onChange={handleChange}
                      checked={values.policy}
                      id="policy"
                    />
                    <label className="form-check-label" htmlFor={"policy"}>
                        {t("Je consens aux")}&nbsp;<a href={config.cguLink} target="_blank"
                                                      rel="noreferrer">{t("CGU")}</a>&nbsp;
                        {t("et aux")}&nbsp;<a href={config.ppLink} target="_blank"
                                              rel="noreferrer">{t("conditions sur les données à caractère privé")}</a>
                    </label>
                </div>

                <div className={styles.checkoutModalFooter}>
                    <a onClick={props.onClose}>Annuler</a>
                    <Button
                      size={"large"}
                      rounded
                      bold
                      type={isValid ? "yellow" : "gray"}
                      disabled={!isValid}
                    >
                        {t("Finaliser la commande")}
                    </Button>
                </div>
            </div>
        </Form>}
    </Formik>;
}