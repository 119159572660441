import styles from "./catalogPromotion.module.scss";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import {ucFirst} from "../../utils/StringUtils";

export default function CatalogPromotion({index}: { index: number }): JSX.Element {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    switch (index) {
        default:
            return <h2 className={styles.promotion}>
                {ucFirst(user.first_name)}, {t("louez tout votre équipement professionnel")}
                <span className={styles.emphasis}> 24/7</span>
            </h2>;
        case 1:
            return <h2 className={styles.promotion}>
                The <span className={styles.emphasis}>smartest</span> and&nbsp;
                <span className={styles.emphasis}>fastest</span> rental in the world
            </h2>;
        case 2:
            return <h2 className={styles.promotion}>
                {ucFirst(user.first_name)}, {t("louez instantanément au")}&nbsp;
                <span className={styles.emphasis}>{t("meilleur prix")}</span>
            </h2>;
        case 3:
            return <h2 className={styles.promotion}>
                {t("Gardez le contact ")}
                <span className={styles.emphasis}>24/7</span>
            </h2>;
        case 4:
            return <h2 className={styles.promotion}>
                {t("Choisissez votre méthode de ")}
                <span className={styles.emphasis}>paiement</span>
            </h2>;
    }
}
