import {Order, Quote} from "../../interfaces/Interfaces";
import Modal from "../Modal/Modal";
import {useContext, useEffect, useState} from "react";
import styles from "./checkoutModal.module.scss";
import Api from "../../classes/Api";
import Loader from "../Loader/Loader";
import CheckoutConfirmationStep from "./steps/CheckoutConfirmationStep";
import CheckoutPaymentStep from "./steps/CheckoutPaymentStep";
import CheckoutFinishStep from "./steps/CheckoutFinishStep";
import OfferContext from "../../context/OfferContext";
import {useHistory} from "react-router-dom";

interface CheckoutModalProps {
    display?: boolean,
    quote?: Quote,
    reference?: string,
    onClose?: () => void,
}

export function CheckoutModal(props: CheckoutModalProps): JSX.Element {
    const [pay, setPay] = useState(false);
    const [order, setOrder] = useState<Order | null>(null);
    const [loading, setLoading] = useState(false);
    const [redirectOnClose, setRedirectOnClose] = useState(false);

    const {received, setReceived} = useContext(OfferContext);

    const history = useHistory();

    useEffect(() => {
        if (order === null) {
            return;
        }

        if (order.state === "paid") {
            clearOffers(order);
            setRedirectOnClose(true);
        }
    }, [order]);

    useEffect(() => {
        if (props.reference === undefined) {
            setOrder(null);
            setLoading(false);
            return;
        }

        fetch();
    }, [props.reference]);

    const fetch = () => {
        setLoading(true);
        Api.get<{
            data: Order[]
        }>("/items/order", {
            reference: {
                _eq: props.reference
            }
        }).then(d => {
            const o = d.data.shift() ?? null;

            if (o === null) {
                setOrder(null);
                setLoading(false);
                return;
            }

            if (o.state === "waiting") {
                setTimeout(() => {
                    fetch();
                }, 1500);
                return;
            }

            setOrder(o);
            setLoading(false);
        }).catch(() => {
            setOrder(null);
            setLoading(false);
        });
    };

    const clearOffers = (order: Order) => {
        const quote = received.filter(q => q.id === order.quote)[0];
        let newOffers = [...received];

        if (quote !== undefined) {
            newOffers = newOffers.filter(o => o.offer !== quote.offer);
        }

        newOffers = newOffers.filter(o => o.id !== order.quote);
        setReceived(newOffers);
    };

    const onClose = () => {
        setPay(false);

        if (redirectOnClose) {
            history.push("/management");
        }

        if (props.onClose) {
            props.onClose();
        }
    };

    return <Modal display={props.display && (props.quote !== undefined || props.reference !== undefined)}
                  onClose={onClose}
                  classNames={{
                      animation: {
                          opening: styles.checkoutModalIn,
                          closing: styles.checkoutModalOut
                      }
                  }}
                  className={styles.checkoutModal}>
        <div className={styles.checkoutModalWrapper} key={props.display ? "1" : "0"}>
            {!loading ? <>
                {props.reference !== undefined || order !== null ?
                  <CheckoutFinishStep reference={(order?.reference ?? props.reference) as string}
                                      order={order ?? undefined} onClose={onClose}/> : <>
                      <h1>Commander mon équipement <small>#{props.quote?.id}</small></h1>

                      {!pay || props.quote === undefined ?
                        <CheckoutConfirmationStep onConfirm={() => setPay(true)} onClose={onClose}/> :
                        <CheckoutPaymentStep quote={props.quote as Quote} onFinish={o => {
                            setOrder(o);
                            clearOffers(o);
                            setRedirectOnClose(true);
                        }}/>}
                  </>}
            </> : <div style={{
                display: "flex",
                alignItems: "center"
            }}>
                <Loader type={"dark"} size={30} style={{
                    marginRight: 10
                }}/><p style={{
                margin: 0
            }}>Récupération des informations de la commande...</p>
            </div>}
        </div>
    </Modal>;
}
