import styles from "./invoice.module.scss";
import logo from "./assets/logo.png";
import {useLocation, useParams} from "react-router-dom";
import {Fragment, ReactNode, useContext, useEffect, useMemo, useState} from "react";
import {CatalogProduct, OfferItem, Order, PartnerProduct, QuoteItem, User} from "../../interfaces/Interfaces";
import Api from "../../classes/Api";
import moment from "moment";
import {ucFirst} from "../../utils/StringUtils";
import {toPrice} from "../../utils/NumberUtils";
import AppContext from "../../context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface LineDetailConfig {
    [key: string]: {
        label: (quoteItem: QuoteItem, offerItem: OfferItem, partnerProduct: PartnerProduct, product: CatalogProduct) => ReactNode,
        price?: (quoteItem: QuoteItem) => number,
    }
}

export default function Invoice(): JSX.Element {
    const {config} = useContext(AppContext);
    const location = useLocation();
    const [order, setOrder] = useState<Order | null>(null);

    const {id} = useParams<{
        id: string
    }>();

    const lineDetailConfig: LineDetailConfig = {
        price: {
            label: (qi, ot, pp, p) => {
                const info: [string, string][] = [];

                if (typeof pp.brand === "string" && pp.brand.trim().length > 1) {
                    info.push(["marque", pp.brand]);
                }

                if (typeof pp.internal_reference === "string" && pp.internal_reference.trim().length > 1) {
                    info.push(["référence fournisseur", pp.internal_reference]);
                }

                const start = moment(ot.rent_start).startOf("day");
                const end = moment(ot.rent_end).startOf("day");
                let rentDate;

                if (start.valueOf() === end.valueOf()) {
                    rentDate = "Loué le " + start.format("DD-MM-YYYY");
                } else {
                    rentDate = "Loué du " + start.format("DD-MM-YYYY") + " au " + end.format("DD-MM-YYYY");
                }

                return <>
                    <h4>{p.label} <small>{rentDate}</small></h4>
                    {info.length > 0 && <p>
                        {info.map(([label, value], i) => <Fragment key={i}>
                            <b>{i === 0 ? ucFirst(label) : label} :</b> {value}{(i + 1) < info.length ? ", " : ""}
                        </Fragment>)}
                    </p>}
                    <p><b>Site : </b>{ot.location_address.replace("\n", ", ")}</p>
                </>;
            },
        },
        price_transport: {
            label: () => "Transport aller/retour"
        },
        price_insurance: {
            label: () => "Assurance"
        }
    };

    useEffect(() => {
        const idOrder = parseInt("" + id);

        if (isNaN(idOrder)) {
            return;
        }

        Api.get<{
            data: Order
        }>(`/items/order/${idOrder}`, {}, {
            fields: [
                "*",
                "beneficiary.*",
                "quote.*",
                "quote.items.*",
                "quote.items.partner_product.*",
                "quote.items.offer_item.*",
                "quote.items.partner_product.product.*",
                "client.*",
                "author.*",
                "transaction.*"
            ]
        }).then(r => {
            setOrder(r.data);
        }).catch(() => {
            setOrder(null);
        });
    }, [id]);

    const getUserInformation = (user: User, supplier ?: boolean): ReactNode => {
        const nodes: ReactNode[] = [];

        if (typeof user.society_name === "string" && user.society_name.trim().length > 0) {
            nodes.push(<p><b>{user.society_name}</b></p>);
        } else {
            nodes.push(<p><b>{user.last_name.toUpperCase()} {user.last_name}</b></p>);
        }

        if (typeof user.society_location_address === "string" && user.society_location_address.trim().length > 0) {
            nodes.push(<p>{user.society_location_address}</p>);
        }

        const location: string[] = [];

        if (typeof user.society_location_zipcode === "string" && user.society_location_zipcode.trim().length > 0) {
            let prefix = "";

            if (typeof user.society_location_country === "string" && user.society_location_country.trim().length > 0) {
                prefix = user.society_location_country.toUpperCase()[0] + "-";
            }

            location.push(prefix + user.society_location_zipcode);
        }

        if (typeof user.society_location_city === "string" && user.society_location_city.trim().length > 0) {
            location.push(user.society_location_city);
        }

        if (location.length > 0) {
            nodes.push(<p>{location.join(" ")}</p>);
        }

        if (typeof user.society_vat_number === "string" && user.society_vat_number.trim().length > 0) {
            nodes.push(<p>N° TVA : {user.society_vat_number}</p>);
        }

        if (supplier === true && typeof user.sav_phone === "string" && user.sav_phone.trim().length > 2) {
            nodes.push(<p>SAV : {user.sav_phone}</p>);
        }

        return nodes.map((n, i) => <Fragment key={i}>
            {n}
        </Fragment>);
    };

    const getItems = (): ReactNode => {
        if (order === null || typeof order.quote !== "object" || !Array.isArray(order.quote.items) || order.quote.items.length === 0) {
            return [];
        }

        let index = 1;
        const lines: ReactNode[] = [];

        order.quote.items.forEach(item => {
            if (typeof item !== "object") {
                return;
            }

            const partnerProduct = item.partner_product as PartnerProduct;

            if (typeof partnerProduct !== "object") {
                return;
            }

            const product = partnerProduct.product;

            if (typeof product !== "object") {
                return;
            }

            const offerItem = item.offer_item;

            if (typeof offerItem !== "object") {
                return;
            }

            (Object.keys(lineDetailConfig) as (keyof QuoteItem)[]).map((priceKey, i) => {
                const conf = lineDetailConfig[priceKey];
                const price = (conf.price !== undefined ? conf.price(item) : item[priceKey]) as number;

                lines.push(<tr className={i === 0 ? styles.invoicePageItemsMain : undefined} key={index + "_" + i}>
                    <td>{index}{i > 0 ? "." + i : ""}</td>
                    <td>{conf.label(item, offerItem, partnerProduct, product)}</td>
                    <td>{offerItem.quantity}</td>
                    <td>{order.vat_rate}%</td>
                    <td>{toPrice(price)}</td>
                </tr>);
            });

            index++;
        });

        return lines;
    };

    const reference = useMemo(() => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get("reference") ?? "";
    }, [location]);

    if (order === null) {
        return null as unknown as JSX.Element;
    }

    return <div className={styles.invoice} id={"invoice"}>
        <div className={styles.invoicePage}>
            <p
              className={styles.invoicePageWatermark}>Page 1/1 - générée le {moment().format("DD-MM-YYYY [à] HH:mm:ss")}</p>
            <div className={styles.invoicePageHeader}>
                <div className={styles.invoicePageHeaderLogo}>
                    <img src={logo} alt=""/>
                </div>

                <div className={styles.invoicePageHeaderCompany}>
                    <h2>Facture</h2>
                    <div dangerouslySetInnerHTML={{__html: config.invoice_header}}/>
                </div>
            </div>

            <div className={styles.invoicePageInformation}>
                <div className={styles.invoicePageInformationClient}>
                    <h4>Facturé à</h4>
                    {getUserInformation(order.client as User)}
                </div>

                <div className={styles.invoicePageInformationSupplier}>
                    <div>
                        <h4>Fourni par</h4>
                        {getUserInformation(order.beneficiary as User, true)}
                    </div>
                </div>

                <div className={styles.invoicePageInformationMeta}>
                    <ul>
                        <li>
                            <p>Date</p>
                            <p>{moment(order.date_paid).format("DD-MM-YYYY")}</p>
                        </li>
                        <li>
                            <p>N° de facture</p>
                            <p>{reference}</p>
                        </li>
                        <li>
                            <p>Référence</p>
                            <p>{order.reference}</p>
                        </li>
                    </ul>
                </div>
            </div>

            <table className={styles.invoicePageItems}>
                <thead>
                <tr>
                    <th/>
                    <th>Désignation</th>
                    <th>Qté</th>
                    <th>TVA</th>
                    <th>Total HT</th>
                </tr>
                </thead>
                <tbody>
                {getItems()}
                </tbody>
            </table>

            <div className={styles.invoicePagePriceInfo}>
                <div className={styles.invoicePagePriceInfoPayment}>
                    {typeof order.transaction === "object" && <>
                        <h3><FontAwesomeIcon icon={["fas", "square-check"]}/> Facture soldée</h3>
                        <p>{toPrice(order.transaction.amount)} par carte bancaire le {moment(order.date_paid).format("DD-MM-YYYY")}.</p>
                        <p>Référence de la transaction : {("" + order.transaction.tid).toUpperCase()}</p>
                    </>}
                </div>

                <ul className={styles.invoicePagePrice}>
                    <li>
                        <p>Total HT</p>
                        <p>{toPrice(order.amount_ht)}</p>
                    </li>
                    <li>
                        <p>Frais de dossier</p>
                        <p>{toPrice(order.fee_applied)}</p>
                    </li>
                    <li>
                        <p>TVA</p>
                        <p>{toPrice(order.amount_vat)}</p>
                    </li>
                    <li className={styles.invoicePagePriceTotal}>
                        <p>Total TTC</p>
                        <p>{toPrice(order.amount)}</p>
                    </li>
                </ul>
            </div>

            <div className={styles.invoicePageFooter} dangerouslySetInnerHTML={{
                __html: config.invoice_footer
            }}/>
        </div>
    </div>;
}