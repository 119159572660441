import {CatalogProduct} from "../interfaces/Interfaces";
import {useEffect, useMemo, useState} from "react";
import Api from "../classes/Api";

export interface PartnerProductsHook {
    products: CatalogProduct[],
    list: {
        [key: string]: string
    },
    options: {
        label: string,
        value: number
    }[]
}

let products: CatalogProduct[] | null = null;

export default function usePartnerProducts(): PartnerProductsHook {
    const [data, setData] = useState<CatalogProduct[]>([]);

    useEffect(() => {
        if (products === null) {
            load();
        } else {
            setData(products);
        }
    }, []);

    const load = () => {
        Api.get<{
            data: CatalogProduct[]
        }>("/items/catalog_product", undefined, {
            limit: 999
        }).then(o => {
            setData(o.data);
            products = o.data;
        });
    };

    const [list, options] = useMemo(() => {
        const l: {
            [key: number]: string;
        } = {};
        const o: {
            value: number,
            label: string,
        }[] = [];

        data.forEach(d => {
            l[d.id] = d.label;
            o.push({
                label: d.label,
                value: d.id
            });
        });

        return [l, o];
    }, [data]);

    return {
        products: data,
        list,
        options
    };
}