import classNames from "classnames";
import {useTranslation} from "react-i18next";
import styles from "./settings.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Editor} from "@tinymce/tinymce-react";
import {useContext, useRef, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import Api from "../../../../classes/Api";
import {Media, User} from "../../../../interfaces/Interfaces";
import Uploader, {UploaderRef} from "../../../../common/Uploader/Uploader";
import Button from "../../../../common/Button/Button";
import AppContext from "../../../../context/AppContext";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";
import {isValidItem} from "../../../../utils/ObjectUtils";

export default function Settings(): JSX.Element {
    const {t, i18n} = useTranslation();

    const {user, setUser} = useContext(AuthContext);
    const {config} = useContext(AppContext);

    const [conditions, setConditions] = useState(user.rental_conditions ?? "");
    const [haveUploadFile, setHaveUploadFile] = useState(false);
    const [savingLogo, setSavingLogo] = useState(false);

    const logoUploader = useRef<UploaderRef>(null);

    const onSave = () => {
        if (conditions.trim() === user.rental_conditions) {
            return;
        }

        Api.patch<{
            data: User
        }>("/users/me", {
            rental_conditions: conditions
        }).then(d => setUser(d.data));
    };

    const onSaveLogo = () => {
        if (logoUploader.current === null || logoUploader.current.getFile() === null) {
            return;
        }

        setSavingLogo(true);

        logoUploader.current.upload({
            folder: config.society_logo_folder
        }).then(m => {
            if (m === null) {
                notifyError(t("Impossible d'envoyer le logo de la société"));
                setSavingLogo(false);
                return;
            }

            Api.patch<{
                data: User
            }>("/users/me", {
                society_logo: m?.id
            }, {
                fields: ["*", "society_logo.*"]
            }).then(d => {
                //delete old logo
                if (isValidItem(user.society_logo)) {
                    Api.delete(`/files/${(user.society_logo as Media).id}`);
                }

                setUser(d.data);
                notifySuccess(t("Logo de la société sauvegardé avec succès"));
            }).catch(() => {
                notifyError(t("Impossible de sauvegarder le logo de la société"));
            }).finally(() => setSavingLogo(false));
        }).catch(() => {
            notifyError(t("Impossible d'envoyer le logo de la société"));
            setSavingLogo(false);
        });
    };

    const getSocietyLogo = (): Media | undefined => {
        if (user.society_logo !== null && user.society_logo !== undefined && typeof user.society_logo === "object" && user.society_logo.hasOwnProperty("id")) {
            return user.society_logo;
        }

        return undefined;
    };

    return <div className={classNames(styles.company, "ps-sm-4 row pb-4 pb-xl-0")}>
        <div className={classNames("col-xl-9 col-xxl-7", styles.companyInformation)}>
            <h2 className={styles.settingsTitle}>{t("Mes paramètres")}</h2>

            <p className={styles.settingsSectionTitle}>Logo de votre société</p>
            <p className={styles.settingsHelper}>
                <FontAwesomeIcon
                  icon={["fas", "circle-question"]}/> Le logo de votre société est utilisé sur les factures générées par Kamat, les devis etc.
            </p>
            <div className={styles.settingsSocietyLogo}>
                {isValidItem(user.society_logo) && <div className={styles.settingsSocietyLogoImg}>
                    <img src={Api.getUrl(`/assets/${(user.society_logo as Media).id}?width=60`, true)} alt={""}/>
                </div>}
                <Uploader onFileChange={f => setHaveUploadFile(f !== null)} ref={logoUploader} disabled={savingLogo}
                          media={getSocietyLogo()}/>
            </div>

            {haveUploadFile && <Button faIcon={["fas", "save"]} style={{
                marginTop: 10
            }} onClick={() => onSaveLogo()} loading={savingLogo}>{t("Sauvegarder")}</Button>}

            <p className={styles.settingsSectionTitle} style={{
                marginTop: 40
            }}>Conditions de location pré-définies</p>
            <p className={styles.settingsHelper}>
                <FontAwesomeIcon
                  icon={["fas", "circle-question"]}/> Les conditions de location définies ici seront pré-remplies lors de la création de devis.
            </p>
            <Editor
              initialValue={user.rental_conditions ?? ""}
              onEditorChange={v => setConditions(v)}
              onBlur={onSave}
              init={{
                  placeholder: "Conditions de locations...",
                  height: 280,
                  menubar: false,
                  language: i18n.language === "fr" ? "fr_FR" : undefined,
                  plugins: [
                      "advlist", "autolink", "lists", "link", "image", "charmap"
                  ],
                  toolbar: "undo redo | bold italic underline backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist | " +
                    "removeformat",
              }}
            />
            <p style={{
                fontSize: "0.8em",
                marginTop: 4
            }}>Sauvegardé automatiquement</p>
        </div>
    </div>;
}
