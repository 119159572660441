import {CatalogProduct, PartnerProduct} from "interfaces/Interfaces";
import {useTranslation} from "react-i18next";
import styles from "./partnerProductCard.module.scss";
import ActionButton from "../ActionButton/ActionButton";
import {useMemo, useState} from "react";
import moment from "moment";
import Api from "classes/Api";
import protect from "./assets/protect.svg";
import tool from "./assets/tool.svg";
import sheet from "./assets/sheet.svg";
import classNames from "classnames";
import {toPrice} from "../../utils/NumberUtils";

interface Pdf {
    label: string,
    path: string
}

export function PartnerProductCard({
                                       product,
                                       onEdit,
                                       onDelete
                                   }: {
    product: PartnerProduct,
    onDelete: () => void,
    onEdit: () => void,
}): JSX.Element {
    const {t} = useTranslation();
    const [unfold, setUnfold] = useState(false);

    const [type, technicalSheet, controlDate, certificate] = useMemo(() => {
        let type: string | null = null;
        let technicalSheet: Pdf | null = null;
        let certificate: Pdf | null = null;
        let controlDate: string | null = null;

        if (product.product !== null && typeof product.product === "object") {
            type = (product.product as CatalogProduct).label;
        }

        if (product.technical_sheet !== null && typeof product.technical_sheet === "object") {
            technicalSheet = {
                label: product.technical_sheet.filename_download,
                path: `/assets/${product.technical_sheet.id}`
            };
        }

        if (product.cce !== null && typeof product.cce === "object") {
            certificate = {
                label: product.cce.filename_download,
                path: `/assets/${product.cce.id}`
            };
        }

        if (product.control_validity_date !== null) {
            const date = moment(product.control_validity_date);

            if (date.isValid()) {
                controlDate = date.format("DD/MM/YYYY");
            }
        }

        return [type, technicalSheet, controlDate, certificate];
    }, [product]);

    return <div className={classNames(styles.productCard, unfold ? styles.productCardOpened : undefined)}>
        <div className={styles.productCardName}>
            <h5>{product.label}</h5>
            <h6>{t("Référence")} : {product.internal_reference}</h6>

            <a className={styles.productCardToggle} onClick={() => setUnfold(!unfold)}>
                <span/>
            </a>
        </div>

        <div className={styles.productCardOptions}>
            {type !== null && <p>{t("Type")} : {type}</p>}
            <p>{t("Operateur")} : {t(product.operator ? "oui" : "non")}</p>
            <p>{t("Tarif journalier")} : {toPrice(product.price_daily ?? 0)} / jour</p>
        </div>

        <div className={styles.productCardTechnic}>
            <p><img src={protect}/> Dernier contrôle : {controlDate ?? "n/a"}</p>
            <p><img src={tool}/> Certificat de conformité : {certificate !== null ?
              <a href={Api.getUrl(certificate.path, true)}
                 target={"_blank"}
                 rel={"noreferrer"}>{certificate.label}</a> : "n/a"}</p>
            <p><img src={sheet}/> Fiche technique : {technicalSheet !== null ?
              <a href={Api.getUrl(technicalSheet.path, true)}
                 target={"_blank"}
                 rel={"noreferrer"}>{technicalSheet.label}</a> : "n/a"}</p>
        </div>

        <div className={styles.productCardTools}>
            <ActionButton type={"edit"} onClick={onEdit}/>
            <ActionButton type={"delete"} onClick={onDelete}/>
        </div>
    </div>;
}