import { Form, Formik } from "formik";
import * as yup from "yup";
import styles from "./formular.module.scss";
import { config } from "configs";
import { useTranslation } from "react-i18next";
import { notifyError, notifySuccess } from "common/Notification/notification";

export function Formular({ email, handleCloseModal }) {
  const { t, i18n } = useTranslation();

  const schema = yup.object({
    password: yup.string().required(t("Mot de passe obligatoire")),
    newPassword: yup.string().required(t("Nouveau mot de passe obligatoire")),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => {
        return new Promise((resolve, reject) => {
          fetch(`${config.apiUrl}/api/auth/password/update`, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }).then(
            (response) => {
              if (response.status !== 200) {
                return response.text().then((text) => {
                  reject(text);
                });
              } else {
                return response.text().then((text) => {
                  notifySuccess(text);
                  handleCloseModal();
                  resolve(true);
                });
              }
            },
            (error) => {
              notifyError(error);
            }
          );
        }).catch((error) => {
          notifyError(error);
        });
      }}
      initialValues={{
        password: "",
        newPassword: ""
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        isValid,
        errors,
      }) => (
        <Form onSubmit={handleSubmit} className={styles.form}>
          <input
            className="form-control my-2"
            type="password"
            name="password"
            placeholder={t("Mot de passe")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />

          <div className={styles.errorText}>
            {errors.password && touched.password && `${errors.password}`}
          </div>

          <input
            className="form-control my-2"
            type="password"
            name="newPassword"
            placeholder={t("Nouveau mot de passe")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
          />
          <div className={styles.errorText}>
            {errors.newPassword && touched.newPassword && `${errors.newPassword}`}
          </div>

          <button
            type="submit"
            className="btn-blue"
            disabled={!isValid}
          >
            {t("Envoyer")}
          </button>
        </Form>
      )}
    </Formik>
  );
}
