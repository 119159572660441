import {LocationSite} from "interfaces/Interfaces";
import {useTranslation} from "react-i18next";
import styles from "./siteCard.module.scss";
import ActionButton from "../ActionButton/ActionButton";
import pinIcon from "./assets/pin.svg";
import managerIcon from "./assets/manager.svg";
import phoneIcon from "./assets/phone.svg";

interface SiteCardProp {
    site: LocationSite,
    onEdit: () => void,
    onDelete: () => void,
}

export const SiteCard: React.FunctionComponent<SiteCardProp> = ({
                                                                    site,
                                                                    onEdit,
  onDelete
                                                                }) => {
    const {t} = useTranslation();

    return <div className={styles.siteCard}>
        <div className={styles.siteCardName}>
            <h5>{site.label}</h5>
        </div>

        <div className={styles.siteCardAddress}>
            <p><img src={pinIcon} alt={"address"}/> {t("Adresse")} : {site?.address || t("non définie")}</p>
        </div>

        <div className={styles.siteCardInfo}>
            <p>
                <img src={managerIcon}
                     alt={"manager"}/> {`${t("Manager du site")} : ${site?.manager || t("non défini")}`}
            </p>
            <p>
                <img src={phoneIcon}
                     alt={"phone"}/>{`${t("Téléphone")} : ${site?.phone || t("non défini")}`}
            </p>
        </div>

        <div className={styles.siteCardTools}>
            <ActionButton type={"edit"} onClick={onEdit}/>
            <ActionButton type={"delete"} onClick={onDelete}/>
        </div>
    </div>;
};
