import React, {ReactNode, useContext, useMemo} from "react";
import classNames from "classnames";
import styles from "./offerTable.module.scss";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {toPrice} from "../../../../utils/NumberUtils";
import {EasytransacStatus, Offer, Quote} from "../../../../interfaces/Interfaces";
import {useHistory} from "react-router-dom";
import Button from "../../../../common/Button/Button";
import Api from "../../../../classes/Api";
import societyLogo from "./assets/society.png";
import {Picture} from "../../../../common/Picture/Picture";
import AppContext from "../../../../context/AppContext";
import AuthContext from "../../../../context/AuthContext";

interface OfferTableProps {
    selected?: number | null,
    offers?: Offer[],
    quotes?: Quote[]
}

export default function OfferTable(props: OfferTableProps): JSX.Element {
    const {t} = useTranslation();

    const history = useHistory();
    const {config} = useContext(AppContext);
    const {user} = useContext(AuthContext);

    const preview = useMemo(() => {
        return user.easytransac_status !== EasytransacStatus.FULLY_VALIDATE || user.verified !== true;
    }, [user]);

    const getQuotes = (): ReactNode[] => {
        if (props.quotes === undefined) {
            return [];
        }

        return props.quotes.map(o => {
            const isSelected = props.selected !== undefined && props.selected !== null && props.selected === o.id;
            const expiration = moment(o.expires_on);
            const isExpired = expiration.valueOf() < moment().valueOf();
            const creation = moment(o.date_created);
            const feeRate = typeof o.author === "object" ? (o.author.fee ?? config.default_fee ?? 0) : (config.default_fee ?? 0);
            let price = 0;

            o.items.forEach(it => {
                if (typeof it !== "object") {
                    return;
                }

                const itemFee = it.price * (feeRate / 100);

                price += itemFee + it.price_insurance + it.price + it.price_transport;
            });

            return <li
              className={classNames(styles.offerLine, styles.customerOfferLine, isSelected ? styles.offerLineActive : "")}
              key={o.id}
              onClick={() => {
                  if (isSelected) {
                      history.push("/offers");
                      return;
                  }
                  history.push("/offers/quote-" + o.id);
              }}>
                <div className={classNames(styles.offerLineName)}>
                    <p>{t("Offre Kamat")} #{o.id}</p>
                </div>

                {creation.isValid() && <>
                    <div className={classNames(styles.offerLineDate)}>
                        <p>Reçue le {creation.format("DD/MM/YYYY [à] HH[h]mm")}</p>
                    </div>
                </>}
                <div className={classNames(styles.offerLineTotal)}>
                    <p>{t("Total HT")} : {toPrice(price)}</p>
                </div>

                {o.state !== "waiting" ? <div className={styles.offerLineState}>
                    <span
                      className={o.state === "accepted" ? styles.offerLineStateSuccess : styles.offerLineStateDanger}>
                        {o.state === "accepted" ? "Accepté" : "Décliné"}
                    </span>
                </div> : <div className={styles.offerLineExpiration}>
                    {isExpired ? <p className={styles.offerLineExpirationExpired}>{t("Expirée")}</p> :
                      <p>{t("Expiration")} : {expiration.fromNow(true)}</p>}
                </div>}
            </li>;
        }).filter(n => n !== null);
    };

    const getOffers = (): ReactNode[] => {
        if (props.offers === undefined) {
            return [];
        }

        return props.offers.map(o => {
            const creation = moment(o.date_created);
            const isSelected = props.selected !== null && props.selected === o.id;
            let companyLogo = societyLogo;

            if (typeof o.client !== "object") {
                return null;
            }

            if (typeof o.client.society_logo === "string" && o.client.society_logo.trim().length > 20 && !preview) {
                companyLogo = Api.getUrl(`/assets/${o.client.society_logo}?width=50`, true);
            }

            return <li
              className={classNames(styles.offerLine, styles.partnerOfferLine, isSelected ? styles.offerLineActive : ""/*, !havePlaceOffer ? styles.partnerOfferLineNew : ""*/)}
              key={o.id}
              onClick={e => {
                  const target = e.target as HTMLElement;

                  if (target.tagName === "BUTTON") {
                      return;
                  }

                  if (isSelected) {
                      history.push("/offers");
                      return;
                  }

                  history.push("/offers/offer-" + o.id);
              }}>
                <div className={styles.offerLineLogo}>
                    <Picture src={companyLogo} alt={""} fallbackImg={societyLogo}/>
                </div>

                <div className={styles.offerLineBody}>

                    <div className={styles.offerLineName}>
                        <p>{preview ? t("Demande de devis") : t("Demande de") + " : " + o.client.society_name}</p>
                    </div>

                    <div className={classNames(styles.offerLineInfo)}>
                        <p>{o.items.length} équipement{o.items.length > 1 ? "s" : ""}</p>
                    </div>

                    {creation.isValid() && <>
                        <div className={classNames(styles.offerLineDate)}>
                            <p>créée le {creation.format("DD/MM/YYYY [à] HH[h]mm")}</p>
                        </div>
                    </>}
                </div>

                {!preview && <div className={classNames(styles.offerLineTools)}>
                    <Button size={"small"} rounded bold faIcon={["fas", "bolt"]}
                            onClick={() => history.push("/offer/" + o.token)}>
                        {t("Faire une offre")}
                    </Button>
                </div>}
            </li>;
        }).filter(n => n !== null);
    };

    if (props.quotes === undefined && props.offers === undefined) {
        return null as unknown as JSX.Element;
    }

    return <ul className={classNames(styles.offerTable)}>
        {getQuotes()}
        {getOffers()}
    </ul>;
}