import {IMenu} from "../../interfaces/Interfaces";
import {MenuCard} from "../MenuCard/MenuCard";
import {createPortal} from "react-dom";
import {ReactNode, useEffect, useState} from "react";
import classNames from "classnames";
import styles from "../../common/SubMenu/subMenu.module.scss";

interface ISubMenuProps {
    items: IMenu[],
    idMenu: number|string,
    onMenuChange: (idMenu: number|string) => void;
}

export default function SubMenu({items, idMenu, onMenuChange}: ISubMenuProps) {
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        const container = getContainer();

        if (container !== null) {
            container.classList.add("used");
            setReady(true);
        }

        return () => {
            const container = getContainer();

            if (container !== null) {
                container.classList.remove("used");
            }
        };
    }, []);

    const getContainer = (): Element | null => {
        return document.querySelector("nav#subNav");
    };

    const getMobileSubNavigation = (): ReactNode => {
        const container = getContainer();

        if (container === null) {
            return null;
        }

        return createPortal(<ul className={styles.subMenu}>
            {items.map(menu => <li key={menu.id} className={menu.id === idMenu ? styles.active : undefined}
                                   onClick={() => onMenuChange(menu.id)}>
                {menu.reducedName ?? menu.name}
            </li>)}
        </ul>, container);
    };

    return <div className={classNames(styles.menuContainer)}>
        {isReady && getMobileSubNavigation()}
        {items.map(menu => <MenuCard
          key={menu.id}
          menu={menu}
          currentChosenMenu={idMenu}
          handleChooseMenu={onMenuChange}
        />)}
    </div>;
}