import styles from "./productCard.module.scss";
import {CatalogProduct, Energy} from "interfaces/Interfaces";
import dieselLogo from "assets/essence.svg";
import electricityLogo from "assets/electricity.svg";
import hybridLogo from "assets/hybrid.png";
import permitLogo from "assets/permisB.svg";
import {useTranslation} from "react-i18next";
import {Picture} from "../../../../common/Picture/Picture";
import Api from "../../../../classes/Api";
import {SyntheticEvent, useMemo} from "react";

interface ICardGridProps extends CatalogProduct {
    onSelect?: () => void,
    onRent?: () => void,
}

export const ProductCard: React.FunctionComponent<ICardGridProps> = ({
                                                                         label,
                                                                         b_permit,
                                                                         energy,
                                                                         picture,
                                                                         onRent,
                                                                         onSelect
                                                                     }) => {
    const {t} = useTranslation();

    const onClick = (e: SyntheticEvent) => {
        const target = e.target as HTMLElement;

        if (target.nodeName === "A" || target.closest("a") !== null) {
            return;
        }

        if (onSelect === undefined) {
            return;
        }

        onSelect();
    };

    const energyIcon = useMemo(() => {
        let src: string | null = null;

        switch (energy) {
            case Energy.DIESEL:
                src = dieselLogo;
                break;
            case Energy.ELECTRIC:
                src = electricityLogo;
                break;
            case Energy.HYBRID:
                src = hybridLogo;
                break;
        }

        if (src !== null) {
            return <img
              src={src}
              alt="Logo"
            />;
        }

        return null;
    }, [energy]);

    const driverLicenceIcon = useMemo(() => {
        if (b_permit === true) {
            return <img
              src={permitLogo}
              alt="Logo"
            />;
        }

        return null;
    }, [b_permit]);

    const haveIcons = useMemo(() => {
        return energyIcon !== null || driverLicenceIcon !== null;
    }, [energyIcon, driverLicenceIcon]);

    return <div className={styles.productCard} onClick={onClick}>
        <Picture src={Api.getUrl(`/assets/${picture}`, true)} className={styles.productCardPic}/>

        {haveIcons && <div className={styles.productCardIcons}>
            {energyIcon}
            {driverLicenceIcon}
        </div>}

        <p className={styles.productCardTitle}>{label}</p>

        <a
          className={styles.productCardAdd}
          onClick={onRent}
        >
            {t("Ajouter")} <span>+</span>
        </a>
    </div>;
};
