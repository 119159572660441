import {useContext, useEffect, useMemo, useState} from "react";
import Api from "../../../../../classes/Api";
import DocumentUploader, {
    DocumentType,
    DocumentUploaderProps,
    SubDocumentProps
} from "./DocumentUploader/DocumentUploader";
import {KycDocument, KycDocumentType} from "../../../../../interfaces/Interfaces";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import Loader from "../../../../../common/Loader/Loader";
import AuthContext from "../../../../../context/AuthContext";

export default function Documents(): JSX.Element {
    const {user} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState<KycDocument[]>([]);

    useEffect(() => {
        Api.get<KycDocument[]>("/kamat/merchant/documents").then(setDocuments).catch(() => setDocuments([])).finally(() => setLoading(false));
    }, []);

    const documentNodes = useMemo(() => {
        if (loading) {
            return <div className={"km-flex-middle"}>
                <Loader type={"dark"} style={{
                    marginRight: 8
                }} size={25}/><p style={{
                margin: 0
            }}>Récupération des documents...</p>
            </div>;
        }

        //init wanted documents
        let types: DocumentType[] = ["CI", "CI_BIS", "RIB", "REGISTRATION_PROOF", "ARTICLES_OF_ASSOCIATION", "SHAREHOLDER_DECLARATION"];

        if (user.company_type === "entrepreneur") {
            types = ["CI", "CI_BIS", "RIB", "ACTIVITY_PROOF"];
        }

        //config...
        const typesWithBack: KycDocumentType[] = ["IDENTITY_PROOF", "DRIVER_LICENSE", "RESIDENCE_PERMIT", "BENEFICIARY", "BENEFICIARY2", "BENEFICIARY3"];
        const typesWithSequence = {
            SHAREHOLDER_DECLARATION: "SHAREHOLDER_DECLARATION2"
        };
        let sentDocuments = [...documents];

        const getData = (allowedTypes: string[] | string): KycDocument[] | null => {
            if (typeof allowedTypes === "string") {
                allowedTypes = [allowedTypes];
            }

            //include type that having a verso
            typesWithBack.forEach(t => {
                if (!allowedTypes.includes(t)) {
                    return;
                }

                (allowedTypes as string[]).push(t + "_BACK");
            });

            //get concerned documents
            let documents = sentDocuments.filter(d => allowedTypes.indexOf(d.DocumentType) !== -1);
            //sort document by sending date
            documents.sort((a, b) => (new Date(b.Date)).valueOf() - (new Date(a.Date)).valueOf());

            //no docs
            if (documents.length === 0) {
                return null;
            }

            documents = documents.slice(0, 2);

            const isSequenceDoc = Object.keys(typesWithSequence).filter(k => documents[0].DocumentType === typesWithSequence[k as keyof typeof typesWithSequence]).length > 0;
            const haveSequence = Object.keys(typesWithSequence).indexOf(documents[0].DocumentType) !== -1;
            const isVersoDoc = typesWithBack.map(t => t + "_BACK").indexOf(documents[0].DocumentType) !== -1;
            const haveVerso = typesWithBack.indexOf(documents[0].DocumentType) !== -1;

            //single document
            if (!isSequenceDoc && !haveSequence && !isVersoDoc && !haveVerso) {
                return documents.slice(0, 1);
            }

            //is verso, getting if second doc is recto
            if (isVersoDoc) {
                if (typesWithBack.indexOf(documents[1].DocumentType) !== -1 && (documents[1].DocumentType + "_BACK") === documents[0].DocumentType) {
                    return documents;
                }

                return documents.slice(0, 1);
            }

            //is sequenced doc, get if other doc is the main page
            if (isSequenceDoc) {
                const key = Object.keys(typesWithSequence).filter(k => typesWithSequence[k as keyof typeof typesWithSequence] === documents[0].DocumentType)[0] ?? null;

                if (key !== null && key === documents[1].DocumentType) {
                    return documents;
                }

                return documents.slice(0, 1);
            }

            //have verso, get if second document is verso
            if (haveVerso) {
                return documents.length === 2 && documents[1].DocumentType === documents[0].DocumentType + "_BACK" ? documents : documents.slice(0, 1);
            }

            //have sequence, get if second document is sequenced document
            if (haveSequence) {
                return documents.length === 2 && typesWithSequence[documents[0].DocumentType as keyof typeof typesWithSequence] === documents[1].DocumentType ? documents : documents.slice(0, 1);
            }

            return documents;
        };

        let usedPrimaryIdentityProof: KycDocumentType | null = null;

        return types.map(type => {
            let customProps: DocumentUploaderProps = {} as DocumentUploaderProps;
            let data: KycDocument[] | null = null;

            switch (type) {
                case "CI":
                    data = getData(["IDENTITY_PROOF", "PASSPORT"]);

                    if (data !== null) {
                        usedPrimaryIdentityProof = data.filter(d => d.DocumentType === "IDENTITY_PROOF" || d.DocumentType === "PASSPORT")[0].DocumentType;

                        //recto + verso
                        if (data.length === 2 && data.filter(d => d.Status === "invalid").length !== 2) {
                            if (data[0].Status === "invalid") {
                                customProps.side = data[0].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                            } else if (data[1].Status === "invalid") {
                                customProps.side = data[1].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                            }
                        }
                    }

                    break;
                case "CI_BIS":
                    data = getData(["IDENTITY_PROOF", "PASSPORT", "DRIVER_LICENSE", "RESIDENCE_PERMIT", "TAX_NOTICE"]);
                    data = (data ?? []).map(d => d.DocumentType === "IDENTITY_PROOF" ? {
                        ...d,
                        Status: "valid"
                    } : d);

                    if (usedPrimaryIdentityProof !== null) {
                        customProps.usedDocument = usedPrimaryIdentityProof;
                    }

                    if (data !== null && data.length === 2 && data.filter(d => d.Status === "invalid").length !== 2) {
                        if (data[0].Status === "invalid") {
                            customProps.side = data[0].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                        } else if (data[1].Status === "invalid") {
                            customProps.side = data[1].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                        }
                    }
                    break;
                case "RIB":
                    data = getData("BANK_DETAILS");
                    break;
                case "REGISTRATION_PROOF":
                    data = getData("REGISTRATION_PROOF");
                    break;
                case "ARTICLES_OF_ASSOCIATION":
                    data = getData("ARTICLES_OF_ASSOCIATION");
                    break;
                case "SHAREHOLDER_DECLARATION":
                    // eslint-disable-next-line no-case-declarations
                    const subTypes: SubDocumentProps[] = [];
                    // eslint-disable-next-line no-case-declarations
                    let side: "recto" | "verso" | "both" | undefined = undefined;

                    for (let i = 1; i <= 3; i++) {
                        const subType = ("BENEFICIARY" + (i > 1 ? i : "")) as KycDocumentType;
                        const data = getData(subType);

                        if (data !== null && data.length === 2 && data.filter(d => d.Status === "invalid").length !== 2) {
                            if (data[0].Status === "invalid") {
                                side = data[0].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                            } else if (data[1].Status === "invalid") {
                                side = data[1].DocumentType.endsWith("_BACK") ? "verso" : "recto";
                            }
                        }

                        subTypes.push({
                            label: "Actionnaire " + i,
                            typeLabel: "Pièce d'identité",
                            type: subType,
                            verso: (subType + "_BACK") as KycDocumentType,
                            data: data ?? undefined,
                            side,
                        });
                    }

                    customProps = {
                        ...customProps,
                        versoBtnContent: "Ajouter une page",
                        versoLabel: "Suite du document",
                        rectoLabel: "Document",
                        subDocuments: {
                            actives: ["BENEFICIARY", "BENEFICIARY2", "BENEFICIARY3"].filter(t => sentDocuments.filter(d => d.DocumentType === t).length > 0) as KycDocumentType[],
                            label: "Pièces d'identité des actionnaires",
                            note: "Merci de transmettre les pièces d'identités de tous les actionnaires ayant au moins 25% des parts de la société.",
                            addBtnLabel: "Ajouter un actionnaire",
                            types: subTypes
                        }
                    };

                    data = getData(["SHAREHOLDER_DECLARATION", "SHAREHOLDER_DECLARATION2"]);
                    break;
            }

            if (data !== null && data.length === 0) {
                data = null;
            }

            if (data !== null) {
                sentDocuments = sentDocuments.filter(d => (data ?? []).map(s => s.DocumentType).indexOf(d.DocumentType) === -1);
            }

            const onUpload = (d: KycDocument) => {
                setDocuments(ArrayUtils.replaceOrPush(documents, el => el.Id === d.Id, d));
            };

            return <DocumentUploader {...customProps} key={type} type={type} onUpload={onUpload}
                                     data={data ?? undefined}/>;
        });
    }, [documents, loading]);

    return <div>
        <p style={{
            fontSize: "1.2em",
            fontWeight: 500,
            marginBottom: 40
        }}>Documents à transmettre</p>

        {documentNodes}
    </div>;
}