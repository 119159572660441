import classNames from "classnames";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./offerList.module.scss";
import emptyLogo from "./assets/empty.svg";
import OfferTable from "./components/OfferTable/OfferTable";
import OfferDetail from "./components/OfferDetail/OfferDetail";
import {useHistory, useParams} from "react-router-dom";
import {Offer, Quote} from "../../interfaces/Interfaces";
import OfferContext from "../../context/OfferContext";
import Tabs from "../../common/Tabs/Tabs";
import TabPane from "../../common/Tabs/TabPane";
import {notifyError} from "../../common/Notification/notification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import arrow from "./assets/arrow.svg";

type Type = "offer" | "quote" | "waitingQuote";

export default function OfferList(): JSX.Element {
    const {t} = useTranslation();
    const history = useHistory();

    const {received, requests, sent, loading} = useContext(OfferContext);

    const {id} = useParams<{
        id?: string
    }>();

    const [quote, setQuote] = useState<Quote | null>(null);
    const [offer, setOffer] = useState<Offer | null>(null);
    const [type, setType] = useState<Type>("offer");

    useEffect(() => {
        setQuote(null);
        setOffer(null);
        history.push("/offers");
    }, [type]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (received.length > 0) {
            setType("offer");
        } else if (requests.length > 0) {
            setType("waitingQuote");
        } else if (sent.length > 0) {
            setType("quote");
        } else {
            notifyError("Aucune offre n'est disponible. Commencez à louer pour en recevoir");
            history.push("/");
            return;
        }
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }

        if (id === undefined) {
            setOffer(null);
            setQuote(null);
            return;
        }

        const array = (id.startsWith("offer-") ? requests : [...sent, ...received]) as {
            id: number
        }[];
        const idItem = parseInt("" + id.replace(/\D+/, ""));
        const match = array.filter(it => it.id === idItem)[0];

        if (match !== undefined) {
            if (id.startsWith("offer-")) {
                setOffer(match as Offer);
            } else {
                setQuote(match as Quote);
            }
        }
    }, [id, loading]);

    const getSubtitle = () => {
        switch (type) {
            case "offer":
                return <>Mes offres spéciales <span>Kamat</span> reçues</>;
            case "quote":
                return <>Mes devis <span>Kamat</span> envoyés</>;
        }

        return <>Mes demandes de devis <span>Kamat</span></>;
    };

    return <div className={styles.offersContainer}>
        <div className={styles.offersBody}>
            {loading ? <p>{t("Chargement")}...</p> : <>
                <h1 className={styles.offersTitle}>Gestion de mes offres Kamat</h1>
                <h1 className={styles.offersSubTitle}>{getSubtitle()}</h1>

                <Tabs active={type} navigation={{
                    itemClassName: styles.offersNavigationItem
                }} onChange={t => setType(t as Type)}>
                    {received.length > 0 && <TabPane tab={"offer"} label={<>
                        <FontAwesomeIcon icon={["fas", "bolt"]}/>
                        <p>Mes offres reçues</p>
                    </>}>
                        <OfferTable
                            quotes={received}/>
                    </TabPane>}

                    {requests.length > 0 && <TabPane tab={"waitingQuote"} label={<>
                        <FontAwesomeIcon icon={["fas", "comment-dollar"]}/>
                        <p>Mes demandes de devis</p>
                    </>}>
                        <OfferTable offers={requests}/>
                    </TabPane>}

                    {sent.length > 0 && <TabPane tab={"quote"} label={<>
                        <FontAwesomeIcon icon={["fas", "file-invoice-dollar"]}/>
                        <p>Mes devis envoyés</p>
                    </>}>
                        <OfferTable
                            quotes={sent}/>
                    </TabPane>}
                </Tabs>

                {sent.length === 0 && requests.length === 0 && received.length === 0 &&
                    <div className={classNames(styles.emptyContent)}>
                        <img src={emptyLogo} alt={""}/>
                        <p>{t("Aucune offre trouvée")}</p>
                    </div>}
            </>}
        </div>

        <div className={styles.offersSidebar}>
            {(quote !== null || offer !== null) ?
              <OfferDetail quote={quote ?? undefined} offer={offer ?? undefined} type={type} onClose={() => {
                  setOffer(null);
                  setQuote(null);
                  history.push("/offers");
              }}/> :
              <div className={styles.offersHelper}>
                  <img src={arrow} alt={""}/>
                  <p>{t("Cliquez sur une offre pour afficher les détails !")}</p>
              </div>}
        </div>
    </div>;
}
