import classNames from "classnames";
import styles from "./invoiceTable.module.scss";
import searchLogo from "../../assets/loupe.svg";
import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {BillCategory, Media, Order} from "../../interfaces/Interfaces";
import {useTranslation} from "react-i18next";
import {toPrice} from "../../utils/NumberUtils";
import moment from "moment";
import Api from "../../classes/Api";
import AuthContext from "../../context/AuthContext";
import Tabs from "../Tabs/Tabs";
import TabPane from "../Tabs/TabPane";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../Loader/Loader";
import emptyImg from "../../assets/missingImage.svg";

export default function InvoiceTable(): JSX.Element {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    const [invoices, setInvoices] = useState<Order[]>([]);
    const [type, setType] = useState<BillCategory>(BillCategory.ALL);
    const [search, setSearch] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const searchThrottler = useRef<number | null>(null);

    const navigation = {
        [BillCategory.ALL]: t("Tout"),
        [BillCategory.N1]: moment().startOf("year").format("YYYY"),
        [BillCategory.N2]: moment().subtract(1, "year").startOf("year").format("YYYY"),
        [BillCategory.N3]: moment().subtract(2, "year").startOf("year").format("YYYY"),
    };

    useEffect(() => {
        load(undefined, true);
    }, [type]);

    const load = (search ?: string, reset ?: boolean) => {
        const params: {}[] = [
            {
                invoice: {
                    _nnull: true
                }
            },
            {
                _or: [
                    {
                        beneficiary: {
                            _eq: user.id
                        }
                    },
                    {
                        client: {
                            _eq: user.id
                        }
                    }
                ]
            }
        ];

        if (search !== undefined) {
            params.push({
                _or: [
                    {
                        reference: {
                            _contains: search
                        }
                    },
                    {
                        invoice: {
                            title: {
                                _contains: search
                            }
                        }
                    },
                    {
                        amount: {
                            _eq: search
                        }
                    }
                ]
            });
        } else if (type !== BillCategory.ALL) {
            const toSub = parseInt("" + type) - 1;
            let d = moment();

            if (toSub > 0) {
                d = d.subtract(toSub, "year");
            }

            d = d.startOf("year");

            params.push({
                date_paid: {
                    _gte: d.startOf("year").toISOString()
                }
            });

            params.push({
                date_paid: {
                    _lte: d.endOf("year").toISOString()
                }
            });
        }

        setLoading(true);

        Api.get<{
            data: Order[]
        }>("/items/order", {
            _and: params
        }, {
            fields: [
                "*",
                "invoice.*"
            ],
            sort: ["-date_paid"]
        }).then(d => {
            if (search !== undefined || reset === true) {
                setInvoices(d.data);
            } else {
                setInvoices([...invoices, ...d.data]);
            }
        }).finally(() => setLoading(false));
    };

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        if (searchThrottler.current !== undefined) {
            clearTimeout(searchThrottler.current as unknown as NodeJS.Timeout);
        }

        const search = event.target.value.trim();

        if (search.length <= 2) {
            setSearch(null);

            //reset
            if (search.length === 0) {
                load(undefined, true);
            }

            return;
        }

        setSearch(search);
        searchThrottler.current = setTimeout(() => {
            load(search);
        }, 300) as unknown as number;
    };

    const clearSearch = () => {
        setSearch(null);
        if (searchThrottler.current !== undefined) {
            clearTimeout(searchThrottler.current as unknown as NodeJS.Timeout);
        }
        load(undefined, true);
    };

    return <div className={classNames(styles.tableContainer, "container-fluid p-0")}>
        <Tabs active={type} onChange={n => setType(n as unknown as BillCategory)} navigation={{
            visible: search === null,
            extraContent: <div className={classNames("km-search", styles.search)} style={{
                marginBottom: 10
            }}>
                <img src={searchLogo} alt=""/>
                <input placeholder={"Chercher une facture (par référence, montant...)"} onChange={onSearch}/>
            </div>,
            customItems: search !== null ? [
                {
                    content: <>
                        Recherche {loading && <Loader type={"dark"} size={20} border={2} style={{
                        marginLeft: 7
                    }}/>}
                    </>,
                    active: true
                },
                {
                    content: <FontAwesomeIcon icon={["fas", "xmark"]}/>,
                    onClick: clearSearch,
                    className: styles.searchClear
                }
            ] : undefined
        }}>
            {(Object.keys(navigation) as unknown as (keyof typeof navigation)[]).map(k => <TabPane tab={k}
                                                                                                   label={navigation[k]}
                                                                                                   key={k}>
                <ul className={styles.invoices}>
                    {invoices.length === 0 && loading && <p>{t("Chargement")}...</p>}

                    {!loading && invoices.length === 0 && <div className={styles.empty}>
                        <img src={emptyImg} alt={""}/>
                        <p>{t(search !== null ? "Aucune facture trouvée avec la recherche" : "Aucune facture trouvée") + (search !== null ? ` ${search}` : "")}...</p>
                    </div>}

                    {invoices.map(invoice => <li key={invoice.id}>
                        <p className={styles.invoicesReference}>
                            N° {(invoice.invoice as Media).title}
                            <small>
                                <FontAwesomeIcon icon={["fas", "link"]}/>
                                <b>{t("Commande liée")} : </b>{invoice.reference}
                            </small>
                        </p>
                        <p className={styles.invoicesAmount}>{toPrice(invoice.amount)}</p>
                        <p
                          className={styles.invoicesDate}>{t("éditée le")} {moment(invoice.date_paid ?? invoice.date_created).format("DD-MM-YYYY")}</p>

                        <a href={Api.getUrl(`/assets/${(invoice.invoice as Media).id}`, true)} target={"_BLANK"}
                           rel="noreferrer">
                            <FontAwesomeIcon icon={["fas", "file-invoice-dollar"]}/>
                        </a>
                        <a href={Api.getUrl(`/assets/${(invoice.invoice as Media).id}?download`, true)}
                           target={"_BLANK"}
                           rel="noreferrer">
                            <FontAwesomeIcon icon={["fas", "cloud-arrow-down"]}/>
                        </a>
                    </li>)}
                </ul>
            </TabPane>)}
        </Tabs>
    </div>;
}