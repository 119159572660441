import {
    LocationSite,
    CatalogProduct,
    OfferItem,
    QuoteItem,
    Rent,
    RentState
} from "../../../../../interfaces/Interfaces";
import moment from "moment";
import {ReactNode, useContext, useMemo} from "react";
import Api from "../../../../../classes/Api";
import {Picture} from "../../../../../common/Picture/Picture";
import styles from "./rentCard.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import AuthContext from "../../../../../context/AuthContext";

interface RentCardProps {
    rent: Rent,
    onDetail: (rent: Rent) => void,
}

export default function RentCard({rent, onDetail}: RentCardProps) : JSX.Element {
    const {t} = useTranslation();
    const {user} = useContext(AuthContext);

    const quoteItem = rent.product as QuoteItem;
    const offerItem = quoteItem.offer_item as OfferItem;
    const product = offerItem.catalog_product as CatalogProduct;
    const site = offerItem.location as LocationSite;

    const getDuration = (): ReactNode => {
        const start = moment(offerItem.rent_start).startOf("day");
        const end = moment(offerItem.rent_end).startOf("day");
        const day = end.diff(start, "day") + 1;
        const hour = end.diff(start, "hour") - (day * 24);

        const sentence: string[] = [];

        if (day >= 1) {
            sentence.push(day + " jour" + (day > 1 ? "s" : ""));
        }

        if (hour > 0) {
            sentence.push(hour + " heure" + (hour > 1 ? "s" : ""));
        }

        if (sentence.length === 0) {
            return null;
        }

        return <small>({sentence.join(" et ")} de location)</small>;
    };

    const getRentPeriod = () => {
        const start = moment(offerItem.rent_start).startOf("day");
        const end = moment(offerItem.rent_end).startOf("day");

        if (start.valueOf() === end.valueOf()) {
            return "le " + start.format("DD-MM-YYYY");
        }

        return "du " + start.format("DD-MM-YYYY") + " au " + end.format("DD-MM-YYYY");
    };

    const getSite = () => {
        const address: string[] = [];
        const location: string[] = [];

        ["label", "address"].forEach(s => {
            if (typeof site[s as keyof LocationSite] === "string" && (site[s as keyof LocationSite] as string).trim().length > 0) {
                address.push(site[s as keyof LocationSite] as string);
            }
        });

        if (typeof site.zipcode === "string" && site.zipcode.trim().length > 0) {
            location.push(site.zipcode);
        }

        if (typeof site.city === "string" && site.city.trim().length > 0) {
            location.push(site.city);
        }

        if (location.length > 0) {
            address.push(location.join(" "));
        }

        return address.join(", ");
    };

    const state: {
        label: string,
        color: string,
    } = useMemo(() => {
        const isRenter = rent.renter.id === user.id;

        if (rent.state === RentState.TO_DELIVER) {
            return {
                label: isRenter ? "A livrer" : "En attente de livraison",
                color: "green"
            };
        } else if (rent.state === RentState.FINISHED) {
            return {
                label: "Terminée",
                color: "blue"
            };
        }

        return {
            label: "Sur site",
            color: "yellow"
        };
    }, [rent]);

    return <div className={styles.rentCard}>
        <div className={styles.rentCardImg}>
            <Picture src={Api.getUrl("/assets/" + product.picture, true)} alt={""}/>
        </div>
        <div className={styles.rentCardContent}>
            <h3>{product.label}</h3>
            <p>
                <b><FontAwesomeIcon icon={["fas", "calendar-days"]}/> Location : </b>{getRentPeriod()} {getDuration()}
            </p>
            <p>
                <b><FontAwesomeIcon icon={["fas", "location-dot"]}/> Site : </b>{getSite()}
            </p>
        </div>
        <div className={styles.rentCardTools}>
            <span className={state.color}>{state.label}</span>
            <a onClick={() => onDetail(rent)} className={styles.rentCardToolsDetails}>
                <FontAwesomeIcon icon={["fas", "circle-info"]}/> {t("Plus de détails")}
            </a>
        </div>
    </div>;
}