import {LocationSite} from "../interfaces/Interfaces";
import {useContext, useEffect, useMemo} from "react";
import Api from "../classes/Api";
import AuthContext from "../context/AuthContext";
import ArrayUtils from "../utils/ArrayUtils";
import AppContext from "../context/AppContext";
import {getUniqKey} from "../utils/NumberUtils";

export interface LocationSiteHook {
    sites: LocationSite[],
    storageSites: LocationSite[],
    buildingSites: LocationSite[],
    getSiteName: (site: LocationSite) => string,
    setSite: (site: LocationSite) => void,
    getSiteAddress: (site: LocationSite) => string[] | null,
    deleteSite: (idSite: number) => void,
}

export default function useLocationSite(): LocationSiteHook {
    const {user} = useContext(AuthContext);
    const {sites, setSites} = useContext(AppContext);
    const {id: idUser} = user;

    useEffect(() => {
        if (sites === null && window.__locationSiteHook === undefined) {
            load();
        }
    }, []);

    const load = () => {
        window.__locationSiteHook = "" + getUniqKey();

        Api.get<{
            data: LocationSite[]
        }>("/items/site", {
            user: idUser
        }).then(o => {
            setSites(o.data);
        }).catch(() => {
            setSites([]);
        });
    };

    const [clonedSites, storageSites, buildingSites] = useMemo(() => ([
        [...(sites ?? [])],
        [...(sites ?? [])].filter(s => s.type === "storage"),
        [...(sites ?? [])].filter(s => s.type === "building")
    ]), [sites]);

    return {
        sites: clonedSites,
        storageSites,
        buildingSites,
        getSiteName: s => `${s.label} (${s.address}, ${s.zipcode} ${s.city})`,
        setSite: s => {
            const newData = ArrayUtils.replaceOrPush([...(sites ?? [])], d => d.id === s.id, s);
            setSites(newData);
        },
        deleteSite: s => {
            const newData = ArrayUtils.delete((sites ?? []), d => d.id === s);
            setSites(newData);
        },
        getSiteAddress: (site: LocationSite): string[] | null => {
            const address: string[] = [];

            [
                ["label"],
                ["address"],
                ["zipcode", "city"]
            ].forEach(fields => {
                const line: string[] = [];

                (fields as (keyof LocationSite)[]).forEach((f: keyof LocationSite) => {
                    if (typeof site[f] !== "string" || (site[f] as string).trim().length === 0) {
                        return;
                    }

                    line.push(site[f] as string);
                });

                if (line.length > 0) {
                    address.push(line.join(", "));
                }
            });

            if (address.length === 0) {
                return null;
            }

            return address;
        }
    };
}