import Modal from "common/Modal/Modal";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import {Formik} from "formik";
import Button from "../../../../common/Button/Button";
import {User} from "../../../../interfaces/Interfaces";
import styles from "./userPasswordModal.module.scss";
import Api from "../../../../classes/Api";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";

interface UserInformationModalProps {
    display?: boolean,
    onClose?: () => void,
}

export default function UserPasswordModal(props: UserInformationModalProps): JSX.Element {
    const {user, setUser} = useContext(AuthContext);
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);

    const schema = yup.object({
        password: yup.string().required(t("Vous devez compléter le mot de passe")).min(6, t("Le mot de passe doit être de 6 caractères minimum")),
        repeat_password: yup.string().required(t("Vous devez répéter le mot de passe")),
    });

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }

        setSaving(false);
    };

    const onSave = (v: {
        password: string,
        repeat_password: string
    }) => {
        if (v.password !== v.repeat_password) {
            notifyError("Les deux mots de passe ne correspondent pas.");
            return;
        }

        setSaving(true);

        Api.patch<{
            data: User
        }>("/users/me", {
            password: v.password
        }).then(() => {
            notifySuccess(t("Mot de passe modifié avec succès."));
        }).catch(() => {
            notifyError(t("Une erreur est survenue lors de la modification du mot de passe."));
        }).finally(() => {
            setSaving(false);
            onClose();
        });
    };

    return <Modal onClose={onClose} display={props.display} className={styles.userPasswordModal}>
        <Formik
          validationSchema={schema}
          onSubmit={onSave}
          key={props.display ? 1 : 0}
          initialValues={{
              password: "",
              repeat_password: ""
          }}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                  isValid,
                  errors,
              }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                  <h4>{t("Nouveau mot de passe")}</h4>

                  <div className="km-input titled required">
                      <label>{t("Mot de passe")}</label>
                      <input
                        className={touched.password && errors.password ? "is-invalid" : undefined}
                        type="password"
                        value={values.password}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.password && touched.password &&
                          <div className="invalid-feedback">{errors.password}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Répéter le mot de passe")}</label>
                      <input
                        className={touched.repeat_password && errors.repeat_password ? "is-invalid" : undefined}
                        type="password"
                        value={values.repeat_password}
                        name="repeat_password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.repeat_password && touched.repeat_password &&
                          <div className="invalid-feedback">{errors.repeat_password}</div>}
                  </div>

                  <div className={"km-flex-middle km-space-between"} style={{
                      marginTop: 50
                  }}>
                      <p className={"required-legend"}>{t("Champs obligatoires")}</p>
                      <Button disabled={!isValid} type={"blue"} loading={saving}>
                          {t("Modifier")}
                      </Button>
                  </div>
              </form>)}
        </Formik>
    </Modal>;
}