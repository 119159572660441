import React, {HTMLAttributes, ReactNode, useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import classNames from "classnames";
import styles from "./modal.module.scss";
import {omit} from "../../utils/ObjectUtils";
import closeImg from "assets/close.svg";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    display?: boolean;
    children?: ReactNode;
    onClose?: () => void;
    sideModal?: boolean;
    closeOnBackdropClick?: boolean;
    hideCloseBtn?: boolean;
    unmountOnClose?: boolean;
    classNames?: {
        body?: string,
        animation?: {
            opening?: string,
            closing?: string
        }
    }
}

export interface TimeoutHandler {
    in: number | null,
    outBody: number | null,
    out: number | null,
}

export default function Modal(props: ModalProps): JSX.Element {
    const [display, setDisplay] = useState<boolean>(props.display === undefined || props.display);
    const [mounted, setMounted] = useState(false);
    const [animation, setAnimation] = useState<"in" | "out" | "re-in" | "default" | null>("default");
    const [backdropAnimation, setBackdropAnimation] = useState<"in" | "out" | null>("in");

    const mount = useRef(false);
    const isDisplayed = useRef(props.display ?? true);
    const animationDuration = props.sideModal === true ? 200 : 300;
    const timeout = useRef<TimeoutHandler>({
        in: null,
        outBody: null,
        out: null,
    });

    //handle props changes
    useEffect(() => {
        if (props.display) {
            if (!mounted) {
                setMounted(true);
            } else {
                setDisplay(true);
            }
        } else {
            close(props.onClose);
        }
    }, [props.display]);

    useEffect(() => {
        isDisplayed.current = display;

        if (display) {
            open();
        }
    }, [display]);

    //on mount
    useEffect(() => {
        mount.current = true;

        return () => {
            mount.current = false;

            if (!isDisplayed.current) {
                return;
            }

            getBody().classList.remove(styles.modalBody);
        };
    }, []);

    useEffect(() => {
        if (mounted) {
            setDisplay(true);
        }
    }, [mounted]);

    const getBody = (): HTMLDivElement => {
        return document.querySelector("body > #root") as HTMLDivElement;
    };

    const getClasses = (): string => {
        let classes: string[] = [styles.modal];

        if (props.sideModal === true) {
            classes.push(styles.modalSidebar);
        }

        if (props.classNames?.body !== undefined) {
            classes = [props.classNames.body];
        }

        //animations
        if (animation === "in") {
            classes.push(classNames(props.classNames?.animation?.opening ?? styles.modalOpening, styles.modalActive));
        } else if (animation === "out") {
            classes.push(classNames(props.classNames?.animation?.closing ?? styles.modalClosing, styles.modalActive));
        } else if (animation === "default") {
            classes.push(classNames(props.classNames?.animation?.opening ?? styles.modalOpening));
        }

        return classNames(classes.join(" "), props.className) as string;
    };

    const getBackdropClasses = (): string => {
        const classes: string[] = [styles.modalBackdrop];

        if (backdropAnimation === "in") {
            classes.push(styles.modalBackdropOpening);
        } else if (backdropAnimation === "out") {
            classes.push(styles.modalBackdropClosing);
        }

        return classes.join(" ");
    };

    const clearTimeouts = () => {
        //not use forEach to keep performance

        if (timeout.current.in !== null) {
            clearTimeout(timeout.current.in);
        }

        if (timeout.current.outBody !== null) {
            clearTimeout(timeout.current.outBody);
        }

        if (timeout.current.out !== null) {
            clearTimeout(timeout.current.out);
        }
    };

    const open = () => {
        clearTimeouts();

        setAnimation("in");
        setBackdropAnimation("in");

        timeout.current.in = setTimeout(() => {
            if (!mount.current) {
                return;
            }

            timeout.current.in = null;
            setAnimation(null);
            setBackdropAnimation(null);
        }, animationDuration) as unknown as number;

        getBody().classList.add(styles.modalBody);
    };

    const close = (cb?: () => void) => {
        if (!mount.current) {
            return;
        }

        clearTimeouts();

        //add in animation
        setAnimation("out");
        setBackdropAnimation("out");

        //clear
        timeout.current.outBody = setTimeout(() => {
            if (!mount.current) {
                return;
            }

            timeout.current.outBody = null;
            getBody().classList.remove(styles.modalBody);
        }, 150) as unknown as number;

        timeout.current.out = setTimeout(() => {
            if (!mount.current) {
                return;
            }

            timeout.current.out = null;
            setDisplay(false);
            setAnimation("default");
            setBackdropAnimation("in");

            if (props.unmountOnClose) {
                setMounted(false);
            }

            if (cb !== undefined) {
                cb();
            }
        }, animationDuration) as unknown as number;
    };

    const onBackdropClick = () => {
        if (props.closeOnBackdropClick !== true) {
            return;
        }

        close(props.onClose);
    };

    const restProps = omit(props,
      "display",
      "title",
      "children",
      "onClose",
      "className",
      "closeOnBackdropClick",
      "sideModal",
      "classNames",
      "hideCloseBtn",
      "unmountOnClose"
    );

    return (mounted ? createPortal(<div className={styles.modalRoot}>
        <div className={styles.modalWrap} tabIndex={-1} role="dialog"
             style={{
                 display: display ? "block" : "none",
             }}>
            {display && <div className={getBackdropClasses()} onClick={onBackdropClick}/>}
            <div
              className={getClasses()} role="document" {...restProps}>
                {props.hideCloseBtn !== true && <a onClick={() => close(props.onClose)} className={styles.modalClose}>
                    <img src={closeImg} alt={""}/>
                </a>}

                {props.children !== undefined && props.children}
            </div>
        </div>
    </div>, document.body) : null) as JSX.Element;
}