import {ButtonHTMLAttributes, ReactNode, SyntheticEvent} from "react";
import {omit} from "../../utils/ObjectUtils";
import styles from "./button.module.scss";
import Loader from "../Loader/Loader";
import {Link, LinkProps, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type ButtonType = "yellow" | "black" | "blue" | "gray" | "danger";
type ButtonSize = "small" | "normal" | "large";

interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick" | "type"> {
    onClick?: (e: SyntheticEvent) => void,
    to?: string,
    type?: ButtonType,
    outline?: boolean,
    size?: ButtonSize,
    loading?: boolean,
    bold?: boolean,
    rounded?: boolean,
    faIcon?: IconProp,
    target?: string,
}

export default function Button(props: ButtonProps): JSX.Element {
    const history = useHistory();
    const restProps = omit(props, "onClick", "className", "type", "outline", "size", "loading", "to", "bold", "rounded", "faIcon");

    const onClick = (e: SyntheticEvent) => {
        if (props.disabled === true || props.loading === true) {
            return;
        }

        if (props.to !== undefined) {
            history.push(props.to);
            return;
        }

        if (props.onClick) {
            props.onClick(e);
        }
    };

    const getClasses = (): string => {
        const classes: string[] = [styles.button];

        if (props.className !== undefined) {
            classes.push(props.className);
        }

        if (props.bold) {
            classes.push(styles.buttonBold);
        }

        if (props.rounded) {
            classes.push(styles.buttonRounded);
        }

        if (props.size === "large") {
            classes.push(styles.buttonLarge);
        } else if (props.size === "small") {
            classes.push(styles.buttonSmall);
        }

        if (props.loading || props.disabled) {
            classes.push(styles.buttonDisabled);
        }

        if (props.loading) {
            classes.push(styles.buttonLoading);
        }

        switch (props.type ?? "yellow") {
            case "gray":
                classes.push(styles.buttonGray);
                break;
            case "danger":
                classes.push(styles.buttonDanger);
                break;
            case "black":
                classes.push(styles.buttonBlack);
                break;
            case "yellow":
                classes.push(styles.buttonYellow);
                break;
            case "blue":
                classes.push(styles.buttonBlue);
                break;
        }

        if (props.outline) {
            classes.push(styles.buttonOutline);
        }

        return classes.join(" ");
    };

    const getLoaderSize = (): number | undefined => {
        if (props.size === "small") {
            return undefined;
        } else if (props.size === "large") {
            return 28;
        }

        return 25;
    };

    const getWrapper = (children: ReactNode): JSX.Element => {
        if (props.to !== undefined && props.target !== undefined) {
            return <Link className={getClasses()} {...restProps as LinkProps} to={props.to}>
                {children}
            </Link>;
        }

        return <button onClick={onClick}
                       className={getClasses()} {...restProps as unknown as ButtonHTMLAttributes<HTMLButtonElement>}>
            {children}
        </button>;
    };

    return getWrapper(<>
        {props.loading ? <Loader size={getLoaderSize()} className={styles.buttonLoader}/> : <>
            {props.faIcon !== undefined &&
                <FontAwesomeIcon icon={props.faIcon} className={styles.buttonIcon}/>}{props.children}
        </>}
    </>);
}