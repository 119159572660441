import Modal from "common/Modal/Modal";
import style from "./rentProductModal.module.scss";
import {CatalogProduct} from "../../../../interfaces/Interfaces";
import CartAddForm from "../CartAddForm/CartAddForm";
import {useMemo} from "react";
import Api from "../../../../classes/Api";
import {notifySuccess} from "../../../../common/Notification/notification";

interface RentProductModalProp {
    product?: CatalogProduct,
    onClose: () => void
}

export default function RentProductModal({onClose, product}: RentProductModalProp): JSX.Element {
    const content = useMemo(() => {
        if (product === undefined) {
            return null;
        }

        return <>
            <h2>Louer maintenant</h2>
            <div className={style.rentProductModalProduct}>
                <img src={Api.getUrl(`/assets/${product.picture}`, true)} alt={""}/>
                {product.label}
            </div>
            <CartAddForm product={product} footer={{
                className: style.rentProductModalFooter,
                nodes: <a className={style.rentProductModalFooterClose} onClick={onClose}>Fermer</a>
            }} button={{
                className: style.rentProductModalFooterAdd,
                label: "Louer maintenant"
            }} onAdd={() => {
                onClose();
                notifySuccess("Équipement ajouté au panier !");
            }}/>
        </>;
    }, [product]);

    return <Modal onClose={onClose} display={product !== undefined} classNames={{
        body: style.rentProductModal,
        animation: {
            opening: style.rentProductModalOpening,
            closing: style.rentProductModalClosing
        }
    }} hideCloseBtn>
        {content}
    </Modal>;
}