import classNames from "classnames";
import styles from "./payment.module.scss";
import {useContext} from "react";
import AuthContext from "../../../../context/AuthContext";
import AccountInformation from "./components/AccountInformation";
import Documents from "./components/Documents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Verification from "./components/Verification";
import Dashboard from "./components/Dashboard";

interface Steps {
    [key: number]: {
        label: string,
        validated: () => boolean
    }
}

export default function Payment(): JSX.Element {
    const {user} = useContext(AuthContext);

    const steps: Steps = {
        1: {
            label: "Création du compte de paiement",
            validated: () => user.easytransac_status !== undefined && user.easytransac_status > 0
        },
        2: {
            label: "Envoi des documents",
            validated: () => user.easytransac_status !== undefined && user.easytransac_status > 1
        },
        3: {
            label: "Vérification & validation du compte",
            validated: () => user.easytransac_status !== undefined && user.easytransac_status === 3
        }
    };

    const getStatus = (): number => {
        if (typeof user.easytransac_status !== "number") {
            return 0;
        }

        return user.easytransac_status;
    };

    return <div className={classNames(styles.company, "ps-sm-4 row pb-4 pb-xl-0")}>
        <div className={classNames("col-xl-9", styles.companyInformation)}>
            <h2 className={styles.settingsTitle}>Paiement en ligne</h2>

            {user.easytransac_status !== 3 && <>
                <div className={styles.settingsInfo}>
                    <p>Pour louer votre équipement vous devez avoir un compte de paiement de valide.</p>
                    <p>Une fois le compte validé par notre partenaire, vous pourrez mettre en location votre équipement et recevoir les paiements directement sur votre compte bancaire.</p>
                </div>

                <p><FontAwesomeIcon
                    icon={["fas", "lock"]}/> Les paiements en ligne sont gérés et sécurisés par notre partenaire <a
                    href={"https://www.easytransac.com/fr"} target={"_blank"} rel={"noreferrer"}>Easytransac</a>.</p>

                <ul className={styles.settingsSteps}>
                    {(Object.keys(steps) as unknown as (keyof typeof steps)[]).map(step => <li key={step}>
                        <span>{step}</span>
                        <p>{steps[step].label} <span
                          className={steps[step].validated() ? styles.settingsStepsValidate : styles.settingsStepsWaiting}>
                        <FontAwesomeIcon
                          icon={["fas", steps[step].validated() ? "check" : "hourglass"]}/> {steps[step].validated() ? "validé" : "en attente"}</span>
                        </p>
                    </li>)}
                </ul>
            </>}

            {getStatus() === 0 && <AccountInformation/>}
            {getStatus() === 1 && <Documents/>}
            {getStatus() === 2 && <Verification/>}
            {getStatus() === 3 && <Dashboard/>}
        </div>
    </div>;
}
