import Modal from "../../../../../common/Modal/Modal";
import styles from "./rentModal.module.scss";
import {
    CatalogProduct,
    OfferItem,
    OrderState,
    PartnerProduct,
    QuoteItem,
    Rent,
    RentState
} from "../../../../../interfaces/Interfaces";
import moment from "moment";
import {ReactNode, useCallback, useContext, useMemo, useState} from "react";
import deliverLogo from "assets/deliver.svg";
import {useTranslation} from "react-i18next";
import technicalLogo from "assets/technical.svg";
import incidentLogo from "assets/incident.svg";
import {useHistory} from "react-router-dom";
import AuthContext from "../../../../../context/AuthContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import phoneLogo from "assets/phone.svg";
import pinLogo from "assets/address.svg";
import managerLogo from "assets/manager.svg";
import Api from "../../../../../classes/Api";
import Loader from "../../../../../common/Loader/Loader";
import {notifyError} from "../../../../../common/Notification/notification";

interface RentModalProps {
    rent: Rent | null,
    onClose?: () => void,
    onChange: (rent: Rent) => void
}

export default function RentModal(props: RentModalProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const {user} = useContext(AuthContext);
    const {id: idUser} = user;

    const [changingStatus, setChangingStatus] = useState(false);
    const [statusToSet, setStatusToSet] = useState<RentState | null>(null);

    const isSelfRenter = useMemo(() => {
        if (props.rent === null) {
            return false;
        }

        return props.rent.renter.id === idUser;
    }, [props.rent]);

    const onClose = () => {
        setStatusToSet(null);

        if (props.onClose) {
            props.onClose();
        }
    };

    const getOrderInfo = () => {
        if (props.rent === null || props.rent.order === undefined || props.rent.order === null || typeof props.rent.order === "number") {
            return;
        }

        if (props.rent.order.state !== OrderState.PAID) {
            return null;
        }

        return <p>
            <span
              className={classNames(styles.rentalModalHeaderStatus, styles.paid)}>payée</span> {props.rent.order.date_paid !== null && "Payée le " + moment(props.rent.order.date_paid).format("DD-MM-YYYY")}
        </p>;
    };

    const getRenterInfo = (): ReactNode => {
        if (props.rent === null || props.rent.order === undefined || props.rent.renter === null) {
            return null;
        }

        const renter = props.rent.renter;
        const info: ReactNode[] = [];

        if (renter.society_name !== null && renter.society_name.trim().length > 0) {
            info.push(renter.society_name + (renter.society_legal_status !== null ? " " + renter.society_legal_status : ""));
        }

        if (renter.society_vat_number !== null && renter.society_vat_number.trim().length > 0) {
            info.push("Numéro de TVA : " + renter.society_vat_number);
        }

        const address = [];
        const location = [];

        if (renter.society_location_address !== null && renter.society_location_address.trim().length > 0) {
            address.push(renter.society_location_address);
        }

        if (renter.society_location_zipcode !== null && renter.society_location_zipcode.trim().length > 0) {
            location.push(renter.society_location_zipcode);
        }

        if (renter.society_location_city !== null && renter.society_location_city.trim().length > 0) {
            location.push(renter.society_location_city);
        }

        if (location.length > 0) {
            address.push(location.join(" "));
        }

        if (renter.society_location_country !== null && renter.society_location_country.trim().length > 0) {
            address.push(renter.society_location_country);
        }

        if (address.length > 0) {
            info.push(address.join(", "));
        }

        if (renter.sav_phone !== null && renter.sav_phone.trim().length > 0) {
            info.push(<>
                <img src={phoneLogo} alt={""}/> SAV : {renter.sav_phone}
            </>);
        }

        return <>
            {info.map((p, i) => <p key={i}>{p}</p>)}
        </>;
    };

    const getSite = () => {
        if (props.rent === null || props.rent.product === undefined || typeof props.rent.product !== "object" || props.rent.product.offer_item === undefined || typeof props.rent.product.offer_item !== "object") {
            return null;
        }

        const location = props.rent.product.offer_item.location;

        if (typeof location !== "object") {
            return null;
        }

        const info: ReactNode[] = [];

        info.push(<p key={1}>{location.label}</p>);
        info.push(<p key={2}><img src={pinLogo} alt={""}/> Adresse : {location.address}</p>);

        const city: string[] = [];

        if (typeof location.zipcode === "string" && location.zipcode.trim().length > 0) {
            city.push(location.zipcode);
        }

        if (typeof location.city === "string" && location.city.trim().length > 0) {
            city.push(location.city);
        }

        if (city.length > 0) {
            info.push(<p key={3}>{city.join(" ")}</p>);
        }

        if (typeof location.manager === "string" && location.manager.trim().length > 0) {
            info.push(<p key={4}><img src={managerLogo} alt={""}/> Manager : {location.manager}</p>);
        }

        if (typeof location.phone === "string" && location.phone.trim().length > 0) {
            info.push(<p key={5}><img src={phoneLogo} alt={""}/> Téléphone : {location.phone}</p>);
        }

        return info;
    };

    const getConfirmStatusMessage = (): string => {
        if (statusToSet === RentState.DELIVERED) {
            return "Vous êtes sur le point d'indiquer que cette location a été livrée sur site";
        } else if (statusToSet === RentState.FINISHED) {
            return "Vous ête sur le point d'indiquer que cette location a été récupérée et donc mettre fin à la location";
        }

        return "";
    };

    const getTechnicalSheet = useCallback((): string | null => {
        if (props.rent === null) {
            return null;
        }

        if (props.rent.product === null || props.rent.product === undefined || typeof props.rent.product === "number") {
            return null;
        }

        if (props.rent.product.partner_product === null || typeof props.rent.product.partner_product !== "object") {
            return null;
        }

        if (typeof props.rent.product.partner_product.technical_sheet === "string" && props.rent.product.partner_product.technical_sheet.trim().length > 0) {
            return props.rent.product.partner_product.technical_sheet;
        }

        return null;
    }, [props.rent]);

    const getStatusToSet = useCallback((): RentState | null => {
        if (props.rent === null) {
            return null;
        }

        if (props.rent.state === RentState.TO_DELIVER) {
            return RentState.DELIVERED;
        } else if (props.rent.state === RentState.DELIVERED) {
            return RentState.FINISHED;
        }

        return null;
    }, [props.rent]);

    const changeStatus = () => {
        if (changingStatus || props.rent === null) {
            return;
        }

        const newState = getStatusToSet();

        if (newState === null) {
            return;
        }

        setChangingStatus(true);

        Api.patch<Rent>(`/items/rent/${props.rent.id}`, {
            state: newState
        }).then(() => {
            if (props.rent !== null) {
                props.onChange({
                    ...props.rent,
                    state: newState
                });
            }
        }).catch(() => {
            notifyError("Impossible de changer le statut de cette location");
        }).finally(() => {
            setStatusToSet(null);
            setChangingStatus(false);
        });
    };

    return <Modal onClose={onClose} className={styles.rentalModal}
                  display={props.rent !== null}>
        {props.rent !== null && <>
            <div className={styles.rentalModalHeader}>
                {typeof props.rent.order === "object" && <div>
                    <h1>#{props.rent.order?.reference}</h1>
                    {getOrderInfo()}
                </div>}

                {statusToSet === null ? <ul className={styles.rentalModalHeaderTools}>
                    {isSelfRenter && [RentState.DELIVERED, RentState.TO_DELIVER].indexOf(props.rent.state) !== -1 &&
                        <li onClick={() => setStatusToSet(getStatusToSet())}>
                            <img src={deliverLogo} alt={""}/>
                            <p>{t(props.rent.state === RentState.DELIVERED ? "Marquer comme récupéré" : "Marquer comme livré")}</p>
                        </li>}

                    <li onClick={() => history.push("/messenger")}>
                        <img src={incidentLogo} alt={""}/>
                        <p>{t("Reporter un incident")}</p>
                    </li>

                    {getTechnicalSheet() !== null &&
                        <li onClick={() => window.open(Api.getUrl(`/assets/${getTechnicalSheet()}`, true), "_blank")}>
                            <img src={technicalLogo} alt={""}/>
                            <p>{t("Fiche technique")}</p>
                        </li>}
                </ul> : <div className={styles.rentalModalHeaderConfirm}>
                    <p><FontAwesomeIcon icon={["fas", "triangle-exclamation"]}/> {getConfirmStatusMessage()}</p>
                    <div className={styles.rentalModalHeaderConfirmWrapper}>
                        <a className={classNames(styles.rentalModalHeaderConfirmOk, changingStatus ? "disabled" : "")}
                           onClick={changeStatus}>
                            {changingStatus ? <Loader size={19}/> : "Confirmer"}
                        </a>

                        {!changingStatus && <a className={styles.rentalModalHeaderConfirmCancel}
                                               onClick={() => setStatusToSet(null)}>Annuler</a>}
                    </div>
                </div>}
            </div>

            <div className={styles.rentalModalBody}>
                <div>
                    <h4>A propos de la location</h4>
                    <p>{(((props.rent.product as QuoteItem).offer_item as OfferItem).catalog_product as CatalogProduct).label}</p>
                    {typeof (props.rent.product as QuoteItem).partner_product === "object" && <p>
                        {((props.rent.product as QuoteItem).partner_product as PartnerProduct)?.internal_reference}
                    </p>}

                    <p style={{
                        marginTop: 10
                    }}>Début : {moment(((props.rent.product as QuoteItem).offer_item as OfferItem).rent_start).format("DD-MM-YYYY")}</p>
                    <p>Fin : {moment(((props.rent.product as QuoteItem).offer_item as OfferItem).rent_end).format("DD-MM-YYYY")}</p>

                    <p>Opérateur : {((props.rent.product as QuoteItem).offer_item as OfferItem).operator ? "oui" : "non"}</p>
                    <p>Samedi inclus : {((props.rent.product as QuoteItem).offer_item as OfferItem).saturday_include ? "oui" : "non"}</p>
                    <p>Dimanche inclus : {((props.rent.product as QuoteItem).offer_item as OfferItem).saturday_include ? "oui" : "non"}</p>
                </div>

                <div>
                    <h4>A propos du fournisseur</h4>
                    {getRenterInfo()}
                </div>

                <div>
                    <h4>A propos du site</h4>
                    {getSite()}
                </div>
            </div>
        </>}
    </Modal>;
}