type ValueHandler<T> = (value: T, index ?: number) => T;

export default class ArrayUtils {
    static clone<T>(array: T[]): T[] {
        return JSON.parse(JSON.stringify(array), ArrayUtils.dateParser) as T[];
    }

    static dateParser(key: string | number, value: string | number | boolean) {
        const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
        const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/;

        if (typeof value === "string") {
            let a = reISO.exec(value);

            if (a) {
                return new Date(value);
            }

            a = reMsAjax.exec(value);

            if (a) {
                const b = a[1].split(/[-+,.]/);

                return new Date(b[0] ? +b[0] : 0 - +b[1]);
            }
        }

        return value;
    }

    static delete<T>(array: T[], element: T | ((e: T) => boolean)): T[] {
        const arr = ArrayUtils.clone(array);
        let index = -1;

        if (typeof element === "function") {
            const el = arr.filter(element as (e: T) => boolean);

            if (el.length === 1) {
                index = arr.indexOf(el[0]);
            }
        } else {
            index = arr.indexOf(element);
        }

        if (index !== -1) {
            arr.splice(index, 1);
        }

        return arr;
    }

    static replace<T>(array: T[], element: T | ((e: T) => boolean), value: T | ValueHandler<T>, clone ?: boolean): T[] {
        const arr = clone !== false ? ArrayUtils.clone(array) : array;
        let index = -1;

        if (typeof element === "function") {
            const el = arr.filter(element as (e: T) => boolean);

            if (el.length === 1) {
                index = arr.indexOf(el[0]);
            }
        }

        if (index !== -1) {
            let newValue = value;

            if (typeof value === "function") {
                newValue = (value as ValueHandler<T>)(arr[index], index);
            }

            arr[index] = newValue as T;
        }

        return arr;
    }

    static replaceOrPush<T>(array: T[], element: T | ((e: T) => boolean), value: T): T[] {
        const arr = ArrayUtils.clone(array);
        let index = -1;

        if (typeof element === "function") {
            const el = arr.filter(element as (e: T) => boolean);

            if (el.length === 1) {
                index = arr.indexOf(el[0]);
            }
        } else {
            index = arr.indexOf(element);
        }

        if (index !== -1) {
            arr[index] = value;
        } else {
            arr.push(value);
        }

        return arr;
    }
}