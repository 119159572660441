import {CatalogCard} from "../CatalogCard/CatalogCard";
import {CatalogItem, CatalogProduct, ICard} from "interfaces/Interfaces";
import ContentLoader from "react-content-loader";
import {useTranslation} from "react-i18next";
import emptyLogo from "assets/empty.svg";
import styles from "./catalogGrid.module.scss";
import {useHistory} from "react-router-dom";
import {ProductCard} from "pages/RentalCatalog/components/ProductCard/ProductCard";
import classNames from "classnames";

interface ICatalogGridProps {
    array: (CatalogItem | CatalogProduct)[];
    onProductDetail?: (product : CatalogProduct) => void,
    onProductRent?: (product : CatalogProduct) => void,
    productCardType?: boolean;
    mainCategory?: boolean;
    loading?: boolean;
}

export const CatalogGrid: React.FunctionComponent<ICatalogGridProps> = ({
                                                                            onProductRent,
                                                                            array,
                                                                            productCardType,
                                                                            mainCategory,
                                                                            onProductDetail,
                                                                            loading
                                                                        }) => {
    const {t} = useTranslation();
    const history = useHistory();

    const defaultLoad = () => {
        const dummyArray = [];

        for (let i = 0; i < 4; i++) {
            dummyArray.push(
              <div key={i} className="catalogBodyGridCard">
                  <ContentLoader
                    speed={1}
                    viewBox="0 0 215 215"
                    style={{width: "90%"}}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                      <rect x="24" y="132" rx="3" ry="3" width="181" height="12"/>
                      <circle cx="114" cy="61" r="48"/>
                  </ContentLoader>
              </div>
            );
        }

        return dummyArray;
    };

    return <>
        {(loading === undefined || !loading) && array.length !== 0 && array.map((element, index) => <div
          className={classNames("catalogBodyGridCard", !productCardType ? "catalogBodyGridCardCategory" : "")} key={index}>
            {productCardType ?
              <ProductCard
                {...element as CatalogProduct}
                onSelect={onProductDetail !== undefined ? () => onProductDetail(element as CatalogProduct) : undefined}
                onRent={onProductRent !== undefined ? () => onProductRent(element as CatalogProduct) : undefined}
                key={index}
              /> : <CatalogCard
                {...element as CatalogItem}
                mainCategory={mainCategory}
                key={index}
              />}
        </div>)}

        {(loading === undefined || loading) && array.length === 0 && defaultLoad()}

        {loading === false && array.length === 0 && <div className={styles.empty}>
            <img src={emptyLogo} alt={"empty"}/>

            <div>
                <p>{t("Produits bientôt disponibles !")}</p>
                <a onClick={() => history.goBack()}>Retour</a>
            </div>
        </div>}
    </>;
};
