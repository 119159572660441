import styles from "./accountNotCompleteAlert.module.scss";
import {HTMLAttributes, useContext, useMemo} from "react";
import AuthContext from "../../context/AuthContext";
import {EasytransacStatus} from "../../interfaces/Interfaces";
import Button from "../Button/Button";
import {omit} from "../../utils/ObjectUtils";
import classNames from "classnames";

interface AccountNotCompleteAlertProps extends HTMLAttributes<HTMLDivElement> {
    actionLabel?: string
}

export default function AccountNotCompleteAlert(props: AccountNotCompleteAlertProps): JSX.Element {
    const {user} = useContext(AuthContext);

    const isVerified = useMemo(() => user.verified === true, [user]);
    const havePaymentAccount = useMemo(() => user.easytransac_status === EasytransacStatus.FULLY_VALIDATE && typeof user.easytransac_id === "number", [user]);

    if (isVerified && havePaymentAccount) {
        return null as unknown as JSX.Element;
    }

    const content = useMemo(() => {
        const action = props.actionLabel ?? "continuer";

        if (!isVerified) {
            return <>
                <p>Les informations de votre compte ne sont pas complètes.</p>
                <p>Vous devez compléter les informations manquantes pour pouvoir {action}.</p>
                <Button to={"/settings?society"} bold>Compléter mes informations</Button>
            </>;
        }

        return <>
            <p>Vous devez avoir configuré vos informations de paiement afin de recevoir les règlements sur votre compte bancaire.</p>
            <p>Vous devez créer et compléter votre compte de paiement pour pouvoir {action}.</p>
            <Button to={"/settings?payment"} bold>Compléter mes informations</Button>
        </>;
    }, [isVerified, havePaymentAccount]);

    return <div className={classNames(styles.alert, props.className)} {...omit(props, "className")}>
        {content}
    </div>;
}