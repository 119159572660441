import classnames from "classnames";
import classNames from "classnames";
import styles from "../layout.module.scss";
import {Link, useHistory, useLocation} from "react-router-dom";
import logo from "../assets/logo.svg";
import {ReactComponent as NavToggleIcon} from "../assets/nav-toggle.svg";
import catalogIcon from "../assets/icons/catalog.svg";
import offerIcon from "../assets/icons/offers.svg";
import managerIcon from "../assets/icons/manager.svg";
import settingIcon from "../assets/icons/setting.svg";
import supportIcon from "../assets/icons/support.svg";
import Collapse from "bootstrap/js/dist/collapse";
import {useContext, useEffect, useRef, useState} from "react";
import OfferContext from "../../../context/OfferContext";
import AuthContext from "../../../context/AuthContext";
import {LoginState} from "../../../interfaces/Interfaces";
import faqLogo from "../../WishListNavComponent/assets/faq.svg";

export default function Navigation(): JSX.Element {
    const history = useHistory();

    const {loginState} = useContext(AuthContext);
    const {received, sent, requests} = useContext(OfferContext);

    const [opened, setOpened] = useState(false);

    const nav = useRef<HTMLDivElement>(null);
    const navToggle = useRef<HTMLButtonElement>(null);

    const location = useLocation();

    useEffect(() => {
        if (nav.current === null) {
            return;
        }

        nav.current.addEventListener("show.bs.collapse", onMobileNavigationOpen);
        nav.current.addEventListener("hide.bs.collapse", onMobileNavigationClose);

        return () => {
            if (nav.current === null) {
                return;
            }

            nav.current.removeEventListener("show.bs.collapse", onMobileNavigationOpen);
            nav.current.removeEventListener("hide.bs.collapse", onMobileNavigationClose);
        };
    }, []);

    useEffect(() => {
        setOpened(false);
    }, [location]);

    useEffect(() => {
        if (opened) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.removeProperty("overflow-y");
        }
    }, [opened]);

    const onMobileNavigationOpen = () => {
        document.body.style.overflowY = "hidden";
        navToggle.current?.classList.add("toggled");
    };

    const onMobileNavigationClose = () => {
        document.body.style.removeProperty("overflow-y");
        navToggle.current?.classList.remove("toggled");
    };

    const getCollapse = (): Collapse | null => {
        if (nav.current === null) {
            return null;
        }

        return new Collapse(nav.current, {
            toggle: false
        });
    };

    const closeNavigation = () => {
        const collapse = getCollapse();

        if (collapse === null || nav.current === null || !nav.current.classList.contains("show")) {
            return;
        }

        collapse.hide();
    };

    const switchMobileNavigation = () => {
        const collapse = getCollapse();

        if (collapse === null) {
            return;
        }

        collapse.toggle();
    };

    return <>
        <nav className={classnames(styles.mainNavigation)}>
            <Link className={classNames(styles.mainNavigationLogo)} to={"/rentals"}>
                <img src={logo} alt="kamatLogo"/>
                <span>bêta</span>
            </Link>

            <div className={styles.mainNavigationMobileHeader}>
                <Link to="/messenger">
                    <img src={faqLogo} alt="faq" className={styles.mainNavigationMobileHeaderFaq}/>
                </Link>

                <button
                  className={classNames(styles.mainNavigationToggle, opened ? styles.mainNavigationToggleOpened : "")}
                  type="button"
                  ref={navToggle}
                  onClick={() => setOpened(!opened)}
                >
                    <NavToggleIcon className={styles.mainNavigationToggleIcon}/>
                </button>
            </div>

            <ul className={classNames(styles.mainNavigationContainer, opened ? styles.mainNavigationOpened : undefined)}>
                <li onClick={() => {
                    history.push("/rentals");
                    closeNavigation();
                }}
                    className={classNames(location.pathname.indexOf("rentals") > -1 ||
                    location.pathname.indexOf("cart") > -1 ? styles.mainNavigationItemActive : undefined)}>
                    <img src={catalogIcon} alt={""}/>
                    <p>Louer</p>
                </li>

                {(received.length > 0 || sent.length > 0 || requests.length > 0) &&
                    <li className={location.pathname.indexOf("offer") > -1 ? styles.mainNavigationItemActive : undefined}
                        onClick={() => {
                            history.push("/offers");
                            closeNavigation();
                        }}>
                        <img src={offerIcon} alt={""}/>
                        <p>Mes offres</p>
                    </li>}

                {loginState === LoginState.LOGGED && <>
                    <li onClick={() => {
                        history.push("/management");
                        closeNavigation();
                    }}
                        className={location.pathname.indexOf("management") > -1 ? styles.mainNavigationItemActive : undefined}>
                        <img src={managerIcon} alt={""}/>
                        <p>Gestion</p>
                    </li>

                    <li onClick={() => {
                        history.push("/settings");
                        closeNavigation();
                    }}
                        className={location.pathname.indexOf("settings") > -1 ? styles.mainNavigationItemActive : undefined}>
                        <img src={settingIcon} alt={""}/>
                        <p>Paramètres</p>
                    </li>
                </>}

                <li onClick={() => {
                    history.push("/messenger");
                    closeNavigation();
                }}
                    className={location.pathname.indexOf("messenger") > -1 ? styles.mainNavigationItemActive : undefined}>
                    <img src={supportIcon} alt={""}/>
                    <p>Support</p>
                </li>
            </ul>

            {/*<div
                  className={classNames("collapse navbar-collapse", styles.navMenu)}
                  ref={nav}
                  id="navbarNav"
                >
                </div>*/}
        </nav>

        <nav id="subNav"/>
    </>;
}