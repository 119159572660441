import React from "react";
import ReactDOM, {createPortal} from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import "./styles/app.global.scss"; // Import the global styles once in the top-most App component
import "rc-tooltip/assets/bootstrap.css";
import "react-toastify/dist/ReactToastify.min.css";
import TagManager from "react-gtm-module";
import { config } from "configs";

if (config.gtm) {
  TagManager.initialize({
    gtmId: config.gtm
  });
}
ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Router><App /></Router>
        {createPortal(<ToastContainer />, document.body)}
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
