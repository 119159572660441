import {ReactNode} from "react";

export type Nullable<T> = {
    [P in keyof T]: T[P] | null
}

export interface ApiDataResponse<T> {
    data: T[]
}

export interface ApiMutationResponse<T> {
    data: T
}

export interface ApiDataCountResponse<T> {
    data: T[],
    meta: {
        total_count: number
        filter_count: number
    }
}

export interface CatalogCategory {
    id: number,
    label: string,
    slug: string,
    picture: string | null
}

export interface CatalogItem {
    label: string,
    picture: string | null,
    url: string,
}

export interface CatalogFamily {
    category: number | CatalogCategory,
    label: string,
    picture: string | null,
    slug: string,
    id: number
}

export interface CatalogProduct {
    id: number,
    b_permit: boolean | null,
    energy: Energy | null,
    slug: string,
    label: string,
    picture: string,
    family: CatalogFamily | number,
    characteristics: {
        label: string,
        value: string
    }[],
    description: string,
    options?: OfferItemOption[]
}

export interface PartnerProduct {
    brand: string | null,
    date_created: string,
    date_updated: string | null,
    location: LocationSite | number,
    id: number,
    insurance: string,
    price_daily: number,
    internal_reference: string | null,
    label: string | null,
    operator: boolean,
    hourly_rental: boolean,
    control_validity_date: string | null,
    owner: string,
    product: number | CatalogProduct,
    status: string,
    technical_sheet: string | null | Media,
    cce: string | null | Media,
}

export type RecursiveGenericType = {
    [key: string]: string | number | boolean | null | undefined | RecursiveGenericType
};

export interface Media {
    filename_download: string,
    filesize: string,
    id: string,
    modified_on: string,
    title: string,
    type: string,
    uploaded_on: string,
}

export interface UploadFileParams {
    filename_download?: string,
    title?: string,
    type?: string,
    folder?: string,
    uploaded_by?: string,
    description?: string,
    tags?: string[],

    [key: string]: string | number | boolean | undefined | string[]
}

export interface Option {
    value: number,
    label: string | number
}

export interface Rent {
    date_created: string,
    date_updated: string | null,
    id: number,
    product: QuoteItem | number,
    renter: User,
    client: User,
    state: RentState,
    order: null | number | Order
}

export interface Order {
    id: number,
    amount: number,
    amount_vat: number,
    amount_ht: number,
    vat_rate: number,
    date_created: string,
    date_paid: string | null,
    reference: string,
    state: OrderState
    client: string | User,
    beneficiary: string | User,
    quote: number | Quote,
    fee_applied: number,
    invoice?: string | Media,
    transaction?: number | Transaction
}

export interface Transaction {
    tid: string,
    order: number,
    merchant: number,
    amount: number,
    state: string
}

export interface DefaultType<T> {
    [key: string]: T
}

export enum OrderState {
    PAID = "paid",
    WAITING = "waiting",
    REFUSED = "refused",
    DEFERRED = "deferred",
    CANCELED = "canceled"
}

export enum RentState {
    TO_DELIVER = "to_deliver",
    DELIVERED = "delivered",
    FINISHED = "finished",
}

export interface Quote {
    author: string | User,
    client: string | User,
    date_created: string,
    date_updated: string | null,
    expires_on: string | null,
    id: number,
    items: (number | QuoteItem)[],
    offer: number,
    rental_conditions?: string,
    state: string,
    fee_applied: number
}

export type KycDocumentType =
  "ACTIVITY_PROOF"
  | "ADDRESS_PROOF"
  | "ARTICLES_OF_ASSOCIATION"
  | "BANK_DETAILS"
  | "BENEFICIARY"
  | "BENEFICIARY_BACK"
  | "BENEFICIARY2"
  | "BENEFICIARY2_BACK"
  | "BENEFICIARY3"
  | "BENEFICIARY3_BACK"
  | "COMMERCIAL_REGISTER"
  | "DRIVER_LICENSE"
  | "DRIVER_LICENSE_BACK"
  | "FAMILY_BOOK"
  | "GENERAL_MEETING"
  | "IDENTITY_PROOF"
  | "IDENTITY_PROOF_BACK"
  | "MARRIAGE_CERTIFICATE"
  | "OTHER"
  | "OTHER2"
  | "OTHER3"
  | "PASSPORT"
  | "RECEIPT_STATEMENT"
  | "REGISTRATION_PROOF"
  | "RESIDENCE_PERMIT"
  | "RESIDENCE_PERMIT_BACK"
  | "SELFIE"
  | "SHAREHOLDER_DECLARATION"
  | "SHAREHOLDER_DECLARATION2"
  | "SIGNATURE"
  | "TAX_NOTICE"
  | "VITAL_CARD"
  | "VITAL_CARD_BACK"
  | "VDI_CERTIFICATE"
  | "ALCOHOL_LICENSE"
  | "EMPOWERMENT";

export interface KycDocument {
    Id: string,
    DocumentType: KycDocumentType,
    Status: "valid" | "pending" | "invalid",
    Date: string,
    DateUpdated: string,
    Comment?: string
}

export interface QuoteItem {
    id: number,
    price: number,
    price_transport: number,
    price_insurance: number,
    insurance_rate: number,
    insurance_covered_days: number,
    offer_item: OfferItem | number,
    quote: Quote | number,
    partner_product: number | null | PartnerProduct
}

export type LocationSiteType = "building" | "storage";

export interface LocationSite {
    address: string,
    city: string | null,
    client: string,
    date_created: string,
    date_updated: null | string,
    id: number;
    label: string,
    manager: string | null,
    phone: string | null,
    zipcode: string | null,
    type: LocationSiteType
}

export interface Offer {
    date_created: string,
    client: User | number,
    expires_on: string | null,
    token: string,
    id: number,
    is_cart: boolean,
    items: OfferItem[] | number[]
}

export type OfferItemOption = "brh" | "bucket" | "hoist" | "carrycot_basket" | "gallows" | "safety_harness";
export type AllOfferItemOption = OfferItemOption | "operator" | "saturday_include" | "sunday_include";

export interface OfferItem {
    catalog_product: number | CatalogProduct,
    id: number,
    location: number | LocationSite,
    location_address: string,
    offer: number,
    operator: boolean,
    transport: boolean,
    saturday_include: boolean,
    sunday_include: boolean,
    quantity: number,
    rent_end: string,
    rent_start: string,
    options: OfferItemOption[]
}

export interface User {
    id: string,
    email: string,
    first_name: string,
    last_name: string,
    phone: string | null
    society_legal_status: string | null,
    society_identification: string | null,
    society_location_address: string | null,
    society_location_city: string | null,
    society_location_country: string | null,
    society_location_zipcode: string | null,
    society_name: string | null,
    society_vat_number: string | null,
    society_logo: string | Media | null,
    sav_phone: string | null,
    verified?: boolean | null,
    rental_conditions?: string,
    easytransac_id?: number,
    easytransac_status?: number,
    company_type?: "entrepreneur" | "company",
    offer_access_token?: string
}

export enum EasytransacStatus {
    INCOMPLETE,
    DOCUMENTS_MISSING,
    VALIDATE,
    FULLY_VALIDATE
}

export interface Countries {
    [key: string]: {
        name: string,
        alpha_3: string
    }
}

export interface Configuration {
    required_account_fields: (keyof User)[],
    app_url: string,
    society_logo_folder: string,
    support_folder: string,
    default_fee: number,
    vat_rate: number,
    invoice_header: string,
    invoice_footer: string,
    faq: {
        question: string,
        answer: string
    }[]
}

export interface QuotePrice {
    price: number,
    transport: number,
    insurance: number,
    commission: number,
    vat: number,
    ht: number,
    ttc: number
}


export interface ICategory {
    id: number;
    label: string;
    translatedLabels: { [key: string]: string };
    code: string;
    image: string;
    attributes?: {
        [key: string]: boolean | string | number
    };
}

export interface IProduct extends ICategory {
    attributes: { [key: string]: string | number | boolean };
}

export interface IDetailedProduct extends IProduct {
    description: string;
    translatedDescriptions: { [key: string]: string };
    isHourlyRentable: boolean;
}

export interface ICard extends ICategory {
    linkURL: string;
    logosPath: string;
    categoryCode?: string;
    subCategoryCode?: string;
}

export interface IDetailedProductCard extends IDetailedProduct {
    linkURL: string;
    logosPath: string;
    categoryCode?: string;
    subCategoryCode?: string;
}

export interface IMenu {
    id: number | string;
    name: string;
    info: string;
    logosPath?: string;
    reducedName?: string;
    img?: ReactNode
}

export interface ISelectValues {
    value: number;
    label: string;
}

export interface ISite extends IMenu {
    latitude: number;
    longitude: number;
    transportPrice?: number;
    manager: string;
    managerPhone: string;
    managerEmail: string;
    address: string;
    phone: string;
    updatable?: boolean;
    deletable?: boolean;
}

export interface IProductAdd {
    wishlistId: number;
    wishlistProduct: {
        quantity: number;
        insurance: boolean;
        operator: boolean;
        sundayInclude: boolean;
        saturdayInclude: boolean;
        optionsFlags: number;
        isHourlyRentable: boolean;
        product: {
            id: number;
        };
        rentFrom: string;
        rentTo: string;
    };
}

export interface IPartnerProduct {
    id: number;
    productId?: number;
    dailyPrice: number;
    referenceNumber: string;
    name: string;
    brand?: string;
    dataSheetUrl?: string;
}

export interface IDashBoardPartnerProduct {
    id?: number;
    defaultPrice: number;
    hasOperator: boolean;
    insuranceRate: number;
    defaultPriceWeek: number;
    defaultPriceMonth: number;
    name: string;
    brand: string;
    dataSheetUrl: string;
    hourlyRentable: boolean;
    product: {
        id: number;
        label?: string;
        translatedLabels?: { [id: string]: string };
    };
    referenceNumber: string;
    storagePlace: {
        id: number;
        name?: string;
    };
    insuranceEnd: string;
    luxControlEnd: string;
    booking: boolean;
}

export interface IWish {
    id: number;
    quantity: number;
    product: IProduct;
    site: ISite;
    rentFrom: string;
    rentTo: string;
    insurance: boolean;
    operator: boolean;
    transport: boolean;
    saturdayInclude: boolean;
    sundayInclude: boolean;
    optionsFlags: number;
    categoryCode: string;
}

export interface IBook extends IWish {
    partnerProducts: IPartnerProduct[];
}

export interface IWishList {
    id: number;
    site: ISite;
    wishlistProducts: IWish[];
    parentId: number;
}

export interface IBookList {
    bookedWishlistProducts: IBook[];
    missingWishlistProducts: IBook[];
    isValidUntil: string;
}

export interface IPartnerCustomerForbidden {
    partnerId: number;
    customerId: number;
}

export interface ICustomer {
    id: number;
    companyName: string;
    businessNumber: string;
    legalStatus: string;
    vatNumber: string;
    street: string;
    streetNumber: string;
    city: string;
    country: string;
    postalCode: string;
}

export interface IPartner extends ICustomer {
    savNumber: string;
    fee: number;
    bookingEmail: string;
    stripeAccountId: string;
    quoteMode: boolean;
}

export interface ICustomerRent {
    id: number;
    name: string;
    quantity: number;
    product?: IProduct;
    site: ISite;
    customer?: ICustomer;
    rentFrom: string;
    rentTo: string;
    insuranceNeeded: boolean;
    operatorNeeded: boolean;
    transportNeeded: boolean;
    saturdayInclude: boolean;
    sundayInclude: boolean;
    optionsFlags: number;
    partner: string;
    partnerProduct: IPartnerProduct;
    deliveredAt: string;
    returnedAt: string;
    stripePayment?: string;
    totalPrice: number;
    orderId: string;
    status: string;
    billRents?: ICustomerBill[];
    rentId?: number;
    active?: boolean;
}

export interface ICustomerRentList {
    id: number;
    productRents: ICustomerRent[];
    billRents: ICustomerBill[];
    createdAt: string;
    active: boolean;
}

export interface ICustomerBill {
    id: number;
    url: string;
    partner: string;
    dateExp: string;
    totalPrice: number;
    kamatInvoiceUrl: string;
}

export interface ICustomerFlatRent {
    name: string;
    site: string;
}

export interface ISimilarProduct {
    id: number;
    referenceNumber: string;
    name: string;
    dailyPrice: number;
    brand: string;
    partner: string;
}

export interface IBookingListCard {
    id: number;
    price: number;
    image: string;
    label: string;
    rentFrom: string;
    rentTo: string;
    transport: boolean;
    insurance: boolean;
    referenceNumber: string;

    handleClick?(): void;

    handleRemoveClick?(): void;
}

export interface IBookingCard {
    id: number;
    price: number;
    image: string;
    label: string;
    rentFrom: string;
    rentTo: string;
    transport: boolean;
    insurance: boolean;
    referenceNumber: string;
    brand: string;
    dailyPrice: number;
    productId: number;
    partner: string;
}

export interface IWishListCard {
    id: number;
    image: string;
    label: string;
    rentFrom: string;
    rentTo: string;
    insurance: boolean;
    quantity: number;
    saturdayInclude: boolean;
    sundayInclude: boolean;
    optionsFlags: number;
    operator: boolean;
    product: IProduct;
    categoryCode: string;
    site: ISite;

    handleClick(form: IWish): Promise<unknown>;

    handleRemoveClick?(): void;
}

export interface IMissingProductCard {
    id: number;
    image: string;
    label: string;
    rentFrom: string;
    rentTo: string;
    transport: boolean;
    insurance: boolean;
    quantity?: number;
}

export interface IPriceCalculus {
    subTotal: number;
    delivery: number;
    insurance: number;
    totalPrice: number;
}

export interface IWebhook {
    id: number;
    url: string;
    tracking: "PRODUCT_RENT" | "PRODUCT_RENT_CANCELLED" | "PRODUCT_RENT_EXPIRING";
}

export interface User {
    id: string,
    first_name: string,
    last_name: string,
    fee: number | null,
    access_token?: string,
    sav_phone: string | null,
    society_legal_status: string | null,
    society_location_address: string | null,
    society_location_city: string | null,
    society_location_country: string | null,
    society_location_zipcode: string | null,
    society_name: string | null,
    society_vat_number: string | null
}

export interface IProfile {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string;
    role: string;
    canWantsBills: boolean;
    tcVersion: number;
    tcAcceptedVersion?: number;
    customer?: ICustomer;
    partner?: IPartner;
    fee?: number
}

export interface Transaction {
    Id: string,
    UserId: number,
    Type: string,
    Date: string,
    Amount: string,
    Fees: string,
    Balance: string,
    Currency: string,
    PayoutId?: string
}

export interface Payout {
    Id: string,
    UserId: number,
    Status: "captured" | string,
    Amount: number,
    Currency: string,
    Iban: string,
    Bic: string,
    Date: string,
    Reason: string
}

export enum LoginState {
    PENDING,
    PARTIALLY_LOGGED,
    LOGGED,
    FAILED
}

export enum OldRentState {
    TO_DELIVER,
    DELIVERED,
    TO_GET_BACK,
    FINISHED
}

export type ITableNavigation = {
    [key in Exclude<OldRentState, OldRentState.FINISHED>]: string;
};

export interface INotification {
    id: number,
    messageKey: string,
    rawText: string,
    read: boolean,
    targetId: 0,
    titleKey: string
}

export enum BillCategory {
    ALL,
    N1,
    N2,
    N3
}

export interface ITablePagination {
    onNextPage: () => void,
    length: number,
    hasMore: boolean,
}

export enum Energy {
    DIESEL = "diesel",
    ELECTRIC = "electric",
    HYBRID = "hybrid"
}

export enum ProductOptions {
    BRH = 1,
    GODET = 2,
    TREUIL = 4,
    PANIER_NACELLE = 8,
    POTENCE = 16
}

export interface IProductBaseInfo {
    attributes: {
        [key: string]: string | number | boolean,
    }
    code: string,
    description: string,
    id: number,
    image: string,
    isHourlyRentable: boolean,
    label: string,
    translatedDescriptions: {
        [key: string]: string
    }
    translatedLabels: {
        [key: string]: string
    }
}

export interface IProductNotFound {
    id: number,
    insurance: boolean,
    operator: boolean,
    optionsFlags: number,
    product: IProductBaseInfo,
    quantity: number,
    rentFrom: string,
    rentTo: string,
    saturdayInclude: boolean,
    sundayInclude: boolean,
    transport: boolean
}

export interface IOfferRequest {
    conditions: string,
    createdAt: string,
    grossPrice: number,
    id: number,
    rentNotFoundId: number,
    totalPrice: number,
    transportPrice: number,
    insurancePrice: number,
    validTo: string,
    partner: ICustomer & IPartner,
    partnerProducts?: IDashBoardPartnerProduct[],
    partnerProductIds: number[] | null,
    partnerProductProductNotFoundIds: number[],
    machines: string[],
    machinesProductNotFoundIds: number[]
}

export interface IOffer {
    createdAt: string,
    id: number,
    productNotFounds: IProductNotFound[],
    rentNotFoundOffers: IOfferRequest[] | null,
    site: ISite,
    userName: string
}

export interface FaqConfig {
    question: string,
    answer: string | string[]
}