import styles from "./loader.module.scss";
import {HTMLAttributes} from "react";
import classNames from "classnames";
import {omit} from "../../utils/ObjectUtils";

interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
    size?: number,
    border?: number,
    type?: "dark" | "primary" | "white"
}

export default function Loader(props: LoaderProps) {
    const commonStyle = {
        width: props.size ?? 18,
        height: props.size ?? 18
    };

    return <div
      className={classNames(styles.loader, props.className, props.type === "dark" ? styles.loaderDark : (props.type === "primary" ? styles.loaderPrimary : undefined))}
      {...omit(props, "className", "size", "border", "style", "type")}
      style={{
          ...(props.style ?? {}),
          ...commonStyle
      }}>
        <div style={{
            ...commonStyle,
            borderWidth: props.border ?? 3
        }}/>
    </div>;
}