import {forwardRef, HTMLAttributes, SyntheticEvent, useEffect, useImperativeHandle, useRef, useState} from "react";
import styles from "./uploader.module.scss";
import classNames from "classnames";
import {getUniqKey} from "../../utils/NumberUtils";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Media, UploadFileParams} from "../../interfaces/Interfaces";
import Api from "../../classes/Api";
import {omit} from "../../utils/ObjectUtils";

interface UploaderProps extends HTMLAttributes<HTMLInputElement> {
    title?: string,
    media?: Media,
    onFileChange?: (file: File | null) => void,
    disabled?: boolean,
    label?: string,
}

export interface UploaderRef {
    getFile: () => File | null,
    upload: (params ?: UploadFileParams) => Promise<Media | null>,
}

// eslint-disable-next-line react/display-name
const Uploader = forwardRef<UploaderRef, UploaderProps>((props, ref) => {
    const {t} = useTranslation();

    const [file, setFile] = useState<File | null>(null);
    const [update, setUpdate] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const key = useRef("uploader_" + getUniqKey());

    useImperativeHandle(ref, () => ({
        getFile: () => file,
        upload: (p) => upload(p)
    }));

    useEffect(() => {
        setFile(null);
    }, [props.media]);

    useEffect(() => {
        if (props.onFileChange) {
            props.onFileChange(file);
        }
    }, [file]);

    const getClasses = (): string => {
        const classes: string[] = ["km-input", styles.uploader];

        if (props.className !== undefined) {
            classes.push(props.className);
        }

        if (props.title !== undefined) {
            classes.push(styles.uploaderTitled);
        }

        return classes.join(" ");
    };

    const onChange = (e: SyntheticEvent) => {
        const files = (e.target as HTMLInputElement).files;

        if (files === null || files[0] === undefined) {
            return;
        }

        setFile(files[0]);
    };

    const getLabel = () => {
        if (props.media !== undefined && file === null && !deleted) {
            return <label htmlFor={key.current} className={styles.uploaderLabelExisted}>
                {props.media.filename_download}
            </label>;
        } else if (file === null) {
            return <label htmlFor={key.current} className={styles.uploaderLabelEmpty}>
                {props.label ?? t("Sélectionner un fichier")}
            </label>;
        }

        return <label htmlFor={key.current}>
            {file.name}
        </label>;
    };

    const canDelete = () => {
        return file !== null || (props.media !== undefined && !deleted);
    };

    const canPreview = () => {
        return file === null && props.media !== undefined && !deleted;
    };

    const onDelete = () => {
        if (props.media !== undefined && file === null && !deleted) {
            Api.delete(`/files/${props.media.id}`).then(() => {
                //deleted
            });
            setDeleted(true);
            return;
        }

        if (file === null) {
            return;
        }

        setFile(null);
        setUpdate(getUniqKey());
    };

    const upload = (params ?: UploadFileParams): Promise<Media | null> => {
        return new Promise((resolve, reject) => {
            //have media and its deletion
            if (props.media !== undefined && deleted && file === null) {
                resolve(null);
                return;
            }

            //not have media and haven't file
            if (props.media === undefined && file === null) {
                resolve(null);
                return;
            }

            if (file !== null) {
                const data = new FormData();

                if (params !== undefined) {
                    (Object.keys(params) as (keyof UploadFileParams)[]).forEach(field => {
                        data.append(field as string, ("" + params[field]) as string);
                    });
                }

                data.append("file", file);

                Api.post<{
                    data: Media
                }>("/files", data, {
                    headers: {
                        "Content-Type": null
                    }
                }).then(d => {
                    resolve(d.data);
                }).catch(reject);
                return;
            }

            if (props.media !== undefined) {
                resolve(props.media);
            }
        });
    };

    const restProps = omit(props, "media", "className", "title", "onFileChange");

    return <div className={getClasses()} {...restProps}>
        {props.title && <span className={"title"}>{props.title}</span>}
        <div className={classNames("km-input-uploader", styles.uploaderContainer)}>
            <FontAwesomeIcon icon={["fas", "upload"]} className={styles.uploaderIcon}/>
            <input type={"file"} onChange={onChange} id={key.current} key={update} disabled={props.disabled}/>

            {getLabel()}

            {canPreview() &&
                <a href={Api.getUrl(`/assets/${(props.media as Media).id}`, true)} target={"_blank"} rel={"noreferrer"}
                   className={styles.uploaderPreview}>
                    <FontAwesomeIcon
                        icon={["fas", "arrow-up-right-from-square"]}/>
                </a>}

            {canDelete() && <FontAwesomeIcon icon={["fas", "trash-can"]} className={styles.uploaderDelete}
                                             onClick={onDelete}/>}
        </div>
    </div>;
});

export default Uploader;