import styles from "./cartAddForm.module.scss";
import DatePicker from "../../../../common/DatePicker/DatePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {HTMLAttributes, ReactNode, useRef, useState} from "react";
import classNames from "classnames";
import {omit} from "../../../../utils/ObjectUtils";
import useCart from "../../../../hooks/useCart";
import moment from "moment";
import {AllOfferItemOption, CatalogProduct, OfferItem, OfferItemOption} from "../../../../interfaces/Interfaces";
import useProductOptions from "../../../../hooks/useProductOption";

interface CartAddFormProps extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    product: CatalogProduct,
    onAdd?: () => void,
    button?: {
        label?: string,
        className?: string
    },
    footer?: {
        className?: string,
        nodes?: ReactNode
    }
}

export default function CartAddForm(props: CartAddFormProps): JSX.Element {
    const {addItem} = useCart();
    const container = useRef<HTMLDivElement>(null);

    const [error, setError] = useState<string | null>(null);
    const [globalOptions, setGlobalOptions] = useState<Partial<OfferItem>>({});
    const [options, setOptions] = useState<OfferItemOption[]>([]);
    const [date, setDate] = useState<{
        start: number | null,
        end: number | null
    }>({
        start: null,
        end: null
    });

    const optionLabels = useProductOptions();

    const addToCart = () => {
        if (props.product === null) {
            return;
        }

        if (date.start === null || date.end === null) {
            setError("Vous devez spécifier vos dates de location");
            return;
        }

        if (date.start > date.end) {
            setError("La date de début ne peut pas être supérieure à la date de fin");
            return;
        }

        if (date.start < moment().startOf("day").valueOf()) {
            setError("La date de début ne peux pas être inférieure à la date actuelle");
            return;
        }

        setError(null);
        addItem({
            ...globalOptions,
            product: props.product.id as number,
            quantity: 1,
            options,
            date: {
                start: date.start as number,
                end: date.end as number
            }
        }).then(() => {
            if (props.onAdd) {
                props.onAdd();
            }
        });
    };

    const getOptions = () => {
        if (!Array.isArray(props.product.options) || props.product.options.length === 0) {
            return;
        }

        const handleOpt = (value: boolean, key: OfferItemOption) => {
            if (value) {
                setOptions([...options, key]);
            } else {
                setOptions(options.filter(opt => opt !== key));
            }
        };

        return props.product.options.map(opt => <div key={opt} className={"km-input-switch"}>
            <input type={"checkbox"} id={"opt_" + opt} onChange={e => handleOpt(e.target.checked, opt)}/>
            <label htmlFor={"opt_" + opt}>{optionLabels[opt]}</label>
        </div>);
    };

    const getDefaultOptions = () => {
        const handleOpt = (value: boolean, key: keyof OfferItem) => {
            setGlobalOptions({
                ...globalOptions,
                [key]: value
            });
        };

        const opt: AllOfferItemOption[] = ["saturday_include", "sunday_include", "operator"];

        return opt.map(opt => <div key={opt} className={"km-input-switch"}>
            <input type={"checkbox"} id={"opt_" + opt}
                   onChange={e => handleOpt(e.target.checked, opt as keyof OfferItem)}/>
            <label htmlFor={"opt_" + opt}>{optionLabels[opt]}</label>
        </div>);
    };

    return <div
      className={classNames(styles.cartAddForm, props.className)} {...omit(props, "className", "onAdd", "className", "footer", "button")}
      ref={container}>
        {error !== null && <div className={styles.cartAddFormError}>
            {error}
        </div>}

        <div className={styles.cartAddFormRange}>
            <div className={styles.cartAddFormRangeInput}>
                <DatePicker placeholder={"Date de début"}
                            value={date.start ?? undefined} onChange={d => setDate({
                    ...date,
                    start: d as number
                })}
                            getDropdownContainer={() => container.current as HTMLDivElement}
                            className={styles.cartAddFormRangeInputPicker}
                            minDate={moment().add(1, "day").startOf("day")}/>
                <FontAwesomeIcon icon={["fas", "calendar-days"]}/>
            </div>
            <div className={styles.cartAddFormRangeInput}>
                <DatePicker placeholder={"Date de fin"}
                            getDropdownContainer={() => container.current as HTMLDivElement}
                            minDate={moment().add(1, "day").startOf("day")}
                            value={date.end ?? undefined} onChange={d => setDate({
                    ...date,
                    end: d as number
                })}
                            className={styles.cartAddFormRangeInputPicker}/>
                <FontAwesomeIcon icon={["fas", "calendar-days"]}/>
            </div>
        </div>

        <div className={styles.cartAddFormOptions}>
            {getDefaultOptions()}
            {getOptions()}
        </div>

        <div className={props.footer?.className}>
            <button onClick={addToCart} className={props.button?.className ?? styles.cartAddFormBtn}>
                {props.button?.label ?? "Ajouter"}
            </button>

            {props.footer?.nodes}
        </div>
    </div>;
}