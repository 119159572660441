import {config} from "../configs";
import {Nullable} from "../interfaces/Interfaces";

export type ApiSearchValue = string | boolean | number;
export type ApiFilter = {
    [key: string]: ApiSearchValue | ApiFilter | ApiFilter[]
}

interface Headers {
    [key: string]: string | null
}

export interface ApiRequestConfig {
    credentials?: boolean,
    ignoreAuthState?: boolean,
    includeToken?: boolean,
    headers?: Headers,
    fields?: string[],
    queries?: string[],
    sort?: string[],
    limit?: number,
    offset?: number,
    meta?: {
        total_count?: boolean,
        filter_count?: boolean
    }
}

export interface ApiError {
    message: string,
    extensions: {
        code: string,
        field?: string
    }
}

export default class Api {

    private static token ?: string;
    private static refreshTokenHandler ?: () => Promise<undefined>;
    private static tokenValidity ?: number | null;

    public static setAuthToken(token: string): void {
        this.token = token;
    }

    public static setAuthRenewTokenHandler(handler: () => Promise<undefined>): void {
        this.refreshTokenHandler = handler;
    }

    public static setTokenValidity(validity: number): void {
        this.tokenValidity = validity;
    }

    public static resetAuthToken(): void {
        this.token = undefined;
    }

    public static getUrl(url: string, includeToken ?: boolean): string {
        const builtUrl: string[] = [];
        const apiUrl = config.apiUrl as string;

        if (apiUrl.endsWith("/")) {
            builtUrl.push(apiUrl.slice(0, -1));
        } else {
            builtUrl.push(apiUrl);
        }

        if (url.startsWith("/")) {
            builtUrl.push(url.slice(1));
        } else {
            builtUrl.push(url);
        }

        let s = builtUrl.join("/");

        if (includeToken && typeof this.token === "string") {
            s += (s.includes("?") ? "&" : "?") + "access_token=" + this.token;
        }

        return s;
    }

    private static async call(url: string, method: string, data: {}, requestConfig?: ApiRequestConfig): Promise<Response> {
        if (requestConfig?.ignoreAuthState !== true && this.refreshTokenHandler !== undefined && this.tokenValidity !== undefined && this.tokenValidity !== null && this.tokenValidity <= (new Date()).valueOf()) {
            try {
                await this.refreshTokenHandler();
            } catch (e) {
                return new Promise((resolve, reject) => reject());
            }
        }

        const headers = new Headers();
        headers.set("Content-Type", "application/json");

        const init: RequestInit = {
            method: method,
        };

        if (requestConfig?.headers !== undefined) {
            Object.keys(requestConfig.headers).forEach(k => {
                const v = ((requestConfig as ApiRequestConfig).headers as Headers)[k];

                if (v === null) {
                    headers.delete(k);
                    return;
                }

                headers.set(k, v);
            });
        }

        if (requestConfig?.credentials !== false) {
            init.credentials = "include";
        }

        if (data instanceof FormData) {
            init.body = data;
        } else if (Object.keys(data).length > 0) {
            init.body = JSON.stringify(data);
        }

        if (this.token !== undefined && requestConfig?.includeToken !== false) {
            headers.set("Authorization", `Bearer ${this.token}`);
        }

        init.headers = headers;

        const queries: string[] = [];

        if (requestConfig?.fields !== undefined) {
            const fields = requestConfig.fields.map(s => `fields[]=${s}`).join("&");
            queries.push(fields);
        }

        if (requestConfig?.sort !== undefined) {
            queries.push("sort=" + requestConfig.sort.join(","));
        }

        if (requestConfig?.queries !== undefined) {
            queries.push(requestConfig.queries.join("&"));
        }

        if (requestConfig?.meta !== undefined) {
            const meta: string[] = [];

            if (requestConfig.meta.total_count) {
                meta.push("total_count");
            }

            if (requestConfig.meta.filter_count) {
                meta.push("filter_count");
            }

            queries.push(meta.map(m => `meta=${m}`).join("&"));
        }

        if (requestConfig?.limit !== undefined) {
            queries.push(`limit=${requestConfig.limit}`);
        }

        if (requestConfig?.offset !== undefined) {
            queries.push(`offset=${requestConfig.offset}`);
        }

        if (queries.length > 0) {
            url += (url.includes("?") ? "&" : "?") + queries.join("&");
        }

        return fetch(this.getUrl(url), init);
    }

    private static async handleResponse<T>(response: Response): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            if (response.status === 200 || response.status === 204) {
                if (response.status === 204) {
                    resolve({} as T);
                    return;
                }

                let responseData: T;

                try {
                    responseData = JSON.parse(await response.text());
                } catch (e) {
                    responseData = {} as T;
                }

                resolve(responseData);
                return;
            }

            try {
                const error = JSON.parse(await response.text());

                if (typeof error === "object" && error.hasOwnProperty("errors") && Array.isArray(error["errors"])) {
                    const directusErrors: ApiError[] = (error["errors"] as ApiError[]).filter(e => typeof e === "object" && e.hasOwnProperty("message") && e.hasOwnProperty("extensions") && typeof e["extensions"] === "object" && e["extensions"].hasOwnProperty("code"));

                    if (directusErrors.length > 0) {
                        reject(directusErrors);
                        return;
                    }
                }

                reject();
            } catch (e) {
                reject(e);
            }
        });
    }

    public static post<T>(url: string, data ?: {}, config?: ApiRequestConfig): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                resolve(await this.handleResponse(await this.call(url, "POST", data ?? {}, config)));
            } catch (e) {
                reject(e);
            }
        });
    }

    public static patch<T>(url: string, data ?: {} | Nullable<Partial<T>>, config?: ApiRequestConfig): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                resolve(await this.handleResponse(await this.call(url, "PATCH", data ?? {}, config)));
            } catch (e) {
                reject(e);
            }
        });
    }

    public static delete<T>(url: string, config?: ApiRequestConfig): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                resolve(await this.handleResponse(await this.call(url, "DELETE", {}, config)));
            } catch (e) {
                reject(e);
            }
        });
    }

    public static get<T>(url: string, filter?: {}, config?: ApiRequestConfig): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                const queries: string[] = [];

                if (filter !== undefined && Object.keys(filter).length > 0) {
                    try {
                        queries.push(`filter=${JSON.stringify(filter)}`);
                    } catch (e) {
                    }
                }

                if (queries.length > 0) {
                    url += (url.includes("?") ? "&" : "?") + queries.join("&");
                }

                resolve(await this.handleResponse(await this.call(url, "GET", {}, config)));
            } catch (e) {
                reject(e);
            }
        });
    }

}