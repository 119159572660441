import styles from "./documentUploader.module.scss";
import {ReactNode, useContext, useEffect, useMemo, useState} from "react";
import Modal from "../../../../../../common/Modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/free-solid-svg-icons";
import {DefaultType, KycDocument, KycDocumentType} from "../../../../../../interfaces/Interfaces";
import Uploader from "../../../../../../common/Uploader/Uploader";
import Button from "../../../../../../common/Button/Button";
import classNames from "classnames";
import {notifyError, notifySuccess} from "../../../../../../common/Notification/notification";
import Api from "../../../../../../classes/Api";
import AuthContext from "../../../../../../context/AuthContext";

export type DocumentType =
  "CI"
  | "CI_BIS"
  | "RIB"
  | "REGISTRATION_PROOF"
  | "ACTIVITY_PROOF"
  | "ARTICLES_OF_ASSOCIATION"
  | "SHAREHOLDER_DECLARATION";

export interface SubDocumentProps {
    label: string,
    typeLabel: string,
    data?: KycDocument[],
    type: KycDocumentType,
    verso?: KycDocumentType,
    side?: "recto" | "verso" | "both",
}

export interface DocumentUploaderProps {
    type?: DocumentType,
    secondary?: {
        type: KycDocumentType,
        label: string,
        typeLabel: string,
        versoType?: KycDocumentType,
        note?: string,
    },
    data?: KycDocument[],
    usedDocument?: KycDocumentType,
    onUpload?: (doc: KycDocument) => void,
    side?: "recto" | "verso" | "both" | KycDocumentType,
    versoBtnContent?: string,
    rectoLabel?: string,
    versoLabel?: string,
    onDelete?: () => void,
    subDocuments?: {
        actives?: KycDocumentType[],
        label: string,
        note?: string,
        addBtnLabel?: string,
        types: SubDocumentProps[]
    }
}

interface iConfig {
    label: string,
    icon?: IconName,
    note?: string,
    type: KycDocumentType
    verso?: () => boolean,
    versoKey?: () => KycDocumentType | null,
    types?: DefaultType<string>
}

export default function DocumentUploader(props: DocumentUploaderProps): JSX.Element {
    const {user} = useContext(AuthContext);
    const sideType = props.side ?? "both";

    const [type, setType] = useState<string | null>(null);
    const [upload, setUpload] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);

    const [verso, setVerso] = useState<File | null>(null);
    const [addVerso, setAddVerso] = useState(false);

    const [secondaryDocuments, setSecondaryDocuments] = useState<KycDocumentType[]>(props.subDocuments?.actives ?? []);

    useEffect(() => {
        reset();
    }, [props]);

    useEffect(() => {
        if (upload) {
            console.log(type);
        }
    }, [upload, type]);

    const reset = () => {
        setType(config?.type ?? null);
        setUpload(false);
        setFile(null);
        setLoading(false);
        setVerso(null);
        setAddVerso(false);
    };

    useEffect(() => {
        setFile(null);
        setVerso(null);
        setAddVerso(false);

        let types: KycDocumentType[] = [];

        if (config !== null) {
            types.push(config.type);

            if (config.types !== undefined) {
                types = [...types, ...Object.keys(config.types) as KycDocumentType[]];
            }

            //remove used documents
            if (props.usedDocument !== undefined) {
                types = types.filter(t => t !== props.usedDocument);
            }

            let typeToSet = config.type;

            if (types.length > 0) {
                typeToSet = types[0];
            }

            //custom overridden side
            if (["recto", "verso", "both"].indexOf(sideType) === -1) {
                typeToSet = sideType as KycDocumentType;
            }

            if (sideType === "verso") {
                typeToSet += "_BACK";
            }

            setType(typeToSet);
        }
    }, [upload]);

    const config = useMemo((): iConfig | null => {
        if (props.secondary !== undefined) {
            return {
                label: props.secondary.label,
                type: props.secondary.type,
                note: props.secondary.note,
                verso: () => props.secondary?.versoType !== undefined,
                versoKey: () => props.secondary?.versoType ?? null
            };
        }

        switch (props.type) {
            case "CI":
                return {
                    label: "1er justificatif d'identité",
                    type: "IDENTITY_PROOF",
                    icon: "id-card",
                    note: "Justificatif d'identité en cours de validité au nom du gérant",
                    verso: () => type === "IDENTITY_PROOF",
                    versoKey: () => type === "IDENTITY_PROOF" ? "IDENTITY_PROOF_BACK" : null,
                    types: {
                        IDENTITY_PROOF: "Carte d'identité",
                        PASSPORT: "Passeport",
                    }
                };
            case "CI_BIS":
                return {
                    label: "2ème justificatif d'identité",
                    type: "IDENTITY_PROOF",
                    icon: "id-card",
                    note: "Justificatif d'identité en cours de validité au nom du gérant",
                    verso: () => type === "IDENTITY_PROOF" || type === "DRIVER_LICENSE" || type === "RESIDENCE_PERMIT",
                    versoKey: () => {
                        if (type === "DRIVER_LICENSE") {
                            return "DRIVER_LICENSE_BACK";
                        } else if (type === "IDENTITY_PROOF") {
                            return "IDENTITY_PROOF_BACK";
                        } else if (type === "RESIDENCE_PERMIT") {
                            return "RESIDENCE_PERMIT_BACK";
                        }

                        return null;
                    },
                    types: {
                        IDENTITY_PROOF: "Carte d'identité",
                        PASSPORT: "Passeport",
                        DRIVER_LICENSE: "Permis de conduire",
                        RESIDENCE_PERMIT: "Titre de séjour",
                        TAX_NOTICE: "Dernier avis d'imposition (année en cours)"
                    }
                };
            case "RIB":
                return {
                    label: "Relevé d'identité bancaire",
                    type: "BANK_DETAILS",
                    icon: "bank",
                    note: "RIB officiel au nom de la société. Veillez à ce que ce RIB comporte le logo de votre établissement bancaire."
                };
            case "REGISTRATION_PROOF":
                return {
                    label: user.society_location_country === "LUX" ? "Extrait d'immatriculation" : "Extrait KBIS",
                    type: "REGISTRATION_PROOF",
                    icon: "clipboard",
                    note: (user.society_location_country === "LUX" ? "Extrait d'immatriculation du RCS" : "Extrait KBIS") + " de l'entreprise daté impérativement de moins de 3 mois."
                };
            case "ACTIVITY_PROOF":
                return {
                    label: "Justificatif d'activité",
                    type: "ACTIVITY_PROOF",
                    icon: "clipboard",
                    note: "Document d'enregistrement justifiant l'activité faisant apparaitre le numéro de SIRET (moins de 3 mois)"
                };
            case "ARTICLES_OF_ASSOCIATION":
                return {
                    label: "Statuts",
                    type: "ARTICLES_OF_ASSOCIATION",
                    icon: "file-contract",
                    note: "Derniers statuts complets de votre entreprise, signés, paraphés et certifiés conformes à l'original."
                };
            case "SHAREHOLDER_DECLARATION":
                return {
                    label: "Déclaration des actionnaires",
                    type: "SHAREHOLDER_DECLARATION",
                    icon: "address-book",
                    note: "Déclaration des actionnaires",
                    verso: () => true,
                    versoKey: () => "SHAREHOLDER_DECLARATION2",
                };
        }

        return null;
    }, [props, type]);

    const onClick = () => {
        if (config === null) {
            return;
        }

        if (props.data !== undefined && props.data.filter(d => d.Status === "invalid").length === 0) {
            return;
        }

        setUpload(true);
    };

    const getStatusIcon = () => {
        let color = "yellow";
        let icon: IconName = "ellipsis";
        let label = "à transmettre";
        const total = (props.data ?? []).length;

        if (props.data !== undefined) {
            //if all are valid
            if (props.data.filter(d => d.Status === "valid").length === total) {
                color = "green";
                icon = "check";
                label = "validé";
            } else if (props.data.filter(d => d.Status === "invalid").length > 0) {
                color = "red";
                icon = "triangle-exclamation";
                label = "invalide";
            } else if (props.data.filter(d => d.Status === "pending").length > 0) {
                color = "blue";
                icon = "hourglass";
                label = "vérification";
            }
        }

        return <div className={classNames(styles.documentUploaderStatus, color)}>
            <p>{label}</p> <FontAwesomeIcon icon={["fas", icon]}/>
        </div>;
    };

    const validate = () => {
        if (loading || file === null) {
            return;
        }

        const name = file.name.split(".");

        if (name.length < 2) {
            notifyError("Type de document envoyé non autorisé.");
            return;
        }

        setLoading(true);
        const files: File[] = [file];
        const documents: KycDocument[] = [];

        if (verso !== null && addVerso) {
            files.push(verso);
        }

        for (const f of files) {
            const extension = (f.name.split(".").pop() as string).toUpperCase();

            if (["PNG", "JPG", "PDF", "TIFF"].indexOf(extension) === -1) {
                notifyError("Seuls les fichiers de type PNG, JPG, PDF ou TIFF sont autorisés.");
                return;
            }
        }

        const onError = () => {
            notifyError("Une erreur est survenue lors du traitement du document");
            reset();
        };

        const treat = () => {
            const f = files.shift();

            if (f === undefined) {
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(f);
            reader.onload = () => {
                const isVerso = f === verso;
                let fileType = isVerso ? null : type;

                if (isVerso && config !== null && config.versoKey !== undefined) {
                    fileType = config.versoKey();
                }

                if (fileType === null) {
                    onError();
                    return;
                }

                Api.post<KycDocument>("/kamat/merchant/document", {
                    type: fileType,
                    content: reader.result,
                    extension: (f.name.split(".").pop() as string).toUpperCase()
                }).then(d => {
                    documents.push(d);

                    if (files.length === 0) {
                        notifySuccess("Document envoyé avec succès.");

                        documents.forEach(d => {
                            if (props.onUpload) {
                                props.onUpload(d);
                            }
                        });

                        reset();
                    } else {
                        treat();
                    }
                }).catch(() => {
                    onError();
                });
            };

            reader.onerror = () => {
                onError();
            };
        };

        treat();
    };

    const canSelectType = () => config !== null && config.types !== undefined && sideType === "both" && Object.keys(config.types).length > 1;

    const getComments = (): string[] => {
        return (props.data ?? []).map(d => {
            if (config === null || typeof d.Comment !== "string" || d.Comment.trim().length <= 2 || d.Status !== "invalid") {
                return null;
            }

            let name: string | null = null;

            if (config.types !== undefined) {
                name = config.types[d.DocumentType.replace("_BACK", "")] ?? null;
            } else if (props.secondary !== undefined) {
                name = props.secondary.typeLabel;
            }

            if (name !== null && d.DocumentType.endsWith("_BACK")) {
                name += " (verso)";
            }

            return (name !== null ? name + " : " : "") + d.Comment;
        }).filter(s => s !== null) as string[];
    };

    const getDocumentNote = () => {
        if (config === null) {
            return null;
        }

        if (sideType === "verso" || sideType === "recto") {
            let name: string | null = config.label;

            if (config.types !== undefined && type !== null) {
                name = config.types[type.replace("_BACK", "")] ?? null;
            }

            if (name === null) {
                return null;
            }

            return <p
              className={styles.documentUploaderWarning}>Merci de renvoyer ce document : {name} ({sideType}).</p>;
        }

        return null;
    };

    const getSecondaryDocuments = () => {
        if (props.subDocuments === undefined) {
            return null;
        }

        const addSecondaryType = () => {
            const available = (props.subDocuments?.types ?? []).filter(t => secondaryDocuments.indexOf(t.type) === -1).map(t => t.type);

            if (available.length >= 1) {
                setSecondaryDocuments([...secondaryDocuments, available.shift() as KycDocumentType]);
            }
        };

        const nodes: ReactNode = secondaryDocuments.map(t => {
            const conf = (props.subDocuments?.types ?? []).filter(c => c.type === t)[0];

            if (conf === null) {
                return null;
            }

            return <DocumentUploader key={t} secondary={{
                type: t,
                versoType: conf.verso,
                label: conf.label,
                typeLabel: conf.typeLabel
            }} onUpload={props.onUpload} data={conf.data} side={conf.side}
                                     onDelete={() => setSecondaryDocuments(secondaryDocuments.filter(d => d !== t))}/>;
        });

        return <div className={styles.documentUploaderSecondaryWrapper}>
            <p className={styles.documentUploaderSecondaryTitle}>{props.subDocuments.label}</p>
            {props.subDocuments.note !== undefined &&
                <p className={styles.documentUploaderSecondaryNote}>{props.subDocuments.note}</p>}

            {nodes}

            <Button type={"blue"} outline size={"small"} faIcon={["fas", "plus"]}
                    className={styles.documentUploaderSecondaryBtn}
                    onClick={addSecondaryType}
                    disabled={secondaryDocuments.length >= props.subDocuments.types.length}>
                {props.subDocuments.addBtnLabel ?? "Ajouter un document"}
            </Button>
        </div>;
    };

    const canDelete = () => {
        if (props.secondary === undefined) {
            return false;
        }

        return (props.data ?? []).filter(d => d.Status === "pending").length === (props.data ?? []).length;
    };

    if (config === null) {
        return null as unknown as JSX.Element;
    }

    return <div
      className={classNames(styles.documentUploader, props.secondary !== undefined ? styles.documentUploaderSecondary : "")}>
        <div className={styles.documentUploaderBody} onClick={onClick} style={{
            cursor: props.data === undefined || props.data.filter(d => d.Status === "invalid").length > 0 ? "pointer" : "default"
        }}>
            {config.icon !== undefined && <div className={styles.documentUploaderIcon}>
                <FontAwesomeIcon icon={["fas", config.icon]}/>
            </div>}
            <div className={styles.documentUploaderContent}>
                <p className={styles.documentUploaderTitle}>{config.label}</p>
                {getComments().map((c, i) => <p className={styles.documentUploaderError} key={i}>{c}</p>)}
                {canDelete() && <Button type={"danger"} size={"small"} outline faIcon={["fas", "trash"]} style={{
                    marginTop: 5,
                    padding: "0 20px"
                }} onClick={props.onDelete}>Retirer</Button>}
            </div>
            {getStatusIcon()}
        </div>

        {getSecondaryDocuments()}

        <Modal display={upload} onClose={() => setUpload(false)} className={styles.documentUploaderModal}
               key={upload ? 1 : 0}>
            <h2>Envoyer un document</h2>

            <p>{config.note}</p>
            {getDocumentNote()}

            {canSelectType() &&
                <div className={"km-input titled"} style={{
                    marginTop: 30
                }}>
                    <label>Type de documents</label>
                    <select onChange={e => setType(e.target.value)}>
                        {Object.keys(config.types as DefaultType<string>).filter(t => props.usedDocument === undefined ? true : props.usedDocument !== t).map(k =>
                          <option key={k} value={k}>
                              {(config?.types as DefaultType<string>)[k as keyof DefaultType<string>] ?? ""}
                          </option>)}
                    </select>
                </div>}

            {addVerso && <label style={{
                marginTop: 25
            }}>{props.rectoLabel ?? "Recto"}</label>}

            <Uploader style={{
                marginTop: addVerso ? 3 : 30
            }} onFileChange={setFile}/>

            {addVerso && <>
                <label style={{
                    marginTop: 14
                }}>{props.versoLabel ?? "Verso"}</label>
                <Uploader style={{
                    marginTop: 3
                }} onFileChange={setVerso}/>
            </>}

            {config.verso !== undefined && config.verso() && !addVerso && sideType === "both" && <>
                <Button size={"small"} outline style={{
                    width: "100%",
                    marginTop: 30
                }} faIcon={["fas", "plus"]} type={"gray"}
                        onClick={() => setAddVerso(true)}>{props.versoBtnContent ?? "Ajouter un verso"}</Button>
                <p className={styles.documentUploaderModalVersoHelper}>Uniquement si le premier document n'est pas complet</p>
            </>}

            <Button faIcon={["fas", "check"]} type={"blue"} style={{
                width: "100%",
                marginTop: 30
            }} disabled={file === null} onClick={validate} loading={loading}>Valider</Button>
        </Modal>
    </div>;
}