import styles from "./catalogLayout.module.scss";
import {useTranslation} from "react-i18next";
import {ReactNode, SyntheticEvent, useContext, useRef} from "react";
import searchLogo from "../../assets/loupe.svg";
import {RentalCatalogContext} from "../../pages/RentalCatalog/RentalCatalog";
import {useHistory, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CartSidebar from "../../pages/RentalCatalog/components/CartSidebar/CartSidebar";
import { Fragment } from "react";
import classNames from "classnames";
import useCart from "../../hooks/useCart";

interface ICatalogLayoutProps {
    promotion: ReactNode,
}

export const CatalogLayout: React.FunctionComponent<ICatalogLayoutProps> = ({
                                                                                children,
                                                                                promotion,
                                                                            }) => {
    const {t} = useTranslation();
    const {breadcrumb, setSearch} = useContext(RentalCatalogContext);
    const {isCartFilled} = useCart();

    const searchHandler = useRef<number | null>(null);
    const location = useLocation();
    const history = useHistory();

    const onBreadcrumbClick = (i: number) => {
        if (breadcrumb.length === 1) {
            return;
        }

        if (breadcrumb.length === (i + 1)) {
            return;
        }

        const path = "/rentals/" + [...breadcrumb].slice(0, -1).map(b => b.slug).join("/");
        history.push(path);
    };

    const onSearch = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;

        if (searchHandler.current !== null) {
            clearTimeout(searchHandler.current);
        }

        if (value.trim().length < 3) {
            setSearch(null);
            return;
        }

        searchHandler.current = setTimeout(() => {
            setSearch(value);
        }, 350) as unknown as number;
    };

    return <section
      className={styles.catalog}
    >
        <div className={styles.catalogHeader}>
            <h1>{t("Catalogue des locations")}</h1>
            <Fragment key={location.key}>
                {promotion}
            </Fragment>
        </div>

        <div className={styles.catalogWrapper}>
            <div className={styles.catalogBody}>
                <div className={styles.catalogBodyTopBar}>
                    <div className={styles.catalogBodyTopBarSearch}>
                        <img src={searchLogo} alt=""/>
                        <input placeholder={"Chercher votre équipement dans notre catalogue..."} onChange={onSearch}/>
                    </div>

                    {breadcrumb.length > 0 && <ul>
                        <li onClick={() => history.push("/rentals")} className={styles.catalogBodyTopBarHome}>
                            <FontAwesomeIcon icon={["fas", "house"]}/>
                        </li>

                        {breadcrumb.map((b, i) => <li key={b.slug}
                                                      className={i === 0 && (i + 1 < breadcrumb.length) ? styles.catalogBodyTopBarLink : undefined}
                                                      onClick={() => onBreadcrumbClick(i)}>
                            {b.label}
                        </li>)}
                    </ul>}
                </div>

                <div className={classNames(styles.catalogBodyGrid, isCartFilled ? styles.catalogBodyGridWithCart : undefined)}>
                    {children}
                </div>
            </div>

            <CartSidebar/>
        </div>
    </section>;
};
