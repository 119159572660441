import Modal from "common/Modal/Modal";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import AuthContext from "../../../../context/AuthContext";
import {Formik} from "formik";
import Button from "../../../../common/Button/Button";
import {Countries, User} from "../../../../interfaces/Interfaces";
import styles from "./societyInformationModal.module.scss";
import Api from "../../../../classes/Api";
import {notifyError, notifySuccess} from "../../../../common/Notification/notification";
import {pick} from "../../../../utils/ObjectUtils";
import {countries} from "../../../../configs";

interface UserInformationModalProps {
    display?: boolean,
    onClose?: () => void,
}

export default function SocietyInformationModal(props: UserInformationModalProps): JSX.Element {
    const {user, setUser} = useContext(AuthContext);
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }

        setSaving(false);
    };

    const onSave = (v: Partial<User>) => {
        setSaving(true);

        Api.patch<{
            data: User
        }>("/users/me", pick(v,
          "society_identification",
          "society_name",
          "society_legal_status",
          "society_vat_number",
          "society_location_address",
          "society_location_city",
          "society_location_zipcode",
          "society_location_country",
          "sav_phone"
        )).then(v => {
            setUser({
                ...user,
                ...v.data
            });

            notifySuccess(t("Informations modifiées avec succès."));
        }).catch(() => {
            notifyError(t("Une erreur est survenue lors de la modification des informations."));
        }).finally(() => {
            setSaving(false);
            onClose();
        });
    };

    return <Modal onClose={onClose} display={props.display} className={styles.userInformationModal}>
        <Formik
          onSubmit={onSave}
          key={props.display ? 1 : 0}
          initialValues={user}
        >
            {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  touched,
                  values,
                  isValid,
                  errors,
              }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                  <h4>{t("Ma société")}</h4>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Dénomination de la société")}</label>
                      <input
                        className={touched.society_name && errors.society_name ? "is-invalid" : undefined}
                        type="text"
                        value={values.society_name ?? ""}
                        name="society_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.society_name && touched.society_name &&
                          <div className="invalid-feedback">{errors.society_name}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Numéro d'identification")}</label>
                      <input
                        className={touched.society_identification && errors.society_identification ? "is-invalid" : undefined}
                        type="text"
                        value={values.society_identification ?? ""}
                        name="society_identification"
                        placeholder={"SIREN, SIRET, numéro d'enregistrement..."}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.society_identification && touched.society_identification &&
                          <div className="invalid-feedback">{errors.society_identification}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Forme légale")}</label>
                      <input
                        className={touched.society_legal_status && errors.society_legal_status ? "is-invalid" : undefined}
                        type="text"
                        value={values.society_legal_status ?? ""}
                        name="society_legal_status"
                        placeholder={"SAS, SA, Sàrl..."}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.society_legal_status && touched.society_legal_status &&
                          <div className="invalid-feedback">{errors.society_legal_status}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Numéro de TVA intracommunautaire")}</label>
                      <input
                        className={touched.society_vat_number && errors.society_vat_number ? "is-invalid" : undefined}
                        type="text"
                        value={values.society_vat_number ?? ""}
                        name="society_vat_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.society_vat_number && touched.society_vat_number &&
                          <div className="invalid-feedback">{errors.society_vat_number}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Adresse")}</label>
                      <input
                        className={touched.society_location_address && errors.society_location_address ? "is-invalid" : undefined}
                        type="text"
                        value={values.society_location_address ?? ""}
                        name="society_location_address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.society_location_address && touched.society_location_address &&
                          <div className="invalid-feedback">{errors.society_location_address}</div>}
                  </div>

                  <div className="row" style={{
                      marginTop: 25
                  }}>
                      <div className="km-input titled required col-6">
                          <label>{t("Ville")}</label>
                          <input
                            className={touched.society_location_city && errors.society_location_city ? "is-invalid" : undefined}
                            type="text"
                            value={values.society_location_city ?? ""}
                            name="society_location_city"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.society_location_city && touched.society_location_city &&
                              <div className="invalid-feedback">{errors.society_location_city}</div>}
                      </div>

                      <div className="km-input titled required col-6">
                          <label>{t("Code postal")}</label>
                          <input
                            className={touched.society_location_zipcode && errors.society_location_zipcode ? "is-invalid" : undefined}
                            type="text"
                            value={values.society_location_zipcode ?? ""}
                            name="society_location_zipcode"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {errors.society_location_zipcode && touched.society_location_zipcode &&
                              <div className="invalid-feedback">{errors.society_location_zipcode}</div>}
                      </div>
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Numéro de téléphone du SAV")}</label>
                      <input
                        className={touched.sav_phone && errors.sav_phone ? "is-invalid" : undefined}
                        type="text"
                        value={values.sav_phone ?? ""}
                        name="sav_phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      {errors.sav_phone && touched.sav_phone &&
                          <div className="invalid-feedback">{errors.sav_phone}</div>}
                  </div>

                  <div className="km-input titled required km-mt-25">
                      <label>{t("Pays")}</label>
                      <select
                        className={touched.society_location_country && errors.society_location_country ? "is-invalid" : undefined}
                        name={"society_location_country"} onChange={handleChange} onBlur={handleBlur}>
                          {Object.keys(countries).map(k => <option key={k}
                                                                   selected={(countries as Countries)[k].alpha_3 === values.society_location_country}
                                                                   value={(countries as Countries)[k].alpha_3}>{(countries as Countries)[k].name}</option>)}
                      </select>

                      {errors.society_location_country && touched.society_location_country &&
                          <div className="invalid-feedback">{errors.society_location_country}</div>}
                  </div>

                  <div className={"km-flex-middle km-space-between"} style={{
                      marginTop: 50
                  }}>
                      <p className={"required-legend"}>{t("Champs obligatoires")}</p>
                      <Button disabled={!isValid} type={"blue"} loading={saving}>
                          {t("Sauvegarder")}
                      </Button>
                  </div>
              </form>)}
        </Formik>
    </Modal>;
}