import {createContext, Dispatch} from "react";
import {Offer, Quote} from "../interfaces/Interfaces";

interface iOfferContext {
    requests: Offer[],
    setRequests: Dispatch<Offer[]>,
    sent: Quote[],
    setSent: Dispatch<Quote[]>,
    received: Quote[],
    setReceived: Dispatch<Quote[]>,
    loading: boolean,
    reload: () => void,
}

const OfferContext = createContext<iOfferContext>({} as iOfferContext);
export default OfferContext;