import i18n from "../../../../i18n";
import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./userInformation.module.scss";
import {ChangeModal} from "./ResetModal/ChangeModal";
import AuthContext from "../../../../context/AuthContext";
import {LoginState} from "../../../../interfaces/Interfaces";
import {ReactComponent as LogoutLogo} from "./assets/logout.svg";
import Select from "react-select";
import classNames from "classnames";
import editIcon from "./assets/edit.svg";
import Api from "../../../../classes/Api";
import {useHistory} from "react-router-dom";
import UserInformationModal from "../UserInformationModal/UserInformationModal";
import UserPasswordModal from "../UserPasswordModal/UserPasswordModal";
import {useCookies} from "react-cookie";

export default function UserInformation(): JSX.Element {
    const {t} = useTranslation();
    const [, , removeCookie] = useCookies();
    const {setLoginState} = useContext(AuthContext);
    const languages: {
        [key: string]: string
    } = {
        fr: "Français",
        en: "Anglais"
    };

    const {user} = useContext(AuthContext);

    const [appLanguage, setAppLanguage] = useState(i18n.language);
    const [showModal, setShowModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [password, setPassword] = useState(false);

    const history = useHistory();

    const getAvailableLanguages = (): {
        label: string,
        value: string
    }[] => {
        return Object.keys(languages).map(locale => ({
            label: languages[locale] as string,
            value: locale
        }));
    };

    const handleLogOut = () => {
        Api.post("/auth/logout").then(() => {
            Api.resetAuthToken();
            setLoginState(LoginState.FAILED);
            removeCookie("directus_refresh_token");
            history.push("/");
        });
    };

    const setLanguage = (v: {
        label: string,
        value: string,
    } | null) => {
        if (v === null) {
            return;
        }

        i18n.changeLanguage(v.value).then(() => {
            document.documentElement.lang = v.value;
            setAppLanguage(v.value);
        });
    };

    return <div className={classNames(styles.profile, "ps-sm-4 row pb-4 pb-xl-0")}>
        <div className={classNames(styles.profileInfo, "col-xl-5 col-12 col-lg-6")}>
            <h2 className={styles.title}>{t("Mon compte")}</h2>

            <div className={styles.profileContainer}>
                <p>{t("Nom")} : {user.last_name ?? <i>{t("non complété")}</i>}</p>
                <p>{t("Prénom")} : {user.first_name ?? <i>{t("non complété")}</i>}</p>
                <p>{t("Email")} : {user.email ?? <i>{t("non complété")}</i>}</p>
                <p>{t("Téléphone")} : {user.phone ?? <i>{t("non complété")}</i>}</p>

                <a className={classNames(styles.updateLink)} onClick={() => setEdit(true)}><img src={editIcon}
                                                                                                alt={""}/> Modifier
                    ses informations</a>
            </div>
        </div>

        <div className={"col-xl-6 mt-5 mt-lg-0 col-12 col-lg-5 offset-xl-1 offset-lg-1"}>
            <h2 className={styles.title} style={{
                paddingTop: 20
            }}>{t("Langue de l'application")}</h2>

            <div className={styles.profileLanguage}>
                <Select onChange={setLanguage} className={styles.languageSelect} classNamePrefix={"language-select"}
                        isSearchable={false} options={getAvailableLanguages()} value={{
                    value: appLanguage,
                    label: languages[appLanguage]
                }}/>
            </div>

            <button onClick={() => setPassword(true)}
                    className={styles.profileButton}>{t("Changer de mot de passe")}</button>
            <button onClick={handleLogOut} className={styles.profileButton}><LogoutLogo/> {t("Déconnexion")}</button>

            <ChangeModal
              modalIsOpen={showModal}
              handleCloseModal={() => setShowModal(false)}
            />

            <UserInformationModal display={edit} onClose={() => setEdit(false)}/>
            <UserPasswordModal display={password} onClose={() => setPassword(false)}/>
        </div>
    </div>;
}
