import style from "./cartSidebar.module.scss";
import useCart from "../../../../hooks/useCart";
import {ReactNode, useMemo, useState} from "react";
import {CatalogProduct, OfferItem} from "../../../../interfaces/Interfaces";
import {Picture} from "../../../../common/Picture/Picture";
import Api from "../../../../classes/Api";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import classNames from "classnames";

export default function CartSidebar(): JSX.Element | null {
    const {cart, removeItem} = useCart();

    const [opened, setOpened] = useState(false);
    const [removing, setRemoving] = useState<number | null>(null);

    const removeCartItem = (id: number) => {
        if (removing) {
            return;
        }

        setRemoving(id);
        removeItem(id).finally(() => {
            setRemoving(null);
        });
    };

    return useMemo((): JSX.Element | null => {
        const items: ReactNode[] = (cart === null || cart.items.length === 0) ? [] : ([...cart.items].filter((it: OfferItem | number) => typeof it === "object") as OfferItem[]).map(item => {
            if (typeof item.catalog_product !== "object") {
                return null;
            }

            const product = item.catalog_product as CatalogProduct;
            const options: string[] = [
                `x${item.quantity ?? 1}`,
            ];

            const start = moment(item.rent_start).startOf("day");
            const end = moment(item.rent_end).startOf("day");

            if (start.valueOf() === end.valueOf()) {
                options.push("le " + start.format("DD-MM-YYYY"));
            } else {
                options.push(`du ${moment(start).format("DD-MM-YYYY")} au ${moment(end).format("DD-MM-YYYY")}`);
            }

            return <li key={item.id} className={style.cartSidebarItem}>
                <div className={style.cartSidebarItemImage}>
                    <Picture src={Api.getUrl(`/assets/${product.picture}`, true)} alt={product.label}/>
                </div>

                <div className={style.cartSidebarItemContent}>
                    <p>{product.label}</p>
                    <p>{options.map((s, i) => <span key={i}>{s}</span>)}</p>
                </div>

                {removing === item.id && <div className={style.cartSidebarItemDeleting}>
                    <p>Suppression...</p>
                </div>}

                {removing === null &&
                    <a onClick={() => removeCartItem(item.id)} className={style.cartSidebarItemDelete}>
                        <FontAwesomeIcon icon={["fas", "trash-alt"]}/>
                    </a>}
            </li>;
        }).filter(n => n !== null);

        return <div className={classNames(style.cartSidebar, opened ? style.cartSidebarOpened : undefined)}>
            <h3 onClick={() => setOpened(!opened)}>Mon panier <span>{items.length}</span></h3>

            <a className={style.cartSidebarToggle} onClick={() => setOpened(!opened)}>
                <span/>
            </a>

            {items.length > 0 ? <ul>
                {items}
            </ul> : <div className={style.cartSidebarEmpty}>
                <FontAwesomeIcon icon={["fas", "box-open"]}/>
                <p>Votre panier est vide</p>
            </div>}

            <Link
              className={classNames(style.cartSidebarSubmit, items.length === 0 ? style.cartSidebarSubmitDisabled : "")}
              to={items.length === 0 ? "#" : "/cart"}>
                louer mon équipement <FontAwesomeIcon icon={["fas", "truck-fast"]}/>
            </Link>
        </div>;
    }, [cart, removing, opened]);
}