import styles from "./rentalTable.module.scss";
import searchLogo from "assets/loupe.svg";
import {SyntheticEvent, useEffect, useRef, useState} from "react";
import Api from "../../../classes/Api";
import {Rent, RentState} from "../../../interfaces/Interfaces";
import RentCard from "./components/RentCard/RentCard";
import {useDidMountEffect} from "../../../utils/Component";
import emptyImg from "assets/missingImage.svg";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RentModal from "./components/RentModal/RentModal";
import Loader from "../../../common/Loader/Loader";
import Tabs from "../../../common/Tabs/Tabs";
import TabPane from "../../../common/Tabs/TabPane";
import classNames from "classnames";

interface Tab {
    name: string,
    filter?: {},
    path?: string
}

interface Cache {
    [key: number]: Rent[]
}

export default function RentalTable(): JSX.Element {
    const {t} = useTranslation();

    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Rent[]>([]);
    const [detail, setDetail] = useState<Rent | null>(null);
    const [search, setSearch] = useState<string | null>(null);

    const cache = useRef<Cache>({});
    const searchCallback = useRef<number | null>(null);
    const searchInput = useRef<HTMLInputElement>(null);
    const searchRef = useRef<string | null>(null);

    const tabs: Tab[] = [
        {
            name: "Toutes"
        },
        {
            name: "A délivrer",
            path: "/waiting",
            filter: {
                state: RentState.TO_DELIVER
            }
        },
        {
            name: "Sur site",
            path: "/pending",
            filter: {
                _and: [
                    {
                        state: RentState.DELIVERED,
                    },
                    {
                        product: {
                            offer_item: {
                                rent_end: {
                                    _gte: "$$NOW"
                                }
                            }
                        }
                    }
                ]
            }
        },
        {
            name: "A récupérer",
            path: "/back",
            filter: {
                _and: [
                    {
                        state: RentState.DELIVERED,
                    },
                    {
                        product: {
                            offer_item: {
                                rent_end: {
                                    _lt: "$$NOW"
                                }
                            }
                        }
                    }
                ]
            }
        },
        {
            name: "Terminées",
            path: "/finished",
            filter: {
                state: RentState.FINISHED
            }
        }
    ];

    useEffect(() => {
        fetch();
    }, [tab]);

    useDidMountEffect(() => {
        if (cache.current.hasOwnProperty(tab)) {
            return;
        }

        if (search !== null) {
            return;
        }

        cache.current[tab] = data;
    }, [data]);

    useEffect(() => {
        searchRef.current = search;
        console.log(search);
    }, [search]);

    const fetch = () => {
        const isSearching = searchRef.current !== null;

        if (!isSearching && cache.current.hasOwnProperty(tab)) {
            setData(cache.current[tab]);
            return;
        }

        setLoading(true);
        Api.get<Rent[]>("/kamat/rents" + (searchRef.current === null ? (tabs[tab].path ?? "") : `?q=${searchRef.current}`)).then(setData).catch(() => setData([])).finally(() => setLoading(false));
    };

    const onSearch = (e: SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        const value = target.value;

        setSearch(value.trim().length >= 3 ? value : null);

        if (value.trim().length < 3) {
            setLoading(false);
            restoreDefaultData();
            return;
        }

        if (searchCallback.current !== null) {
            clearTimeout(searchCallback.current);
        }

        setLoading(true);
        searchCallback.current = setTimeout(fetch, 350) as unknown as number;
    };

    const clearSearch = () => {
        setSearch(null);
        searchRef.current = null;
        setLoading(false);

        if (searchInput.current !== null) {
            searchInput.current.value = "";
        }

        restoreDefaultData();
    };

    const restoreDefaultData = () => {
        if (cache.current.hasOwnProperty(tab)) {
            setData(cache.current[tab]);
        } else {
            setLoading(true);
            fetch();
        }
    };

    return <div>
        <Tabs active={0} navigation={{
            visible: search === null,
            extraContent: <div className={classNames("km-search", styles.search)} style={{
                marginBottom: 10
            }}>
                <img src={searchLogo} alt=""/>
                <input placeholder={"Chercher une location (par machine, site, référence...)"} onChange={onSearch}
                       ref={searchInput}/>
            </div>,
            customItems: search !== null ? [
                {
                    content: <>
                        Recherche {loading && <Loader type={"dark"} size={20} border={2} style={{
                        marginLeft: 7
                    }}/>}
                    </>,
                    active: true
                },
                {
                    content: <FontAwesomeIcon icon={["fas", "xmark"]}/>,
                    onClick: clearSearch,
                    className: styles.searchClear
                }
            ] : undefined
        }} onChange={k => setTab(parseInt("" + (k as string)))}>
            {tabs.map((tab, index) => <TabPane key={index} tab={index} label={tab.name}>
                {data.map((rent, i) => <RentCard rent={rent} key={i} onDetail={setDetail}/>)}
                {!loading && data.length === 0 && <div className={styles.empty}>
                    <img src={emptyImg} alt={""}/>
                    <div>
                        <p>{t(search !== null ? "Aucune location trouvée avec la recherche" : "Aucune location trouvée") + (search !== null ? ` ${search}` : "")}...</p>
                        <Link to={"/rentals"}>Commencer à louer <FontAwesomeIcon
                            icon={["fas", "basket-shopping"]}/></Link>
                    </div>
                </div>}
                {data.length === 0 && loading && <p>{t("Chargement")}...</p>}
            </TabPane>)}
        </Tabs>

        <RentModal rent={detail} onClose={() => setDetail(null)} onChange={r => {
            setDetail(r);
            cache.current = {};
            fetch();
        }}/>
    </div>;
}