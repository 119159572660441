import {createContext, Dispatch} from "react";
import {LoginState, User} from "../interfaces/Interfaces";

interface AuthContextProps {
    user: User,
    setUser: Dispatch<User>,
    loginState: LoginState,
    setLoginState: Dispatch<LoginState>,
    authenticate: (mail: string, password: string) => Promise<undefined>,
    isAccountComplete: boolean,
    getAccountFieldLabel: (field : keyof User) => string,
    tokenExpiration: number|null,
    refreshToken: () => Promise<undefined>,
    refreshUser: () => Promise<undefined>
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
export default AuthContext;