import React, {ReactNode, useContext, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import styles from "./offerForm.module.scss";
import {useTranslation} from "react-i18next";
import AuthContext from "../../context/AuthContext";
import {useHistory, useParams} from "react-router-dom";
import {
    EasytransacStatus,
    LoginState,
    Nullable,
    Offer,
    OfferItem,
    PartnerProduct,
    QuoteItem
} from "../../interfaces/Interfaces";
import moment from "moment";
import {toPrice} from "../../utils/NumberUtils";
import {notifyError, notifySuccess} from "../../common/Notification/notification";
import DatePicker from "../../common/DatePicker/DatePicker";
import editLogo from "./assets/edit.svg";
import trashLogo from "./assets/trash.svg";
import LinkedProductSelect from "./components/LinkedProductSelect/LinkedProductSelect";
import ArrayUtils from "../../utils/ArrayUtils";
import Tooltip from "rc-tooltip";
import Api from "../../classes/Api";
import {Picture} from "../../common/Picture/Picture";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OfferContext from "../../context/OfferContext";
import Button from "../../common/Button/Button";
import {Editor} from "@tinymce/tinymce-react";
import AppContext from "../../context/AppContext";
import {useDidMountEffect} from "../../utils/Component";
import {getItemsInformation} from "../../utils/OrderUtils";
import {isValidItem} from "../../utils/ObjectUtils";
import AccountNotCompleteAlert from "../../common/AccountNotCompleteAlert/AccountNotCompleteAlert";

interface iOfferPrice {
    subTotal: number,
    transport: number,
    insurance: number,
    fee: number,
    vat: number,
    ht: number,
    ttc: number,
}

interface Item {
    price: number,
    transport: number,
    insurance_rate: number,
    insurance_days: number,
    isDaily: boolean,
    product?: number
}

interface Items {
    [id: number]: Item
}

export default function OfferForm(): JSX.Element {
    const {t, i18n} = useTranslation();

    const history = useHistory();
    const {token} = useParams<{
        token?: string
    }>();

    const {user, loginState} = useContext(AuthContext);
    const {reload} = useContext(OfferContext);
    const {config} = useContext(AppContext);

    const [loadingOffer, setLoadingOffer] = useState(true);
    const {fee: userFee} = user;

    const [saving, setSaving] = useState(false);
    const [offer, setOffer] = useState<Offer | null>(null);
    const [validity, setValidity] = useState(moment().add(4, "days").add(10, "hours").startOf("hour"));
    const [deleted, setDeleted] = useState<number[]>([]);

    //prices
    const [items, setItems] = useState<Items>({});

    const [conditions, setConditions] = useState(user.rental_conditions ?? "");

    const [userToken, setUserToken] = useState<string>("");
    const [userProducts, setUserProducts] = useState<PartnerProduct[]>([]);

    const minDate = useRef(moment().add(2, "hours").startOf("hour"));
    const maxDate = useRef(moment().add(3, "month").startOf("hour"));
    const itemsRef = useRef<Items>({});

    const preview = useMemo(() => {
        return user.easytransac_status !== EasytransacStatus.FULLY_VALIDATE || user.verified !== true;
    }, [user]);

    useEffect(() => {
        reset();
    }, [offer]);

    useDidMountEffect(() => {
        itemsRef.current = items;
    }, [items]);

    useEffect(() => {
        setLoadingOffer(true);

        Api.post<{
            offer: Offer,
            machines: PartnerProduct[],
            user: {
                first_name: string,
                last_name: string,
                token: string,
                rental_conditions: string,
                id: string
            }
        }>("/kamat/offer/info", {
            token
        }).then(data => {
            setUserProducts(data.machines);

            setOffer(data.offer);
            const tmp: Items = {};

            data.offer.items.forEach(it => {
                if (typeof it !== "object") {
                    return;
                }

                tmp[it.id] = {
                    isDaily: true,
                    insurance_rate: 0,
                    insurance_days: 0,
                    price: 0,
                    transport: 0,
                };
            });

            itemsRef.current = tmp;
            setItems(tmp);

            setUserToken(loginState === LoginState.LOGGED ? user.offer_access_token + "." + user.id : data.user.token);
            setConditions(user.rental_conditions ?? data.user.rental_conditions ?? "");
        }).catch(() => {
            setOffer(null);
            setUserToken("");
            setConditions(user.rental_conditions ?? "");
            setUserProducts([]);
        }).finally(() => {
            setLoadingOffer(false);
        });
    }, [token]);

    useEffect(() => {
        if (!loadingOffer && offer === null) {
            history.push("/");
            notifyError(t("L'offre demandée est introuvable..."));
            return;
        }
    }, [loadingOffer, offer]);

    const reset = () => {
        setItems({});
        setDeleted([]);
        setConditions(user.rental_conditions ?? "");
    };

    const getItems = (): ReactNode[] => {
        if (offer === null) {
            return [];
        }

        return offer.items.map(item => {
            if (typeof item !== "object" || typeof item.catalog_product !== "object") {
                return null;
            }

            const info = getItemsInformation(item, true, t);

            if (info === null) {
                return null;
            }

            return <li key={item.id} className={classNames(styles.offerFormItem)}>
                <Picture src={Api.getUrl(`/assets/${item.catalog_product.picture}`, true)} className={styles.logo}/>

                <div>
                    <h4>{item.catalog_product.label}</h4>

                    <div className={styles.offerFormItemBody}>
                        <p>{info.date}</p>
                        {info.options.length > 0 && <p>
                            <b>Option{info.options.length > 1 ? "s" : ""} :</b> {info.options.join(", ").toLowerCase()}
                        </p>}
                        <p><FontAwesomeIcon
                          icon={["fas", "location-dot"]}/> {info.address}</p>
                    </div>

                    <div className={styles.offerFormItemProductLink}>
                        <p style={{
                            marginBottom: 6
                        }}><b>{t("Quel équipement de votre flotte Kamat souhaitez-vous proposer ?")}</b></p>
                        <LinkedProductSelect idProduct={item.catalog_product.id}
                                             products={loginState !== LoginState.LOGGED ? userProducts : undefined}
                                             onSelect={v => onSelectProduct(item.id, v)}/>
                    </div>
                </div>

                <ul className={classNames(styles.offerFormItemPrices)}>
                    <li>
                        <select className={styles.offerFormItemPricesSelect}
                                onChange={e => updateValue(e, item.id, "isDaily")}>
                            <option value="daily">Tarif journalier</option>
                            <option value="total">Sous-total</option>
                        </select>

                        <div className={classNames(styles.offerFormItemPricesInput)}>
                            <input type={"number"} step={0.01} defaultValue={items[item.id]?.price}
                                   placeholder={"0.00"}
                                   onBlur={e => updateValue(e, item.id, "price")}/>
                            <p>€</p>
                        </div>
                    </li>
                    <li>
                        <p>{t("Transport A/R")}</p>
                        <div className={styles.offerFormItemPricesInput}>
                            <input type={"number"} step={0.01} defaultValue={items[item.id]?.transport}
                                   placeholder={"0.00"}
                                   onBlur={e => updateValue(e, item.id, "transport")}/>
                            <p>€</p>
                        </div>
                    </li>
                    <li>
                        <p>{t("Assurance")}</p>
                        <div className={styles.offerFormItemPricesInsurance}>
                            <input type={"number"} step={0.01} defaultValue={items[item.id]?.insurance_rate}
                                   placeholder={"0"}
                                   onBlur={e => updateValue(e, item.id, "insurance_rate")}/>
                            <p>% sur</p>
                            <input type={"number"} step={1} defaultValue={items[item.id]?.insurance_days}
                                   placeholder={"0"}
                                   onBlur={e => updateValue(e, item.id, "insurance_days")}/>
                            <p>jour{(items[item.id]?.insurance_days ?? 0) > 1 ? "s" : ""}</p>
                        </div>
                    </li>
                </ul>

                {offer.items.length > 1 &&
                    <Tooltip overlay={t("Retirer cette machine de mon offre")} placement={"top"}>
                        <a onClick={() => setDeleted([
                            ...deleted, item.id
                        ])} className={styles.offerFormItemDelete}>
                            <img src={trashLogo} alt={""}/>
                        </a>
                    </Tooltip>}

                {deleted.indexOf(item.id) !== -1 && <div className={styles.offerFormItemDeleted}>
                    <p>{t("Cette machine ne fait pas partie de votre offre")}</p>
                    <a onClick={() => setDeleted(ArrayUtils.delete(deleted, e => e === item.id))}>{t("Annuler")}</a>
                </div>}
            </li>;
        });
    };

    const offerPrice = useMemo<iOfferPrice>(() => {
        let subTotal = 0;
        let insurance = 0;
        let transport = 0;

        ((offer?.items ?? []) as OfferItem[]).forEach(item => {
            if (!isValidItem(item)) {
                return;
            }

            const itemPrice = items[item.id];

            if (itemPrice === undefined || deleted.indexOf(item.id) !== -1) {
                return;
            }

            const info = getItemsInformation(item, true, t);

            if (info === null) {
                return;
            }

            let itemHt = itemPrice.price ?? 0;

            if (itemPrice.isDaily) {
                itemHt *= info.rentDuration.days;
            }

            subTotal += itemHt;
            transport += itemPrice.transport ?? 0;
            insurance += (itemHt * ((itemPrice.insurance_rate ?? 0) / 100)) * (itemPrice.insurance_days ?? 0);
        });

        const feeRate = userFee ?? config.default_fee ?? 0;
        const fee = subTotal * (feeRate / 100);
        const ht = subTotal + transport + insurance + fee;
        const vat = parseFloat((ht * (config.vat_rate / 100)).toFixed(2));

        return {
            subTotal,
            transport,
            insurance,
            fee,
            ht,
            vat,
            ttc: ht + vat
        } as iOfferPrice;
    }, [items, deleted]);

    const updateValue = (e: React.SyntheticEvent, id: number, key: keyof Item) => {
        const value = (e.target as HTMLInputElement).value;
        let validateValue: string | number | boolean = 0;

        if (key !== "product" && key !== "isDaily" && !isNaN(parseFloat("" + value))) {
            validateValue = parseFloat("" + value);

            if (validateValue < 0 || validateValue >= Math.pow(10, 9)) {
                validateValue = 0;
            } else if (key === "insurance_rate" && validateValue > 100) {
                validateValue = 100;
            }

            if (key === "insurance_days") {
                validateValue = parseInt("" + value);
            }
        }

        if (key === "isDaily") {
            validateValue = value === "daily";
        }

        if (value.trim().length === 0) {
            return;
        }

        setItems({
            ...items,
            [id]: {
                ...items[id],
                [key]: validateValue
            }
        });
    };

    const onSelectProduct = (id: number, product: PartnerProduct) => {
        let insuranceDays = 0;

        if (offer !== null) {
            const item = ((offer.items ?? []) as OfferItem[]).filter((it: OfferItem) => isValidItem(it) && it.id === id)[0];

            if (item !== undefined) {
                const info = getItemsInformation(item, true, t);

                if (info !== null) {
                    insuranceDays = info.rentDuration.days;
                }
            }
        }

        setItems({
            ...(itemsRef.current),
            [id]: {
                ...itemsRef.current[id],
                insurance_rate: parseFloat(product.insurance ?? 0),
                insurance_days: insuranceDays,
                price: parseFloat("" + (product.price_daily ?? 0)),
                isDaily: true,
                product: product.id
            }
        });
    };

    const send = () => {
        if (saving || offer === null) {
            return;
        }

        if (offerPrice.subTotal <= 0) {
            notifyError(t("Les prix indiqués sont incorrects."));
            return;
        }

        if (validity.valueOf() < minDate.current.valueOf()) {
            notifyError(t("La date de validité doit être supérieure à") + " " + minDate.current.format("DD-MM-YYYY HH[h]mm"));
            return;
        }

        const quoteItems: Nullable<Partial<QuoteItem>>[] = [];

        ((offer?.items ?? []) as OfferItem[]).forEach(item => {
            if (!isValidItem(item)) {
                return;
            }

            const itemPrice = items[item.id];

            if (itemPrice === undefined || deleted.indexOf(item.id) !== -1) {
                return;
            }

            const info = getItemsInformation(item, true, t);

            if (info === null) {
                return;
            }

            const price = itemPrice.isDaily ? itemPrice.price * info.rentDuration.days : itemPrice.price;
            const insurance = (price * (itemPrice.insurance_rate / 100)) * itemPrice.insurance_days;

            quoteItems.push({
                offer_item: item.id,
                price,
                price_transport: itemPrice.transport,
                price_insurance: insurance,
                insurance_rate: itemPrice.insurance_rate,
                insurance_covered_days: itemPrice.insurance_days,
                partner_product: itemPrice.product
            });
        });

        if (quoteItems.length === 0) {
            notifyError(t("Votre devis ne contient aucun équipement valide."));
            return;
        }

        setSaving(true);

        Api.post("/kamat/offer/place", {
            id: offer.id,
            token: userToken,
            params: {
                rental_conditions: conditions,
                expires_on: validity.toDate().toISOString(),
                items: quoteItems,
            }
        }).then(() => {
            notifySuccess(t("Offre envoyée avec succès !"));
            reload();
            history.push("/");
        }).catch(() => {
            notifyError("Une erreur est survenue lors de la création de l'offre...");
            setSaving(false);
        });
    };

    const getSubtitle = () => {
        if (offer === null || typeof offer.client !== "object") {
            return null;
        }

        let name: string;

        if (typeof offer.client.society_name === "string" && offer.client.society_name.trim().length > 0) {
            name = offer.client.society_name;
        } else {
            name = offer.client.last_name.toUpperCase() + " " + offer.client.first_name;
        }

        return <h2 className={styles.offerFormSubTitle}>{t("Demande spéciale Kamat de")} <span>{name}</span></h2>;
    };

    return <div className={styles.offerFormContainer}>
        <h1 className={styles.offerFormTitle}>{t("Nouveau devis")}</h1>
        {offer !== null && getSubtitle()}

        {loadingOffer && <p>{t("Chargement de l'offre")}...</p>}

        {!loadingOffer && offer !== null && <>
            <div className={styles.offerFormContent}>
                <div className={styles.offerFormBox}>
                    <ul className={classNames(styles.offerFormItems)}>
                        {getItems()}
                    </ul>
                </div>

                <ul className={classNames(styles.offerFormTotal)}>
                    <h2>{t("Mon prix spécial")}</h2>

                    <li>
                        <p>{t("Sous-total")}</p>
                        <p>{toPrice(offerPrice.subTotal)}</p>
                    </li>
                    <li>
                        <p>{t("Transport")}</p>
                        <p>{toPrice(offerPrice.transport)}</p>
                    </li>
                    <li>
                        <p>{t("Assurance")}</p>
                        <p>{toPrice(offerPrice.insurance)}</p>
                    </li>
                    <li>
                        <p>{t("Frais de dossier")}</p>
                        <p>{toPrice(offerPrice.fee)}</p>
                    </li>

                    <li>
                        <p>{t("TVA")} {config.vat_rate}%</p>
                        <p>{toPrice(offerPrice.vat)}</p>
                    </li>

                    <li className={classNames(styles.offerFormTotalHT)}>
                        <div>
                            <p>{t("Total HT")}</p>
                        </div>
                        <p>{toPrice(offerPrice.ht)}</p>
                    </li>
                    <li className={classNames(styles.offerFormTotalTTC)}>
                        <p>{t("soit")} {toPrice(offerPrice.ttc)} TTC</p>
                    </li>
                </ul>
            </div>

            <div className={classNames(styles.offerFormFooter, "row")}>
                {preview ? <AccountNotCompleteAlert style={{
                    maxWidth: 700,
                    margin: "0 auto"
                }} actionLabel={"faire une offre"}/> : <>
                    <h2>{t("Mes conditions de location")}</h2>

                    <div className={classNames(styles.offerFormConditions)}>
                        <p>{t("Indiquez ici vos conditions qui définissent votre prix et le bon déroulement de la location")}</p>
                        <Editor
                          initialValue={conditions}
                          onEditorChange={v => setConditions(v)}
                          value={conditions}
                          init={{
                              placeholder: "Conditions de locations...",
                              height: 250,
                              menubar: false,
                              language: i18n.language === "fr" ? "fr_FR" : undefined,
                              plugins: [
                                  "advlist", "autolink", "lists", "link", "image", "charmap"
                              ],
                              toolbar: "undo redo | bold italic underline backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist | " +
                                "removeformat",
                          }}
                        />
                    </div>

                    <div className={styles.offerFormConfig}>
                        <div className={classNames(styles.offerFormValidity)}>
                            <p>{"Fin de validité de l'offre"}</p>
                            <DatePicker canSelectTime value={validity} minDate={minDate.current}
                                        maxDate={maxDate.current} onChange={d => setValidity(moment(d))}/>
                            <img src={editLogo} alt={""}/>
                        </div>

                        <br/>
                        {deleted.length === offer.items.length && <p style={{
                            textAlign: "center",
                            color: "#c0392b"
                        }}><b>{t("Votre offre en contient aucune machine.")}</b></p>}

                        <Button disabled={saving || deleted.length === offer.items.length} onClick={send} size={"large"}
                                className={styles.offerFormOfferSubmitButton} faIcon={["fas", "paper-plane"]}
                                bold rounded>
                            {t(saving ? "Envoi en cours..." : "Envoyer mon offre")}
                        </Button>
                    </div>
                </>}
            </div>
        </>}
    </div>;
}